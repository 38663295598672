import React, { useState } from "react"
import { Input, Space, Form, Divider, Checkbox, Spin } from 'antd';
import IncrementFormatter from "../../formatters/IncrementFormatter";
export default function GiftCardPersonalize(props) {



    const GIFTCARD_MINIMUM = (parseInt(window.sitesettings.store_info.giftcard_minimum) < 1) ? 1 : parseInt(window.sitesettings.store_info.giftcard_minimum);



    const { cart_info = {}, emailed = false } = props;
    const { quantity = 1 } = cart_info;
    const { cart_notes = {} } = cart_info;
    const { giftcard_price = GIFTCARD_MINIMUM } = cart_notes;
    const { gift_card_info = {} } = cart_notes
    const { personalize = true, to = "", from = "", message = "", recipient_email = "" } = gift_card_info;
    const [personalized, setPersonalized] = useState(personalize);





    const togglePersonalized = (e) => {

        setPersonalized(!personalized);
        props.form.setFieldsValue({ "personalize": !personalized });

    }


    const validateEmail = (_, value) => {

        if (!value) {
            return Promise.resolve();
        }
        // Email regex pattern for basic validation
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!value || !value.match(emailPattern)) {
            return Promise.reject('Please enter a valid email address');
        }
        return Promise.resolve();
    };


    const displayPersonalize = () => {



        if (personalized) {

            return (<>

                <Form.Item name="to" initialValue={to} label="To:" >
                    <Input maxLength={50} placeholder="To name..." type="text" />
                </Form.Item>

                {((emailed || recipient_email) &&
                    <Form.Item rules={[
                        {
                            required: true,
                            message: 'To Email is required.',
                        },
                        {
                            validator: validateEmail,
                        },
                    ]} name="recipient_email" initialValue={recipient_email} label="To Email:" >
                        <Input maxLength={50} placeholder="To name..." />
                    </Form.Item>
                )}


                <Form.Item name="from" initialValue={from} label="From:">
                    <Input maxLength={50} placeholder="From name..." type="text" />
                </Form.Item>
                <Form.Item name="message" initialValue={message} label={(<span>Message: <small>(max 100 characters)</small></span>)}>
                    <Input.TextArea showCount maxLength={100} placeholder="Message..." type="text" />
                </Form.Item>



            </>)
        } else {

            return (<></>);
        }


    }

    const displayForm = () => {

        // props.form.setFieldsValue({"quantity" : quantity});
        return (<>
            <div aria-hidden><Divider /></div>
            <Form onFinish={(e) => props.callback(e)} form={props.form} layout="vertical" >

                <Space size="large">
                    
                    <Spin spinning={(emailed || recipient_email)} indicator={<></>}>
                        <Form.Item style={{ "width": "90px" }} name="quantity" value={quantity} initialValue={quantity} label="Quantity">
                            <IncrementFormatter disabled={(emailed || recipient_email)} label={"Gift Card Quantity"} defaultValue={quantity} />
                        </Form.Item>
                    </Spin>
                    <Form.Item style={{ "width": "90px" }} name="amount" label="Amount ($)" initialValue={giftcard_price}>
                        {/* <InputNumber max={999} formatter={v=>parseInt(v).toFixed(2)} min={GIFTCARD_MINIMUM} type="number" /> */}
                        {/* <InputNumber max={999}  min={GIFTCARD_MINIMUM} type="number" /> */}
                        <IncrementFormatter label={"Gift Card Dollar Value"} giftcard min={parseInt(GIFTCARD_MINIMUM)} defaultValue={giftcard_price} />
                    </Form.Item>
                    <Form.Item style={{ "width": "80px" }} label="Personalize?">
                        <Checkbox aria-label="Personalize options" disabled={(emailed || recipient_email)} checked={(emailed || recipient_email) ? true : personalized} onClick={(e) => togglePersonalized(e)} />
                    </Form.Item>
                    <Form.Item name="personalize" initialValue={personalized} >
                        <Input type="hidden" />
                    </Form.Item>
                </Space>



                {displayPersonalize()}

            </Form>
        </>
        )

    }

    return displayForm();

}