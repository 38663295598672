import { Space } from "antd";
import React from "react";
import { Link } from "react-router-dom";

import { useSession } from "../../../util/Session"
import conditional from "../../utilities/conditional";
import Audiobook from "../item/Audiobook";
import ItemSummary from "../item/ItemSummary";

function List(props) {
  const { data, audiobook, routable = "", view="summary", displayPicker = false, rows = "false" } = props;
  const [session] = useSession();

  const displayStatus = () => {
    if (props.data) {
      return displayList();
    } else {
      return displayEmpty();
    }
  }

  const displayEmpty = () => {
    return (<div>Loading...</div>)
  }

  const displayList = () => {
    return (<>
      <conditional.true value={(audiobook)}>
          <Space style={{ "justifyContent": "center", "cursor": "pointer" }} align="center" direction="vertical">
          <Audiobook eisbn={audiobook}>
            <div style={{"textAlign" : "center"}}>
              <img alt="Libro FM" style={{ "width": "150px", "padding": "10px", "textAlign" : "center" }} src="https://cdn1.bookmanager.com/i/librofm_logo.png" />
            </div>
            <div style={{ "padding": "10px", "textAlign": "center" }}>
              <h3>Digital Audiobook</h3>
              <p style={{ "width": "70%", "margin": "0 auto" }}>Available as a digial download from our audiobook partner, Libro.fm</p>
            </div>
            <div className="shim"></div>
            </Audiobook>
          </Space>
      
      </conditional.true>
      {data.map((item) => {
        return (
          <Link 
            key={item.eisbn} 
            className="nav" 
            to={{ "pathname": "/item/" + item.eisbn + routable, "data": item }}>
          {(view==="summary") 
            ? <ItemSummary.ListItem popup={props.popup} show_price={(window.sitesettings.guest_show_price || session.logged_in)} data={item} /> 
            : <ItemSummary.Card show_price={(window.sitesettings.guest_show_price || session.logged_in)} data={item} />}
            
            </Link>
        )
      })
      }
      </>)
  }

  if(view === "card"){
    return (
      <div className="card-wrapper">
        {displayStatus()}
      </div>
    )
  }

  return (
    <div className={(displayPicker && rows === "true") ? "small-wrapper" : "wrapper"}>
      {displayStatus()}
    </div>
  )
}

export default List;
