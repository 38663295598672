import React, {useState} from "react";
import { Form, Input, Button, Space, Divider} from 'antd';
import {apiCall} from "../../utilities/Api";
import { ExclamationCircleFilled } from '@ant-design/icons';
import {useSession} from "../../../util/Session";

export default function AccountSave(props){

    //  Requires Email & Callback Function

    const {callback, title="", close, form} = props; 
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("")
    const [context, setContext] = useSession();
   
    const onCheckPassword = (_status, _result) =>{

        if(!_status){
          setError(true);
          setMessage(_result.error);
        } else {
            // reset context 
           setContext({...context, "user" : _result.user})
           close(); 
           callback();
        }

    }
    
    const checkPassword = (_form) =>{
    
        form["password"] = _form.password;

        if(!form["is_institution"]){
            form["company"] = "";
        }

        delete form["is_institution"];

        apiCall("account/modify", form, onCheckPassword);
    }

    const displayForm = () =>{
        
        return (
            <div style={{"maxWidth" : "300px"}} >
                <strong>{title}</strong>
                <div aria-hidden><Divider /></div>
                <p>Please re-enter your password to save changes.</p>
                <Form layout="vertical" onFinish={(form) => checkPassword(form)} >
                    <Form.Item rules={[{ message : "Password required.", required: true }]} name="password" label="Password">
                        <Input.Password />
                    </Form.Item>
                    {(error && <p style={{ "paddingBottom": "15px", "color": "#ff4d4f", "textAlign" : "center" }} className="error"><ExclamationCircleFilled /> {message}</p>)}
                    <Space><Button type="primary" htmlType="submit">Save Changes</Button><Button onClick={() => close()}>Cancel</Button></Space>
                </Form>
            </div>
            );
    }


    return displayForm(); 
    

}