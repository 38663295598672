import { Button } from "antd";
import React from "react";
import { getColor } from "../util/CC";

export default function CustomButton(props) {

    const template = window.sitesettings.template_info;
    const { content = {} } = props;

    const {
        location = "main",
        component = "blank_space",
        container = true,
        data = {}
    } = content;

    const {
        label = "Button",
        link = "",
        type = 0,
        tab = true,
        align = "center",
        padding_x = 0,
        padding_y = 0,
        button_block = false,
        background_colour = 0,
    } = data;

    const linkOut = () => {
        if (link) {
            window.open(link, (tab) ? "_blank" : "_self");
        }
    }

    const drawRender = () => {

        let _style = {
            display : "block",
            textAlign : align,
            padding : padding_y + "px " + padding_x + "px",
            background: getColor(background_colour, template)
        }

        return (
            <div className={(container) ? "preview-container" : ""}>
                <div style={_style}>
                <Button block={button_block} onClick={() => linkOut()} className={"bm-btn-" + type} >{label}</Button>
                </div>
            </div>
        )

    }

    return drawRender();
}