import React, { useState, useEffect } from "react";
import { Button, Divider, Spin, Badge, Result, Empty, Space, Select } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { apiCall } from "../../utilities/Api";
import { hasProperty } from "../../../util/Utils";
import ItemSummary from "../item/ItemSummary";
import { EditOutlined, GiftOutlined, ShareAltOutlined } from '@ant-design/icons';
import GiftRegistryCreate from "./GiftRegistryCreate";
import conditional from "../../utilities/conditional";
import moment from "moment";
export default function GiftRegistryDetails() {

    const { item_id } = useParams();

    const [results, setResults] = useState({ items: [], owner: { is_you: false, name: "" } });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [filter, setFilter] = useState("all");
    const [sort, setSort] = useState("title");
    const history = useHistory();


    const drawPurchased = (_requested = 0, _purchased = 0) => {
        return _purchased.toString() + " of " + _requested.toString() + " products purchased";
    }


    const fetchRegistry = () => {
        setLoading(true);
        apiCall("giftregistry/get", { gift_registry_id: item_id, show_items: true }, (_status, _result) => {
            if (_status) {
                setResults(_result);
            } else {
                setError(_result.error);
            }
            setLoading(false)
        })
    }

    useEffect(fetchRegistry, []);

    const drawItems = () => {
        return results.items.sort((a, b) => {
            if (!hasProperty(a, "title.title") || !hasProperty(a, "title.authors")) {
                return false;
            }
            if (sort === "title") {
                return a.title.title.localeCompare(b.title.title);
            } else {
                return a.title.authors[0].name.localeCompare(b.title.authors[0].name);
            }

        }).filter(item => {

            switch (filter) {
                case "purchased":
                    return (item.qty_purchased === item.qty_requested);
                case "not_purchased":
                    return (item.qty_purchased !== item.qty_requested);
                default:
                    return true;
            }


        }).map((item, index) => {

            let _soldout = ((item.qty_purchased) && item.qty_purchased >= item.qty_requested);

            return (
                <Badge.Ribbon color={(_soldout) ? "green" : "volcano"} key={index} placement="start" text={(_soldout) ? <small>{item.qty_purchased} of {item.qty_requested} Purchased</small> : <small>{item.qty_purchased} of {item.qty_requested} Purchased</small>}>
                    <div onClick={() => history.push("/item/" + item.title.eisbn + "?registry=" + item.gift_registry_id)} style={{ "opacity": (_soldout) ? "0.3" : "1", "cursor": "pointer", "pointerEvents": (_soldout) ? "none" : "all" }} className="greyhover" >
                        <ItemSummary.Gift gift_registry_id={item.gift_registry_id} data={item.title} />
                    </div>
                </Badge.Ribbon>
            );
        })
    }

    const update = (_gift_registry) => {
        setResults(_gift_registry);
    }

    if (!window.sitesettings.gift_registry_enabled) {
        return (<></>)
    }

    return (
        <>

            <div className="container">
                <conditional.true value={(!loading && !error)}>
                    <div style={{ "float": "right" }}>
                        <Space>
                            <Button onClick={() => history.push("/gift-registry/")}>Go back</Button>
                            <GiftRegistryCreate share data={results}>
                                <Button><ShareAltOutlined /> Share</Button>
                            </GiftRegistryCreate>
                            {(results.owner.is_you) && <GiftRegistryCreate updateRow={update} edit={true} data={results}>
                                <Button><EditOutlined /> Edit</Button>
                            </GiftRegistryCreate>}
                        </Space>
                    </div>
                    <h2>{results.title} <span>{results.occasion} gift registry created by: <em>{results.owner.name}</em></span>
                        <div style={{ "marginTop": "-10px" }}><span><small>{drawPurchased(results.total_requested, results.total_purchased)}</small></span></div>

                        <conditional.true value={(results.purchase_deadline)}>
                            <div style={{ "fontSize": "15px", "fontWeight": "normal" }}><small>Gift Registry creator has asked for items off this list to be purchased before {moment(results.purchase_deadline * 1000).format("MMM Do, YYYY")} end of day.</small></div>
                        </conditional.true>
                        <conditional.true value={(results.shipping_encouraged)}>
                            <div style={{ "fontSize": "15px", "fontWeight": "normal" }}><small>Gift Registry creator has asked for items off this list to be shipped. The store will handle the shipping, but you will pay for any applicable shipping charges</small></div>
                        </conditional.true>
                    </h2>



                    <div style={{ "float": "right" }}>
                        <Space>
                            <div>
                                <div><small>Filter:</small></div>
                                <Select onChange={(e) => setFilter(e)} size="small" defaultValue="all" style={{ width: 140 }}>
                                    <Select.Option value="all"><small>Show All</small></Select.Option>
                                    <Select.Option value="purchased"><small>Show purchased</small></Select.Option>
                                    <Select.Option value="not_purchased"><small>Show not purchased</small></Select.Option>
                                </Select>
                            </div>
                            <div>
                                <div><small>Sort by:</small></div>
                                <Select onChange={(e) => setSort(e)} size="small" defaultValue="title" style={{ width: 140 }}>
                                    <Select.Option value="title"><small>Title</small></Select.Option>
                                    <Select.Option value="author"><small>Author</small></Select.Option>
                                </Select>
                            </div>
                        </Space>
                    </div>

                    <p>{results.description}</p>
                    <br clear="all" />

                    <conditional.true value={(window.sitesettings.store_info.giftcards_enabled)} >
                        <div aria-hidden><Divider style={{ "marginBottom": "10px" }} /></div>
                        <em>Having a tough time making a choice? <a onClick={() => history.push("/gift-cards")} style={{ "color": "#069", "fontWeight": "600" }}>Gift cards can be purchased here!</a></em>
                        <div aria-hidden><Divider style={{ "marginTop": "10px" }} /></div>
                    </conditional.true>
                    <conditional.true value={(!window.sitesettings.store_info.giftcards_enabled)} >
                        <div aria-hidden><Divider /></div>
                    </conditional.true>

                </conditional.true>
                <conditional.true value={(!loading && error)}>
                    <Result
                        icon={<Empty aria-hidden description="" />}
                        status="warning"
                        title="Gift registry not found."
                        subTitle={<>{error}</>} />
                </conditional.true>

                <Spin spinning={loading}>
                    <div style={{ "display": "grid", "gridGap": "20px 20px", "gridTemplateColumns": "repeat(auto-fit, minmax(389px, .34fr))" }}>
                        {drawItems()}
                    </div>
                </Spin>

            </div>
        </>
    )

}