import React, { useState, useEffect } from "react";
import { Modal, Tooltip } from 'antd';
import GridDisplay from "../../../shared/GridDisplay";
import { useLocation } from "react-router-dom";
import { CanadaIcon } from "../../../util/Icons";


export default function Authors(props) {

    const data = props.data;
    const loc = useLocation();
    const [modalVisible, setModalVisible] = useState(false);
    const [authorName, setAuthorName] = useState("");
    const close = () => { setModalVisible(false) }

    const closeIfVisible = () => {
        if (modalVisible) {
            close();
        }
    }

    useEffect(closeIfVisible, [loc])

    

    const parceAuthors = (_authors) => {
        if (!_authors) { return "" }
        return _authors.map((_author, index) => {

            let flag = (_author.canadian && window.sitesettings.store_info.country === "Canada");

            return (<div style={{ "cursor": "pointer", "color": "#069", "display": "inline-block" }} onClick={(e) => searchAuthor(_author.name)} key={index}>{_author.name}{(flag && <span style={{ "paddingLeft": "2px" }}><CanadaIcon /></span>)}{(index !== _authors.length - 1) ? ", " : ""} &nbsp;</div>);
        });
    }

    const searchAuthor = (_name) => {
        setAuthorName(_name)
        setModalVisible(true);
    }


    return (<>
    
        <strong> <Tooltip mouseEnterDelay={0.5} title={"View more titles by " + data.authors.map(item => item.name).join(", ") + "."}>{parceAuthors(data.authors)}</Tooltip></strong>
        <div className="shim"></div><div className="shim"></div>
        
        <Modal wrapClassName="siteModal" width="90%" title={authorName} open={modalVisible} onCancel={close} destroyOnClose closable={true} footer={null}>

            
            <GridDisplay browse_link={"/browse/filter/k/author/t/" + authorName} popup={true} show_count={false} method="title/getAuthorItems" wrap={false} args={{ "author": authorName }} />
        </Modal>

    </>
    );

}