import { Form, Select } from "antd";
import React from "react";

export default function SelectBoxField(props) {

    const { data = {}, form, contact = {} } = props

    const {
        id = "",
        label = "Select",
        type = "select",
        description = "",
        options = [{ label: "" }]
    } = data;

    let defaultValue = (contact.hasOwnProperty([id])) ? contact[id] : options[0].label;

    return (
        <Form.Item initialValue={defaultValue} extra={description} label={label} name={id} >
            <Select dropdownMatchSelectWidth >
                {options.map((item, index) => {
                    return (<Select.Option key={index} value={item.label}>{item.label}</Select.Option>)
                })}
            </Select>
        </Form.Item>
    )
}