import React, { useState, useEffect } from "react";
import { Card, Row, Col, Layout, Divider, Alert, Button, Modal, Typography } from 'antd';
import { CartSummary } from "./Cart";
import { useSession } from "../../../util/Session";
import { apiCall } from "../../utilities/Api";
import { getPaymentButton } from "./PaymentChoices";
import { useHistory } from "react-router-dom";
import GiftCardPurchase from "../giftcards/GiftCardPurchase";
import conditional from "../../utilities/conditional";
import Html from "../../utilities/Html";
import AccountLoginForm from "../account/AccountLoginForm";

export default function Payment(props) {

    const history = useHistory();
    const [session, setSession] = useSession();
    const { saved, setSaved } = props;
    const [errorMSG, setErrorMSG] = useState("")
    const [loading, setLoading] = useState(true);
    const [choice, setChoice] = useState("")
    const [paymentOptions, setPaymentOptions] = useState({})
    const [loginVisible, setLoginVisible] = useState(false);


    const loadPaypalSDK = () => {
        if (window.sitesettings.checkout_info.paypal_id) {
            const script = document.createElement("script");
            script.src = "https://www.paypal.com/sdk/js?client-id=" + window.sitesettings.checkout_info.paypal_id + "&currency=" + window.sitesettings.checkout_info.currency;
            script.async = true;
            document.body.appendChild(script);
        }
    }

    useEffect(loadPaypalSDK, []);


    let mychoice = "";
    const onGetPaymentChoices = (_status, _result) => {

        window.payment = "";
        if (_status) {
            setSaved({ ...saved, "payment": choice });
            setPaymentOptions(_result);
            setLoading(false);
        }
    }

    const getPaymentChoices = () => {
        apiCall("checkout/getPaymentChoices", {}, onGetPaymentChoices)
    }

    useEffect(getPaymentChoices, [])

    const onSetPaymentChoice = (_status, _result) => {
        if (_status) {
            window.checksum = _result.checksum;
            setSession({ ...session, "cart": _result });

            switch (mychoice) {
                case "credit_card":
                    history.push("/checkout/credit_card");
                    break;

                // case "clearent":
                //     history.push("/checkout/clearent");
                //     break;

                // case "global":
                //     history.push("/checkout/global");
                //     break;

                case "chase":
                    history.push("/checkout/chase");
                    break;

                default:
                    history.push("/checkout/review");
                    break;
            }

        } else {
            setErrorMSG("There was an error.")
        }
    }

    const setPaymentChoice = (_choice) => {
        setErrorMSG("")
        setSaved({ ...saved, "payment": _choice });

        switch (_choice) {

            case "paypal":
                window.payment = "paypal";
                break;

            case "clearent":
                window.payment = "clearent";
                break;

            case "global":
                window.payment = "global";
                break;

            default:
                window.payment = "";
                break;
        }


        apiCall("checkout/setPaymentChoice", { payment_choice: _choice }, onSetPaymentChoice);
    }

    const setCurrentChoice = (_item) => {

        if (_item === "no_global") {
            setLoginVisible(true);
            return;
        }
        setChoice(_item);
        mychoice = _item;
        setTimeout(setPaymentChoice(_item), 1000);
    }

    const displayChoices = () => {
        if (saved && saved.delivery) {

            let type = (saved.delivery === "local_delivery" || saved.delivery === "custom_ship") ? "ship" : saved.delivery;
            type = (saved.delivery === "custom_pickup") ? "pickup" : type;

            return paymentOptions[type].map((_item, _index) => {
                return (
                    <div style={{ "opacity": (session.cart.summary.total === "0.00") ? "0.5" : "1" }} key={_index}>
                        <span onClick={() => setCurrentChoice(_item)}>{getPaymentButton(_item, _index, (choice === _item) ? "selected" : "default", (session.cart.summary.total === "0.00"))}</span>
                        <div className="shim"></div>
                    </div>)
            })
        }
    }

    const display = () => {
        return (
            <>
                <Modal footer={null} onCancel={() => setLoginVisible(false)} width={350} open={loginVisible}>
                    <Typography.Title level={5}>Login Required</Typography.Title>
                    <Divider dashed style={{"margin" : "15px 0px"}} />
                    <p>Please log in to use your credit card.</p>
                    <AccountLoginForm callback={() => {setLoginVisible(false); history.push("/checkout/cart")}} show_create={true} />
                    <Divider dashed style={{"margin" : "15px 0px"}} />
                    <div className="float-flex">
                        <div>&nbsp;</div>
                        <Button onClick={() => setLoginVisible(false)}>Cancel</Button>
                    </div>
                </Modal>
                <Layout className="responsiveSider">
                    <Layout.Content style={{ "width": "auto" }}>
                        <Card className="hideCard">
                            <Row gutter={20}>
                                <Col xs={24} lg={14}>

                                    <conditional.true value={(window.sitesettings.store_info.giftcards_enabled)}>
                                        <GiftCardPurchase />
                                    </conditional.true>

                                    <conditional.true value={(!window.sitesettings.store_info.giftcards_enabled)}>
                                        <div aria-hidden><Divider orientation="left">Notice</Divider></div>
                                        <div style={{ "padding": "30px", "border": "1px dashed #ddd" }}>
                                            <Html className="" html={window.sitesettings.checkout_info.checkout_message} />
                                        </div>
                                    </conditional.true>

                                </Col>
                                <Col xs={24} lg={10}>
                                    <div aria-hidden><Divider orientation="left" >Payment Method</Divider></div>

                                    <conditional.true value={(session.cart.summary.total === "0.00")}>
                                        <span onClick={() => setCurrentChoice("not_required")}>
                                            <Button style={{ "height": "50px" }} block><strong style={{ "fontWeight": "bold", "color": "#1f1f1f" }} >&nbsp;  Continue (it's free!)  </strong    ></Button>
                                        </span>
                                    </conditional.true>

                                    <div style={{ "opacity": (session.cart.summary.total === "0.00") ? "0.5" : "1" }} key={"giftcard"}>
                                        <div className="shim"></div>
                                    </div>

                                    {displayChoices()}

                                    <conditional.true value={(session.cart.summary.is_shipping_tbd)}>
                                        <Card>
                                            <strong>Heads up!</strong>
                                            <p>Shipping charges still need to be calculated for your order. We will contact you shortly to confirm the cost to ship before finalizing any additional payments.</p>
                                        </Card>
                                    </conditional.true>
                                </Col>
                            </Row>
                        </Card>
                    </Layout.Content>
                    <Layout.Sider width="none" className="responsiveSummary" style={{ "paddingLeft": "20px" }} theme="light">
                        <CartSummary>
                            <br />
                            {(errorMSG &&
                                <><Alert className="msg" description={<><span style={{ "textAlign": "center", "width": "100%", "display": "block" }}>{errorMSG}</span></>} type="error" /><br /></>)}
                        </CartSummary>
                    </Layout.Sider>
                </Layout>
            </>
        )
    }

    const displayStatus = () => {
        if (loading) return (<></>);
        return display();
    }

    return displayStatus();


}