import React, {useState} from "react";
import { Form, Input, Button, Space, Divider} from 'antd';
import {apiCall} from "../../utilities/Api";
import { ExclamationCircleFilled } from '@ant-design/icons';
import {useSession} from "../../../util/Session";

export default function AccountDelete(props){

    //  Requires Email & Callback Function

    const {callback, email_address, title="", close} = props; 
    // Wrap button and pass Callback function

    const [error, setError] = useState(false);
    const [message, setMessage] = useState("")
    const [, setContext] = useSession(); 
   
    const onCheckPassword = (_status, _result) =>{

       
        if(!_status){
          setError(true);
          setMessage(_result.error);
        } else {
            setContext(_result);
            callback(); 
        }
       
        
    }
    
    const checkPassword = (_form) =>{
        
        apiCall("account/delete", {email_address : email_address, password : _form.password}, onCheckPassword);
    }

    const displayForm = () =>{

        return (
            <div style={{"maxWidth" : "300px"}} >
                <strong>{title}</strong>
                <div aria-hidden><Divider /></div>
                <p>This action CANNOT be undone.<br /><br />Some of your information will still exist in the form of transactions made. However, deleting an account here will remove it, thus rendering it inaccessible.</p>
                <Form layout="vertical" onFinish={(form) => checkPassword(form)} >
                    <Form.Item rules={[{ message : "Password required.", required: true }]} name="password" label="Password">
                        <Input.Password />
                    </Form.Item>
                    {(error && <p style={{ "paddingBottom": "15px", "color": "#ff4d4f", "textAlign" : "center" }} className="error"><ExclamationCircleFilled /> {message}</p>)}
                    <Space><Button type="danger" htmlType="submit">Delete Account</Button><Button onClick={() => close()}>Cancel</Button></Space>
                </Form>
            </div>
            );
    }


    return displayForm(); 
    

}