import { Button, Divider, Space, Form, Modal, Input } from "antd";
import { FileTextOutlined } from '@ant-design/icons';
import React from "react";
import { useState } from "react";
import { apiCall } from "../../utilities/Api";
import { useHistory } from "react-router";

export default function RequestQuote() {

    const history = useHistory();
    const [modalVisible, setModalVisible] = useState(false);

    const onRequestQuote = (_status, _result) => {
        if (_status) {
            let order_number = _result.order_number;
            history.push({ "pathname": "/account/order/" + order_number, "state": { "data": _result }, "search": "success" });
        }
    }

    const requestQuote = (_f) => {
        apiCall("checkout/requestQuote", _f, onRequestQuote)
    }

    const drawModal = () => {
        return (
            <>
                <Modal onCancel={() => setModalVisible(false)} open={modalVisible} centered destroyOnClose footer={null}>
                    <h3>Request a school / bulk order quote</h3>
                    <div aria-hidden><Divider /></div>
                    <p>
                        This option is for those that would like a quote on product pricing, availability, and/or shipping before an order is submitted. We will review your request first, then contact you with more details before processing your order.
                    </p>
                    <p>
                        Use the box below to briefly explain anything particular about your order, and if you would like your quote to include shipping or if you will be picking your order up.
                    </p>
                    <Form onFinish={(_f) => requestQuote(_f)}>
                        <Form.Item name="message" rules={[{ required: true, message: 'Please enter a message.' }]}>
                            <Input.TextArea aria-label="Message" rows={6}>
                            </Input.TextArea>
                        </Form.Item>
                        <em>Your cart will be cleared upon submitting your quote.</em>
                        <div className="shim"></div><div className="shim"></div><div className="shim"></div><div className="shim"></div>
                        <div style={{ "float": "right" }}>
                            <Space>
                                <Button onClick={() => setModalVisible(false)}>Cancel</Button>
                                <Button htmlType="submit" type="primary">Submit quote</Button>
                            </Space>
                        </div>
                    </Form>
                    <br clear="all" />
                </Modal>
            </>
        )
    }
    return (
        <>
            {drawModal()}
            <div className="shim"></div><div className="shim"></div>
            <Button style={{ "height": "50px", "lineHeight": "16px" }} onClick={() => setModalVisible(true)} block>Request a school quote <br />(or a bulk order quote)</Button>
        </>
    );
}