import { Divider, Space, Button, Row, Col, Spin } from "antd";
import React, { useState, useEffect, useCallback, useRef } from "react";
import moment from "moment";
import EventUI from "./helpers/EventUI";
import { getColor } from "../util/CC";
import { apiCall } from "../components/utilities/Api";
import EventViewMore from "../components/components/events/EventViewMore";
import EventRSVP from "../components/components/events/EventRSVP";
import { useHistory } from "react-router-dom";
import EventSocial from "../components/components/events/EventSocial";

export default function EventList(props) {

    const template = window.sitesettings.template_info;
    const history = useHistory();
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const observer = useRef();

    const lastRowRef = useCallback(
        (node) => {
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    setPage((prevPage) => prevPage + 1);
                }
            });
            if (node) observer.current.observe(node);
        },
        []
    );

    const { content = {} } = props;

    const {
        location = "main",
        component = "event_list",
        container = true,
        injected_data = {},
        data = {}
    } = content;

    // Component specific
    const {
        align = "center",
        past_events = false,
        categories = [],
        container_width = 1248,
        constrain_container = false,
        width = 200,
        background_colour = 0,
        padding_x = 20,
        padding_y = 20,
        title_preset = 0,
        description_preset = 0,
        date_preset = 0,
        button_type = 0,
        button_label = "Buy Books",
        button_align = "left",
        link_id = 0,

    } = data;


    const fetchEvents = () => {
        setLoading(true);

        if (window.sitesettings.using_events_v2) {

            let _limit = 20;
            let _page = page;
            let _offset = _page * _limit;

            let obj = {
                limit: _limit,
                offset: _offset
            }

            if (past_events) {
                obj.descending = true;
            } else {
                obj.start_date = moment().format("YYYYMMDD")
            }

            if (categories.length > 0) {
                obj.categories = JSON.stringify([...categories].map(item => item.id));
            }

            apiCall("event/v2/list", obj, (_status, _result) => {
                if (_status) {
                    setEvents((prevRows) => [...prevRows, ..._result.rows]);
                }
                setLoading(false)
            })
        } else {

            apiCall("event/getList", { from: moment().format("YYYYMMDD") }, (_status, _result) => {
                if (_status) {
                    setEvents(_result.rows);
                }
                setLoading(false);
            })
        }
    }
    useEffect(fetchEvents, [page, past_events]);


    const linkOut = (_id) => {
        history.push("/events/" + _id)
    }

    const drawButtons = (eventData) => {

        // Buy Tickets
        // Buy Books
        // RSVP
        const {
            info = {},
            books = [],
            tickets = [],
            ticket_label = "",
            rsvp_label = "",
            attendance = false
        } = eventData;
        const { book = [], ticket = [] } = info;

        let _tickets = ticket;
        let _tickets_label = "Tickets"
        let _books = book;
        let _books_label = button_label;
        let _attendance = attendance;
        let _rsvp_label = "";

        // New Events
        if (window.sitesettings.using_events_v2) {
            _tickets = tickets;
            _tickets_label = (ticket_label) ? ticket_label : "Tickets";
            _books = books;
            _rsvp_label = (rsvp_label) ? rsvp_label : "RSVP";
        }

        return (
            <>
                <div className="shim" /><div className="shim" />
                <div style={{ "textAlign": button_align }}>
                    <Space wrap>
                        {((_tickets.length > 0) && <EventViewMore event_id={eventData.id} type="ticket" data={_tickets} cc_event><Button className={"bm-btn-" + button_type}>{_tickets_label}</Button></EventViewMore>)}
                        {((_books.length > 0) && <EventViewMore event_id={eventData.id} type="book" data={_books} cc_event><Button className={"bm-btn-" + button_type}>{_books_label}</Button></EventViewMore>)}
                        {((_attendance === "request") && <EventRSVP event={eventData}><Button className={"bm-btn-" + button_type}>{_rsvp_label}</Button></EventRSVP>)}
                    </Space>
                </div>
            </>
        )
    }

    const drawImages = (eventData) => {

        const {
            id = "",
            info = {},
            image_url = "",
            title = "",
            books = []
        } = eventData;
        const { name = "", img_path = "", book = [] } = info;

        let _books = book;
        let _image = img_path;
        let _title = name;

        if (window.sitesettings.using_events_v2) {
            _books = books;
            _image = image_url;
            _title = title;
        }

        return (
            <div style={{ width: '100%', textAlign: 'center' }}>
                <div onClick={() => linkOut(id)} style={{ width: '100%' }}>
                    <img className="bm-img-link" onError={i => i.target.style.display = 'none'} src={_image} alt={_title} style={{ width: '100%', height: 'auto' }} />
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                    {(_books.slice(0, 2).map(item => {
                        return (
                            <div>
                                <EventViewMore event_id={eventData.id} type="book" data={_books} cc_event><img className="bm-img-link" onError={(e) => e.target.src = "https://bookmanager.com/i/nocover.png"} src={"https://bookmanager.com/i/m?b=" + item.eisbn} alt={item.title} style={{ width: '100%', height: 'auto' }} /></EventViewMore>
                            </div>
                        )
                    }))}
                </div>
            </div>
        )
    }


    const drawEvent = (item) => {
        const {
            id = "",
            from = 0,
            title = "",
            info = {}
        } = item;

        const { name = "" } = info;

        let _wrapperStyle = {}
        let _bgStyle = {}

        return (
            <div key={item}>
                <div style={_bgStyle}>
                    <div style={_wrapperStyle}>
                        <Row gutter={[20, 40]} className="bm-wrap" >
                            <Col flex={(width + "px")}>
                                {drawImages(item)}
                                <div style={{ "textAlign": "center" }}>
                                    <EventSocial link_id={link_id} name={(name) ? name : title} path={item.id} />
                                </div>
                            </Col>
                            <Col flex="auto">
                                <EventUI
                                    flourish={false}
                                    link_id={link_id}
                                    event_id={id}
                                    title_preset={title_preset}
                                    date_preset={date_preset}
                                    description_preset={description_preset}
                                    event={item} />
                                {drawButtons(item)}
                            </Col>
                        </Row>
                    </div>
                </div>
                <Divider />
            </div>
        )
    }

    const drawRender = () => {

        let _style = {
            backgroundColor: getColor(background_colour, template),
            padding: padding_y + "px " + padding_x + "px",
            width: (constrain_container) ? container_width + "px" : "100%",
            margin: (align === "center") ? "0 auto" : (align === "right") ? "0 0 0 auto" : "initial"
        }
        return (
            <Spin spinning={loading}>
                <div className={(container) ? "preview-container" : ""}>
                    <div style={_style}>
                        {events.map((item, index) => {
                            if (index === events.length - 1) {
                                return (<div key={index} ref={lastRowRef}>{drawEvent(item)}</div>)
                            } else {
                                return (<div key={index}>{drawEvent(item)}</div>)
                            }
                        })}
                    </div>
                </div>
            </Spin>
        )
    }

    return (
        <>
            {drawRender()}
        </>
    )

}