import React, {useState} from "react";
import {apiCall} from "../../utilities/Api";
import {Button, Select, Modal, Space} from "antd";

export default function FeaturesPDF(props){

    const {list_id} = props; 
    const [labels, setLabels] = useState([]); 
    const [error, setError] = useState(false); 
    const [modalVisible, setModalVisible] = useState(false);
    const [selected, setSelected] = useState("");

    const onFetchOptions = (_status, _result) =>{
        if(_status){
            setSelected(_result[0].layout);
            setLabels(_result);
        } else {
            setError(true);
        }
    }

    const fetchOptions = () =>{
        apiCall("feature/getPdfOptions", {}, onFetchOptions)
    }

    const onChangeLabel = (_label) =>{
        setSelected(_label)
    }

    const displayLabelOptions = () =>{

        // empty
        if(error){
            return(<>There was an error.</>)
        }
        if(labels.length<1){
            return(<></>);
        }
        return(<>
            <Space direction="vertical">
            <Select style={{"width" : "200px"}} onChange={(_label) => onChangeLabel(_label)} defaultValue={labels[0].layout}>
                {labels.map((item, index) =>{
                    let {layout, options } = item;
                    return(<Select.Option key={index} value={layout}>{options.display}</Select.Option>)
                })}
            </Select>
            <Button onClick={() => downloadPDF()} style={{"width" : "200px"}} type="primary" block>Download PDF</Button>
            </Space>
        </>)
    }

    const downloadPDF = (_selected) =>{

        let obj = {}
        obj.list_id = list_id; 
        obj.layout = selected;

        apiCall("titlelist/getPdf", obj, (_status, _result) => {
            if(_status){
                window.open("/pdf/" + _result.progress_report_id);
                setModalVisible(false);
            }
       })

    }

    const openModal = () =>{

        //only load once
        if(!error && labels.length === 0){
            fetchOptions(); 
        }
        setModalVisible(true);
    }

    const display = () =>{
        const contextHolder= Modal.useModal().contextHolder;
        return(
        <>
        <Modal width="auto" centered open={modalVisible} onCancel={() => setModalVisible(false)} footer={null}>
            <div style={{"textAlign" : "center"}}>
                <h4>Create PDF</h4>
                <p>Select layout option:</p>
            </div>
            {displayLabelOptions()}
        </Modal>
        <Button size="small" onClick={() => openModal()}><small>{props.children}</small></Button>
        {contextHolder}
        </>
        )
    }

    return display(); 

}