/*
    ---- conditional ----
    Html style tags for conditions - just for easy structure
*/

import React from "react";

export default function wrap(props) {
    return (<></>);
}


wrap.is = (props) => {

    if(props.value){
        return <>{props.children}</> 
    } else {
        return(<></>)
    }

}

wrap.tags = (props) => {

    let ret = props.children;
    let _s = {}
    const { hide = false, value = true, em = false, pointer = false, strong = false, small = false, color = false, dim = false, suffix = <></>, prefix = <></> } = props;

    if (!value) {
        if (hide) {
            return <></>
        } else {
            return <>{ret}</>
        }
    }

    // wrap tags
    if (small) { ret = <small>{ret}</small> }
    if (strong) { ret = <strong>{ret}</strong> }
    if (em) { ret = <em>{ret}</em> }

    // build style
    if(pointer){
        _s.cursor = "pointer"
    }
    if (color) { _s.color = color }
    if (dim) { _s.opacity ="0.5"; _s.pointerEvents = "none"; }

    if (dim || color) {
        ret = <span style={_s}>{prefix}{ret}{suffix}</span>
    }
    return <>{ret}</>;
}