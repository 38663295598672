import React from "react";
import Html from "../components/utilities/Html";
import { getColor } from "../util/CC";
import { Button, Divider } from "antd";
import { getItemAlt, parceAuthors } from "../util/Utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function SingleTitleFeature(props) {

    const history = useHistory();
    const template = window.sitesettings.template_info;
    const { content = {} } = props;

    const {
        location = "main",
        component = "blank_space",
        container = true,
        data = {},
        injected_data = {}
    } = content;

    const {
        title = "",
        eisbn = "",
        cover_image_cache = "",
        authors = [],
        description = "",
        bio = []

    } = injected_data

    const {
        isbn = "",
        padding_x = 10,
        padding_y = 10,
        flip = true,
        background_colour = 0,
        image_width = 20,
        text_width = 100,
        text_padding_x = 15,
        text_padding_y = 0,
        text_align = "left",
        align = "0",
        title_preset = 0,
        author_preset = 0,
        bio_preset = 0,
        description_preset = 0,
        title_text = "Title...",
        author_text = "Author...",
        bio_text = "Bio...",
        description_text = "Description...",
        use_title = true,
        use_author = true,
        use_bio = true,
        use_description = true,
        constrain_container = false,
        container_width = 1248,
        link_id = 0
    } = data;

    const { background_image = "" } = injected_data;

    const parseBios = (_bios) => {
        return _bios.join("<br /><br />");
    }

    const linkOut = (_url = "", _tab = true) => {
        history.push("/item/" + eisbn)
    }

    const parsePath = (url) => {
        const abs = /^(?:[a-z]+:)?\/\//i;
        return (abs.test(url)) ? url : "https://bookmanager.com/i/" + window.san + url;
    }

    const drawRender = () => {

        let _style = {
            padding: text_padding_y + "px " + text_padding_x + "px",
            maxWidth: (1248 * (text_width / 100)) + "px",
            width: "100%",
            alignSelf: "flex-start",
            textAlign: text_align,
        }

        let containerStyle = {
            padding: padding_y + "px " + padding_x + "px",
            background: getColor(background_colour, template),
            maxWidth: (constrain_container) ? container_width + "px" : "100%",
            margin: align
        }

        let _stack = (flip) ? "row" : "row-reverse";

        if (props.view === "phone") {
            _stack = (flip) ? "column" : "column-reverse"
        }

        return (
            <div className={(container) ? "preview-container" : ""}>
                <div style={containerStyle}>
                    <div className={(flip) ? "bm-stack" : "bm-stack-flip"} style={{ display: 'flex', width: '100%' }}>
                        <div onClick={() => linkOut()} style={{ flex: '0 0 ' + image_width + "%", "cursor": "pointer" }}>
                            {(eisbn) ? <img alt={getItemAlt(injected_data)} style={{ "width": "100%" }} src={"https://cdn1.bookmanager.com/i/m?b=" + eisbn + "&cb=" + cover_image_cache} /> : <img alt="Placeholder image" style={{ "width": "100%" }} src={"https://bookmanager.com/i/sample_content/placeholder.jpg"} />}
                            <br /> <br />
                        </div>
                        <div style={{ flex: '0 0 ' + (100 - image_width) + "%", display: 'flex' }}>
                            <div style={_style}>
                                <h3 onClick={() => linkOut()} style={{ "display": "block", "cursor": "pointer" }} className={"bm-txt-" + title_preset} >
                                    {(use_title) ? title : title_text}
                                </h3>
                                <div style={{ "display": "block" }} className={"bm-txt-" + author_preset} >

                                    {(use_author) ? parceAuthors(authors) : author_text}
                                </div>
                                <div aria-hidden><Divider style={{ "margin": "15px 0px" }} /></div>
                                <div className={"bm-txt-" + description_preset} >
                                    {(use_description) ? <><Html className={"bm-lnkin-" + link_id} clear={false} html={description} />{(description && <br />)}</> : <><Html className={"bm-lnkin-" + link_id} clear={false} html={description_text} />{(description_text && <br />)}</>}
                                </div>

                                <div className={"bm-txt-" + bio_preset} >
                                    {(use_bio) ? <Html className={"bm-lnkin-" + link_id} clear={false} html={parseBios(bio)} /> : <Html className={"bm-lnkin-" + link_id} clear={false} html={bio_text} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }



    return drawRender();
}