import React from "react";
import { getColor } from "../util/CC";

export default function BlankSpace(props) {

    const template = window.sitesettings.template_info;
    const {content = {}} = props; 

    const {
        location = "main",
        component = "blank_space",
        container = true,
        data = {}
    } = content;

    const {
        height = 30,
        background_colour = 0,
        divider = false,
        divider_height = 1,
        divider_colour = 0,
        divider_style = "solid", // dots, dashes
        divider_width = 100,
    } = data;

    const drawRender = () => {
        return (
            <div className={(container) ? "preview-container" : ""}>
                 <div className="shim" style={{ "display" : "flex", "flexDirection" : "column", "justifyContent" : "space-evenly", "height": height + "px", "background": getColor(background_colour, template) }}>
                    {(divider && <div style={{"margin": "0 auto", "width": divider_width + "%", "height" :"0px", "borderTop" : divider_height + "px " + divider_style + " " + getColor(divider_colour, template, "#000")}} />)}
                </div>
            </div>
        )
    }

    return drawRender();
}