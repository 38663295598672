import React, { useState, useEffect } from "react";
import { Form, Input, Select, Radio, Checkbox } from 'antd';
import { apiCall } from "../../utilities/Api";
import PostalCodeFormatter from "../../formatters/PostalCodeFormatter";
import PhoneNumberFormatter from "../../formatters/PhoneNumberFormatter";
import conditional from "../../utilities/conditional";

import AccountInsitutionToggle from "./AccountInstitutionToggle";
/**
 * User account form
 *  - Optionally pre-fill fields with user data
 *  - Fetch countries and provinces
 *
 * @component
 * @param {object} [user] Optionally pre-fill fields with user data
 * @param {string} [user.first_name]
 * @param {string} [user.last_name]
 * @param {string} [user.phone_number]
 * @param {string} [user.phone_number_type = "home"]
 * @param {string} [user.alt_phone_number]
 * @param {string} [user.alt_phone_number_type = "cell"]
 * @param {string} [user.communication_preference = "email"]
 * @param {string} [user.street_address]
 * @param {string} [user.street_address_2]
 * @param {string} [user.city]
 * @param {string} [user.province] 
 * @param {string} [user.country]
 * @param {string} [user.postal_code] 
 * @param {string} [user.company]
 * 
 */

function AccountForm(props) {


    const { user = {} } = props;

    // User Data
    const {
        first_name = "",
        last_name = "",
        phone_number = "",
        phone_number_type = "home",
        phone_number_ext = "",
        alt_phone_number = "",
        alt_phone_number_type = "cell",
        alt_phone_number_ext = "",
        communication_preference = "email",
        street_address = "",
        street_address_2 = "",
        city = "",
        postal_code = "",
        country = "",
        company = "",
        province = "",
        is_institution = false
    } = user;


    let enabled_prefs = Object.keys(window.sitesettings.store_info.allowed_comm_prefs).filter(key => (window.sitesettings.store_info.allowed_comm_prefs[key]));
    let preferred_communication = (communication_preference && window.sitesettings.store_info.allowed_comm_prefs[communication_preference]) ? communication_preference : enabled_prefs[0];

    const [isInstitution, setIsInstitution] = useState((company) ? true : false);


    const [countries, setCountries] = useState([]);
    /**
    * Callback for an API Call that runs on load.
    * - eg.  http://apiv1.bookmanager.com/listCountries
    * @param  {bool} _status
    * @param  {array | object} _result Array of provinces, or error object
    */
    const onListCountries = (_status, _result) => {
        if (_status) {
            setCountries(_result);
        }
    }

    /**
     * API Call that runs on load to fetch list of countries. 
     * - eg.  http://apiv1.bookmanager.com/listCountries
     * @function
     */
    const listCountries = () => {
        apiCall("country/getList", {}, onListCountries)
    }


    useEffect(listCountries, []);

    const [selectedCountry, setSelectedCountry] = useState(country);
    const [selectedProvince, setSelectedProvince] = useState(province);
    const [provinces, setProvinces] = useState([])
    /**
     * Callback for an API Call that runs on load and when the country select box is changed.
     *  - eg.  http://apiv1.bookmanager.com/listProvinces?country=CAN
     * @function
     * @param  {bool} _status 
     * @param  {array | object} _result Array of provinces, or error object
     */
    const onListProvinces = (_status, _result) => {
        if (_status) {
            setProvinces(_result);
        }
    }

    /**
    * API Call that runs on load and when the country select box is changed.
    *  - eg.  http://apiv1.bookmanager.com/listProvinces?country=CAN
    * @function
    * @param  {string} _country
    */
    const listProvinces = (_country) => {

        if (!_country) {
            _country = selectedCountry;
        }

        apiCall("province/getList", { "country": _country }, onListProvinces)
    }


    useEffect(listProvinces, [selectedCountry]);



    // Display Form
    /** 
     * Displays the JSX for the form
     * @returns {jsx} JSX for the form
     */


    const postalError = () => {
        let ret = "Valid postal code is required.";
        if (selectedCountry === "Canada") {
            return ret + " eg. A1B 2C3"
        }

        if (selectedCountry === "United States") {
            return ret + " eg. 12345 or 12345-6789"
        }

        return ret;
    }


    useEffect(() => {
        props.form.setFieldsValue({ is_institution: isInstitution });
    }, [isInstitution]);

    const displayForm = () => {



        return (
            <>

                {/* <conditional.true value={(props.create)}>
                    <Form.Item help={<small>This type of account will enable you to link other accounts made on this site that can purchase on behalf of this institution.</small>} valuePropName="checked" name="is_institution" value={isSchool}>
                        <Checkbox onChange={(v) => setIsSchool(v.target.checked)}>Institution <small>(School or Library)</small>? </Checkbox>
                    </Form.Item>
                    <div className="shim"></div> <div className="shim"></div>
                </conditional.true> */}



                {(first_name !== false && <Form.Item initialValue={first_name} label={(isInstitution) ? "Main contact first name" : "First name"} name="first_name" rules={[{ message: "First name is required.", required: true }]}>
                    <Input aria-label={(isInstitution) ? "Main contact first name" : "First name"} placeholder={(isInstitution) ? "Main contact first name" : "First name"} maxLength={80} />
                </Form.Item>)}

                {(last_name !== false && <Form.Item initialValue={last_name} label={(isInstitution) ? "Main contact last name" : "Last name"} name="last_name" rules={[{ message: "Last name is required.", required: true }]}>
                    <Input aria-label={(isInstitution) ? "Main contact last name" : "Last name"} placeholder={(isInstitution) ? "Main contact last name" : "Last name"} maxLength={80} />
                </Form.Item>)}

                {(street_address !== false && <Form.Item initialValue={street_address} label="Street address" name="street_address" rules={[{ message: "Street address is required.", required: true }]}>
                    <Input aria-label="Street address" placeholder="Street address" maxLength={80} />
                </Form.Item>)}

                {(street_address_2 !== false && <Form.Item initialValue={street_address_2} label="Street address (line 2)" name="street_address_2" rules={[{ required: false }]}>
                    <Input aria-label="Street address line 2" placeholder="Street address (line 2)" maxLength={80} />
                </Form.Item>)}

                {(city !== false && <Form.Item initialValue={city} label="City" name="city" rules={[{ message: "City is required.", required: true }]}>
                    <Input aria-label="City" placeholder="City" maxLength={80} />
                </Form.Item>)}

                {(country !== false && <Form.Item initialValue={country} name="country" label="Country" rules={[{ validateTrigger: "onBlur", validator: (_, value) => (countries.includes(selectedCountry)) ? Promise.resolve() : Promise.reject("no"), message: "Valid country is required.", required: true }]}>
                    <Select aria-label="Select country" showSearch placeholder="Select country" style={{ maxWidth: 400 }} onChange={(v) => setSelectedCountry(v)}>
                        {countries.map((item, index) => {
                            return (<Select.Option key={index} value={item}>{item}</Select.Option>)
                        })}
                    </Select>
                </Form.Item>)}

                {(province !== false && (provinces.length > 0) && (selectedCountry) && <Form.Item initialValue={province} label="State / Province" name="province" rules={[{ validateTrigger: "onBlur", validator: (_, value) => (provinces.includes(selectedProvince)) ? Promise.resolve() : (provinces.length > 0) ? Promise.reject("no") : Promise.resolve(), message: "Valid state / province is required.", required: true }]}>
                    <Select aria-label="Select state / province" showSearch placeholder="Select state / province" onChange={(v) => setSelectedProvince(v)} style={{ maxWidth: 400 }}>
                        {provinces.map((item, index) => {
                            return (<Select.Option key={index} value={item}>{item}</Select.Option>)
                        })}
                    </Select>
                </Form.Item>)}

                {(province !== false && (!provinces.length > 0) && (selectedCountry) && <Form.Item initialValue={province} label="State / Province" name="province" rules={[{ validateTrigger: "onBlur", validator: (_, value) => (provinces.includes(selectedProvince)) ? Promise.resolve() : (provinces.length > 0) ? Promise.reject("no") : Promise.resolve(), message: "Valid state / province is required.", required: false }]}>
                    <Input aria-label="Select state / province" placeholder="Select state / province" style={{ maxWidth: 400 }} maxLength={80} />
                </Form.Item>)}


                {(phone_number !== false && <Form.Item rules={[{ required: true }]} label={<><div style={{ "fontFamily": "SimSun, sans-serif", "color": "#ff4d4f" }}>*</div>&nbsp;Phone number</>}>
                    <Input.Group compact>

                        <Form.Item noStyle initialValue={phone_number} name="phone_number" rules={[{ message: "Required. Must be a phone number.", pattern: new RegExp(/^[0-9+. \-)(]{10,15}$/g), required: true }]}>
                            <PhoneNumberFormatter field="phone_number" form={props.form} country={selectedCountry} style={{ width: "156px" }} inputMode="numeric" placeholder="Phone number" />
                        </Form.Item>

                        <Form.Item noStyle initialValue={phone_number_ext} name="phone_number_ext" rules={[{ message: "Must be between 2 and 6 digits", pattern: new RegExp(/^[0-9]{2,6}$/g), required: false }]}>
                            <Input aria-label="Alternate phone number extension" field="phone_number_ext" style={{ width: '80px' }} maxLength={6} inputMode="numeric" placeholder="Ext." />
                        </Form.Item>

                        <Form.Item noStyle initialValue={(["cell", "home", "work"].includes(phone_number_type) ? phone_number_type : "cell")} name="phone_number_type" rules={[{ required: true }]}>
                            <Select aria-label="Select phone number type" virtual={false} style={{ width: '100px' }} >
                                <Select.Option value="cell">Cell</Select.Option>
                                <Select.Option value="home">Home</Select.Option>
                                <Select.Option value="work">Work</Select.Option>
                            </Select>
                        </Form.Item>
                    </Input.Group>
                </Form.Item>)}

                {(alt_phone_number !== false && <Form.Item rules={[{ required: true }]} label="Alternate phone number">
                    <Input.Group compact>
                        <Form.Item noStyle rules={[{ message: "Must be a phone number", pattern: new RegExp(/^[0-9+. \-)(]{10,15}$/g) }]} initialValue={alt_phone_number} name="alt_phone_number" >
                            <PhoneNumberFormatter field="alt_phone_number" form={props.form} country={selectedCountry} style={{ width: "156px" }} inputMode="numeric" placeholder="Phone number" />
                        </Form.Item>
                        
                        <Form.Item noStyle initialValue={alt_phone_number_ext} name="aext" rules={[{ message: "Must be between 2 and 6 digits", pattern: new RegExp(/^[0-9]{2,6}$/g), required: false }]}>
                            <Input  aria-label="Alternate phone number extension" field="aext" style={{ width: '80px' }} maxLength={6} inputMode="numeric" placeholder="Ext." />
                        </Form.Item>

                        <Form.Item noStyle initialValue={(["cell", "home", "work"].includes(alt_phone_number_type) ? alt_phone_number_type : "home")} name="alt_phone_number_type">
                            <Select aria-label="Select alternate phone number type" virtual={false} style={{ width: '100px' }} >
                                <Select.Option value="home">Home</Select.Option>
                                <Select.Option value="cell">Cell</Select.Option>
                                <Select.Option value="work">Work</Select.Option>
                            </Select>
                        </Form.Item>
                    </Input.Group>
                </Form.Item>)}

                {(preferred_communication !== false && <Form.Item initialValue={(!window.sitesettings.store_info.texting_enabled && preferred_communication === "text") ? "phone" : preferred_communication} label="Preferred method of communication" name="communication_preference" rules={[{ required: true }]}>
                    <Radio.Group buttonStyle="solid">
                        {(window.sitesettings.store_info.allowed_comm_prefs.email && <Radio.Button value="email">Email</Radio.Button>)}
                        {(window.sitesettings.store_info.allowed_comm_prefs.phone && <Radio.Button value="phone">Phone</Radio.Button>)}
                        {(window.sitesettings.store_info.allowed_comm_prefs.text && <conditional.true value={window.sitesettings.store_info.texting_enabled}>
                            <Radio.Button value="text">Text</Radio.Button>
                        </conditional.true>
                        )}
                    </Radio.Group>
                </Form.Item>)}

                {(postal_code !== false && <Form.Item initialValue={postal_code} label={(selectedCountry === "United States") ? "Zip code" : "Postal code"} name="postal_code" rules={[{ pattern: (selectedCountry === "Canada") ? new RegExp(/^[A-Za-z]\d[A-Za-z][ ]?\d[A-Za-z]\d$/) : (selectedCountry === "United States") ? new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/) : "", message: postalError(), required: true }]}>
                    <PostalCodeFormatter form={props.form} country={selectedCountry} />
                </Form.Item>)}


                <Form.Item hidden initialValue={isInstitution} name="is_institution" valuePropName="checked" noStyle>
                    <Checkbox />
                </Form.Item>
                <AccountInsitutionToggle company={company} create={props.create} isInstitution={isInstitution} setIsInstitution={setIsInstitution} />



                <div className="shim"></div><div className="shim"></div>
                <div className="shim"></div><div className="shim"></div>

            </>
        )

    }

    return displayForm();

}




export default AccountForm;