import moment from "moment-timezone";
import React from "react";
import Slider from "react-slick";
import { getItemAlt } from "../../../util/Utils";

export default function ItemImageMobileGallery(props) {


    const {data} = props; 
    const {eisbn, cover_image_cache, interiors = [], interior_objects = []} = data; 

    const settings = {
        dots: true,
        arrows : false,
        infinite: true,
        adaptiveHeight : true,
        appendDots: dots => (
            <div
              style={{
                padding: "0px",
                margin: "0px",
                bottom : (interiors.length>12) ? "-23px" : "-10px",
              }}
            >
              <ul className="mobileDots" style={{ margin : "0px", padding : "0px" }}> {dots} </ul>
            </div>
          ),

        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const imageFallback = (i) => {
        i.target.src = "https://cdn1.bookmanager.com/i/nocover.png"
        i.target.className = "imageNotFound";
    }

    return (
        <>
            <div style={{"paddingTop" : "40px"}} >
                {((interior_objects.length === 0) && <>
                    <div>
                        <img alt={getItemAlt(data)} style={{ "margin": "0 auto" }} onError={(i) => imageFallback(i)} className="shadow-light full-image" src={"https://cdn1.bookmanager.com/i/m?b=" + eisbn + "&cb=" + cover_image_cache} />
                    </div>
                </>)}

                {((interior_objects.length > 0) && <>
                <Slider {...settings}>
                    <div>
                        <img alt={getItemAlt(data)} style={{ "margin": "0 auto" }} onError={(i) => imageFallback(i)} className="shadow-light full-image" src={"https://cdn1.bookmanager.com/i/m?b=" + eisbn + "&cb=" + cover_image_cache} />
                    </div>
                    {
                        interior_objects.map((item, index) => {
                            return(<img key={index} alt={getItemAlt(data) + " - Image " + index} onError={(i) => imageFallback(i)} className="shadow-light full-image" src={'https://cdn1.bookmanager.com/i/m?b=' + eisbn + '&imgp=' + (item.key) + "&cb=" + item.cb} />)
                        })
                    }
                </Slider>
                </>)}
            </div>
        </>
    )
}