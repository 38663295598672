import React, { useState } from "react";
import { Space, Divider, Form, Input, Select, Radio } from 'antd';
import { getCreditCardIcon } from "./PaymentChoices";
import creditCardType from "credit-card-type";
import PostalCodeFormatter from "../../formatters/PostalCodeFormatter";

import moment from "moment";

export default function CreditCardForm(props) {

    const form = props.form;

    // matching short names from the api to actual names of the cards
    const card_types = [];
    card_types["visa"] = "Visa";
    card_types["mastercard"] = "Mastercard";
    card_types["amex"] = "American Express";
    card_types["discover"] = "Discover";


    const getCountry = (_c) => {
        return (_c === "Canada") ? _c : (_c === "United States") ? _c : "Other";
    }

    const [selectedCountry, setSelectedCountry] = useState(getCountry(window.sitesettings.store_info.country))


    // ensure credit card # provides is a valid type & is a type accepted by the store
    const testCardNumber = (_number) => {

        let m = creditCardType(_number).filter((card) => {
            let ret = false;
            for (let i = 0; i < window.sitesettings.checkout_info.accepted_credit_cards.length; i++) {
                if (card.niceType === card_types[window.sitesettings.checkout_info.accepted_credit_cards[i]]) {
                    ret = true;
                }
            }
            return ret;
        })

        if (m.length > 0) {
            return true;
        } else {
            return false;
        }

    }


    // Validate ccv (3-4) digit interger
    const [ccv, setCcv] = useState("");
    const validateCCV = (e) => {
        let reg = /^-?\d*(\.\d*)?$/;
        if ((!isNaN(e.target.value) && reg.test(e.target.value))) {
            setCcv(e.target.value);
        }
        return;
    }

    const [card_type, setCardType] = useState("");
    const updateCreditCard = (e) => {
        e.preventDefault();
        e.stopPropagation();
        form.setFieldsValue({ number: formatCreditCard(e.target.value) });
    };

    const formatCreditCard = (orig_value) => {
        orig_value = orig_value.replace(/ /g, "");
        let types = creditCardType(orig_value);
        if (types.length === 1) {
            let type = types[0];
            setCardType(type.type);

            let gaps = type.gaps;
            let new_value = orig_value;
            for (let i = gaps.length - 1; i >= 0; i--) {
                if (orig_value.length > gaps[i]) {
                    new_value = new_value.substr(0, gaps[i]) + ' ' + new_value.substr(gaps[i]);
                }
            }
            return new_value;
        } else {
            setCardType(null);
        }

        return orig_value;
    };

    // ensure not empty, is number and is a valid and accepted type
    const validateCreditCard = (_rule, _value) => {

        let v = _value.replace(/ /g, "")
        if ((v) && v.length < 13) {
            return Promise.reject('Must be at least 13 digits');
        }

        let reg2 = /^-?\d*(\.\d*)?$/;
        if ((!isNaN(v) && reg2.test(v))) {

            if (!testCardNumber(v)) {
                return Promise.reject('Must be an accepted credit card');
            } else {
                return Promise.resolve();
            }

        }
        return Promise.reject('Must be a valid credit card number');
    }

    const addLeadingZero = (n) => {
        return (n < 10) ? ("0" + n) : n;
    }



    // expiry 10 years into the future starting now
    const years = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const start = moment().year();


    const validate = (rule, value, callback) => {

        try {
            throw new Error('Something wrong!');
          } catch (err) {
            callback(err);
          }

    }
   

    const postalError = () => {
        let ret = "Valid postal code is required.";
        if(selectedCountry === "Canada"){
            return ret + " eg. A1B 2C3" 
        } 

        if(selectedCountry === "United States"){
            return ret + " eg. 12345 or 12345-6789" 
        } 

        return ret; 
    }


    return (
        <>
            <div style={{ "textAlign": "left" }}>
                <Form.Item label="Accepted cards">
                    <Space>
                        {window.sitesettings.checkout_info.accepted_credit_cards.map((_item, _index) => {
                            return getCreditCardIcon(_item, _index);
                        })}
                    </Space>
                </Form.Item>

                <Form.Item label="Name on card" name="name" rules={[{ required: true, message: 'Please input your name.' }]}>
                    <Input placeholder="Name on card" rules={[{ required: true, message: 'Please enter a valid credit card number' }]} style={{ maxWidth: '248px' }}></Input>
                </Form.Item>
                <Form.Item label="Credit card number" name="number" rules={[{ min: 16, required: true, pattern: new RegExp(/[\d +]/g), validator: validateCreditCard }]} >
                    <Input addonAfter={<div style={{ "paddingTop": "4px", "width": "35px" }}>{getCreditCardIcon(card_type)}</div>} placeholder="Credit card number" onChange={(e) => updateCreditCard(e)} maxLength={25} style={{ maxWidth: '248px' }}></Input>
                </Form.Item>

                {/* POSTAL CODE  */}


                <Form.Item label="Country">
                    <Radio.Group size="small" defaultValue={selectedCountry} onChange={(e) => setSelectedCountry(e.target.value)} buttonStyle="solid">
                        <Radio.Button value="Canada"><small>Canada</small></Radio.Button>
                        <Radio.Button value="United States"><small>United States</small></Radio.Button>
                        <Radio.Button value="Other"><small>Other</small></Radio.Button>
                    </Radio.Group>
                </Form.Item>
                
                <Form.Item style={{ maxWidth: '248px' }} label={(selectedCountry === "United States") ? "Zip code" : "Postal code"} name="postal_code" rules={[{ pattern: (selectedCountry === "Canada") ? new RegExp(/^[A-Za-z]\d[A-Za-z][ ]?\d[A-Za-z]\d$/) : (selectedCountry === "United States") ? new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/) : "", message: postalError(), required: true }]}>
                    <PostalCodeFormatter form={form} country={selectedCountry} />
                </Form.Item>

                <Form.Item label="Expiry" className="ant-form-item-required" style={{ marginBottom: 0 }}>
                    <Form.Item
                        name="expiry_month"
                        rules={[{ required: true, message: 'Month required', validator: async (rule, value) => {
                            let _year = form.getFieldValue("expiry_year");
                            if(!_year){ return true; }
                            let now = moment().format("YYYYMM");
                            let _now = Number.parseInt(now);
                            let _input = Number.parseInt(_year.toString() + value.toString());
                            if(_input < _now){
                                rule.message = "Must be future date";
                                throw new Error('Something wrong!');
                            } else {
                                return true; 
                            }
                          } }]}
                        style={{ display: 'inline-block', width: '120px' }}
                    >
                        <Select placeholder="Month" style={{ width: 120 }} >
                            {moment.monthsShort().map((m, i) => {
                                return (<Select.Option key={i} value={addLeadingZero(i + 1)}>{i + 1} - {m}</Select.Option>)
                            })}

                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="expiry_year"
                        placeholder="Year"
                        // initialValue={start + 0}
                        rules={[{ required: true, message: 'Year required' }]}
                        style={{ display: 'inline-block', width: '120px', margin: '0 8px' }}
                    >
                        <Select placeholder="Year" style={{ width: 120 }} >
                            {years.map((m, i) => {
                                return (<Select.Option key={i} value={start + i}>{start + i}</Select.Option>)
                            })}
                        </Select>
                    </Form.Item>
                </Form.Item>
                <Space>
                    <Form.Item name="cvv" rules={[{ required: true, min: 3, message: 'Please enter a valid CVV.' }]} label="CVV">
                        <Input placeholder="CVV" style={{ "maxWidth": "80px" }} maxLength={4} value={ccv} onChange={(e) => validateCCV(e)} ></Input>
                    </Form.Item>
                </Space>
                <div aria-hidden><Divider style={{ "marginBottom": "2px" }} /></div>
            </div>
        </>
    )

}