import React, {useState} from "react";
import { Form, Input, Button, Space, Divider} from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import {useSession} from "../../../util/Session";
import {apiCall} from "../../utilities/Api";
function AccountChangeEmail(props){

    const {title, close, callback} = props; 
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [context, setContext] = useSession();
    const [verifySent, setVerifySent] = useState(false);
    const [newEmail, setNewEmail] = useState("");

    const onChangeEmail = (_status, _result) =>{

        if(!_status){
            setError(true);
            setErrorMsg(_result.error);
          } else {

            if(_result.exists){
                setError(true);
                setErrorMsg("An account already exists with this email.");
                return; 
            } 

              if(_result.validation_required){
                  // show email sceen
                  setNewEmail(_result.address);
                  setVerifySent(true); 

              } else {
                  close(); 
                  setContext({...context, "user" : _result.user})
                  callback(); 
              }

          }
    }

    const changeEmail = (_form) =>{

        if(window.sitesettings.require_email_verification){
            _form["callback_url"] = "account/details";
        }

       // _form["email_address"] = email_address; 
        apiCall("account/modify",_form, onChangeEmail);
    }


    const display = () =>{

        if(verifySent){
            return(
                <>
                    <div aria-hidden><Divider /></div>
                    <h4>Please verify your new email address</h4>
                    <p>You have entered {newEmail} as your new email address.<br />
                    We have sent you an email that contains your confirmation link.</p>
                    <Button onClick={() => close()}>Close</Button>
                </>
            )

        }
        return( 
            <div style={{"minWidth" : "300px"}} >
                <strong>{title}</strong>
                <div aria-hidden><Divider /></div>
                <Form  layout="vertical" onFinish={(form) => changeEmail(form)} >
    
                    <Form.Item rules={[{ message : "Email address required.", required: true }]} name="email_address" label="New Email Address">
                        <Input maxLength={80} inputMode="email" type="email" />
                    </Form.Item>
    
                    <Form.Item rules={[{ message : "Password required.", required: true }]} name="password" label="Password">
                        <Input.Password maxLength={80} />
                    </Form.Item>
                   
                    {(error && <p style={{ "paddingBottom": "15px", "color": "#ff4d4f", "textAlign" : "center" }} className="error"><ExclamationCircleFilled /> {errorMsg}</p>)}
                    <Space><Button type="primary" htmlType="submit">Change Email</Button><Button onClick={() => close()}>Cancel</Button></Space>
                </Form>
            </div>
        )

    }
      
    return display(); 
   
}

export default AccountChangeEmail; 