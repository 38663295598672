import React, { useState } from 'react';
import { Spin } from "antd";
const LoadingOverlay = (props) => {

    const { loading = false } = props;

    return (
        <>
            {loading && (
                <a onClick={(e) => {
                    e.preventDefault(); 
                    e.stopPropagation(); 
                }} className="bm-overlay">
                    <div style={{ "textAlign": "center" }}>
                        <Spin spinning={loading} />
                        <div className='shim' />
                        <div style={{ "color": "#fff" }}>Processing....</div>
                    </div>
                </a>
            )}
            {/* The rest of your application content goes here */}
        </>
    );
};

export default LoadingOverlay;