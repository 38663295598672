import React from "react"
import { Badge, Divider } from 'antd';
import { CanadaIcon } from "./Icons";
import AddToCart from "../components/AddToCart";
import AlsoAvailable from "../components/components/item/AlsoAvailable";
import DrawShelfLocation from "../components/components/DrawShelfLocation";
import moment from "moment";

const successColor = "#3C8617";
const warningColor = "#bd5800";
const disabledColor = "#999999";
const futureColor = "#1581E9";
const bestsellerColor = "#e70d3d";




export const hasProperty = (_obj, _path) => {
  let props = _path.split(".");
  var obj = _obj;
  for (var i = 0; i < props.length; i++) {
    if (!obj || !obj.hasOwnProperty(props[i])) {
      return false;
    } else {
      obj = obj[props[i]];
    }
  }
  return true;
}

// Object.prototype.hasOwnNestedProperty = function(propertyPath) {
//   if (!propertyPath)
//     return false;

//   var properties = propertyPath.split('.');
//   var obj = this;

//   for (var i = 0; i < properties.length; i++) {
//     var prop = properties[i];

//     if (!obj || !obj.hasOwnProperty(prop)) {
//       return false;
//     } else {
//       obj = obj[prop];
//     }
//   }

//   return true;
// };

export const convertLineBreaks = (_str) => {
  const regex = /\\n|\\r\\n|\\n\\r|\\r/g;
  return _str.replace(regex, '<br />');
}

export const drawUSCanPrice = (_us, _can) => {

  if (_us === "0.00" && _can === "0.00") {
    return <></>;
  }

  if (_us !== "0.00" && _can === "0.00") {
    return <div style={{ "fontSize": "16px" }}><small><strong>${_us} </strong>US list price</small></div>
  }

  if (_us === "0.00" && _can !== "0.00") {
    return <div style={{ "fontSize": "16px" }}><small><strong>${_can} </strong>CA list price</small></div>
  }

  return <div style={{ "fontSize": "16px" }}><small><strong>${_us} </strong>US / <strong>${_can} </strong>CA list price</small></div>;

}


export const findNested = (_arr, _key, _val, _children_key = "submenu") => {
  for (let i = 0; i < _arr.length; i++) {
    const item = _arr[i];
    if (item[_key] === _val) {
      return item;
    }

    if (item[_children_key] && item[_children_key].length > 0) {
      const child = findNested(item[_children_key], _key, _val);
      if (child) {
        return child;
      }
    }
  }

  // Key was not found in the array or its children
  return null;
}


export const getOGColor = (_key, _default = "#fff") => {

  if (window.sitesettings.styles[_key]) {
    return window.sitesettings.styles[_key];
  } else {
    return _default;
  }

}

export const checkBrightness = (_c, _val = 240) => {

  var c = _c.substring(1);
  var rgb = parseInt(c, 16);
  var r = (rgb >> 16) & 0xff;
  var g = (rgb >> 8) & 0xff;
  var b = (rgb >> 0) & 0xff;

  var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  if (luma > _val) {
    return true;
  }

  return false;

}



export const processAvailable = (_available, _render, _price, _showprice = true, data = {}, _gift_registry_id = "", popup = false, tempSession = {}, setTempSession = () => {}) => {


  return _available.map((item, index) => {
    let messageColor = (item.row_price > 0 && (item.onhand > 0 || item.onhand === "Y")) ? "#3C8617" : "#bd5800";
    let price = (item.row_price > 0) ? "$" + item.row_price : "";

    // hack to put large print into condition to flag on add to cart
    item.is_large_print = data.is_large_print;

    //let original_price = (item.code) ? _price : item.price; 
    let original_price = item.price;
    let strike_price = (parseFloat(item.row_price) < parseFloat(original_price) & parseFloat(item.row_price) !== 0) ? original_price : false;

    if (!window.sitesettings.show_strikethrough) {
      strike_price = false;
    }

    if (!_showprice) {
      price = "";
      strike_price = "";
    }

    switch (_render) {

      case "impulse":
        return (
          <>
            <span key={index}><span key={index}>
              <strong>{item.text}  </strong>
              <strong style={{ "color": messageColor, "fontSize": "14px" }} >{item.message}</strong>
              {(item.sub_message !== "" && (
                <>
                  <span style={{ "color": "#666", "fontSize" : "12px", "lineHeight": "1.3em", "display": "block", "marginTop": "-4px", "marginBottom": "3px" }}>
                    {item.sub_message}
                  </span><div className="shim"></div>
                </>))}
                
              <strong style={{ "fontSize": "14px", "color": (strike_price) ? "#e70d3d" : "#1f1f1f", "lineHeight": "12px", "display": "block" }}>{(strike_price && (<><span style={{ "fontSize": "12px", "fontWeight": "normal", "color": "#5f5f5f" }}> List price: <s>${strike_price}</s></span><div className="shim"></div></>))} {price} <div className="shim"></div></strong>
              <div className="shim"></div>
            </span>

              <AddToCart setTempSession={setTempSession} tempSession={tempSession} impulse={true} popup={popup} showLists={false} data={data} summary={item} summaryIndex={index} />

              {(index !== _available.length - 1 && <div aria-hidden><Divider style={{ "margin": "10px 0px" }} /></div>)}
            </span>

          </>
        )
      case "sidebar":
        return (<span key={index}><span style={{ "color": messageColor }} >{item.message}</span><br /></span>);
      case "list":
        return (<span key={index}><span key={index}>
          <strong>{item.text}  </strong>
          <strong style={{ "color": messageColor, "fontSize": "19px" }} >{item.message}</strong>
          {(item.sub_message !== "" && (
            <><span style={{ "color": "#666", "lineHeight": "1.3em", "display": "block", "marginTop": "-4px", "marginBottom": "3px" }}>

              {item.sub_message}

            </span><div className="shim"></div></>))}
             
          <strong style={{ "fontSize": "18px", "color": (strike_price) ? "#e70d3d" : "#1f1f1f", "lineHeight": "15px", "display": "block" }}>{(strike_price && (<><span style={{ "fontSize": "12px", "fontWeight": "normal", "color": "#5f5f5f" }}> List price: <s>${strike_price}</s></span><div className="shim"></div></>))} {price} <div className="shim"></div></strong>
          {drawUSCanPrice(data.list_price_usa, data.list_price_can)}
          <div className="shim"></div>
          <DrawShelfLocation shim={0} available={item} />
        </span>
          <small><AlsoAvailable eisbn={data.eisbn} summary={true} count={data.at_other_locations} /></small>
          <AddToCart popup={popup} showLists={(_available.length === (index + 1))} data={data} summary={item} summaryIndex={index} />

          {(index !== _available.length - 1 && <div aria-hidden><Divider style={{ "margin": "10px 0px" }} /></div>)}
        </span>);


      case "gift":
        return (<span key={index}><span key={index}>
          <strong>{item.text}  </strong>
          <strong style={{ "color": messageColor }} >{item.message}</strong>
          {(item.sub_message !== "" && (
            <><span style={{ "color": "#666", "lineHeight": "1.3em", "display": "block", "marginTop": "-4px", "marginBottom": "3px" }}>{item.sub_message}

            </span><div className="shim"></div></>))}

          <strong style={{ "fontSize": "18px", "color": (strike_price) ? "#e70d3d" : "#1f1f1f", "lineHeight": "15px", "display": "block" }}>{(strike_price && (<><span style={{ "fontSize": "12px", "fontWeight": "normal", "color": "#5f5f5f" }}> List price: <s>${strike_price}</s></span><div className="shim"></div></>))} {price} <div className="shim"></div></strong>
          <div className="shim"></div>
        </span>
          <AddToCart popup={popup} gift_registry_id={_gift_registry_id} gift showLists={(_available.length === (index + 1))} data={data} summary={item} summaryIndex={index} />
          {(index !== _available.length - 1 && <div aria-hidden><Divider style={{ "margin": "10px 0px" }} /></div>)}
        </span>);



      case "card":
        return (
          <span key={index}><span key={index}>
            <strong>{item.text}  </strong>
            <div className="shim"></div>
            <strong style={{ "fontSize": "14px", "color": (strike_price) ? "#e70d3d" : "#1f1f1f", "lineHeight": "15px", "display": "block" }}>{(strike_price && (<><span style={{ "fontSize": "12px", "fontWeight": "normal", "color": "#5f5f5f" }}> List price: <s>${strike_price}</s></span><div className="shim"></div></>))} {price} <div className="shim"></div></strong>
            <div className="shim"></div>
          </span>
            <AddToCart popup={popup} showLists={false} data={data} summary={item} summaryIndex={index} />
            {(index !== _available.length - 1 && <div aria-hidden><Divider style={{ "margin": "10px 0px" }} /></div>)}
          </span>
        );
      case "cart":
        return (<span key={index}>
          <span style={{ "color": messageColor }} >{item.message}</span>
        </span>);
      case "format":
        return (<span key={index}>
          <span>{item.text}<br /> </span>
        </span>);
      case "estimate":
        return (<span key={index}>
          <div style={{ "display": "block", "color": "#E77E21" }}><em><small>{item.sub_message}</small></em></div>
        </span>);
      case 'mobile_cart':
        return (<span key={index}><span style={{ "color": messageColor }}>{item.message}</span></span>);
      default:
        return (
          <span key={index}><strong>{item.text}  </strong><strong style={{ "color": messageColor }} >{item.message}</strong>{(item.sub_message !== "" && (<span style={{ "color": "#666" }}><br /> {item.sub_message}</span>))}{(strike_price && (<span><br /><s>${strike_price}</s></span>))}<br /><strong>{price}</strong><br /></span>);
    }
  })
}

export const processCartOptions = (_available, _price, _data) => {
  return _available.map((item, index) => {
    let messageColor = (item.row_price > 0 && (item.onhand > 0 || item.onhand === "Y")) ? "#3C8617" : "#bd5800"; // green or yellow
    let price = (item.row_price > 0) ? "$" + item.row_price : "$0.00";
    //let strike_price = (item.row_price < _price && item.code === "" & item.row_price !== "0.00") ? _price : "";

    // let original_price = (item.code) ? _price : item.price; 
    let original_price = item.price;


    let strike_price = (parseFloat(item.row_price) < parseFloat(original_price) & parseFloat(item.row_price) !== 0) ? original_price : false;
    let savings = (item.discount) ? (original_price - item.row_price).toFixed(2) : false;


    if (!window.sitesettings.show_strikethrough) {
      strike_price = false;
    }



    let ret = {
      "label": item.text,
      "color": messageColor,
      "code": item.code,
      "price": price,
      "note": item.note,
      "is_extra_orderable": item.is_extra_orderable,
      "onhand": item.onhand,
      "onorder": item.onorder, // not getting
      "used": item.used,
      "discount": item.discount,
      "discount": item.discount,
      "is_large_print": _data.is_large_print,
      "savings": savings,
      "strike_price": strike_price,
      "message": item.message,
      "sub_message": item.sub_message,
      "bm_class": item.bm_class,
    };
    return ret;
  })
}

export const parseRank = (_rank, _show_txt = true) => {

  if (!_rank) { return "" }

  let txt = "";
  let prefix = "";

  if (_rank.hasOwnProperty('bestseller')) {
    if (_show_txt) { txt = " in bestsellers"; prefix = "#" }
    return (<span style={{ "color": bestsellerColor }}>{prefix + _rank.bestseller + txt}</span>);
  }

  if (_rank.hasOwnProperty('future')) {
    if (_show_txt) { txt = " in future releases"; prefix = "#" }
    return (<span style={{ "color": futureColor }}>{prefix + _rank.future + txt}</span>);
  }
  return "";

}


export const parseAvailabie = (_available, _list = false) => {

  return _available.map((item, index) => {
    switch (item.availability) {
      case "available":
        if (item.onhand > 0) {
          return (<><span>{item.message}</span> - <span style={{ "color": successColor }}>{item.sub_message}</span><br /><span>${item.row_price}</span><br /></>)
        } else {
          return (<><span style={{ "color": warningColor }}>{item.message}</span> - <span style={{ "color": disabledColor }}>{item.sub_message}</span><br /><span>${item.row_price}</span><br /></>)
        }
      case "forthcoming":
        return (<><span style={{ "color": warningColor }}>{item.message}</span> - <span style={{ "color": disabledColor }}>{item.sub_message}</span><br /><span>${item.row_price}</span><br /></>)
      default:
        return (<><span style={{ "color": warningColor }}>{item.message}</span> <br /><span style={{ "color": disabledColor }}>{item.sub_message}</span><br /></>)
    }
  });

}

export const prettyTime = (_str) => {
  const time = moment(_str, 'HH:mm:ss')

  if(time.isValid()){
    return time.format('h:mm A');
  }
  else {
    return "";
  }

};

export const getItemAlt = (_item) => {

  let _for = "";
  let _by = "";
  if(_item.hasOwnProperty("authors") && Array.isArray(_item.authors)){
    _by = " by " + _item.authors.map(item => item.name).join(", ");
  }

  if(_item.hasOwnProperty("title" && _item.title)){
    _for = " for " + _item.title; 
  }
 
  return(`Product cover image${_for}${_by}`)
}



export const parseAvailability = (_available, _badge = true) => {


  // map all
  return _available.map((a, i) => {

    // construct labels 

    return (<div key={i}>{getAvailabilityBadge(a, _badge)}</div>);

  })



}


const getAvailabilityBadge = (_available, _badge) => {

  switch (_available.availability) {
    case "available":
      // [TODO] Add "In store, etc" from sitesettings
      if (_available.onhand < 1) {
        // Special Order
        return (<><strong>${_available.row_price}</strong> &nbsp; {getBadge("Special order", warningColor, _badge)}<br />[TODO ADD MESSAGE]</>);
      } else {
        return (<><strong>${_available.row_price}</strong> &nbsp; {getBadge(_available.onhand + " " + _available.text + " in store", successColor, _badge)}</>);
      }
    case "forthcoming":
      // [TODO] Add "Pre order, etc" from sitesettings
      return (<><strong>${_available.row_price}</strong> &nbsp;{getBadge("Preorder", warningColor, _badge)}</>);
    case "no_price":
      return (<>{getBadge("May not be available", disabledColor, _badge)}</>);
    case "out_of_stock_indefinitely":
      return (<>{getBadge("Out of stock indefinitely", disabledColor, _badge)}</>);
    case "out_of_print":
      return (<>{getBadge("Out of print", disabledColor, _badge)}</>);
    default:
      return (<></>);
  }
}

const getBadge = (_label, _color, _badge) => {
  if (_badge) {
    return (<Badge style={{ "display": "block", "backgroundColor": _color }} count={_label} />);
  } else {
    return (<span style={{ "display": "block", "color": _color }}  >{_label}</span>);
  }
}

export const parseSubjects = (_subjects) => {
  if (!_subjects) { return "" }


  return _subjects.filter(item => item.label !== "").map((_subject, index) => {
    if (_subject.sub_labels.filter(sub => sub.label !== "").length > 0) {
      // has sub
      return (<span key={index}><strong>{_subject.subject}</strong> / {_subject.sub_labels.filter(sub => sub.label !== "").map((_sub) => { return (_sub.label) }).join(" / ")}<br /></span>)
    } else {
      return (<strong key={index}>{_subject.subject}</strong>);
    }
  })
}

export const parseSubjectsList = (_subjects) => {
  if (!_subjects) { return "" }
  return _subjects.map((_subject, index) => {
    if (_subject.sub_labels.filter(sub => sub.label !== "").length > 0) {
      // has sub
      return (<span key={index}><strong>{_subject.subject}</strong> <br /> {_subject.sub_labels.filter(sub => sub.label !== "").map((_sub, i) => { return (<span key={i}>{_sub.label}<br /></span>) })}</span>)
    } else {
      return (<span key={index}><strong>{_subject.subject}</strong><br /></span>);
    }
  })
}

export const parceAuthors = (_authors) => {
  if (!_authors) { return "" }
  return _authors.map((_author, index) => {

    let flag = (_author.canadian && window.sitesettings.store_info.country === "Canada");

    return (<span key={index}>{_author.name}{(flag && <span style={{ "paddingLeft": "2px" }}><CanadaIcon /></span>)}{(index !== _authors.length - 1) ? ", " : ""}</span>);
  });
}

export const getISBNCount = (_str) => {
  // Regular expression to match ISBNs
  const isbnRegex = /(?:ISBN(?:: ?| ))?((?:97[89])?\d{9}[\dx])/gi;
  // Use the regular expression to search the string and get all matches
  const matches = _str.match(isbnRegex);
  // If there are no matches, return 0
  if (!matches) {
    return 0;
  }
  // Return the length of the matches array
  return matches.length;
}

export const parseEventDates = (_from, _to, _timeonly = false) => {
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const dayLabels = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
  let from = new Date(_from * 1000);
  let to = new Date(_to * 1000);
  if (_timeonly) {
    return (formatAMPM(from) + " - " + formatAMPM(to));
  }
  if (from.getDay() !== to.getDay()) {
    return (dayLabels[from.getDay()] + " " + monthNames[from.getMonth()] + " " + from.getDate() + ", " + from.getFullYear() + " @" + formatAMPM(from) + "  -  " + dayLabels[to.getDay()] + " " + monthNames[to.getMonth()] + " " + to.getDate() + " @" + formatAMPM(to));
  }
  return (dayLabels[from.getDay()] + " " + monthNames[from.getMonth()] + " " + from.getDate() + ", " + from.getFullYear() + " | " + formatAMPM(from) + " - " + formatAMPM(to));
}

const formatAMPM = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

export const parceDate = (_date, _mini = false) => {
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  let d = new Date(_date * 1000);
  if (_mini) {
    return (monthNames[d.getMonth()].substr(0, 3) + " " + d.getFullYear());
  }
  return (monthNames[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear());
}

export const pluralize = (count, noun, suffix = 's') => {
  return (count === 1) ? noun : noun + suffix;
}

export const findInContext = (_context, _eisbn, _code = false) => {
  if (!_code) {
    // return all that match
    return _context.cart.items.filter(item => item.title_info.eisbn === _eisbn);
  } else {
    // return a specific that match
    let match_eisbn = _context.cart.items.filter(item => item.title_info.eisbn === _eisbn);
    return match_eisbn.filter(item => item.title_info.available.code === _code);
  }
}



export const existsInCart = (_context, _eisbn, _code = false) => {

  if (!_context.cart || !_context.cart.items) {
    return false;
  }
  let ret = _context.cart.items.filter(item => item.title_info.eisbn === _eisbn);
  ret = ret.filter(item => item.title_info.available.code === _code)


  if (ret.length > 0) {
    let retobj = {};
    retobj.count = ret[0].cart_info.quantity;
    retobj.cart_id = ret[0].cart_info.cart_id;
    retobj.code = _code;
    return retobj;
  } else {
    return false;
  }
}

export const existsInWishlist = (_context, _eisbn) => {
  if (!_context.wishlist.rows) return false;
  let ret = _context.wishlist.rows.filter(item => item.eisbn === _eisbn);
  if (ret.length > 0) {
    return true;
  } else {
    return false;
  }
}

export const getCookieByName = (_name) => {
  let cookieArr = document.cookie.split(";");
  for (let i = 0; i < cookieArr.length; i++) {
    let cookiePair = cookieArr[i].split("=");
    if (_name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    } else {
      return "";
    }
  }
}

export const addCommas = (_num) => {
  return _num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const objToPath = (_prefix, _obj) => {
  let location = _prefix;
  Object.keys(_obj).forEach((key) => {
    location += (Array.isArray(_obj[key]) && _obj[key].length > 0) ? "/" + key.toString() + "/" + _obj[key].join("/") : "";
  })
  //offset
  if (_obj.o !== 0) {
    location += "/o/" + _obj.o.toString();
  }
  //term
  if (_obj.t !== "") {
    location += "/t/" + encodeURI(_obj.t)
  }
  //key type
  if (_obj.k !== "") {
    location += "/k/" + encodeURI(_obj.k)
  }
  return location;
}



export const objectToPath = (_obj, firstpage = false) => {
  let location = "/filter";
  if (firstpage) {
    _obj.o = 0;
  }
  //(June 29, 2023) George - 
  //Brought this loop over from Biz site to be reused here and to be implemented as time goes on
  Object.keys(_obj).forEach((key) => {
    //runs if value is an array to properly append all values to url
    if (Array.isArray(_obj[key]) && _obj[key].length > 0) {
      if (_obj[key].includes("AND") && _obj[key].length < 3) {
        // strip and
        _obj[key] = _obj[key].filter(itm => itm !== "AND");
      }
      location += "/" + key.toString() + "/" + _obj[key].join("/")
      //runs for non-array values, like limit and offset
    } else if (_obj.hasOwnProperty(key) && _obj[key]) {
      //checks to see if the value at key is a string for encoding purposes
      if (typeof _obj[key] !== "string") {
        //run when value is not a string, so it can be turned into one for the sake of the url
        //examples are limit or offset, which are numbers
        //specific if to make sure firstpage values aren't being added to the url
        if ((key === 'l' && _obj[key] !== 26) || (key === 'o' && _obj[key] !== 0)) {
          location += "/" + key.toString() + "/" + _obj[key].toString();
        }
      } else {
        //runs when it is a string, so it can be properly encoded
        //example would be filter for subject
        location += "/" + key.toString() + "/" + encodeURI(_obj[key]);
      }
    }
  })

  return location;
}

export const extractValueFromUrl = (str) => {

  const index = str.indexOf("/lists/");
  if (index === -1 || index >= str.length - 12) {
    return ""; // "/lists/" not found or not enough characters after it
  }
  return str.substr(index + 7, 12); // 7 is the length of "/lists/" string

}

export const parseURL = (_itm) => {

  if (_itm.type === "features") {
    return "/features";
  }

  let _searchObj = _itm.search;

  if (typeof _searchObj !== "object" || Array.isArray(_searchObj) || _searchObj === null) {
    return false;
  }

  let { status = [], subj1 = [], binding = [], pubdate = [], audience = [], search_type = "", search_term = "" } = _searchObj;

  const map = {
    b: status, // browse
    s: subj1.filter((cat) => { return cat.substr(0, 2) !== "__" }), // subjects
    n: subj1.filter((cat) => { return cat.substr(0, 2) === "__" }), // non-books
    d: pubdate, // date 
    f: binding, // format 
    a: audience, // age
    o: 0, // offset
    l: 26, // limit
    t: search_term, // term
    k: search_type, // type
  }
  return objToPath("/browse/filter", map);
}