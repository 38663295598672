import React, { useState, useEffect, useRef } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';

// Take images, divide into slides (sets of images). number of slides depends on how many images can fit in the available width. 

const ProductCarousel = (props) => {

  const { drawItem = () => { }, limit = 0, drawPlaceholder = () => { }, gap = 10, image_size = 100, button_type = 1, sliderStyle = {} } = props;
  const carouselRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [containerWidth, setContainerWidth] = useState(1248);


  const numberToArray = (count) => {
    const resultArray = [];
    for (let i = 1; i <= count; i++) {
      resultArray.push(i);
    }
    return resultArray;
  }

  const images = (props.images.length > 0) ? props.images : numberToArray(limit);

  useEffect(() => {

    // Event fires like crazy, debounce to calm it down...
    const debounce = (func, delay) => {
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          func(...args);
        }, delay);
      };
    };

    // Resize
    const handleResize = debounce(() => {
      if(!carouselRef){
        return;
      }
      if(!carouselRef.current){
        return;
      }
      setContainerWidth(carouselRef.current.clientWidth);
      setCurrentSlide(0); // Reset current slide on resize
    }, 300);

    // Initial set
    handleResize();

    // Listener
    window.addEventListener('resize', handleResize);

    // Destroy listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };


  }, [])


  const divideIntoSlides = () => {
    const slides = [];

    
    for (let i = 0; i < images.length; i += Math.floor(containerWidth / (image_size + gap))) {
      slides.push(images.slice(i, i + Math.floor(containerWidth / (image_size + gap))));
    }
    return slides;
  };

  const slides = divideIntoSlides();

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === slides.length - 1 ? 0 : prevSlide + 1));
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? slides.length - 1 : prevSlide - 1));
  };



  return (




    <div style={sliderStyle}>
      <div ref={carouselRef} style={{ width: '100%', overflow: 'hidden', whiteSpace: 'nowrap', "position": "relative" }}>
        <div style={{ transition: 'transform 0.5s', transform: `translateX(-${currentSlide * 100}%)` }}>
          {slides.map((slide, index) => {

            // Most cases
            let _justify = "space-evenly";

            // Last silde if there are fewer images than can fit
            // if (currentSlide === (slides.length - 1) && slide.length < Math.floor(containerWidth / (image_size + gap))) {
            //   _justify = "flex-start";
            // }

            // two looks weird with a big gap - space even works best
            if (slide.length <= 5) {
              _justify = "center";
            }
            


            return (

              <div key={index} style={{ display: 'inline-block', "width": containerWidth + "px" }}>
                {/* Don't space evenly on last silde if there are fewer images than can fit... */}
                <div style={{ "display": 'flex', "justifyContent": _justify, gap: gap + "px", "alignItems" : "baseline" }}>

                  {slide.map(item => {

                    if (item.hasOwnProperty("eisbn")) {
                      return drawItem(item)
                    } else {
                      return drawPlaceholder(item)
                    }

                  })}

                  {/* {slide.map((image, i) => (

              
                // <img
                //   key={i}
                //   src={"https://cdn1.bookmanager.com/i/n?b=" + image.eisbn}
                //   alt={`Slide ${index + 1} Image ${i + 1}`}
                //   style={{ maxWidth: image_size + "px", height: 'auto' }}
                // />
              ))} */}
                </div>
              </div>
            )
          }
          )

          }
        </div>
        {/* HIDE IF ON FIRST SLIDE */}
        {((currentSlide !== 0) && <>
          <Button className={"bm-btn-" + button_type} disabled={(!currentSlide)} onClick={() => handlePrevSlide()} style={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)' }}>
            <LeftOutlined style={{ "minWidth": "20px" }} />
          </Button>
        </>)}

        {/* HIDE IF ON LAST SLIDE */}
        {((currentSlide !== (slides.length - 1)) && <>
          <Button className={"bm-btn-" + button_type} disabled={(currentSlide === (slides.length - 1))} onClick={() => handleNextSlide()} style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }} >
            <RightOutlined style={{ "minWidth": "20px" }} />
          </Button>
        </>)}
      </div>

    </div>
  );
};
export default ProductCarousel;