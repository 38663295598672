import React from "react";

import Html from "../components/utilities/Html";
import CSS from "../components/utilities/CSS";

export default function Custom(props) {

    const template = window.sitesettings.template_info;
    const { content = {} } = props;

    const {
        location = "main",
        component = "blank_space",
        container = true,
        data = {}
    } = content;

    const {
        html = "",
        css = ""
    } = data;

    const drawRender = () => {

        return(
            <div className={(container) ? "preview-container" : ""}>
            <CSS html={css} />
            <Html clear={false} className="" html={html} />
            </div>
        )
       
    }

    return drawRender();
}