import React, { useState } from "react";
import { Dropdown, Menu, Button, Space } from "antd";
import { GiftOutlined, PlusCircleFilled, MinusCircleFilled } from '@ant-design/icons';
import { apiCall } from "../../utilities/Api";
import { Link, useHistory } from "react-router-dom";

export default function GiftRegistryAdd(props) {

    const { eisbn = "", popup=false } = props;
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const history = useHistory(); 

    const adjustItem = (e, _gift_registry_id, _count, _amt) => {

        e.preventDefault();
        e.stopPropagation();

        if (_count === 1 && _amt === -1) {
            apiCall("giftregistry/deleteItem", { eisbn: eisbn, gift_registry_id: _gift_registry_id }, (_status, _result) => {
                if (_status) {
                    updateRow(_result);
                }
            })
            return;
        }
        if (!_count) {
            apiCall("giftregistry/addItem", { eisbn: eisbn, gift_registry_id: _gift_registry_id, qty: 1 }, (_status, _result) => {
                if (_status) {
                    updateRow(_result);
                }
            })
            return;
        } else {
            apiCall("giftregistry/updateItem", { eisbn: eisbn, gift_registry_id: _gift_registry_id, qty: _count + _amt }, (_status, _result) => {
                if (_status) {
                    updateRow(_result);
                }
            })
            return;
        }
    }

    const updateRow = (_row) => {
        let _arr = [...results];
        let _index = results.findIndex(item => item.gift_registry_id === _row.gift_registry_id);
        console.log(_index);
        _arr[_index] = _row;
        setResults(_arr);
    }

    const fetchGiftRegistry = () => {
        if (results.length === 0) {
            setLoading(true)
            apiCall("giftregistry/getAll", { show_items: true }, (_status, _result) => {
                if (_status) {
                    setResults(_result.registries);
                }
                setLoading(false)
            })
        }
    }

    const drawOverlay = (_eisbn) => {

        if (loading) {
            return (
                <Menu>
                    <Menu.Item style={{ "padding": "0px", "pointerEvents": "none" }}>
                        <div style={{ "padding": "5px 12px", "overflow": "hidden", "textOverflow": "ellipsis", "width": "200px" }} >Loading...</div>
                    </Menu.Item>
                </Menu>
            )
        }

        if (results.length === 0) {
            return (
                <Menu>
                    <Menu.Item style={{ "padding": "0px" }}>
                        <div style={{ "padding": "5px 12px", "overflow": "hidden", "textOverflow": "ellipsis", "width": "200px" }} >No registries. <Link to="/gift-registry">Create new</Link></div>
                    </Menu.Item>
                </Menu>
            )
        }

        return (
            <Menu>

                {results.map((item, index) => {

                    let _pos = item.items.findIndex(item => item.title.eisbn === eisbn);
                    let _found = (_pos > -1) ? true : false;
                    let _count = 0;

                    if (_found) {
                        console.log(_pos);
                        _count = item.items[_pos].qty_requested;
                    }

                    return (
                        <Menu.Item onClick={(e) => { adjustItem(e.domEvent, item.gift_registry_id, _count, 1) }} key={index} style={{ "padding": "0px" }}>
                            <Space size={0}>
                                {/* <Button onClick={(e) => pickQuantity(e)} size="small" type="text"><small><NumberOutlined style={{"color" : "#108ee9"}} /></small></Button> */}
                                <div style={{ "padding": "5px 12px", "overflow": "hidden", "textOverflow": "ellipsis", "width": "200px" }} >{item.title}</div>
                                <div style={{ "marginTop": "-3px" }}><Space size={0}> <MinusCircleFilled onClick={(e) => adjustItem(e, item.gift_registry_id, _count, -1)} style={{ "fontSize": "11px", "color": (_count > 0) ? "#888" : "#ddd" }} /> <div style={{ "width": "20px", "textAlign": "center" }}><small>{_count}</small></div> <PlusCircleFilled onClick={(e) => adjustItem(e, item.gift_registry_id, _count, 1)} style={{ "fontSize": "11px", "color": "#888" }} /><>&nbsp;&nbsp;&nbsp;</></Space></div>
                            </Space>
                        </Menu.Item>
                    )
                })}
                <Menu.Divider />
                <Menu.Item onClick={(e) => {
                        e.domEvent.preventDefault();
                        e.domEvent.stopPropagation();
                        history.push("/gift-registry");
                    }} style={{ "padding": "0px" }}>
                    <div  style={{ "padding": "5px 12px", "overflow": "hidden", "textOverflow": "ellipsis", "width": "210px" }} > View or create gift registries</div>
                </Menu.Item>
            </Menu>
        )
    }

    const drawRegistryBtn = (_eisbn) => {

        return (
            <>
                <Dropdown
                    zIndex={(popup) ? 1000 : 0}
                    overlay={drawOverlay(_eisbn)}
                    trigger={['click']}
                    arrow
                >
                    <Button style={{ "padding": "0px" }} onClick={() => fetchGiftRegistry()} icon={<GiftOutlined aria-hidden />} type="text"><small> Registry</small></Button>
                </Dropdown>
            </>
        )
    }



    return (<>{drawRegistryBtn()}</>)
}