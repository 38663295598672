import { Divider, Card, Button, Space, Popconfirm, Result, Empty } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { apiCall } from "../../utilities/Api";
import GiftRegistryCreate from "./GiftRegistryCreate";
import { EditOutlined, DeleteOutlined, ShareAltOutlined } from '@ant-design/icons';
import conditional from "../../utilities/conditional";
import moment from "moment";

export default function GiftRegistry() {

    const history = useHistory();
    const [results, setResults] = useState([])

    const getRegistries = () => {
        apiCall("giftregistry/getAll", { show_items: true }, (_status, _result) => {
            if (_status) {
                setResults(_result.registries);
            }
        })
    }

    const deleteRegistry = (e, _id) => {
        e.preventDefault();
        e.stopPropagation();
        apiCall("giftregistry/delete", { gift_registry_id: _id }, (_status, _result) => {
            if (_status) {
                getRegistries();
            }
        })
    }

    const updateRow = (_row) => {
        let _arr = [...results];
        let _index = results.findIndex(item => item.gift_registry_id === _row.gift_registry_id);
        if (_index !== -1) {
            _arr[_index] = _row;
        } else {
            _arr.push(_row);
        }
        setResults(_arr);
    }

    useEffect(getRegistries, []);

    const drawPurchased = (_requested = 0, _purchased = 0) => {
        return _purchased.toString() + " of " + _requested.toString() + " products purchased";
    }

    const drawRegistries = () => {
        return results.map((item, index) => {
            return (
                <Card key={index} size="small" title={<>{item.title}<div style={{ "marginTop": "-5px", "color": "#757575" }}><small>{drawPurchased(item.total_requested, item.total_purchased)}</small>

                  
                    

                </div></>} extra={
                    <Space size={2} >
                        <div onClick={(e) => e.stopPropagation()}><GiftRegistryCreate share data={item}><Button size="small"><small><ShareAltOutlined /></small></Button></GiftRegistryCreate></div>
                        <div onClick={(e) => e.stopPropagation()}><GiftRegistryCreate updateRow={updateRow} edit={true} data={item}><Button size="small"><small><EditOutlined /></small></Button></GiftRegistryCreate></div>
                        <div onClick={(e) => e.stopPropagation()}><Popconfirm title="Delete registry?" onConfirm={(e) => deleteRegistry(e, item.gift_registry_id)}><Button danger size="small"><small><DeleteOutlined /></small></Button></Popconfirm></div>
                    </Space>} onClick={() => history.push("/gift-registry/" + item.gift_registry_id)} hoverable>
                    <Card.Meta description={<div style={{"whiteSpace" : "break-spaces"}}>{item.description}</div>} />
                    <div className="shim" /><div className="shim" />
                    <div style={{ "height": "85px", "overflow": "hidden" }}>
                        <conditional.true value={(item.items.length === 0)}>
                            <Empty style={{ "marginTop": "10px" }} description="No products added to registry." image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </conditional.true>
                        <Space align="start" size={[9]} wrap>
                            {item.items.slice(0, (item.items.length === 6) ? 6 : 5).map(itm => {
                                return (<img onError={(i) => i.target.src = "https://cdn1.bookmanager.com/i/nocover.png"} alt={itm.title.title} style={{ "width": "55px" }} src={"https://cdn1.bookmanager.com/i/m?b=" + itm.title.eisbn + "&cb=" + itm.title.cover_image_cache} />)
                            })}
                            {((item.items.length > 6) && <div className="vcenter" style={{ "width": "55px", "height": "82px", "backgroundColor": "#eee", "border": "1px solid #ddd", "lineHeight": "12px", "textAlign": "center" }}><div style={{ "width": "55px" }}><small>+{item.items.length - 5}<br />more</small></div></div>)}
                        </Space>
                    </div>
                </Card>
            )
        })
    }

    if (!window.sitesettings.gift_registry_enabled) {
        return (<></>)
    }

    return (
        <>
            <div className="container">
                <div style={{ "float": "right" }}>
                    <GiftRegistryCreate updateRow={updateRow}> <Button >Create New</Button></GiftRegistryCreate>
                </div>
                <h2>Gift Registry</h2>
                <div aria-hidden><Divider /></div>
                {(results.length === 0 && <Result
                    icon={<Empty aria-hidden description="" />}
                    status="warning"
                    title="You have no gift registries."
                    subTitle={<><GiftRegistryCreate updateRow={updateRow}><Button style={{ "opacity": "0.6" }} type="link">Click here to create a new gift registry.</Button></GiftRegistryCreate></>}
                />)}
                <div className="giftwrapper">
                    {drawRegistries()}
                </div>
            </div>
        </>
    );

}