import React from "react";

import CSS from "../components/utilities/CSS";
import { getColor } from "./CC";

export const checkBrightness = (_c, _v = 240) => {

    var c = _c.substring(1);
    var rgb = parseInt(c, 16);
    var r = (rgb >> 16) & 0xff;
    var g = (rgb >> 8) & 0xff;
    var b = (rgb >> 0) & 0xff;

    var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    if (luma > _v) {
        return true;
    }

    return false;

}

export const rgbaToHex = (rgba) => {

    // Is a gradient - just return white to force a border
    if(rgba.substr(0, 6) === "linear"){
        return "#ffffff";
    }
    // Extracting the individual RGB values and alpha channel
    const values = rgba.substring(rgba.indexOf('(') + 1, rgba.lastIndexOf(')')).split(',');
  
    // Converting each RGB value to hexadecimal
    const hexValues = values.slice(0, 3).map((v) => {
      const hex = parseInt(v).toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    });
  
    // Converting alpha channel to hexadecimal
    const alphaHex = Math.round(parseFloat(values[3]) * 255).toString(16);
  
    // Concatenating the hex values
    const hex = '#' + hexValues.join('') + alphaHex;
  
    return hex;
  }


function Style() {







    const styles = window.sitesettings.styles;

    // "open_menu_background": "#00aeef",
    // "main_colour": "#f5f5f5",
    // "search_bar_colour": "#202020",
    // "search_bar_text_colour": "#ffffff",
    // "b_light_colour": "#683939",
    // "b_dark_colour": "#620D0C",
    // "b_text_1_colour": "#AAAAAA",
    // "open_menu_text": "#FFFFFF",
    // "solid_banner_colour": null,
    // "navbar_colour": "#202020",
    // "background_colour": "",
    // "logo_url": "https://bookmanager.com/i/mosaic/logo_navbar.png",
    // "background_url": "https://bookmanager.com/i/mosaic/background.png"


    let default_color = "#1890ff";


    // "search_bar_colour": "#202020",
    // "search_bar_text_colour": "#ffffff",
    // "main_colour": "#f5f5f5",
    // "main_text_colour": "#202020",
    // "open_menu_background_colour": "#00aeef",
    // "open_menu_text_colour": "#ffffff",
    // "b_light_colour": "#683939",
    // "b_dark_colour": "#620D0C",
    // "b_text_1_colour": "#AAAAAA",
    // "b_text_2_colour": "#FFFFFF",
    // "solid_banner_colour": null,
    // "background_colour": "#fbfbfb",



    let search_bar_colour = styles.search_bar_colour;
    let search_bar_text_colour = styles.search_bar_text_colour;
    let main_colour = styles.main_colour;
    let main_text_colour = styles.main_text_colour;
    let open_menu_background_colour = styles.open_menu_background_colour;
    let open_menu_text_colour = styles.open_menu_text_colour;
    let background_fixed = (styles.background_fixed) ? "fixed" : "unset";


    let gov_color = (checkBrightness(search_bar_colour, 127)) ? "" : "invert(1)";




    let background_url = (window.sitesettings.styles.use_background_colour) ? "" : styles.background_url;
    let background_colour = styles.background_colour;

    let bordered;
    let border_color;

    if (window.sitesettings.is_v1) {
        bordered = (checkBrightness(search_bar_colour)) ? "" : "none";
        border_color = (checkBrightness(search_bar_colour)) ? "#ddd" : "#fff";
        window.lightheader = (checkBrightness(search_bar_colour));
       
    } else {
        const template = window.sitesettings.template_info;
        let search_bar = window.sitesettings.global_content.find(item => item.component === "search_bar");
        background_colour = getColor(template.background_colour, template)
       
        bordered = (checkBrightness(rgbaToHex(getColor(search_bar.data.background_colour, template)))) ? "" : "none";
        border_color = (checkBrightness(rgbaToHex(getColor(search_bar.data.background_colour, template)))) ? "#ddd" : "#fff";
        window.lightheader = (checkBrightness(rgbaToHex(getColor(search_bar.data.background_colour, template))));

      
    }


    return (
        <>



            <CSS html={`


/* Store Title*/


.container{
    box-shadow: ${(window.sitesettings.is_v1) ? "0 2px 2px 0 rgba(0,0,0,.1);" : "none;"}
}

.store_name{
color: ${search_bar_text_colour};
}

/* Sidebar */

  .left_list_title{
    background-color:${main_colour};
    color: ${main_text_colour};
  }
  a.left_list_title{
    background-color:${main_colour};
    color: ${main_text_colour};
  }

  .left_list_title:hover{
    color: ${main_text_colour};
  }

/* Search */


.header_border{
    border: 1px solid ${border_color};
    
  }


.header_search .ant-input, .header_search .ant-input:hover, .header_search .ant-input:focus{
    border-color: ${border_color};
   
  }
  
  .header_search .ant-btn, .header_search .ant-btn:hover, .header_search .ant-btn:focus{
    border-color: ${border_color};
    
  }


  

.header_search{
    border:${bordered}; 
    border-radius:none;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child{
    background-color:transparent;
}

.header_search .ant-input-group-addon{
    border:${bordered};
    background-color:#fff;
}

/* Button */

.featured_btn{
    background-color: ${main_colour};
}

.featured_icon{
   fill: ${main_text_colour};
}

.featured_txt{
    color: ${main_text_colour};
 }

.styled_btn {
    background-color: ${search_bar_colour};
    color: ${search_bar_text_colour};
    border: 1px solid ${search_bar_text_colour};
}


/* Contact Table */

#contact_us_locations_wrapper{
  background-color: ${search_bar_colour};
  color: ${search_bar_text_colour};
}

#contact_us_locations_wrapper a{
  color: ${search_bar_text_colour};

}

#contactTitle{
    color:rgba(0, 0, 0, .8);
    text-align:center; 
    font-size : 2em;
}

.contact_table{
  background-color: #1581E9;
  color: #fff;
}



/* ARBITRARY */

.ant-drawer-header{
    border-bottom: 1px solid ${search_bar_colour};
  }

        .search_bar_colour{
            color : ${search_bar_colour};
        }


        .main_text_colour{
            color : ${main_text_colour};
        }
        .main_text_colour_fill{
            fill : ${main_text_colour};
        }

        .main_text_colour_bg{
            background-color : ${main_text_colour};
        }


        .search_bar_text_colour{
            color : ${search_bar_text_colour};
        }
        .search_bar_text_colour_fill{
            fill : ${search_bar_text_colour};
        }

        .search_bar_text_colour_bg{
            background-color : ${search_bar_text_colour};
        }

        .main_colour{
            color : ${main_colour};
        }

        .main_colour_bg{
            background-color : ${main_colour};
        }

        .main_text_colour{
            color : ${main_text_colour};
        }
        

        .open_menu_background_colour{
            color : ${open_menu_background_colour};
        }

        .open_menu_text_colour{
            color : ${open_menu_text_colour};
        }

        .ant-menu-dark.ant-menu-horizontal > .ant-menu-item, .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu a{
            color : ${main_text_colour}
        }

        .ant-menu-submenu-title a{
            color : ${main_text_colour}
        }
        

        .ant-menu-dark.ant-menu-horizontal> .ant-menu-submenu-selected a{
            color : ${open_menu_text_colour};
        }

        
/* Calandar */

.oldcal .eventCell{
  background-color: #fff;
  color: #000;
  border:1px solid ${main_colour};
  aspect-ratio : 4/3;
}

.oldcal .eventCellActive{
  color:  ${open_menu_text_colour};
  background-color: ${open_menu_background_colour};
  border:1px solid ${main_colour};
  aspect-ratio : 4/3;
}

.oldcal .eventCellActive:hover{
  border:1px solid ${main_text_colour};
}

.oldcal .calHeader{
  background-color: ${main_colour};
}

.oldcal .calHeader svg{
  fill:${main_text_colour};
}

.oldcal .calHeader h2{
  color:${main_text_colour};
}

.oldcal .ant-picker-cell{
  color: #fff;
}

.oldcal .ant-picker-cell-in-view{
  color: ${main_colour};
}
.oldcal.ant-picker-calendar .ant-picker-panel .ant-picker-body{
  background-color: ${main_colour} !important;
}

.oldcal .ant-picker-cell-inner{
  background-color: #fff;
}

.oldcal .ant-picker-content thead{
  background-color: ${open_menu_background_colour};
}

.oldcal .ant-picker-content th{
  color: ${open_menu_text_colour};
}

.oldcal.dateBox{
    width: 50px;
    height: 70px;
    padding: 5px 0px;
    background-color: ${open_menu_background_colour} !important;
}

.oldcal.dateBox span{
    display: block;;
    line-height: 20px;
    text-align: center;
    color: ${open_menu_text_colour};
}

.oldcal .dot{
    background-color: ${open_menu_text_colour};
}
  

/* BACKGROUND */

        body{
            background:url('${background_url}'); 
            background-size : auto;
            background-attachment : ${background_fixed};
            background-color: ${background_colour};
        }

        @media only screen and (max-width: 992px) {
           
            body:after {
                content: '';
                width: 100%;
                height: 100%;
                background-position: inherit;
                background-image: inherit;
                background-size: 1000px;
                background-attachment: inherit;
                background-repeat: inherit;
                position: fixed;
                top: 0;
                z-index: -1;
            }

        }

/* HEADER/FOOTER*/


        
        .brand_color, .brand_color svg{
           color: ${search_bar_colour};
        }
      
        .header-wrap, .desktopLogoNav, .site-footer, .mobileLogoNav{
            background-color:${search_bar_colour};
        }
        .mobileSearchBG{
            background-color:${search_bar_colour};
        }
        .ant-drawer-header{
            background:${search_bar_colour};
        }
        

/* HEADER/FOOTER ICON & TEXT  */


        .icon-btn, .footer-btn, .site-footer h4,  .site-footer p, .site-footer span, .site-footer div{
            color:${search_bar_text_colour};
            fill:${search_bar_text_colour};
        }

        

        .site-footer a, .site-footer a:hover{
            color:${search_bar_text_colour};
        }
        
        .icon-btn:hover, .site-footer a:hover{
          
        }

        .ant-drawer-title, .ant-drawer-close{
            color:${search_bar_text_colour};
            transition:none; 
        }

        .ant-drawer-close:hover{
            transition:none; 
            color:${search_bar_text_colour};
        }
        
/* MENU & NAVIGATION */
     
        
        .sitenav-wrap, .ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub{
            background-color:${main_colour};
        }

        .ant-menu-dark .ant-menu-item, .ant-menu-submenu-vertical-left, .ant-menu-submenu-vertical-left, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a, .ant-menu-dark .ant-menu-item > span > a{
            color:${main_text_colour};
            transition:none;
        }

        .ant-menu-dark .ant-menu-item > span > a:hover{
            color:${open_menu_text_colour};
        }

        .ant-menu-dark .ant-menu-item-selected > a, .ant-menu-dark .ant-menu-item-selected > span > a{
            color:${open_menu_text_colour};
            transition:none;
        }
   

        
        .ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub{
            color:${main_text_colour};
        }
      

        .ant-menu-dark .ant-menu-item-active, .ant-menu-dark .ant-menu-item:hover, .ant-menu-dark .ant-menu-item:focus-within, .ant-menu-dark .ant-menu-submenu-active, .ant-menu-dark .ant-menu-submenu-open, .ant-menu-dark .ant-menu-submenu-selected, .ant-menu-dark .ant-menu-submenu-title:hover, .ant-menu-dark .ant-menu-submenu-title:focus-within{
            color:${main_text_colour};
            transition:none;
        }

        .ant-menu-dark .ant-menu-item:hover > a, .ant-menu-dark .ant-menu-item:focus-within > a, .ant-menu-dark .ant-menu-item-active > a, .ant-menu.ant-menu-dark .ant-menu-item-selected,  .ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-dark.ant-menu-horizontal > .ant-menu-item:focus-within{
            color:${open_menu_text_colour};
            transition:none;
            background-color:${open_menu_background_colour};
            
        }

        .ant-menu-dark .ant-menu-item-selected > a{
            color:${open_menu_text_colour};
            transition:none;
        }
        
        .ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
            color:${main_text_colour};
            transition:none;
        }

        .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title {
            color:${open_menu_text_colour};
            transition:none;
        }

        
        .ant-menu-submenu-title{
            transition:none;
        }
        .ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title .anticon{
            transition:none;
        }

        .ant-menu-dark.ant-menu-horizontal .ant-menu-submenu-active{
            color:${open_menu_text_colour};
        }

        .ant-menu-dark.ant-menu-horizontal .ant-menu-submenu:hover, .ant-menu-dark.ant-menu-horizontal .ant-menu-submenu:hover > a, .ant-menu-dark.ant-menu-horizontal .ant-menu-submenu:focus-within, .ant-menu-dark.ant-menu-horizontal .ant-menu-submenu:focus-within > a{
            background-color:${open_menu_background_colour};
            transition:none; 
        }

        .ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover, .ant-menu-submenu-title:focus-within{
            background-color:${open_menu_background_colour};
            transition:none; 
        }

        .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title, .ant-menu-horizontal > .ant-menu-submenu:focus-within > .ant-menu-submenu-title{
            color:${open_menu_text_colour};
            transition:none; 
        }

        .ant-menu-dark.ant-menu-horizontal .ant-menu-submenu-selected, .ant-menu-submenu .ant-menu-submenu-inline .ant-menu-submenu-selected{
            transition:none;
            background-color:${open_menu_background_colour};
        }

        .ant-menu-title-content a:focus{
            color:${open_menu_text_colour} !important;
        }
        .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title{
            color:${open_menu_text_colour};
            transition:none;
        }
        .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title{
            color:${open_menu_text_colour};
            transition:none;
        }

        .ant-menu-dark .ant-menu-item:hover, .ant-menu-dark .ant-menu-item:focus-within, .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{
            background-color:${open_menu_background_colour};
            transition:none;
        }

        .ant-menu-horizontal > .ant-menu-submenu{
            transition:none;
        }


        

        /* INLINE */

        

        
        .ant-menu-dark .ant-menu-item:hover > a, .ant-menu-dark .ant-menu-item:focus-within > a, .ant-menu-dark .ant-menu-item-active > a, .ant-menu.ant-menu-dark .ant-menu-item-selected,  .ant-menu-dark.ant-menu-inline > .ant-menu-item:hover, .ant-menu-dark.ant-menu-inline > .ant-menu-item:focus-within{
            color:${open_menu_text_colour};
            transition:none;
            background-color:${open_menu_background_colour};
            
        }

        .ant-menu-dark .ant-menu-item-selected > a{
            color:${open_menu_text_colour};
            transition:none;
        }
        
        .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
            color:${main_text_colour};
            transition:none;
        }

        .ant-menu-dark.ant-menu-inline .ant-menu-submenu:hover, .ant-menu-dark.ant-menu-inline .ant-menu-submenu:hover > a, .ant-menu-dark.ant-menu-inline .ant-menu-submenu:focus-within, .ant-menu-dark.ant-menu-inline .ant-menu-submenu:focus-within > a{
            background-color:${open_menu_background_colour};
            transition:none; 
        }

        .ant-menu-inline > .ant-menu-submenu:hover > .ant-menu-submenu-title,  .ant-menu-inline > .ant-menu-submenu:focus-within > .ant-menu-submenu-title{
            color:${open_menu_text_colour};
            transition:none; 
        }


        .ant-menu-dark.ant-menu-inline .ant-menu-submenu-selected{
            transition:none;
            background-color:${open_menu_background_colour};
        }

        .ant-menu-inline > .ant-menu-submenu-selected > .ant-menu-submenu-title{
            color:${open_menu_text_colour};
            transition:none;
        }
        .ant-menu-inline > .ant-menu-submenu-selected > .ant-menu-submenu-title{
            color:${open_menu_text_colour};
            transition:none;
        }

        .ant-menu-dark .ant-menu-item:hover, .ant-menu-dark .ant-menu-item:focus-within, .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{
            background-color:${open_menu_background_colour};
            transition:none;
        }

        .ant-menu-inline > .ant-menu-submenu{
            transition:none;
        }


/* DEFAULTS */     
        

        // .ant-btn-text, .ant-btn-text:hover, .ant-btn-text:focus{
        //     color:${default_color};
        // }

        // .ant-btn-dashed:active, .ant-btn-dashed:hover, .ant-btn-dashed:focus{
        //     border-color:${default_color};
        // }


        // a, a:hover, a:focus{
        //     color:${default_color};
        // }

        
       

        // button.ant-btn-primary, button.ant-btn-primary:hover, button.ant-btn-primary:focus{
        //     background-color:${default_color};
        //     border:${default_color};
        //     color:#fff;
        // }

        // .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
        //     color:${default_color};
        // }

        

        
        `} />
        </>)


}

export default Style; 