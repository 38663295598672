import React from "react";
import {useParams} from "react-router-dom";
import OrderDetails from "../components/components/account/OrderDetails";
import Orders from "../components/components/account/Orders";

function OrdersLayout(){

    const {id} = useParams();
   
    const displayOrders = () =>{

        if(id){
           return(<OrderDetails />);
        } else {
        return(<Orders />);
        }
    }
return  (<div className="container">{displayOrders()}</div>)
}

export default OrdersLayout; 