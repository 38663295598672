import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Html from "../../utilities/Html";
import { Button } from 'antd';
import ItemSummary from "../item/ItemSummary";
import { apiCall } from "../../utilities/Api"
import EventsCalandar from "../events/EventsCalandar";
import { useSession } from "../../../util/Session";

/**
 * Vertical is a wrapper for custom/dynamic lists (mostly used on homepage)
 * - Can display a limited list of items vertically inside a sidebar.
 * - See more button added to the bottom
 * - Can display header and/or HTML
 * - Can be limited by "limit" field 
 */
export default function Vertical(props) {

    const [session] = useSession();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [results, setResults] = useState([]);
    const {list_id, has_titles} = props.data;

    // Callback from api call
    const onGetListItems = (_status, _result) => {
        if (!_status) {
            // error
            setError(true);
            setResults(_result);
        }
        setResults(_result.rows);
        setLoading(false);
    }

    // API Call on load

    const getListItems = () =>{
        setLoading(true);

        let obj = {
            "list_id": list_id
        }
        if(props.data.limit !==-1){
            obj.limit = props.data.limit;
        }
        apiCall("titlelist/getItems", obj, onGetListItems);
    }

    useEffect(getListItems, []);

    // UI while content is loading (empty)
    const displayLoading = () => {
        return (<></>)
    }

    // UI for error
    const displayError = () => {
        console.log(results);
        return (<></>)
    }

    // UI for empty
    const displayEmpty = () => {
        return (<></>);
    }

    // UI for Display
    const displayResults = () => {

        let limit = (props.data.limit > 2) ? props.data.limit : results.length;
        return results.slice(0, limit).map((item, index) => {
            return (<Link key={index} className="nav" to={{ "pathname": "/item/" + item.eisbn, "data": item }}><ItemSummary.SideBar show_price={(window.sitesettings.guest_show_price || session.logged_in)} data={item} /></Link>)
        })

    }

    // Run checks to see what to display
    const displayStatus = () => {

        // Check loading status
        if (loading) {
            return displayLoading();
        } else {

            // check for error
            if (error) { return displayError(); }

            else {

                // check if results are empty
                if (results.length < 1) {
                    return displayEmpty();
                }

                else {
                    return (<>
                        <div key={list_id} style={{"pointerEvents" : "none"}} className="slide" >
                            <div style={{ "display": "block", "textAlign" : "center" }}>
                                <img alt="list" onError={i => i.target.style.display = 'none'} src={"https://bookmanager.com/tl_img?edef_id=" + list_id} />
                            </div>
                        </div>

                        {displayResults()}
                        <Link to={"/lists/" + list_id} key="seeMore"><Button block style={{ "display": "block", "paddingBottom": "5px" }} type="text">View all...</Button></Link>
                    </>
                    );
                }
            }
        }
    }


    const ConditionalLink = (props) =>{

        if(has_titles){
            return(<Link className="left_list_title main_text_colour" to={"/lists/" + list_id}>{props.children}</Link>)
        } else {
            return(<div className="left_list_title main_text_colour">{props.children}</div>)
        }
    }



    const parseCalendar = () => {
        let chunks = props.data.description.split("<!--Calendar-->");
        return chunks.map((item, ind) => {
            if (ind === chunks.length - 1) {
                return (<Html key={ind} html={item} />);
            } else {
                return (<div key={ind}><Html html={item} /><span><EventsCalandar sidebar /></span></div>)
            }

        });
    }

    return (
        <div >
            {/* Display Header */}
            {(props.data.header_visible && (<><div style={{ "textAlign": "center" }}><ConditionalLink>{props.data.header}</ConditionalLink></div></>))}
            {/* inject Calendars into html */}
            {(props.data.calendar_widgets.length > 0 && parseCalendar())}
            {/* Display HTML regularly */}
            {(props.data.calendar_widgets.length === 0 && <Html html={props.data.description} />)}
            {displayStatus()}
        </div>
    )

}
