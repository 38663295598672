import React from "react";
import { getColor } from "../util/CC";
import { Button, Space, Form, Input, Spin } from "antd";
import MailchimpSubscribe from "react-mailchimp-subscribe";

export default function MailChimpEmbedded(props) {

    const template = window.sitesettings.template_info;
    const { content = {} } = props;

    const {
        location = "main",
        component = "blank_space",
        container = true,
        data = {},
    } = content;

    const {
        url = "",
        heading = "Subscribe",
        heading_preset = 0,
        subheading = "",
        subheading_preset = 0,
        label_preset = 0,
        response_preset = 0,
        button_type = 0,
        show_name = false,
        show_phone = false,
        background_colour = 0,
        width = 1248,
        has_width = false,
        align = "0 auto",
        vertical = false,
        padding_x = 10,
        padding_y = 10,
    } = data;

    const drawRender = () => {

        let _style = {
            padding: padding_y + "px " + padding_x + "px",
            maxWidth : (has_width) ? width + "px" : "100%",
            margin : align,
            textAlign : "center",
            backgroundColor : getColor(background_colour, template)
        }

        return (
            <div className={(container) ? "preview-container" : ""}>
                <div>
                    <MailchimpSubscribe
                        url={url}
                        render={({ subscribe, status, message }) => (
                            <>
                                <Spin spinning={(status === "sending")}>

                                    <div style={_style} >

                                        {(heading &&
                                            <h3 className={"bm-txt-" + heading_preset}>{heading}</h3>
                                        )}

                                        {(subheading &&
                                            <p className={"bm-txt-" + subheading_preset}>{subheading}</p>
                                        )}


                                        <Form onFinish={(_f) => subscribe(_f)} size={"large"} layout="vertical">
                                            <Space align="center" style={{ "justifyContent": "center" }} wrap direction={(vertical) ? "vertical" : "horizontal"}>

                                                <Form.Item style={{ "marginBottom": "0px" }} name="EMAIL">
                                                    <Input style={{ "width": "100%" }} placeholder="Email address..." type="email" />
                                                </Form.Item>

                                                {((show_name) && <>
                                                    <Form.Item style={{ "marginBottom": "0px" }} name="FNAME">
                                                        <Input placeholder="First name..." />
                                                    </Form.Item>

                                                    <Form.Item style={{ "marginBottom": "0px" }} name="LNAME">
                                                        <Input placeholder="Last name..." />
                                                    </Form.Item>
                                                </>)}

                                                {((show_phone) && <>
                                                    <Form.Item style={{ "marginBottom": "0px" }} name="PHONE">
                                                        <Input placeholder="Phone..." />
                                                    </Form.Item>
                                                </>)}

                                                <Form.Item style={{ "marginBottom": "0px" }} label={null}>
                                                    <Button className={"bm-btn-" + button_type} htmlType="submit" type="primary">Subscribe</Button>
                                                </Form.Item>

                                            </Space>

                                            <div className="shim" /><div className="shim" />
                                            {((status === "error" || status === "success") &&
                                                <div className={"bm-txt-" + response_preset}>{message}</div>
                                            )}
                                            <div className="shim" /><div className="shim" />
                                        </Form>
                                    </div>
                                </Spin>
                            </>
                        )}
                    />
                </div>
            </div>
        )

    }

    return drawRender();
}