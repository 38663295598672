import React from "react";
import moment from "moment";
import { Col, Divider, Row } from "antd";
export default function Hours(props) {


    const { ws_hours = {}, template = {}, data = {} } = props;

    const {
        hours_heading_preset = 0,
        hours_heading = "Hours",
        hours_preset = 0,
        upcoming_heading_preset = 0,
        upcoming_heading = "Upcoming Dates",
        upcoming_preset = 0
    } = data;

    const {
        title = "",
        weekdays = [],
        singles = []
    } = ws_hours;


    // Check if special dates are within 30 days of today's date
    const inRange = (month, day) => {
        const currentDate = moment();
        // moment months are zero indexed (bookmanager data isn't)
        const targetDate = moment().set({ month: month - 1, date: day });
        const thirtyDaysFromNow = moment().add(30, 'days');
        return targetDate.isBetween(currentDate, thirtyDaysFromNow, null, '[]');
    }

    // filter out dates out of range
    const specialArray = singles.filter(item => inRange(item.mon, item.day))

    // Name the weekdays starting monday (for some reason), * bookmanager dates are not zero indexed
    const weekdayNames = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];


    const formatTime = (minutes) => {
        if (minutes === -1) {
            return "Closed";
        }
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        const period = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
        return `${formattedHours}:${mins.toString().padStart(2, '0')} ${period}`;
    }

    const getDay = (month, day) => {
        const date = new Date(new Date().getFullYear(), month - 1, day - 1);
        return date.getDay();
    }

    const hours = weekdays.map((times, index) => {
        const [open, close] = times;
        const dayMatches = specialArray.some(item => {
            const { mon = 0, day = 0, range = [] } = item;
            const entry = getDay(mon, day);
            return entry === index;
        });

        return {
            day: weekdayNames[index],
            open: open === close ? "closed" : formatTime(open),
            close: open === close ? "closed" : formatTime(close),
            special: dayMatches,
        };
    });

    const special = specialArray.map(item => {
        const { mon = 0, day = 0, range = [] } = item;
        const [open, close] = range;
        return {
            day: weekdayNames[getDay(mon, day)],
            date: moment().set({ month: mon - 1, date: day }).format("MMMM Do"),
            open: open === close ? "closed" : formatTime(open),
            close: open === close ? "closed" : formatTime(close),
        }
    })


    const drawWeekDay = (_weekday) => {

        // No data for open = closed
        if (_weekday.open === "closed") {
            return (
                <Row>
                    <Col style={{ "textAlign": "left" }} span={12}>
                        <div>{_weekday.day} {(_weekday.special) ? "*" : <></>}</div>
                        <div>&nbsp;</div>
                    </Col>
                    <Col style={{ "textAlign": "center" }} span={12}>
                        <div>Closed &nbsp;</div>
                        <div>&nbsp;</div>
                    </Col>
                </Row>
            )
        }

        return (
            <Row>
                <Col style={{ "textAlign": "left" }} span={12}>
                    <div>{_weekday.day} {(_weekday.special) ? "*" : <></>}</div>
                    <div>&nbsp;</div>
                </Col>
                <Col style={{ "textAlign": "center" }} span={6}>
                    <div>{_weekday.open}</div>
                    <div>&nbsp;</div>
                </Col>
                <Col style={{ "textAlign": "center" }} span={6}>
                    <div>{_weekday.close}</div>
                    <div>&nbsp;</div>
                </Col>
            </Row>
        )
    }

    const drawWeekdays = () => {


        let _default_hours_style = {
            color: "#fff",
            fontSize: "14px",
            fontWeight: "normal"
        }


        return (
            <div className={"bm-txt-" + hours_preset} style={(hours_preset) ? {} : _default_hours_style}>
                <Row>
                    <Col style={{ "textAlign": "left" }} span={12}></Col>
                    <Col style={{ "textAlign": "center" }} span={6}>Open</Col>
                    <Col style={{ "textAlign": "center" }} span={6}>Close</Col>
                </Row>
                <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />
                {hours.map(item => {
                    return drawWeekDay(item)
                })}
            </div>
        )
    }


    const drawSpecial = (_special) => {



        // No data for open = closed
        if (_special.open === "closed") {
            return (
                <Row>
                    <Col style={{ "textAlign": "left" }} span={12}>
                        <div>* {_special.date}</div>
                    </Col>
                    <Col style={{ "textAlign": "center" }} span={12}>
                        <div>Closed &nbsp;</div>
                    </Col>
                </Row>
            )
        }

        return (
            <Row>
                <Col style={{ "textAlign": "left" }} span={12}>
                    <div>* {_special.date}</div>
                </Col>
                <Col style={{ "textAlign": "center" }} span={6}>
                    <div>{_special.open} &nbsp;</div>
                </Col>
                <Col style={{ "textAlign": "center" }} span={6}>
                    <div>{_special.close} &nbsp;</div>
                </Col>
            </Row>
        )
    }

    const drawSpecials = () => {

        let _default_upcoming_style = {
            color: "#f3ef6a",
            fontSize: "14px",
            fontWeight: "bold"
        }


        return (
            <div className={"bm-txt-" + upcoming_preset} style={(upcoming_preset) ? {} : _default_upcoming_style}>
                <Row>
                    <Col style={{ "textAlign": "left" }} span={12}></Col>
                    <Col style={{ "textAlign": "center" }} span={6}>Open</Col>
                    <Col style={{ "textAlign": "center" }} span={6}>Close</Col>
                </Row>
                
                <div>
                    {special.map(item => {
                        return drawSpecial(item)
                    })}
                </div>
            </div>
        )
    }


    const drawHours = () => {

        // Backend returns empty array instead of object if there are no hours
        if(Array.isArray(ws_hours)){
            return(<></>);
        }

        let _style = {
            maxWidth: "400px",
            margin: "0 auto"
        }

        let _default_hours_heading = {
            color: "#fff"
        }

        let _default_upcoming_heading = {
            color: "#f3ef6a"
        }

        return (
            <div style={_style}>
                <div>
                    <h4 className={"bm-txt-" + hours_heading_preset} style={(hours_heading_preset) ? {} : _default_hours_heading} >{title}</h4>
                    <div className="shim" style={{ "height": "20px" }} />
                    <div className={"bm-txt-" + hours_preset}>
                        {drawWeekdays()}
                    </div>
                </div>
                <div className="shim" /><div className="shim" /><div className="shim" />
                {(special.length > 0 &&
                    <div>
                        <h4 className={"bm-txt-" + upcoming_heading_preset} style={(upcoming_heading_preset) ? {} : _default_upcoming_heading}>{upcoming_heading}</h4>
                        <div className="shim" style={{ "height": "20px" }} />
                        <div className={"bm-txt-" + upcoming_preset}>
                            {drawSpecials()}
                        </div>
                    </div>
                )}
            </div>
        )

    }

    return drawHours();



}