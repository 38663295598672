import React, { useEffect, useState } from "react";
import { Card, Row, Col, Layout, Divider, Button, Form, Input, Alert, Radio } from 'antd';
import { CartSummary } from "./Cart";
import { useSession } from "../../../util/Session";
import { apiCall } from "../../utilities/Api";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Html from "../../utilities/Html";
import PhoneNumberFormatter from "../../formatters/PhoneNumberFormatter";
import conditional from "../../utilities/conditional";
import BMAlert from "../../../util/BMAlert";
import CustomForm from "./CustomForm";

export default function ContactInfo(props) {

    const history = useHistory();
    const [session, setSession] = useSession();
    const { saved, setSaved } = props;
    const [errorMSG, setErrorMSG] = useState("");
    const [contactForm] = Form.useForm();
    const [loading, setLoading] = useState();
    const [locationNotes, setLocationNotes] = useState("Beach | 1950 Queen Street East")
    const book_city_san = "1170384";
    let enabled_prefs = Object.keys(window.sitesettings.store_info.allowed_comm_prefs).filter(key => (window.sitesettings.store_info.allowed_comm_prefs[key]));

    let {
        preferred_communication = (session.user.communication_preference && window.sitesettings.store_info.allowed_comm_prefs[session.user.communication_preference]) ? session.user.communication_preference : enabled_prefs[0],
        promo_code = "",
        instructions = "",
    } = saved.contact;

    // HACK FOR BOOK CITY
    if (window.sitesettings.store_info.san === book_city_san) {
        instructions = instructions.replace("Beach | 1950 Queen Street East", "");
        instructions = instructions.replace("Bloor West | 2354 Bloor Street West", "")
        instructions = instructions.replace("Danforth | 348 Danforth Ave", "")
        instructions = instructions.replace("St. Clair | 1430 Yonge Street", "")
        instructions = instructions.replace(" | ", "")
    }

    // HACK FOR BOOK CITY
    const removeLocations = () => {
        if (saved.hasOwnProperty("contact")) {
            if (saved.contact.hasOwnProperty("instructions")) {
                setSaved({ ...saved, "contact": { ...saved.contact, "instructions": instructions } })
            }
        }
    }
    useEffect(removeLocations, [])

    // HACK FOR BOOK CITY
    const drawLocationSelection = () => {
        if (window.sitesettings.store_info.san === book_city_san && saved.delivery !== "ship") {
            return (
                <>

                    <div aria-hidden><Divider orientation="left" >Location</Divider></div>
                    <Radio.Group onChange={(e) => setLocationNotes(e.target.value)} value={locationNotes}>
                        <Radio value={"Beach | 1950 Queen Street East"}><strong>Beach</strong> | 1950 Queen Street East</Radio>
                        <div className="shim" />
                        <Radio value={"Bloor West | 2354 Bloor Street West"}><strong>Bloor West</strong> | 2354 Bloor Street West</Radio>
                        <div className="shim" />
                        <Radio value={"Danforth | 348 Danforth Ave"}><strong>Danforth</strong> | 348 Danforth Ave</Radio>
                        <div className="shim" />
                        <Radio value={"St. Clair | 1430 Yonge Street"}><strong>St. Clair</strong> | 1430 Yonge Street</Radio>
                    </Radio.Group>
                    <div className="shim" /> <div className="shim" />
                </>
            )
        }
    }



    const getCustomForm = () => {

        let ci = window.sitesettings.checkout_info;
        let formdata = false;
        switch (saved.delivery) {
            case "pickup":
                if ((ci.form_pickup || ci.form_pickup === 0) && !saved.custom) {
                 
                    formdata = ci.custom_forms[ci.form_pickup]
                }
                if ((ci.form_custom_pickup || ci.form_custom_pickup === 0) && saved.custom) {
                    formdata = ci.custom_forms[ci.form_custom_pickup]
                }
                break;
            case "ship":
                if ((ci.form_ship || ci.form_ship === 0) && !saved.custom) {
                    formdata = ci.custom_forms[ci.form_ship]
                }
                if ((ci.form_custom_ship || ci.form_custom_ship === 0) && saved.custom) {
                    formdata = ci.custom_forms[ci.form_custom_ship]
                }
                break;
        }

        if (formdata) {
            return formdata;
        } else {
            return false;
        }
    }

    const customForm = getCustomForm();


    const onSetExtras = (_status, _result) => {

        setLoading(false);
        if (_status) {
            window.checksum = _result.checksum;
            setSession({ ...session, "cart": _result })
            history.push("/checkout/payment");
        } else {
            setErrorMSG(_result.error);
            console.log(_result);
        }
    }

    const setExtras = (_form) => {

        if (_form.hasOwnProperty("phone")) {
            _form["phone"] = _form["phone"].replace(/\D/g, '');
        }

        // HACK FOR BOOK CITY
        if (window.sitesettings.store_info.san === book_city_san) {
            _form.instructions = locationNotes + " | " + _form.instructions;
        }

        setLoading(true);
        setErrorMSG("");
        setSaved({ ...saved, "contact": _form });
        apiCall("checkout/setExtras", _form, onSetExtras);
    }

    const drawEmailGiftCardsDisclaimer = () => {
        return (

            <div>
                <div><strong>{window.sitesettings.store_info.giftcard_emailed_label}</strong></div>
                <div aria-hidden><Divider dashed style={{ "margin": "15px 0px" }} /></div>
                <Html className="" html={window.sitesettings.store_info.giftcard_emailed_message} />
                <br />
            </div>

        )
    }

    const displayNotice = () => {


        if (saved.delivery === "electronic") {
            return (
                <div>
                    <div aria-hidden><Divider orientation="left" >Notice</Divider></div>
                    <div style={{ "padding": "30px", "border": "1px dashed #ddd" }}>
                        {drawEmailGiftCardsDisclaimer()}
                    </div>
                </div>
            )

        }

        return (
            <>

                <div aria-hidden><Divider orientation="left" >Notice</Divider></div>
                <div style={{ "padding": "30px", "border": "1px dashed #ddd" }}>
                    <conditional.true value={(!session.logged_in)}>
                        <conditional.true value={(window.sitesettings.checkout_info.ship_enabled)}>
                            <p style={{ "color": "#e70d3d" }}><em>Guest checkout is for in-store pick up only. If you would like your order shipped, <Link to="/checkout/cart"><strong style={{ "color": "#e70d3d" }}>tap here to go back</strong></Link> and log in or create an account.</em></p>
                        </conditional.true>
                    </conditional.true>

                    <Html className="" html={window.sitesettings.checkout_info.checkout_message} />

                    <conditional.true value={(session.cart.summary.contains_emailed_gift_card)}>
                        {drawEmailGiftCardsDisclaimer()}
                    </conditional.true>

                </div>
            </>)
    }


    const displayContact = () => {
        if (session.cart.can_click_collect && !session.logged_in) {
            return (<></>)
        } else {
            return (<>
                <div aria-hidden><Divider orientation="left" >Contact Info</Divider></div>

                <BMAlert className="msg" description={<>
                    <div style={{ "textAlign": "left" }}>
                        <strong>Contact</strong>
                        <div>{session.user.first_name} {session.user.last_name}</div>
                        <div>{session.user.email_address}</div>
                        <div><PhoneNumberFormatter display country={session.user.country} value={session.user.phone_number} /></div>
                        <div className="shim"></div>
                        <div className="shim"></div>
                        <Link to="/account/details"><Button size="small" ><small>Edit Account</small></Button></Link>
                        <div className="shim"></div>
                        <div className="shim"></div>
                        {(Object.keys(saved.shipping).length > 0 &&
                            <>
                                <strong>{(saved.delivery === "local_delivery" ? "Local Delivery" : "Shipping")}</strong>
                                <div>{saved.shipping.name}</div>
                                <div>{saved.shipping.email_address}</div>
                                <div>{saved.shipping.phone_number}</div>
                                <div>{saved.shipping.street_address}</div>
                                {(saved.shipping.street_address_2 && <div>{saved.shipping.street_address_2}</div>)}
                                <div>{saved.shipping.city}, {saved.shipping.province} {saved.shipping.country}</div>
                                <div>{saved.shipping.postal_code}</div>
                            </>
                        )}
                        <div style={{ "height": "2px" }} className="shim"></div>
                    </div>
                </>} /></>)
        }
    }


    const displayClickCollectForm = () => {
        if (session.cart.can_click_collect && !session.logged_in) {
            return (<>
                <Form.Item label="Your name" initialValue={(saved.contact.name)} name="name" rules={[{ required: true, message: 'Please input your name.' }]}>
                    <Input placeholder="Your name" />
                </Form.Item>
                <Form.Item label="Email address" name="email" initialValue={(saved.contact.email)} rules={[{ type: "email", required: true, message: 'Please input your email address.' }]}>
                    <Input placeholder="Email address" type="email" />
                </Form.Item>
                <Form.Item label="Phone" name="phone" initialValue={(saved.contact.phone)} rules={[{ required: true, message: 'Please input your phone number.' }]}>
                    {/* <Input placeholder="Phone" inputMode="numeric" pattern="[0-9]*" /> */}
                    <PhoneNumberFormatter form={contactForm} country={"Canada"} style={{ width: "156px" }} inputMode="numeric" placeholder="Phone Number" />
                </Form.Item>
            </>)
        } else {
            return (<></>)
        }
    }


    const instructions_message = (window.sitesettings.checkout_info.show_order_inscription) ? "Inscription" : "Instructions or comments"


    return (<>
        <Layout className="responsiveSider">
            <Layout.Content style={{ "width": "auto" }}>
                <Card>


                    <Row gutter={20}>
                        <Col xs={24} lg={14}>
                            {displayNotice()}
                            {displayContact()}
                        </Col>
                        <Col xs={24} lg={10}>

                            {drawLocationSelection()}
                            <div aria-hidden><Divider orientation="left" >Additional Information</Divider></div>
                            <Form onFinish={(_form) => setExtras(_form)} form={contactForm} layout="vertical">
                                {displayClickCollectForm()}

                                {(!(customForm && customForm.override_po_id) &&
                                    <Form.Item initialValue={promo_code} label="Purchase Order #" name="promo_code">
                                        <Input maxLength={10} placeholder="Purchase Order # (optional)" />
                                    </Form.Item>
                                )}

                                <Form.Item initialValue={instructions} label={instructions_message} name="instructions">
                                    <Input.TextArea maxLength={1000} rows={5} placeholder={instructions_message} />
                                </Form.Item>

                                <Form.Item help={((preferred_communication !== session.user.communication_preference) && session.logged_in) ? <small style={{ "color": "#e70d3d" }}>*Your previously set preference ({session.user.communication_preference}) will be changed.</small> : ""} initialValue={(!window.sitesettings.store_info.texting_enabled && preferred_communication === "text") ? "phone" : preferred_communication} label="Preferred method of communication" name="preferred_communication">
                                    <Radio.Group buttonStyle="solid">
                                        {(window.sitesettings.store_info.allowed_comm_prefs.email && <Radio.Button value="email">Email</Radio.Button>)}
                                        {(window.sitesettings.store_info.allowed_comm_prefs.phone && <Radio.Button value="phone">Phone</Radio.Button>)}
                                        {(window.sitesettings.store_info.allowed_comm_prefs.text && <conditional.true value={window.sitesettings.store_info.texting_enabled}>
                                            <Radio.Button value="text">Text</Radio.Button>
                                        </conditional.true>
                                        )}
                                    </Radio.Group>
                                </Form.Item>

                                <CustomForm formdata={customForm} saved={saved} form={contactForm} />
                                {/* {JSON.stringify(saved, null, 2)} */}

                            </Form>
                        </Col>
                    </Row>
                </Card>
            </Layout.Content>
            <Layout.Sider width="none" className="responsiveSummary" theme="light">
                <CartSummary>
                    <br />
                    {(errorMSG &&
                        <><BMAlert className="msg" description={<><span style={{ "textAlign": "center", "width": "100%", "display": "block" }}>{errorMSG}</span></>} type="error" /><br /></>)}
                    <Button loading={loading} block onClick={() => contactForm.submit()} type="primary">Save &amp; Continue</Button>


                    {((saved.delivery === "pickup") && <>
                        <div className="shim" /><div className="shim" />
                        <BMAlert style={{ "padding": "10px 10px", "textAlign": "center", "lineHeight": "16px" }} message={<small>{((session.cart.summary.contains_gift_registry_item) ? <>If you would like the recipient of gift registry purchases to pick up their gift(s) at the store, you will need to notify them when their item(s) are ready for pick up.<br /><div className="shim" /> We will only be sending order status updates to you.</> : <>If someone other than yourself is going to be picking up, please let us know ahead of time who that will be. <br /><div className="shim" />If you provided this in the "Instructions or comments" during checkout, thank you, no follow up is needed!<br /><div className="shim" /> We will only be sending order status updates to you.</>)}</small>} type="error" />
                    </>)}

                </CartSummary>

            </Layout.Sider>
        </Layout>
    </>
    )
}