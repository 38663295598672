import React, { useEffect, useState } from "react";
import { Button, Modal, Tooltip } from 'antd';
import GridDisplay from "../../../shared/GridDisplay";
import { useLocation } from "react-router-dom";
import conditional from "../../utilities/conditional";
import moment from "moment";
import { DownOutlined } from '@ant-design/icons';

export default function Bindings(props) {

    const data = props.data;
    const {is_large_print} = data; 
    const loc = useLocation();
    const [modalVisible, setModalVisible] = useState(false);
    const close = () => { setModalVisible(false) }

    const closeOnChange = () => {
        if (modalVisible) {
            close();
        }
    }

    useEffect(closeOnChange, [loc])

    const errorMessage = () => {

        return (
            <div style={{ "textAlign": "center", "padding": "50px" }}>
                <h2>We're sorry!</h2>
                <p><span style={{ "fontSize": "20px", "color": "#444" }}>There seems to be no other editions for {data.title}.</span>
                    <br />
                    Our database is not always perfectly linked up. We suggest trying the search to locate other possible editions or bindings.
                </p>

            </div>
        )

    }

    const wrapPublishDate = (_date) => {
        if (data.is_forthcoming) {
            return (<span style={{ "color": "#BD5800" }}>Releases: {moment(data.release_date * 1000).format('MMM DD, YYYY')}</span>)
        } else {
            return (<span>Published: {moment(data.release_date * 1000).format('MMM DD, YYYY')}</span>)
        }
    }


    return (
        <>
            <conditional.true value={((data.binding) && data.release_date)}>
                <Tooltip mouseEnterDelay={0.5} title={"View other editions or formats of this title."}>
                    <Button icon={<DownOutlined aria-hidden style={{ "float": "right", "padding": "15px 10px" }} />} onClick={() => setModalVisible(true)} size="large" style={{ "textAlign": "left", "height": "60px", "fontSize": "14px" }} block type="dashed">
                        <strong>{(is_large_print) ? "Large Print " : ""}{(data.binding) ? data.binding : "Bindings"}</strong><br />
                        <div className="see-other" ><em>Other editions</em></div>
                        {(data.release_date && wrapPublishDate(data.release_date))}
                    </Button>
                </Tooltip>
            </conditional.true>
            <Modal wrapClassName="siteModal" width="90%" title={"Other editions of '" + data.title + "'"} open={modalVisible} onCancel={close} destroyOnClose closable={true} footer={null}>
                <GridDisplay popup={true} overwrite_error={errorMessage()} audiobook={(data.has_libro_audiobook) ? data.eisbn : false} data={data} show_count={false} method="title/getBindingItems" wrap={false} args={{ "eisbn": data.eisbn }} />
            </Modal>
        </>
    );

}