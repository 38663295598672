import React from "react"
import { Card, Steps, Button, Layout, Divider } from 'antd';
import { CartSummary } from "../checkout/Cart";
import ShippingProviders from "./ShippingProviders";

export default function Checkout() {


    const drawSteps = () => {
        return (
            <div className="hide-block" style={{ "padding": "20px 100px" }}>
                <Steps size="small">
                    <Steps.Step key="cart" title="Cart" />
                    <Steps.Step key="address" title="Address" />
                    <Steps.Step key="delivery" title="Delivery" />
                    <Steps.Step key="contact" title="Contact" />
                    <Steps.Step key="payment" title="Payment" />
                    <Steps.Step key="review" title="Review" />
                </Steps>
                <div className="shim" /><div className="shim" /><div className="shim" />
            </div>
        )
    }


    return (
        <>
            <div className="container">
                <Button style={{ "float": "right" }}><span style={{ "fontSize": "14px", "lineHeight": "18px" }}>Go back</span></Button>
                <h2>Delivery</h2>
                {drawSteps()}


                <Layout className="responsiveSider">
                    <Layout.Content style={{ "width": "auto" }}>
                       
                       <ShippingProviders />



                    </Layout.Content>
                    <Layout.Sider width="none" className="responsiveSummary" theme="light">
                        <CartSummary>
                            <br />
                            <Button block type="primary">TO DO - Continue</Button>
                        </CartSummary>
                    </Layout.Sider>
                </Layout>




            </div>
        </>
    )

}


