import React from "react";
import { Checkbox, Badge, Modal, Select, Divider } from "antd";
import Cookies from "js-cookie";
import { useState } from "react";
import { DatabaseFilled } from '@ant-design/icons';
export default function LiveToggle() {

    
    const [isLive, setIsLive] = useState(Cookies.get("l") || "false");

    // Don't show toggle in production
    let mode = process.env.REACT_APP_API || 'inherit';
    if (mode === "live" || mode === "staging") {
        return (<></>);
    }

    const showModal = () => {

        Modal.info({
            title: "DB & API",
            icon : <></>,
            width: 300,
            content: (
                <>
                    <Divider style={{"margin" : "15px 0px"}} dashed />
                    <Checkbox defaultChecked={(isLive === "true")} onChange={(e) => Cookies.set("l", (e.target.checked) ? "true" : "false")}>Use live data?</Checkbox>
                    <div className="shim" />
                    <div className="shim" />
                    
                    <label>API Cookie</label>
                    <Select defaultValue={Cookies.get("api") || ""} style={{"width" : "100%"}} onChange={(e) => {
                        
                        if(e){
                            Cookies.set("api", e);
                        } else {
                            Cookies.remove('api');
                        }
                        
                    }}>
                        <Select.Option key="unset" value="">Unset</Select.Option>
                        <Select.Option key="tbraun" value="https://tbraunapi.bookmanager.com/customer/">Tobias <small><em>(tbraun)</em></small></Select.Option>
                        <Select.Option key="eric" value="https://ericapi.bookmanager.com/customer/">Eric <small><em>(eric)</em></small></Select.Option>
                        <Select.Option key="atoth" value="https://atothapi.bookmanager.com/customer/">Adam <small><em>(atoth)</em></small></Select.Option>
                        <Select.Option key="dev" value="https://devapi.bookmanager.com/customer/">Dev <small><em>(dev)</em></small></Select.Option>
                        <Select.Option key="staging" value="https://stagingapi.bookmanager.com/customer/">Staging <small><em>(staging)</em></small></Select.Option>
                        <Select.Option key="live" value="https://api.bookmanager.com/customer/">Live <small><em>(api)</em></small></Select.Option>
                    </Select>
                </>
            ),
            onOk : () => {
                window.location.reload(); 
            }
        })

    }

    let _style = {
        boxShadow: "0px 2px 3px 1px #aaa",
        width: "26px",
        position: "fixed",
        zIndex: "1000",
        top: "-1px",
        left: "20px",
        backgroundColor: "#fff",
        borderBottomLeftRadius: "3px",
        borderBottomRightRadius: "3px",
        cursor: "pointer",
        margin: "0 auto"
    }


    return (
        <div onClick={() => showModal()} style={_style} >
            <div style={{"height" : "2px"}} className="shim" />
            <div style={{"textAlign" : "center"}}><Badge color={(isLive === "true") ? "green" : "default"} /></div>
            <div style={{"height" : "2px"}} className="shim" />
        </div>
    )
}