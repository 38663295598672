

// Resources
import React, { useState } from "react";
import { Divider, Button, Empty, Result, Skeleton, Row, Col } from 'antd';
import { Link } from "react-router-dom";


// Components
import NewSlider from "../wrappers/NewSlider";
import PageNavigation from "../../../shared/PageNavigation";
import {pluralize} from "../../../util/Utils";
import FilterOptionsBar from "./FeaturesOptionsBar";
import FeaturesSearchAndSort from "../features/FeaturesSearchSort";
import moment from "moment";

export default function FeaturesResults(props) {

    const {results, loading=false, filters=[], initialFilters={}, error=false, dim, searchFilters, setSearchFilters, limit} = props; 
    const {row_count} = results;

    const setReset = () =>{
        setSearchFilters(initialFilters)
    }
    
    //[TODO] Link to filters
    const[currentPage, setCurrentPage] = useState(1);

    const onPageChange = (_page, _page_size) =>{
        setCurrentPage(_page);
        setOffset((_page-1)*limit);
        setSearchFilters({...searchFilters, "o" : (_page-1)*limit});
    }

     //[TODO] Send Up 
     const setOffset = (_bla) =>{

    }
  
    const displayStatus = () =>{

        if(loading) return displayLoading();
        if(error) return displayError(); 

        if(results.row_count<1){
            return displayEmpty(); 
        } else {
            return display(); 
        }



    }

    const displayError = () =>{
        return (<div className={(props.wrap) ? "container" : ""} >
                    {(props.title!=="" && <h2>{props.title}</h2>)}
                    <div aria-hidden><Divider /></div>
                    <div style={{ "display": "block", "textAlign": "center" }}>
                        <Result
                            status="error"
                            title={props.error_message}
                        />
                    </div>
                </div>
            )
    }

    const displayLoading = () =>{
        return (<div className={(props.wrap) ? "container" : ""} >
                    <Skeleton active />
                    <Skeleton active />
                    <Skeleton active />
                </div>
            )
    }

    const displayEmpty = () => {

        return (<div className={(props.wrap) ? "container" : ""} >
                    <h2>{props.title}</h2>
                  
                    <div aria-hidden><Divider style={{"marginTop" : "46px"}} /></div>
                    <Result
                        status="warning"
                        icon={<Empty aria-hidden description="" />}
                        title={"No Results."}
                        extra={  <Button onClick={() => setReset()}>Reset</Button>}
                    />
                </div>)
    }

    const display = () => {
        

        return (
            <div className={(props.wrap) ? "container" : ""} >
                <h2>{props.title}</h2>

                <Row style={{"marginTop" : "-8px"}}>
                <Col xs={24} md={12}><div style={{"height" : "32px"}} className="vcenter"><h3 style={{ "display": "block", "lineHeight" : "9px"}}>{results.row_count + " " + pluralize(results.row_count, "feature", "s")} </h3></div></Col>
                <Col xs={24} md={12}><div><FeaturesSearchAndSort initialFilters={initialFilters} setSearchFilters={setSearchFilters} searchFilters={searchFilters} filters={filters} /></div></Col>
                </Row>
                <div aria-hidden><Divider /></div>
                {(row_count>limit && <><PageNavigation total={row_count} current={currentPage} page_size={limit} onChange={onPageChange} /><div aria-hidden><Divider /></div></>)}
                
                <div className={(dim) ? "wait" : "ready" }> 
                    {results.rows.map((list, index) => {
                        return(
                        <div key={index}>
                              <div style={{"textAlign" : "center"}}>
                                <div className="shim" /><div className="shim" />
                        <h3><Link to={"/lists/" + list.list_id}>{list.header}</Link>
                        <span style={{"fontSize" : "14px", "marginTop" : "5px"}}>
                            <strong>{moment(list.date*1000).format("MMM Do YYYY")}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;
                            {(list.category!=="" && <strong>{list.category}</strong>)}
                            {(list.sub_category!=="" && <> / {list.sub_category}</>)} 
                        </span>
                        
                        </h3>
                        </div>
        
                        <div style={{"marginLeft" : "-20px"}}>

                          
                                

                             <NewSlider sidebar={false} features={true} index={1} key={list.list_id} data={{...list, "header_visible" : false}} />

                            {/* <Slider data={{...list, "header_visible" : false}} /> */}
                            
                            </div>
                        <FilterOptionsBar data={list} />
                        <div aria-hidden><Divider /></div>
                        </div>
                        ); 
                    })}
                </div>
                {(props.show_count && <div aria-hidden><Divider /></div>)}
                {(row_count>limit && <><PageNavigation total={row_count} current={currentPage} page_size={limit} onChange={onPageChange} /><div aria-hidden><Divider /></div></>)}
            </div>
        )
    }


    return displayStatus();

}
