import React, {useEffect, useState} from "react"
import Html from "../components/utilities/Html";
import { apiCall } from "../components/utilities/Api";
export default function GlobalBanner(){

    const [results, setResults] = useState({});

    const onGetList = (_status, _results) =>{
        if(_status){
            setResults(_results);
        } else {
            setResults(_results);
        }
    }

    const getList = () =>{
        if(window.sitesettings.persistent_banner_list_id){
            apiCall("titlelist/get",{"list_id" : window.sitesettings.persistent_banner_list_id}, onGetList)
        }
    }

    useEffect(getList, []);
    

    return(
        <>
          {(results.image && <img alt={results.header} src={results.image} />)}
          {(results.description && <div className="bannerWrap"><Html html={results.description}/></div>)}
        </>
    )
}