import React, { useState } from "react";

export default function BrowseFilterSub(props) {

    const { heading, index, currentFilters = [], parent_ref = "", scroll = false } = props;


    const isNumeric = (str) => {
        if (typeof str != "string") return false // we only process strings!  
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }

    let start_open = false;
    currentFilters.forEach(element => {
        if (isNumeric(element.substr(element.length - 3, 3))) {
            start_open = true;
        }
    });

    const [show, setShow] = useState(start_open);

    const moveSetShow = (_v) => {

        if (_v && scroll) {
            setTimeout(() => {
                parent_ref.current.scrollTop = parent_ref.current.scrollTop + 32;

            }, 100)
        }
        setShow(_v);
    }

    return (
        <div key={index}>
            <div className={(show) ? "flip" : "noflip"} style={{ "cursor": "pointer" }} onClick={() => moveSetShow(!show)}>{props.heading}</div>
            <div style={{ "display": (show) ? "inherit" : "none" }}>
                {props.children}
            </div>
        </div>
    )


}