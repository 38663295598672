import React, { useState } from "react";
import { Alert, Button, Checkbox, DatePicker, Divider, Form, Input, message, Modal, Select, Typography, Table, Space } from "antd";
import { useSession } from "../../../util/Session";
import { GiftOutlined, PlusCircleFilled, MinusCircleFilled } from '@ant-design/icons';
import moment from "moment-timezone";
import { apiCall } from "../../utilities/Api";
import conditional from "../../utilities/conditional";
import { Link } from "react-router-dom";
import AddressForm from "../account/AddressForm";
import { useForm } from "antd/lib/form/Form";

export default function GiftRegistryCreate(props) {

    const [visible, setVisible] = useState(false);


    const { data = {}, edit = false, share = false, updateRow = () => { } } = props;
    const {
        gift_registry_id = "",
        store_can_edit = false,
        title = "",
        occasion = "Birthday",
        description = "",
        shipping_encouraged = true,
        shipping_id = null,
        purchase_deadline = null,
        items = []
    } = data;




    const [session, setSession] = useSession();


    const saveAddress = (_fields) => {

        // remove optional
        if (!_fields.email_address) {
            delete _fields.email_address;
        }

        apiCall("address/create", _fields, (_status, _result) => {
            if (_status) {

                apiCall("session/get", {}, (_s, _r) => {
                    setSession(_r);
                    setModalVisible(false);
                    setShipping(_result.address_id);
                })
            }
        });
    }


    const [form] = useForm();
    const [modalVisible, setModalVisible] = useState(false);


    const drawModal = () => {

        return (
            <Modal destroyOnClose onCancel={() => setModalVisible(false)} open={modalVisible} footer={<></>}>
                <Form layout="vertical" onFinish={(e) => saveAddress(e)} form={form}>
                    <Typography.Title level={5}>Add address</Typography.Title>
                    <div aria-hidden><Divider style={{ "margin": "10px 0px" }} /></div>
                    <AddressForm form={form} />
                    <Button htmlType="submit">Submit</Button>
                </Form>
            </Modal>
        )

    }



    const occasions = ["Birthday", "Wedding", "Baby", "School", "Holiday", "Bookfair", "Other"];
    let addresses = []
    addresses = session.user.addresses || [];

    const [shipping, setShipping] = useState((addresses.find(item => item.address_id === shipping_id)) ? shipping_id : "");
    const [encouraged, setEncouraged] = useState(shipping_encouraged);


    const [copied, setCopied] = useState(false);
    const copyToClipboard = (_txt) => {
       
        navigator.clipboard.writeText(_txt);
        setCopied(true);
        message.success("Link Copied!");
        setTimeout(() => {
            setCopied(false)
        }, 1000);
    }



    const adjustItem = (e, _esibn, _gift_registry_id, _count, _amt) => {



        e.preventDefault();
        e.stopPropagation();

        // force remove
        if (_amt === "remove") {
            _count = 1;
            _amt = -1;
        }

        if (_count === 1 && _amt === -1) {
            apiCall("giftregistry/deleteItem", { eisbn: _esibn, gift_registry_id: _gift_registry_id }, (_status, _result) => {
                if (_status) {
                    updateRow(_result);
                }
            })
            return;
        }
        if (!_count) {
            apiCall("giftregistry/addItem", { eisbn: _esibn, gift_registry_id: _gift_registry_id, qty: 1 }, (_status, _result) => {
                if (_status) {
                    updateRow(_result);
                }
            })
            return;
        } else {
            apiCall("giftregistry/updateItem", { eisbn: _esibn, gift_registry_id: _gift_registry_id, qty: _count + _amt }, (_status, _result) => {
                if (_status) {
                    updateRow(_result);
                }
            })
            return;
        }
    }


    const showLink = (_res) => {

        let link = window.sitesettings.store_info.base_url + "/gift-registry/" + _res.gift_registry_id;
        Modal.info({
            title: <strong>{(share) ? "Share" : "Registry Created!"}</strong>,
            closable: true,
            icon: <></>,
            test: () => {
                // console.log("called")
            },
            okButtonProps: { style: { display: 'none' } },
            okText: "",
            content: <>
                You can now use the Search bar or Browse area to find titles and add them to your gift registry. Once you are ready, share the following link with whomever you would like to allow viewing and purchasing. Check back under your account’s Gift Registry menu to see which products have been purchased, and if you want to make changes to the list or requested quantities.
                <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />
                <Input.Search style={{"fontFamily" : "monospace"}} onSearch={(e) => copyToClipboard(e)} value={link} enterButton={(copied) ? "Copied!" : "Copy link"} />
            </>
        })
    }

    const createRegistry = (_form) => {

        // cull shipping and deadline if not set
        if (!_form.gift_registry_id) { delete _form.gift_registry_id; }
        if (!_form.shipping_id) { delete _form.shipping_id; }
        if (!_form.purchase_deadline_ts) { delete _form.purchase_deadline_ts; } else {
            _form.purchase_deadline_ts = moment(_form.purchase_deadline_ts).endOf('day').unix();
        }

        if (edit) {
            apiCall("giftregistry/update", _form, (_status, _result) => {
                if (_status) {
                    updateRow(_result);
                    message.success("Registry updated.");
                    setVisible(false);
                } else {
                    message.error(_result.error)
                }
            })
        } else {
            apiCall("giftregistry/create", _form, (_status, _result) => {
                if (_status) {
                    updateRow(_result);
                    setVisible(false);
                    showLink(_result);
                } else {
                    message.error(_result.error)
                }
            })
        }
    }

    const disabledDate = (current) => {
        return current && current.valueOf() < Date.now();
    }


    const columns = [
        {
            title: '', dataIndex: 'image', width: 50, key: 'image', render: (e, f) => {
                return (<img onError={(i) => i.target.src = "https://cdn1.bookmanager.com/i/nocover.png"} className="shadow-cart" alt={f.title.title} style={{ "width": "35px" }} src={"https://cdn1.bookmanager.com/i/m?b=" + f.title.eisbn + "&cb=" + f.title.cover_image_cache} />)
            }
        },
        {
            title: <small>Title</small>, dataIndex: 'title', key: 'title', render: (e, f) => {
                return <small>{f.title.title}</small>
            }
        },

        {
            title: <small></small>, width: 70, dataIndex: 'actions', key: 'actions', render: (e, f) => {
                return <Space>
                    <div style={{ "marginTop": "-3px" }}><Space size={0}> <MinusCircleFilled onClick={(e) => { return (f.qty_requested > 1) ? adjustItem(e, f.title.eisbn, f.gift_registry_id, f.qty_requested, -1) : false }} style={{ "fontSize": "13px", "color": (f.qty_requested > 1) ? "#888" : "#ddd" }} /> <div style={{ "width": "20px", "textAlign": "center" }}><small>{f.qty_requested}</small></div> <PlusCircleFilled onClick={(e) => adjustItem(e, f.title.eisbn, f.gift_registry_id, f.qty_requested, 1)} style={{ "fontSize": "13px", "color": "#888" }} /><>&nbsp;&nbsp;&nbsp;</></Space></div>
                </Space>
            }
        },
        {
            title: <small>Title</small>, width: 80, dataIndex: 'remove', key: 'remove', render: (e, f) => {
                return <Button onClick={(e) => adjustItem(e, f.title.eisbn, f.gift_registry_id, f.qty_requested, "remove")} size="small" danger><small>Remove</small></Button>
            }
        }
    ];


    const drawForm = () => {

        return (
            <>
                {drawModal()}
                <Typography.Title level={5}>{(edit) ? "Edit" : "New"} Gift Registry
                    <div className="shim" />
                    <span style={{ "display": "block", "color": "#838383", "fontWeight": "normal", "lineHeight": "16px" }}>
                        <small>Add products to a gift registry, then share the link. When titles are purchased from that link, they will be marked so others will not accidentally purchase more than you want. You can modify the contents of a registry at any given time without the need to send a new link.</small>
                    </span>
                </Typography.Title>
                <Divider style={{ "margin": "15px 0px" }} />
                <Form onFinish={(_f) => createRegistry(_f)} layout="vertical">

                    <Form.Item hidden initialValue={gift_registry_id} name="gift_registry_id">
                        <Input />
                    </Form.Item>

                    <Form.Item initialValue={title} name="title" label="Title" rules={[{ required: true, message: 'Title is required.' }]}>
                        <Input placeholder="Title..." />
                    </Form.Item>

                    <Form.Item initialValue={occasion} name="occasion" label="Occasion" rules={[{ required: true, message: 'Occasion is required.' }]}>
                        <Select>
                            {occasions.map((item, index) => {
                                return (<Select.Option key={index} value={item}>{item}</Select.Option>)
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item initialValue={description} name="description" label="Description" rules={[{ required: true, message: 'Description is required.' }]}>
                        <Input.TextArea maxLength={2000} placeholder="Description..." />
                    </Form.Item>

                    <conditional.true value={((window.sitesettings.checkout_info.ship_enabled || window.sitesettings.checkout_info.local_delivery_enabled) && addresses.length > 0)}>
                        <Form.Item initialValue={(addresses.find(item => item.address_id === shipping_id)) ? shipping_id : ""} name="shipping_id" help={<>
                            <small>{(shipping) ? "Address from your address book will show up as a shipping option for purchasers." : "The purchaser is responsible for shipping or pick up"}</small>
                        </>} label="Shipping">
                            <Select onChange={(e) => setShipping(e)} placeholder="Select an address...">
                                <Select.Option key={"noship"} value={""}>
                                    Do not ship to me
                                    <div style={{ "marginTop": "-10px", "color": "#AAA" }}><small>the purchaser is responsible for shipping or pick up</small></div>
                                </Select.Option>
                                {addresses.map((item, index) => {
                                    return (<Select.Option disabled={(!item.can_ship_to)} key={index} value={item.address_id}>
                                        {item.name}
                                        {(!item.can_ship_to && <div style={{ "float": "right", "color": "#f00" }}><small>Can't ship to {item.province}</small></div>)}
                                        <div style={{ "marginTop": "-10px", "color": "#AAA" }}><small>{item.street_address} </small></div>
                                    </Select.Option>)
                                })}
                            </Select>
                        </Form.Item>
                    </conditional.true>

                    <conditional.true value={(shipping && (window.sitesettings.checkout_info.ship_enabled || window.sitesettings.checkout_info.local_delivery_enabled))}>
                        <div className="shim" /><div className="shim" />
                        <Form.Item initialValue={shipping_encouraged} help={<div style={{ "lineHeight": "16px" }}><small>
                            <conditional.true value={(encouraged)}>
                                The above address from your address book will show up as the recommended shipping option for purchasers, though their own shipping address(es) and the option to pick up in store (if applicable) will remain as options.
                            </conditional.true>
                            <conditional.true value={(!encouraged)}>
                                The above address from your address book will show up as a shipping option for purchasers, alongside their own shipping address(es) and the option to pick up in store (if applicable).
                            </conditional.true>

                        </small></div>} valuePropName="checked" name="shipping_encouraged" >
                            <Checkbox onChange={(e) => setEncouraged(e.target.checked)}>Encourage purchasers to ship to selected address.</Checkbox>
                        </Form.Item>
                        <div className="shim" />
                    </conditional.true>

                    <conditional.true value={(addresses.length === 0)}>
                        <Alert message={<>No recomended shipping addresses available. <div><small>
                            {/* <Link to="/account/addresses"> */}
                            <span style={{ "color": "#4c93eb", "cursor": "pointer" }}>
                                <em onClick={() => setModalVisible(true)}>Click here to add an alternate addresses</em>
                            </span>
                            {/* </Link> */}
                        </small>
                        </div></>} type="warning" />
                        <div className="shim"></div> <div className="shim"></div>
                    </conditional.true>

                     <div className="shim" /><div className="shim" />
                    <Form.Item initialValue={(purchase_deadline) ? moment(purchase_deadline * 1000) : null} name="purchase_deadline_ts" label="Purchase deadline (End of day)">
                        <DatePicker disabledDate={disabledDate} dropdownClassName="interact" />
                    </Form.Item>
                   
                    <Form.Item noStyle initialValue={store_can_edit} valuePropName="checked" name="store_can_edit" >
                        <Checkbox>Allow store to manage registry?</Checkbox>
                    </Form.Item>
                    <div className="shim" />
                    <div className="ant-form-item-explain"><div style={{"lineHeight" : "16px"}}><small>When this option is checked, the store will have the ability to add or remove titles, change the desired quantities for titles, and edit the Title, Description, Occasion, and Purchase Deadline. The store will not be able to modify the shipping address or shipping preference if one was specified. Use this option if you would like the store to co-manage this gift registry.</small></div></div>

                    
                    <div className="shim" /><div className="shim" /> <div className="shim" /><div className="shim" />

                    <conditional.true value={(items.length > 0)}>
                        <div className="ant-col ant-form-item-label">
                            <label>Products</label>
                        </div>
                        <Table showHeader={false} scroll={{ y: 279 }} style={{ "border": "1px solid #eee", "borderBottom": "0px" }} pagination={false} columns={columns} dataSource={items} size="small"></Table>
                    </conditional.true>

                    <Divider style={{ "margin": "0px", "marginBottom": "20px" }} />
                    <div style={{ "float": "right" }}><Button type="primary" htmlType="submit">Save</Button></div>
                    <br clear="all" />
                </Form>
            </>
        )

    }


    if (share) {
        return (<><span onClick={() => showLink(data)}>{props.children}</span></>)
    }

    return (
        <>
            <Modal destroyOnClose footer={false} onCancel={() => setVisible(false)} open={visible}>{drawForm()}</Modal>
            <div onClick={(e) => { e.stopPropagation(); e.preventDefault(); setVisible(!visible) }}>{props.children}</div>
        </>
    );
}