import { Button, Input, Form, Space, Divider } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import Modal from "antd/lib/modal/Modal";
import React, { useState } from "react";
import { apiCall, apiDownload } from "../../utilities/Api";
import { FileExcelOutlined} from '@ant-design/icons';

export default function ExportList(props) {

    const { list_id = "" } = props;
    const [modalVisible, setModalVisible] = useState(false);
    const [excelOptions, setExcelOptions] = useState([]);

    const onGetExcelOptions = (_status, _result) => {
        if (_status) {
            setExcelOptions(_result);
            setModalVisible(true);
        }
    }

    const getExcelOptions = () => {
        
        apiCall("titlelist/getExcelOptions", {}, onGetExcelOptions);
    }

  
    const getExcel = (_f) => {

        let m = {}

        // change checkbox true to 1 for reasons... 
        Object.keys(_f).forEach((item) =>{
            if(_f[item]) {
                m[item] = (_f[item] && item !== "list_id") ? "1" : "0";
            }
        })

        m.list_id = _f.list_id; 

        apiDownload(m, "titlelist/getExcel");
       // apiCall("titlelist/getExcel", m, onGetExcel, true);
    }

    const drawOptions = () => {
        return (
            <>
                <Form layout="vertical" onFinish={(_f) => getExcel(_f)}>
                    {excelOptions.map((item, _index) => {
                        return (
                            <div key={_index}>
                                <Form.Item valuePropName="checked" name={item.flag} noStyle>
                                    <Checkbox >{item.options.display}</Checkbox>
                                </Form.Item>
                                <div className="shim"></div>
                            </div>
                        )
                    })}
                    <Form.Item noStyle initialValue={list_id} name="list_id">
                        <Input type="hidden"></Input>
                    </Form.Item>
                    <div className="shim"></div>   <div className="shim"></div>
                    <div className="shim"></div> <div className="shim"></div>
                    <div style={{ "float": "right" }}>
                        <Space>
                            <Button onClick={() => setModalVisible(false)}>Cancel</Button>
                            <Button type="primary" htmlType="submit">Export</Button></Space>
                    </div>
                    <br clear="all" />
                </Form>
            </>
        )
    }

    return (
        <>
            <Modal onCancel={() => setModalVisible(false)} destroyOnClose open={modalVisible} centered={true} width={400} footer={null}>
                <h3>Export to Excel</h3>
                <div aria-hidden><Divider /></div>
                <p>To export this title list to Excel format, choose from the display option(s) below and click "Export".</p>
                <div className="shim"></div>
                {drawOptions()}
            </Modal>
            <Button onClick={() => getExcelOptions()} type="text" icon={<FileExcelOutlined aria-hidden />}></Button>
        </>
    );
}