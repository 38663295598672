import React, { useEffect, useState } from "react";
import { apiCall } from "../../utilities/Api"
import { Divider, Space } from 'antd';
import Html from "../../utilities/Html";
import { Link } from "react-router-dom";

export default function Featured(props) {

    const { list_id, description = "", header_visible = false, header = "", has_titles = true, image_cache="" } = props.data;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [eisbn, setEisbn] = useState("");
    const [coverCache, setCoverCache] = useState("");

    const [imageCount, setImageCount] = useState(1);

    const onLoadListItems = (_status, _results) => {
        if (!_status) {
            setError(true);
        } else {
            if (_results.rows.length > 0) {
                setImageCount(imageCount + 1);
                setEisbn(_results.rows[0].eisbn);
                setCoverCache(_results.rows[0].cover_image_cache);
            }
        }
        setLoading(false);
    }

    const loadListItems = () => {
        setLoading(true);
        apiCall("titlelist/getItems", { list_id: list_id, limit: 1 }, onLoadListItems);
    }

    useEffect(loadListItems, [])


    // hack
    const fillWidth = () => {

        return (<div style={{ "height": "0px", "opacity": "0", "overflow": "hidden" }}>
            --------------------
            --------------------
            --------------------
            --------------------
            --------------------
            --------------------
            --------------------
            --------------------
            --------------------
            --------------------
            --------------------
        </div>);
    }

    const displayLoading = () => {
        return (<></>);
    }

    const displayError = () => {
        return (<></>)
    }

    const dropImage = (_i) => {
        _i.target.style.display = 'none';
        // setImageCount(imageCount - 1);
    }

    const ConditionalLink = (props) => {

        if (has_titles) {
            return (<Link to={"/lists/" + list_id}>{props.children}</Link>)
        } else {
            return (<>{props.children}</>)
        }
    }


    const display = () => {
        return (<>
            <div className="">
                {(header_visible && <ConditionalLink><><h3 style={{ "display": "inline", "fontSize": "24px", "fontWeight": "normal" }}>{header}</h3>&nbsp;&nbsp;&nbsp;&nbsp;<small style={{ "color": "#069" }}>See more</small></></ConditionalLink>)}
                <div className="shim"></div>
                <div className="shim"></div>
                <div className="shim"></div>

                <div className="hide">
                    <Space align="start">
                        <div className="vcenter" style={{ "height": "200px" }}>
                            <ConditionalLink>
                                <img alt={header} key="image" style={{ "maxHeight": "200px" }} onError={i => dropImage(i)} src={"https://cdn1.bookmanager.com/i/tl_img?full=1&edef_id=" + list_id + "&cb=" + image_cache} />
                            </ConditionalLink>
                        </div>
                        {((imageCount === 2) && <div aria-hidden><Divider type="vertical" style={{ "height": "200px" }} /></div>)}
                        {(eisbn &&
                            <ConditionalLink>
                                <img alt={header} style={{ "height": "200px" }} onError={i => dropImage(i)} className="shadow-slider" key="item" src={"https://cdn1.bookmanager.com/i/m?b=" + eisbn + "&cb=" + coverCache } />
                            </ConditionalLink>)}
                        {((imageCount > 0) && <div aria-hidden><Divider type="vertical" style={{ "height": "200px" }} /></div>)}
                        <div className="hide">
                            {fillWidth()}
                            <Html html={description} />
                        </div>
                    </Space>
                </div>

                <div className="show">

                    <div style={{ "margin": "0 auto", "textAlign": "center" }}>
                        <ConditionalLink>
                            <img alt={header} key="image" style={{ "maxHeight": "200px" }} onError={i => dropImage(i)} src={"https://cdn1.bookmanager.com/i/tl_img?full=1&edef_id=" + list_id + "&cb=" + image_cache}  />
                        </ConditionalLink>
                        <div className="shim" /><div className="shim" /><div className="shim" />
                        {(eisbn &&
                            <ConditionalLink>
                                <img alt={header} style={{ "height": "200px" }} onError={i => dropImage(i)} key="item" src={"https://cdn1.bookmanager.com/i/m?b=" + eisbn + "&cb=" + coverCache } />
                            </ConditionalLink>)}
                    </div>


                    {((imageCount > 0) && <><br /><br /></>)}<Html html={description} />

                </div>

            </div>
        </>)
    }

    const displayStatus = () => {

        if (loading) return displayLoading();
        if (error) return displayError();
        return display();

    }

    return displayStatus();

}