/*
 AccountBox 
  - Desktop displays a drop-down (PopOver) 
 Props
  -
*/

// Resources
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSession } from "../util/Session";
import { Popover, Button, Divider, Drawer, Badge, Avatar } from 'antd';
import { UserOutlined, CheckOutlined } from '@ant-design/icons';
import { apiCall } from "../components/utilities/Api";
import PermissionSelector from "./PermissionSelector";


// Deconstructs

function AccountBox(props) {

  const {avatar_color = "", avatar_bg = ""} = props; 

  const [session, setSession] = useSession()
  const [popOverVisible, setPopOverVisible] = useState(false);

  const dismiss = () => {
    setPopOverVisible(false);
    setVisible(false);
  }

  const handlePopOverVisibleChange = (visible) => {
    setPopOverVisible(visible);
  }

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onLogout = (_status, _result) => {
    if (_status) {
      setSession(_result);
    } else {
      // somehow failed to logout - force
      console.log("Failed to logout - Weird Error");
      setSession({
        "session_id": "26EkYyG1XfMJCEtSuq9dRW8ow1LP",
        "logged_in": false,
        "user": [],
        "cart": []
      });
    }
  }

  const logout = () => {
    setPopOverVisible(false);
    apiCall("account/logout", {}, onLogout);
  }


  // ask the permission selector if there is multiple accounts to display the link account
  const [, setHasAccountLink] = useState(false);

  
  const getInitals = () => {

    let _f = session.user.first_name.substr(0,1);
    let _l = session.user.last_name.substr(0,1);
    return _f + _l; 
  }

  const loginForm = (
    <div style={{ minWidth: "180px" }}>
      <PermissionSelector dismiss={dismiss} setHasAccountLink={setHasAccountLink} />
      <Link to="/account/details"><Button onClick={() => dismiss()} style={{ "width": "100%", "marginBottom": "10px" }} htmlType="submit">Account Details</Button></Link>
      {/* <conditional.true value={(hasAccountLink || session.user.is_institution)}>
        <br />
        <Link to="/account/link"><Button onClick={() => dismiss()} style={{ "width": "100%", "marginBottom": "10px" }} htmlType="submit">Linked Accounts</Button></Link>
      </conditional.true> */}
      <br />

      <Link to="/account/addresses"><Button onClick={() => dismiss()} style={{ "width": "100%", "marginBottom": "10px" }} htmlType="submit">Address Book</Button></Link>
      <br />
      <Link to="/account/lists"><Button onClick={() => dismiss()} style={{ "width": "100%", "marginBottom": "10px" }} htmlType="submit">My Lists</Button></Link>
      
      {((window.sitesettings.gift_registry_enabled) && <><Link to="/gift-registry"><Button onClick={() => dismiss()} style={{ "width": "100%", "marginBottom": "10px" }} htmlType="submit">Gift Registry</Button></Link><br /></>)}
      
      <Link to="/account/orders"><Button onClick={() => dismiss()} style={{ "width": "100%" }} htmlType="submit">View Orders</Button></Link>
      <Divider />
      <Link to="/"><Button onClick={() => logout()} style={{ "width": "100%" }} type="danger" htmlType="submit">Log out</Button></Link>
    </div>
  );

  return (
    <div>
      <Popover destroyTooltipOnHide className="hide" open={popOverVisible} onOpenChange={handlePopOverVisibleChange} title="Account" trigger="click" content={loginForm} placement="bottomRight" >
        <Button aria-label="Account" className="tab styled_btn" ><span aria-hidden style={{"maxWidth" : "120px", "overflow" : "hidden", "textOverflow" : "ellipsis"}}>
          <UserOutlined />&nbsp; {(session.user.is_institution && session.user.company)  ? session.user.company : session.user.first_name}</span></Button>
      </Popover>
      <div className="show"><Avatar style={{"marginTop" : "0px", "cursor" : "pointer",  "backgroundColor" : "#ffffff11", "color" : avatar_color, "boxShadow" : "0px 0px 0px 1px #00000011"}} onClick={showDrawer} className="bm-icon" size={28}><div style={{"marginTop" : "0px" }}>{getInitals()}</div></Avatar> 
      {/* <Badge offset={[-5, 20]} count={<span className="bm-icon">MH</span>}><UserOutlined className="icon-btn bm-icon" onClick={showDrawer} /></Badge> */}
        <Drawer
          
          placement="bottom"
          title={session.user.first_name + " " + session.user.last_name}
          height="380px"
          mask={false}
          onClose={onClose}
          open={visible}
        >
          <div style={{ "backgroundColor": "#fff", "padding": "20px" }}>
            {loginForm}
            <br />
          </div>
        </Drawer>
      </div>
    </div>
  );
}

export default AccountBox;