import React from "react";

import OrderDetails from "./OrderDetails";

export default function OrderComplete(){

    //console.log("GUEST CHECKOUT!");
    return(
    <div className="container">
          <OrderDetails />
    </div>
  
    );

}