import React, { useState, useEffect } from "react"
import Cookies from 'js-cookie';
import uuid from "react-uuid";


const defaultApi = "https://ericapi.bookmanager.com/customer/";
const mode = process.env.REACT_APP_API || 'inherit';
const live = Cookies.get("l") || "false";


const getApiPath = () => {
    // Get ENV variable from start and build scripts and set the path. 
    let _path = "";
    switch (mode) {
        case "dev":
            _path = "https://devapi.bookmanager.com/customer/"
            break;
        case "staging":
            _path = "https://stagingapi.bookmanager.com/customer/"
            break;
        case "live":
            _path = "https://api.bookmanager.com/customer/"
            break;
        default:
            _path = defaultApi;
            break;
    }
    return _path;
}

const apiPath = (Cookies.get('api')) ? Cookies.get('api') : getApiPath();

const unique_id = uuid();


export const apiDownload = (_obj, _api) => {

    let path = window.location.origin + window.sitesettings.basename + "download/customer/" + _api + "?store_id=" + window.sitesettings.store_info.id;

    //let path =  window.sitesettings.store_info.base_url + "/download/customer/" + _api +  "?store_id=" + window.sitesettings.store_info.id;
    Object.keys(_obj).forEach(element => {
        path += "&" + element + "=" + _obj[element];
    });



    window.open(path, "_blank");
}

export const apiCall = (_methodName, _args, _callback, _link = false) => {

    var form_data = new FormData();

    form_data.append("uuid", unique_id);


    let session_id = Cookies.get("session_id");


    if (session_id) {
        form_data.append("session_id", Cookies.get("session_id"));
    }

    if (!session_id) {
        session_id = window.session_id;
        form_data.append("session_id", session_id);
    }



    form_data.append("log_url", window.location.pathname);

    if (window.sitesettings) {
        form_data.append("store_id", window.sitesettings.store_info.id);
    }

    if (_args) {
        for (var key in _args) {
            form_data.append(key, _args[key]);
        }
    }

    if (_link) {
        const linkData = [...form_data.entries()];
        const asString = linkData
            .map(x => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
            .join('&');
        _callback(true, asString);

        return;
    }

    let _params = {
        method: 'POST',
        body: form_data
    }

    if (mode !== "live" && mode !== "staging" && live === "true") {
        _params.headers = { "x-tbm-data": "l" }
    }


    fetch((apiPath + _methodName), _params)
        .then(response => response.json())
        .then((r) => {
            // request is formatted, but there is an error
            if (r.error) {
                // is error
                _callback(false, r)
            } else {
                // is success
                _callback("success", r)
            }
        }).catch((e) => {
            _callback(false, e)
            // is error
        });

}

export function Api(props) {

    const [results, setResults] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const makeCall = () => {
        apiCall(props.method, props.args, callBack);
    }

    useEffect(makeCall, []);

    const callBack = (_status, _result) => {
        if (!_status) {
            setError(true);
        }
        setLoading(false);
        setResults(_result);
    }

    return (<div>API
        {React.cloneElement(props.component, { loading: loading, error: error, results: results })}
    </div>)
}