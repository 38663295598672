/*
    ---- Html ----
    Displays HTML
*/

import React, { useEffect, useState } from "react";
import { $ } from 'react-jquery-plugin';

function Html(props) {





  const { className = "html", clear = true } = props;
  const [hidden, setHidden] = useState("hidden");
  const replaceAll = (string, search, replace) => {
    return string.split(search).join(replace);
  }


  const loadCode = (_string) => {
    let qualityRegex = /<script[^>]*>(.*?)<\/script>/gis,
      matches,
      qualities = [];
    while ((matches = qualityRegex.exec(_string))) {

      // some stuff can't be decoded, so try first then try the unencoded below
      try {
        qualities.push(decodeURIComponent(matches[1]));
      } catch (err) {
        qualities.push(matches[1]);
      }


    }
    if (qualities.length > 0) {
      qualities.forEach(element => {
        if (element !== "") {

          try {
            window.eval(element);
          } catch (e) {
            console.log(e);
          }

        }
      });
    }
  }



  const loadScript = (_string) => {
    let qualityRegex = /<script [^>]*src="([^"]+)"[^>]*>/gis,

      matches,
      qualities = [];
    while (matches = qualityRegex.exec(_string)) {

      // some stuff can't be decoded, so try first then try the unencoded below
      try {
        qualities.push(decodeURIComponent(matches[1]));
      } catch (err) {
        qualities.push(matches[1]);
      }
    }
    if (qualities.length > 0) {
      setHidden("inherit");
      qualities.forEach(element => {
        if (!window.loadedScripts) {
          window.loadedScripts = [];
        }
        if (!window.loadedScripts.includes(element)) {

          if (element) {
            $.ajaxSetup({ cache: true });
            $.getScript(element, () => {

              loadCode(_string);
            });
          }

        }
        window.loadedScripts.push(element);
      });

    } else {

      loadCode(_string);
    }

  }

  const truncate = (str, n, useWordBoundary) => {
    if (str.length <= n) { return str; }
    const subString = str.substr(0, n - 1); // the original check
    return (useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(" "))
      : subString) + "&hellip;";
  };


  let html = props.html;

  useEffect(() => {

    loadScript(html);




  }, []);


  if (!html) {
    return "";
  }

  html = replaceAll(html, "<object data=", "<div class='object-svg'><img src=");
  html = replaceAll(html, "</object>", "</img></div>");

  const regex = /(<([^>]+)>)/ig;

  if (props.strip) {
    html = html.replace(regex, '');
  }

  if (props.clip) {
    html = truncate(html, props.clip, true);
  }

  const display = () => {
    return { __html: html };
  }



  return (
    <>
      <div style={{ "overflow": hidden }} className={className} dangerouslySetInnerHTML={display()}></div>
      {(clear && <br clear="both" />)}
    </>
  );

}

export default Html;