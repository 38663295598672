import React from "react";
import { getColor } from "../util/CC";
import { Button } from "antd";

export default function ButtonGrid(props) {

    const template = window.sitesettings.template_info;
    const { content = {} } = props;

    const {
        location = "main",
        component = "button_grid",
        container = true,
        data = {},
        injected_data = {},
    } = content;


    const {
        padding_y = 10,
        padding_x = 10,
        width = 1248,
        constrain = false,
        align = "0 auto",
        gap_y = 10,
        gap_x = 10,
        justify = "space-evenly",
        background_colour = 0,
        preset = 0,
        button_block = false,
        buttons = [
            { label: "Button", link: "", tab: true },
            { label: "Button", link: "", tab: true },
            { label: "Button", link: "", tab: true },
        ],

    } = data;


    const drawButton = (_button, _index) => {
        return (
            <div style={{ "flex": (button_block) ? "1 1 auto" : "unset" }}>
                <Button block={button_block} className={"bm-btn-" + preset} onClick={() => linkOut(_button)}>{_button.label}</Button>
            </div>
        )
    }

    const linkOut = (_image) => {
        if (_image.link) {
            window.open(_image.link, (_image.tab) ? "_blank" : "_self");
        }
    }


    const drawRender = () => {

        const gridStyle = {
            display: 'flex',
            flexWrap: "wrap",
            justifyContent: justify,
            gap: `${gap_y}px ${gap_x}px`,
            padding: `${padding_y}px ${padding_x}px`,
            maxWidth: (constrain) ? width + "px" : "100%",
            margin: align,
        };

        return (
            <div className={(container) ? "preview-container" : ""}>
                <div style={{ background: getColor(background_colour, template) }}>
                    <div style={gridStyle}>
                        {buttons.map((item, index) => {
                            return drawButton(item, index);
                        })}
                    </div>
                </div>
            </div>
        )
    }


    return drawRender();
}