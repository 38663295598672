import React from "react";
import { getColor } from "../util/CC";
export default function ImageGif(props) {

    const template = window.sitesettings.template_info;
    const { content = {} } = props;

    const {
        location = "main",
        component = "blank_space",
        container = true,
        data = {},
        injected_data = {},
    } = content;

    const {
        background_colour = 0,
        align = "left",
        link = "",
        alt="",
        new_tab = true,
        padding_x = 0,
        padding_y = 0,
        width = 1248,
        has_width = false,
        image_radius = 0
    } = data;

    const { image = "" } = injected_data;

    const WrapLink = (props) => {
        if (link) {
            return <a href={link} target={(new_tab) ? "_blank" : "_self"}>{props.children}</a>
        } else {
            return props.children;
        }
    }

    const parsePath = (url) => {
        const abs = /^(?:[a-z]+:)?\/\//i;
        return (abs.test(url)) ? url : "https://cdn1.bookmanager.com/i/" + window.san + url;
    }

    const drawRender = () => {

        let _style = {
            padding: padding_y + "px " + padding_x + "px",
            maxWidth: (has_width) ? width + "px" : "100%"
        }

        let _img_style = {
            maxWidth: "100%",
            borderRadius: image_radius + "px"
        }

        let _align = {
            background: getColor(background_colour, template),
            display: "flex",
            flexDirection: "column",
        }

        switch (align) {
            case "center":
                _style.alignSelf = "center";
                break;
            case "right":
                _style.alignSelf = "flex-end";
                break;
            default:
                _style.alignSelf = "flex-start";
                break;
        }

        return (
            <div className={(container) ? "preview-container" : ""}>
                <div style={_align}>
                    <div style={_style}>
                        <div className={(link) ? "bm-img-link" : ""}>
                            {(image && <WrapLink><img alt={alt} style={_img_style} src={parsePath(image)} /></WrapLink>)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    return drawRender();
}