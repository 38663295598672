import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSession } from "../../../util/Session";
import { Divider, message } from 'antd';
import { apiCall } from "../../utilities/Api";
export default function AccountVerify() {

    const history = useHistory();
    const [session, setSession] = useSession();

    let hasToken = false;

    if (history.location.search) {
        if (history.location.search.includes("?token=")) {
            hasToken = history.location.search.replace("?token=", "");
        }
    }

    const [token] = useState(hasToken);

    const checkToken = () => {
        if (token) {
            apiCall("account/getEmailFromToken", { token: token }, (_status, _result) => {
                if (_status) {
                    let user = { "email_address": _result.email }
                    setSession({ ...session, "user": user });
                    history.push({ "pathname": "/account/create", "state": { "token": token } })
                } else {
                    message.error(_result.error)
                }
            })
        }
    }

    useEffect(checkToken, [])

    const displayMessage = () => {
        return (
            <>
                <h2>Please verify your email address</h2>
                <div aria-hidden><Divider /></div>
                <p>You have entered {history.location.state.email_address.address} as your account email</p>
                <p>To complete the creation of your account, we have sent you an email that contains your account activation link.</p>
            </>
        )
    }

    const displayRedirect = () => {
        return (
            <>
                <h2>Please verify your email address</h2>
                <div aria-hidden><Divider /></div>
                <p>Redirecting...</p>
            </>
        )
    }

    const display = () => {
        if (token) {
            return displayRedirect();
        } else {
            return displayMessage();
        }
    }

    return display();

}