import React, { useState, useRef } from "react";
import { Button, Modal } from 'antd';
import { RightOutlined, LeftOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import Slider from "react-slick";
import "../../../css/slick.min.css";
import "../../../css/slick-theme.min.css";
import moment from "moment-timezone";
import { getItemAlt } from "../../../util/Utils";


export default function ItemImageThumbnailGallery(props) {


    const eisbn = props.data.eisbn;
    const data = props.data.interior_objects;
    const [modalVisible, setModalVisible] = useState(false);
    const close = () => { setModalVisible(false); }
    const imageSlider = useRef(null);
    const thumbSlider = useRef(null);
    const verticalSlider = useRef(null);
    const [selectedSlide, setSelectedSlide] = useState(0);

    const setIndex = (_index) => {
        setSelectedSlide(_index);
    }

    const next = () => {
        verticalSlider.current.slickNext();
    }

    const prev = () => {

        verticalSlider.current.slickPrev();
    }

    const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
        <span {...props}>{children}</span>
    );

    const displayVertical = () => {


        return (

            <div style={{ "width": "63px", "height": "270px" }}>

                {(data.length > 3 &&
                    <>
                        <Button disabled={(selectedSlide === 0)} onClick={() => prev()} size="small" style={{ "width": "63px" }} icon={<UpOutlined aria-hidden style={{ "fontSize": "12px" }} />}></Button>
                        <div className="shim"></div><div className="shim"></div>
                    </>
                )}


                <Slider


                    infinite={false}
                    vertical={true}
                    slidesToShow={3}
                    ref={verticalSlider}
                    beforeChange={(old_index, new_index) => setIndex(new_index)}
                    arrows={false}
                    swipe={false}
                    style={{ "margin": "0", "height": "300px" }}

                >
                    {data.map((item, index) => {
                        return (
                            <div key={item.key}>
                                <div >

                                    <img alt={getItemAlt(data) + " - " + item.key} onClick={() => selectImage(index)} onError={(i) => imageFallback(i)} className="gallery-vert" src={'https://cdn1.bookmanager.com/i/m?b=' + eisbn + '&imgp=' + (item.key) + "&cb=" + item.cb} />

                                </div>
                            </div>)
                    })}


                </Slider>
                {(data.length > 3 &&
                    <Button disabled={(selectedSlide === (data.length - 3))} onClick={() => next()} size="small" style={{ "width": "63px" }} icon={<DownOutlined aria-hidden style={{ "fontSize": "12px" }} />}></Button>
                )}
            </div>
        )


    }

    const selectImage = (_index) => {

        if (!modalVisible) {
            setModalVisible(true);
            selectThumb(_index)
        }

        if (imageSlider.current) {
            imageSlider.current.slickGoTo(_index);
        }

    }

    const selectThumb = (_index) => {
        if (thumbSlider.current) {

            thumbSlider.current.slickGoTo(_index);
        }
    }

    const imageFallback = (i) => {
        i.target.src = "https://cdn1.bookmanager.com/i/nocover.png"
    }

    const displayGallery = () => {

        return (<>

            <div>
                <div className="shim"></div>
                <div className="shim"></div>
                <div className="shim"></div>
                <div className="shim"></div>
                <div className="shim"></div>
                <div className="shim"></div>
                <Slider className="hide"

                    asNavFor={thumbSlider.current}
                    ref={imageSlider}
                    nextArrow={<SlickButtonFix>
                        <RightOutlined />
                    </SlickButtonFix>}
                    prevArrow={<SlickButtonFix>
                        <LeftOutlined />
                    </SlickButtonFix>}
                    infinite={false}
                    beforeChange={(old_index, new_index) => selectThumb(new_index)}
                    style={{ "margin": "0", "border": "1px solid #eee" }}
                    // className="gallery-slider"
                >
                    {data.map((item, index) => {

                        return (
                            // <TransformWrapper>
                            //             <TransformComponent>
                            <div key={item}>
                                 
                                <div >
                                   
                                            <img  alt={getItemAlt(data) + " - " + item.key} onError={(i) => imageFallback(i)} className="gallery-img" src={'https://cdn1.bookmanager.com/i/m?b=' + eisbn + '&imgp=' + (item.key) + "&cb=" + item.cb} />
                                       
                                </div>
                                 
                            </div>
                            // </TransformComponent>
                            //         </TransformWrapper>
                            )


                    })}


                </Slider>

                {(data.length > 1 && <>
                    <div className="shim"></div>
                    <div className="shim"></div>
                    <div className="shim"></div>
                    <Slider

                        asNavFor={imageSlider.current}
                        ref={thumbSlider}
                        slidesToShow={6}
                        infinite={false}
                        swipeToSlide={true}
                        focusOnSelect={true}
                        nextArrow={<SlickButtonFix>
                            <RightOutlined />
                        </SlickButtonFix>}
                        prevArrow={<SlickButtonFix>
                            <LeftOutlined />
                        </SlickButtonFix>}

                        className="gallery-thumb-slider"
                        responsive={[
                            {
                                breakpoint: 850,
                                settings: {
                                    slidesToShow: 4,
                                    slidesToScroll: 3,
                                    infinite: true,
                                    dots: true
                                }
                            },
                            {
                                breakpoint: 580,
                                settings: {
                                    slidesToShow: 3,
                                    slidesToScroll: 2,
                                    initialSlide: 2
                                }
                            },
                            {
                                breakpoint: 480,
                                settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 1
                                }
                            }
                        ]
                        }

                    >

                        {data.map((item, index) => {

                            return (
                                <div key={index} >

                                    <div onClick={() => selectImage(index)} ><img alt={getItemAlt(data) + " - " + item.key}  onError={(i) => imageFallback(i)} className="gallery-thumb" src={'https://cdn1.bookmanager.com/i/m?b=' + eisbn + '&imgp=' + (item.key) + "&cb=" + item.cb} /></div>

                                </div>)


                        })}


                    </Slider>
                </>)}
            </div>

        </>
        )

        // return data.map((image, index) => {




        // })

    }


    const displayStatus = () => {

        if (!props.data.interiors || !props.data.interiors.length > 0) {
            return (<></>)
        }


        else {

            return display();
        }

    }

    const display = () => {
        return (<>
            {displayVertical()}
            {/* <Button style={{ "maxWidth": "90%" }} type="text" icon={<div style={{ "padding": "5px" }} className="hide"><PictureOutlined /></div>} block onClick={() => open()}><strong>More images</strong></Button> */}
            <Modal forceRender width="auto" bodyStyle={{ "padding": "20px 50px" }} style={{ "maxWidth": "900px" }} open={modalVisible} onCancel={close} footer={null} >


                {displayGallery()}

            </Modal>
        </>
        );
    }




    return displayStatus();

}