import React,{useEffect} from "react"

export function isLocalStorageAvailable(){
  var test = 'test';
  try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
  } catch(e) {
      return false;
  }
}


export function usePersistedState(key, defaultValue) {

    
    const [state, setState] = React.useState(
      () => {
        
        if(isLocalStorageAvailable()){
          return  JSON.parse(localStorage.getItem(key)) || defaultValue;
        } else {
          return  defaultValue; 
        }
      }
    );
    useEffect(() => {
      if(isLocalStorageAvailable()){
        localStorage.setItem(key, JSON.stringify(state));
      }
    }, [key, state]);
    return [state, setState];

  }