
import React, { useEffect } from "react";
import { Divider, Card, Row, Col, Button, Alert, Modal, Form, Select } from 'antd';
import { useSession } from "../../../util/Session";
import Html from "../../utilities/Html"
import AccountAddressBook from "../account/AccountAddressBook";
import { Link } from "react-router-dom";
import PhoneNumberFormatter from "../../formatters/PhoneNumberFormatter";
import conditional from "../../utilities/conditional";
import PostalCodeFormatter from "../../formatters/PostalCodeFormatter";
export default function ShippingForm(props) {

    const { setShipping = () => { }, setLoading = () => { } } = props;
    const [session] = useSession();


    let _initselectedshipping = "";
    if (session.user.alt_addresses.length > 0) {

        _initselectedshipping = session.user.alt_addresses[0].address_id;
    }


    const setAltShippingID = (_stuff) => {
        _initselectedshipping = _stuff;
    }


    const setAltShipping = () => {
        let _find = session.user.alt_addresses.find(item => item.address_id === _initselectedshipping);
        if (_find) {
            setShipping(_find);
        }
    }


    const autoSelect = () => {
        if (session.user.alt_addresses.length === 1) {
            if (session.user.alt_addresses[0].shipping_encouraged) {
                _initselectedshipping = session.user.alt_addresses[0].address_id;
                setAltShipping();
            }
        }
    }

    useEffect(autoSelect, []);

    const drawShippingRecommendation = () => {

        if (session.user.alt_addresses.length > 0) {

            // Auto select if there is only one address
            if (session.user.alt_addresses.length === 1) {
                _initselectedshipping = session.user.alt_addresses[0].address_id;
                setAltShipping();
                return;
            }

            Modal.confirm({
                cancelText: "Cancel",

                onOk: () => {
                    setAltShipping();
                },

                title: "Gift registry shipping", content: <>
                    <p>Your cart contains items from multiple gift registrys. Select the gift registry address you would prefer items to be shipped.</p>
                    <Form layout="vertical">
                        <Form.Item label="Select shipping:">
                            <Select onChange={(e) => setAltShippingID(e)} defaultValue={_initselectedshipping}>
                                {session.user.alt_addresses.map(item => {
                                    return (
                                        <Select.Option value={item.address_id}>{item.gift_registry_name}<div><small style={{ "color": "#AAA" }}>{item.street_address}</small></div></Select.Option>
                                    )
                                })}

                            </Select>
                        </Form.Item>
                    </Form>
                </>
            })
        }

        return;

    }

    // useEffect(drawShippingRecommendation, []);

    const clearError = () => {
        props.setErrorMSG("");
        setLoading(false);
    }

    const resetShipping = () => {
        props.setErrorMSG("");
        props.setSaved({ ...props.saved, "shipping": {} });

    }

    const drawAddressSwap = () => {


        if (props.saved.shipping.hasOwnProperty("gift_registry_name")) {
            // is a registry address 
            return (
                <>
                    <Alert message={<>The address below is the recomended shipping for a gift registy. <a onClick={() => resetShipping()} style={{ "color": "#1fa1e5" }}>Use a different address</a></>} type="success" />
                    <div className="shim" /><div className="shim" />
                    <Card className="addressItem">
                        <div>
                            <strong>{props.saved.shipping.name}</strong><br />
                            <span>{props.saved.shipping.email_address}</span><br />

                            <span><PhoneNumberFormatter display country={props.saved.shipping.country} value={props.saved.shipping.phone_number} /> {(props.saved.shipping.phone_number_ext && <> ext. {props.saved.shipping.phone_number_ext}</>)}</span><br />
                            {(props.saved.shipping.company_name && <><span>{props.saved.shipping.company_name}</span><br /></>)}
                            <span>{props.saved.shipping.street_address}</span><br />
                            {(props.saved.shipping.street_address_2 && <><span>{props.saved.shipping.street_address_2}</span><br /></>)}
                            <span>{props.saved.shipping.city}, {props.saved.shipping.province}</span><br />
                            <span>{props.saved.shipping.country}</span><br />
                            <span><PostalCodeFormatter display value={props.saved.shipping.postal_code} country={props.saved.shipping.country} /></span><br />
                            <div className="shim"></div><div className="shim"></div><div className="shim"></div>
                        </div>
                        <div className="shim"></div><div className="shim"></div>

                    </Card>


                </>
            )



        } else {
            // is non-registry or unsaved
            return (
                <>
                    <conditional.true value={(session.user.alt_addresses.filter(item => item.address_id).length > 0)}>
                        <Alert message={<>Your cart contains items from a gift registry. The creator of the gift registry would prefer those items be shipped to their address <a onClick={() => drawShippingRecommendation()} style={{ "color": "#1fa1e5" }}>Use recomended shipping</a></>} type={((session.user.alt_addresses.length > 1)) ? "error" : "warning"} />
                        <div className="shim" /><div className="shim" />
                    </conditional.true>

                    <AccountAddressBook clearError={clearError} errorMSG={props.errorMSG} setErrorMSG={props.setErrorMSG} setSaved={props.setSaved} saved={props.saved} form={props.form} shipping />
                </>
            )

        }


    }



    const displayAddressOptions = () => {

        return (
            <>
                <Row gutter={20}>
                    <Col xs={24} lg={12}>
                        <div aria-hidden><Divider orientation="left">Billing Address</Divider></div>
                        <Card className="addressItem">
                            <div style={{ "height": "200px", "padding": "0px 3px" }}>
                                <strong>{session.user.first_name} {session.user.last_name}</strong><br />
                                <span>{session.user.email_address}</span><br />
                                <span><PhoneNumberFormatter display country={session.user.country} value={session.user.phone_number} /></span><br />
                                <span>{session.user.street_address}</span><br />
                                {(session.user.street_address_2 && <><span>{session.user.street_address_2}</span><br /></>)}
                                <span>{session.user.city}, {session.user.province}</span><br />
                                <span>{session.user.country}</span><br />
                                <span>{session.user.postal_code}</span><br />
                            </div>
                            <div className="shim"></div><div className="shim"></div>
                            <Link to="/account/details"><Button size="small"><small>Edit Account</small></Button></Link>
                        </Card>
                    </Col>
                    <Col xs={24} lg={12}>
                        <div aria-hidden><Divider orientation="left">Shipping Address</Divider></div>

                        {drawAddressSwap()}

                        {/* <pre>{JSON.stringify(props.saved, null, 2)}</pre> */}

                    </Col>
                </Row>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </>
        )

    }

    const display = () => {
        return (
            <Card >
                {/* {showInfo()} */}
                {displayAddressOptions()}
            </Card>
        )
    }


    return display();

}
