import { Select, Input } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import React from "react";

const { Option } = Select;


export default function Test() {



  return (

    <div>
      <label htmlFor="selectBox">Choose an option:</label>
      <select id="selectBox" aria-label="Choose an option">
        <option value="option1">Option 1</option>
        <option value="option2">Option 2</option>
        <option value="option3">Option 3</option>
      </select>
    </div>

  )

  return (<div>
    <div>
      <label htmlFor="type">Test</label>
      <div className="ant-select ant-select-single ant-select-show-arrow ant-select-show-search" style={{ width: '30%' }}>
        <input
          type="search"
          id="type"
          autoComplete="off"
          className="ant-select-selection-search-input"
          aria-haspopup="listbox"
          aria-expanded="false"
          aria-autocomplete="list"
          role="combobox"
          aria-label="Select a person"
          placeholder="Select a person"
          value=""
        />
        <span className="ant-select-arrow" aria-hidden="true" style={{ userSelect: 'none' }}>
          {/* Icon content (e.g., SVG for the arrow) can go here */}
        </span>
      </div>
      <input
        placeholder="Email"
        className="ant-input"
        type="text"
        value=""
        style={{ width: '70%' }}
      />
    </div>

  </div>)
}