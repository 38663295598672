import React, { useState, useEffect } from "react";
import { getColor } from "../util/CC";
import { Button, Modal } from "antd";
import Html from "../components/utilities/Html";

export default function PopupNotice(props) {

    const template = window.sitesettings.template_info;
    const { content = {} } = props;
    const [open, setOpen] = useState(false);

    const {
        location = "main",
        component = "popup_notice",
        container = true,
        injected_data = {},
        data = {}
    } = content;

    const {
        background_colour = 0,
        background_radius = 0,
        width = 250,
        gap = 10,
        text_align = "center",
        padding_x = 20,
        padding_y = 20,
        heading = "Popup Notice",
        heading_preset = 0,
        paragraph = "",
        paragraph_preset = 0,
        button_label = "",
        button_type = 0,
        button_block = false,
        link_id = 0
    } = data;


    const modalRender = () => {

        let _style = {
            padding: padding_y + "px " + padding_x + "px",
            alignSelf: "flex-end",
            textAlign: text_align,

        }

        let bg_style = {
            backgroundColor: getColor(background_colour, template, "#fff"),
            borderRadius: background_radius + "px",
            border: "1px solid " + getColor(background_colour, template, "#fff"),
            maxWidth: width + "px",
            margin: "0 auto",
            boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.5)",
        }

        return (
            <div style={bg_style}>
                <div style={_style}>
                    {(heading &&
                        <h4 style={{ "display": "block" }} className={"bm-txt-" + heading_preset} >
                            {heading}
                        </h4>
                    )}
                    <div className="shim" style={{ "height": gap + "px" }} />
                    {(paragraph &&
                        <div className={"bm-txt-" + paragraph_preset} >
                           <Html clear={false} className={"bm-lnkin-" + link_id} html={paragraph} />
                        </div>
                    )}
                    {(button_label &&
                        <div onClick={() => setOpen(false)} style={{ "display": "block" }}>
                            <div className="shim" /><div className="shim" />
                            
                            <Button className={"bm-btn-" + button_type} >{button_label}</Button>
                        </div>
                    )}

                </div>
            </div>
        )
    }

    useEffect(() => {
        setOpen(true);
    }, [])

    const drawRender = () => {
        return (
            <Modal
                className="bm-pop-up"
                width={width}
                onCancel={() => setOpen(false)}
                modalRender={modalRender}
                open={open}>
            </Modal>
        )
    }



    return drawRender();
}