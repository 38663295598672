import React from "react";

export default function BMAlert(props) {

    const { className = "msg", description = "", message = "", type = "", style = { padding: "15px" } } = props;

    let _s = {...style};
    

    if (type === "error") {
        _s.backgroundColor = "#ffe6e7";
        _s.border = "1px solid #ff8a97"
    }

    if (type === "success") {
        _s.backgroundColor = "#f7fff6";
        _s.border = "1px solid #6cba66"
    }


    return (
        <div style={_s} className="msg">
            {message}
            {description}
        </div>
    )
}