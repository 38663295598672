import { Input } from "antd";
import React from "react";

export default function PhoneNumberFormatter(props) {

  const setValue = (_v) =>{
    if(props.form){
        props.form.setFieldsValue({[props.field] : _v})
    }

    return(_v);

}


  const formatPhoneNumber = (_value, _country) => {
    if (!_value) return _value;

    // no formatting if not can / us
    if (_country !== "Canada" && _country !== "United States") {
      return _value;
    }

    // convert to string with only numbers
    let value = _value.replace(/[^\d]/g, '');

    // remove international digit if it exists & trim the rest
    if (value.length > 9 && value.substr(0, 1) === "1") {
      value = value.substr(1, value.length - 1);
    }

    // first bit
    if (value.length < 4) {
      return setValue(value);
    }
    // middle bit
    if (value.length < 7) {
      return setValue(`(${value.slice(0, 3)}) ${value.slice(3)}`);
    };
    // full number

    return setValue(`(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`);

  };


  if (props.display) {
    return formatPhoneNumber(props.value, props.country)
  } else {
    return (<Input aria-label="Enter Phone Number" {...props} value={formatPhoneNumber(props.value, props.country)} minLength={10} maxLength={14} />);
  }




}