import { Card, message, Radio, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useSession } from "../../../util/Session";
import { apiCall } from "../../utilities/Api";

export default function MultipleShipments(props) {

    const [session, setSession] = useSession(); 
    const { batch, updateBatch = () => {},   provider = "", address_id = "", delivery_preference = "", active = false, delivery_min = "", delivery_max = "", saved = {} } = props;
    const [results, setResults] = useState([])
    const eta = ((delivery_min && delivery_max && (delivery_min !== "0" && delivery_max !== "0"))) ? <strong> ({delivery_min} - {delivery_max}) business days ETA</strong> : <></>;

    const setShipping = (_batch = "all", _provider = saved.provider) => {
    
        let obj = {
            delivery_preference: saved.delivery,
            address_id: saved.shipping.address_id,
            provider: _provider,
            multiship_method: _batch
        }

        apiCall("checkout/setShipping", obj, (_status, _result) => {
            if (_status) {
                setSession({...session, "cart" : _result})
            } else {
                message.error(_result.error);
            }
        })
    }

    const fetchShippingBatch = () => {
        if (active) {
            apiCall("checkout/getShippingBatch", {delivery_preference : delivery_preference, provider : provider, address_id : address_id}, (_status, _result) => {
                if (_status) {
                    setResults(_result);
                    setShipping("all", provider);
                }
            })
        }
    }

    const saveBatch = (e, _val) => {
        e.stopPropagation();
        e.preventDefault(); 
        updateBatch(_val);
        setShipping(_val);
    }

    useEffect(fetchShippingBatch, [active]);

    if(!Array.isArray(results) || results.length !== 2){
        return(<></>)
    }

    return (
        <>
            <Card onClick={(e) => {e.preventDefault(); e.stopPropagation();}} style={{ "border": "1px dashed #e70d3d" }}>
                <div style={{ "color": "#000", "fontSize": "16px" }}>Multiple Shipments</div>
                <div className="shim" />
                <strong style={{ "color": "#e70d3d" }}>Heads up! Some of the items in your cart need to be ordered, while others are in-stock and ready to ship:</strong>
                <div className="shim" /><div className="shim" /><div className="shim" />
                <Space onClick={(e) => saveBatch(e, "all")}>
                    <Radio checked={(batch === "all")} />
                    <div style={{ "color": "#e70d3d", "width": "60px" }}>+ ${results[0].delta_cost}</div>
                    <div style={{ "color": ((batch === "all")) ? "#000" : "inherit" }}>Ship everything once all items have arrived. - <em>1 shipment</em></div>
                </Space>
                <div className="shim" /><div className="shim" />
                <Space onClick={(e) => saveBatch(e, "batched")}>
                    <Radio checked={(batch === "batched")} />
                    <div style={{ "color": "#e70d3d", "width": "60px" }}>+ ${results[1].delta_cost}</div>
                    <div style={{ "color": (batch === "batched") ? "#000" : "inherit" }}>Ship what's in-stock now{eta}, and the rest once they are all in-stock. - <em>2 shipments</em></div>
                </Space>
            </Card>
        </>
    )
}