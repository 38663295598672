import { Divider, Space, Button, Row, Col, Modal } from "antd";
import React, { useState, useEffect, useCallback, useRef } from "react";
import moment from "moment";
import EventUI from "./helpers/EventUI";
import { getColor } from "../util/CC";
import { apiCall } from "../components/utilities/Api";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import EventViewMore from "../components/components/events/EventViewMore";
import EventRSVP from "../components/components/events/EventRSVP";
import EventSocial from "../components/components/events/EventSocial";

export default function Events(props) {

    
    const { event_filter = "" } = useParams();

    const filterEvents = (_arr) => {
        if (!event_filter) {
            return _arr;
        }

        if (event_filter.length === 8) {
            // date
            
            return _arr.filter((item) => {

                // Exact
                let x = moment(event_filter).format("YYYY MM Do");
                let a = moment(item.from * 1000).format("YYYY MM Do");
                let b = moment(item.to * 1000).format("YYYY MM Do");
                if (x === a && x === b){
                    return true; 
                }               
                return moment(event_filter).isBetween(moment(item.from * 1000).startOf("day").add("-1", "hour"), moment(item.to * 1000).endOf("day"));

            })
        } else {
            // id
            return _arr.filter((item) => {
                return (item.id.toString() === event_filter.toString());
            })
        }

    }



    const { view = "desktop" } = props;

    const template = window.sitesettings.template_info;
    const history = useHistory(); 

    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState(props.content);
    const [w, setW] = useState(false);

    const [page, setPage] = useState(0);
    const observer = useRef();

    const fetchEvents = () => {
        setLoading(true);

        if (window.sitesettings.using_events_v2) {

            let _api = "event/v2/list";
            let _single = false; 
            let _limit = 20;
            let _offset = page * _limit; 
            let _start_date = moment().format("YYYYMMDD");

            let _obj = {
                start_date : _start_date,
                offset : _offset,
                limit : _limit
            }

            // filter by ID or Date
            if(event_filter){
                if(event_filter.length === 8){
                    _obj.start_date = event_filter;
                    _obj.end_date = event_filter;
                } else {
                    _api = "event/v2/get";
                    _obj.event_id = event_filter; 
                    _single = true; 
                }
            }

            apiCall(_api, _obj, (_status, _result) => {
                if (_status) {
                   
                    if(_single){
                        setEvents([_result]);
                    } else {
                        setEvents((prevRows) => [...prevRows, ..._result.rows]);
                    }
                    
                }
                setLoading(false)
            })
        } else {

            apiCall("event/getList", { from: moment().format("YYYYMMDD") }, (_status, _result) => {
                if (_status) {

                    setEvents(filterEvents(_result.rows));
                }
                setLoading(false);
            })
        }
    }
    useEffect(fetchEvents, [page, event_filter]);

    const lastRowRef = useCallback(
        (node) => {
          if (observer.current) observer.current.disconnect();
          observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {

                if(!event_filter){
                    setPage((prevPage) => prevPage + 1);
                }
             
            }
          });
          if (node) observer.current.observe(node);
        },
        []
      );

      const linkOut = (_id) => {
        history.push("/events/" + _id)
    }

    // Component agnostic
    const {
        name = "",
        container = true,
        data = {},
        date_changed = 0
    } = content;

    // Component specific
    const {
        //new
        align = "center",
        container_width = 1248,
        constrain_container = false,

        width = 200,
        background_colour = 0,
        padding_x = 20,
        padding_y = 20,
        title_preset = 0,
        description_preset = 0,
        date_preset = 0,
        button_type = 0,
        button_label = "Buy Books",
        button_align = "left",
        link_id = 0,
    } = data;


    const drawButtons = (eventData) => {

        // Buy Tickets
        // Buy Books
        // RSVP
        const {
            info = {},
            books = [],
            tickets = [],
            ticket_label = "",
            rsvp_label = "",
            attendance = false
        } = eventData;
        const { book = [], ticket = [] } = info;

        let _tickets = ticket;
        let _tickets_label = "Tickets"
        let _books = book;
        let _books_label = button_label;
        let _attendance = attendance;
        let _rsvp_label = "";

        // New Events
        if (window.sitesettings.using_events_v2) {
            _tickets = tickets;
            _tickets_label = (ticket_label) ? ticket_label : "Tickets";
            _books = books;
            _rsvp_label = (rsvp_label) ? rsvp_label : "RSVP";
        }

        return (
            <>
                <div className="shim" /><div className="shim" />
                <div style={{ "textAlign": button_align }}>
                    <Space wrap>
                        {((_tickets.length > 0) && <EventViewMore event_id={eventData.id} type="ticket" data={_tickets} cc_event><Button className={"bm-btn-" + button_type}>{_tickets_label}</Button></EventViewMore>)}
                        {((_books.length > 0) && <EventViewMore event_id={eventData.id} type="book" data={_books} cc_event><Button className={"bm-btn-" + button_type}>{_books_label}</Button></EventViewMore>)}
                        {((_attendance === "request") && <EventRSVP event={eventData}><Button className={"bm-btn-" + button_type}>{_rsvp_label}</Button></EventRSVP>)}
                    </Space>
                </div>
            </>
        )
    }

    const openImage = (item = {}) => {
        Modal.info({
            icon : null,
            okText : "Close",
            width : "fit-content",
            content : <><img onError={(e) => e.target.src = "https://bookmanager.com/i/nocover.png"} src={item.image_url} alt={item.title} style={{"maxWidth" : "100%"}} /></>
        })
    }

    const drawImages = (eventData) => {

        const {
            info = {},
            image_url = "",
            title = "",
            books = []
        } = eventData;
        const { name = "", img_path = "", book = [] } = info;

        let _books = book;
        let _image = img_path;
        let _title = name;

        if (window.sitesettings.using_events_v2) {
            _books = books;
            _image = image_url;
            _title = title;
        }

        return (
            <div style={{ width: '100%', textAlign: 'center' }}>
                <div onClick={() => openImage(eventData)} style={{ width: '100%' }}>
                    <img onError={i => i.target.style.display = 'none'}  src={_image} alt={_title} style={{ width: '100%', height: 'auto',  "cursor" :  "pointer" }} />
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                    {(_books.slice(0, 2).map(item => {
                        return (
                            <div>
                                <EventViewMore event_id={eventData.id} type="book" data={_books} cc_event><img onError={(e) => e.target.src = "https://bookmanager.com/i/nocover.png"} src={"https://bookmanager.com/i/m?b=" + item.eisbn} alt={item.title} style={{ width: '100%', height: 'auto', "cursor" : "pointer" }} /></EventViewMore>
                            </div>
                        )
                    }))}
                </div>
            </div>
        )
    }

    const drawEvent = (item) => {
        const {
            id = "",
            from = 0,
            title = "",
            info = {}
        } = item;

        const { name = "" } = info;

        let _wrapperStyle = {}
        let _bgStyle = {}

        return (
            <div key={item}>
                <div style={_bgStyle}>
                    <div style={_wrapperStyle}>
                        <Row gutter={[20, 40]} className="bm-wrap" >
                            <Col flex={(width + "px")}>
                                {drawImages(item)}
                                <div style={{ "textAlign": "center" }}>
                                    <EventSocial link_id={link_id} name={(name) ? name : title} path={item.id} />
                                </div>
                            </Col>
                            <Col flex="auto">
                                <EventUI
                                    single={(event_filter.length>8)}
                                    link_id={link_id}
                                    event_id={id}
                                    flourish={false}
                                    title_preset={title_preset}
                                    date_preset={date_preset}
                                    description_preset={description_preset}
                                    event={item} />
                                {drawButtons(item)}
                            </Col>
                        </Row>
                    </div>
                </div>
                <Divider />
            </div>
        )
    }

    const drawRender = () => {
        let _style = {
            backgroundColor: getColor(background_colour, template),
            padding: padding_y + "px " + padding_x + "px",
            width: (constrain_container) ? container_width + "px" : "100%",
            margin: (align === "center") ? "0 auto" : (align === "right") ? "0 0 0 auto" : "initial"
        }
        return (
            <div className={(container) ? "preview-container" : ""}>
                <div style={_style}>
                    {events.map((item, index) => {
                        if(index === events.length-1){
                            return (<div ref={lastRowRef}>{drawEvent(item)}</div>)
                        } else {
                            return (<div>{drawEvent(item)}</div>)
                        }
                        
                    })}
                </div>
            </div>
        )
    }

    return (
        <>
            {drawRender()}
        </>
    )

}


// import React from "react";
// import { getColor } from "../util/CC";
// import wrap from "../util/wrap";
// import CalendarUI from "./helpers/CalendarUI";
// import { Button, Col, Divider, Row, Space } from "antd";
// import EventUI from "./helpers/EventUI";
// import EventViewMore from "../components/components/events/EventViewMore";
// import EventSocial from "../components/components/events/EventSocial";
// import moment from "moment-timezone";
// import { useParams } from "react-router-dom";
// export default function Events(props) {


//     const { event_filter = "" } = useParams();

//     const filterEvents = (_arr) => {

//         if (!event_filter) {
//             return _arr;
//         }

//         if (event_filter.length === 8) {
//             // date
            
//             return _arr.filter((item) => {

//                 // Exact
//                 let x = moment(event_filter).format("YYYY MM Do");
//                 let a = moment(item.from * 1000).format("YYYY MM Do");
//                 let b = moment(item.to * 1000).format("YYYY MM Do");
//                 if (x === a && x === b){
//                     return true; 
//                 }               
//                 return moment(event_filter).isBetween(moment(item.from * 1000).startOf("day").add("-1", "hour"), moment(item.to * 1000).endOf("day"));

//             })
//         } else {
//             // id
//             return _arr.filter((item) => {
//                 return (item.id.toString() === event_filter.toString());
//             })
//         }

//     }


//     const template = window.sitesettings.template_info;
//     const { content = {} } = props;

//     const {
//         location = "main",
//         component = "blank_space",
//         container = true,
//         injected_data = [],
//         data = {}
//     } = content;

//     const {
//         width = 200,
//         background_colour = 0,
//         padding_x = 20,
//         padding_y = 20,
//         title_preset = 0,
//         description_preset = 0,
//         date_preset = 0,
//         button_type = 0,
//         button_label = "Buy Books",
//         button_align = "left",
//         link_id = 0
//     } = data;



//     const drawButton = (eventData) => {
//         const { info = {} } = eventData;
//         const { name = "", img_path = "", book = [] } = info;

//         if (book.length > 0) {
//             return (<>
//                 {((button_label) && <>
//                     <div className="shim" /><div className="shim" />
//                     <div style={{ "display": "block", "textAlign": button_align }}>

//                         {/* <pre>{JSON.stringify(book, null, 2)}</pre> */}
//                         <EventViewMore type="book" data={book} cc_event>
//                             <Button className={"bm-btn-" + button_type}>{button_label}</Button>
//                         </EventViewMore>

//                     </div>

//                 </>)}
//             </>)
//         } else {
//             return (<></>)
//         }
//     }

//     const drawTicket = (eventData) => {
//         const { info = {} } = eventData;
//         const { name = "", img_path = "", ticket = [] } = info;

//         if (ticket.length > 0) {
//             return (<>
//                 {((button_label) && <>
//                     <div className="shim" /><div className="shim" />
//                     <div style={{ "display": "block", "textAlign": button_align }}>

//                         {/* <pre>{JSON.stringify(book, null, 2)}</pre> */}
//                         <EventViewMore type="ticket" data={ticket} cc_event>
//                             <Button className={"bm-btn-" + button_type}>Buy Tickets</Button>
//                         </EventViewMore>

//                     </div>

//                 </>)}
//             </>)
//         } else {
//             return (<></>)
//         }
//     }

//     const drawEventImages = (eventData) => {


//         const { info = {} } = eventData;
//         const { name = "", img_path = "", book = [] } = info;

//         let book_img = ""
//         if (book.length > 0) {
//             book_img = "https://cdn1.bookmanager.com/i/m?b=" + book[0].eisbn + "&cb=" + book[0].cover_image_cache
//         }

//         if (!eventData) {
//             return (
//                 <div style={{ "width": width + "px", "display": "flex", "flexDirection": "column" }}>
//                     <img onError={i => i.target.style.display = 'none'} style={{ width: "100%", objectFit: "cover", height: "auto" }} alt={name} src={" https://bookmanager.com/i/sample_content/placeholder.jpg"} />
//                 </div>
//             )
//         }

//         return (
//             <div style={{ "width": width + "px", "display": "flex", "flexDirection": "column" }}>
//                 {(img_path && <img onError={i => i.target.style.display = 'none'} style={{ width: "100%", objectFit: "cover", height: "auto" }} alt={name} src={img_path} />)}
//                 {(book_img && <img onError={i => i.target.style.display = 'none'} style={{ width: "100%", objectFit: "cover", height: "auto" }} alt={name} src={book_img} />)}
//             </div>
//         )


//     }




//     const drawEvent = (item, index) => {


//         const {
//             id = "".
//                 from = 0,
//             to = 0,
//             info = {}
//         } = item;

//         const {
//             name = "",
//             description = "",
//             img_path = "",
//             book = []
//         } = info;

//         let _wrapperStyle = {}
//         let _bgStyle = {}


//         return (
//             <div key={item}>

//                 <div style={_bgStyle}>
//                     <div style={_wrapperStyle}>
//                         <Row gutter={[20, 40]} className="bm-wrap">
//                             <Col flex={(width + "px")}>
//                                 {drawEventImages(item)}
//                                 <div>
//                                     <EventSocial link_id={link_id} name={name} path={id} />
//                                 </div>
//                             </Col>

//                             <Col flex="auto">
//                                 <EventUI
//                                     link_id={link_id}
//                                     event_id={id}
//                                     title_preset={title_preset}
//                                     date_preset={date_preset}
//                                     description_preset={description_preset}
//                                     event={item} />
//                                 <div>
//                                     <Space size={0}>
//                                         {drawButton(item)}
//                                         {drawTicket(item)}
//                                     </Space>
//                                 </div>
//                             </Col>
//                         </Row>
//                     </div>
//                 </div>
//                 <div aria-hidden><Divider /></div>
//             </div>
//         )
//     }

//     const drawRender = () => {

//         let _style = {
//             backgroundColor: getColor(background_colour, template),
//             padding: padding_y + "px " + padding_x + "px"
//         }
//         return (
//             <div className={(container) ? "preview-container" : ""}>
//                 <div style={_style}>
//                     {filterEvents(injected_data).map((item, index) => {
//                         return drawEvent(item, index)
//                     })}
//                 </div>
//             </div>
//         )
//     }


//     return drawRender();
// }