import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { apiCall } from "../components/utilities/Api";
import conditional from "../components/utilities/conditional";
import Html from "../components/utilities/Html";
import GridDisplay from "../shared/GridDisplay";
import { StarFilled } from '@ant-design/icons';
import { useSession } from "../util/Session";

export default function ListGridLayout(props) {


    const {cartable = false} = props; 
    const [session,] = useSession(); 
    const params = useParams();
    let page_id = 1;
    if (params.page_id) {
        page_id = parseInt(params.page_id);
    }

    const [title, setTitle] = useState("")

    // fetch item


    const onGetList = (_status, _result) => {


        // check for fav
        
        if(session.wishlist_id === _result.list_id){
            _result.header = <><StarFilled style={{"paddingBottom" : "5px"}}  /> Favourites</>;
        }
        
        //list_id

        if (_status) {

            document.title = window.sitesettings.store_info.name + " | " + _result.header;

            if (_result.header_visible) {
                setTitle(
                    <>
                        <h2>{_result.header}</h2>
                        <conditional.true value={_result.description}>
                            <Html html={_result.description} />
                        </conditional.true>
                    </>
                )
            } else {
                setTitle(
                    <>
                        <conditional.true value={_result.description}>
                            <Html html={_result.description} />
                        </conditional.true>
                    </>
                )
            }
        }
    }
    const getList = () => {        
        apiCall("titlelist/get", { list_id: params.list_id }, onGetList);
    }
    useEffect(getList, [params.list_id]);


    return (<GridDisplay 
                browse_link = {"/browse/filter/x/" +  params.list_id}
                wrap
                displayPicker 
                showSizeChanger 
                back_button 
                cartable={cartable} 
                routable={"/lists/" + params.list_id + "/"} 
                page_id={page_id} 
                title={title} 
                method="titlelist/getItems" 
                args={{ "list_id": params.list_id }} />);
}

