import React, { useEffect, useState } from "react";
import { Layout, Divider } from 'antd';
import { apiCall } from "../components/utilities/Api"
import { useLocation, useHistory } from "react-router-dom";
import FeaturesFilters from "../components/components/features/FeaturesFilters";
import FeaturesResults from "../components/components/features/FeaturesResults";


export default function FeaturesLayout() {

    const [filters, setFilters] = useState([]);
    const [results, setResults] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [dim, setDim] = useState(false);

    const limit = 26;
    const loc = useLocation();

    const [initialRun, setInitalRun] = useState(true);

    const initialFilters = {
        c: [], // categories
        f: [], // subcategories
        o: 0, // offset
        l: limit, // limit
        t: "", // term
        s: window.sitesettings.default_feature_sort_by
    };

    const [searchFilters, setSearchFilters] = useState(initialFilters);

    const parsePath = (_path) => {

        let startIndex = _path.search(/features/i);
        let p = _path.substr(startIndex, _path.length);
        let split = p.split("/");
        let key;
        for (let i = 0; i < split.length; i++) {
            if (split[i].length === 1) {
                key = split[i];
            } else {
                if (initialFilters.hasOwnProperty(key)) {
                    if (Array.isArray(initialFilters[key])) {
                        initialFilters[key] = [...initialFilters[key], split[i]];
                    } else {
                        if (key === "o" || key === "l") {
                            initialFilters[key] = parseInt(split[i]);
                        } else {
                            initialFilters[key] = split[i];
                        }
                    }

                }
            }
        }

        setSearchFilters(initialFilters);
    }

    const history = useHistory();


    const loadParse = () =>{
        if (initialRun) {
            setInitalRun(false);
            parsePath(loc.pathname);
            return;
        }
        var location = "/features";
        Object.keys(searchFilters).forEach((key) => {
            location += (Array.isArray(searchFilters[key]) && searchFilters[key].length > 0) ? "/" + key.toString() + "/" + searchFilters[key].join("/") : "";
        })
        //offset
        if (searchFilters.o !== 0) {
            location += "/o/" + searchFilters.o.toString();
        }
        //term
        if (searchFilters.t !== "") {
            location += "/t/" + encodeURI(searchFilters.t)
        }
        //key type
        if (searchFilters.k !== "") {
            location += "/s/" + encodeURI(searchFilters.s)
        }
        history.push(location);
    }

    useEffect(loadParse, [searchFilters]);

    const onGetFeaturedLists = (_status, _result) => {
        if (!_status) {
            // error
            setError(true);
        } else {
            setFilters(_result.filter_info);
            setResults(_result)
        }
        setLoading(false);
        setDim(false);
    }

    const getFeaturedLists = () => {
        setDim(true);
        let obj = {};
        for (var key in searchFilters) {
            if (key === "o" || key === "l" || key === "s") {
                obj[key] = searchFilters[key];
            } else {
                obj[key] = JSON.stringify(searchFilters[key]);
            }
        }
        apiCall("feature/getList", obj, onGetFeaturedLists)
    }

    useEffect(getFeaturedLists, [searchFilters])

    const displayLoading = () => {
        return (<></>);
    }

    const displayError = () => {
        return (<></>)
    }

    const displayStatus = () => {

        if (loading) { return displayLoading() }
        if (error) { return displayError() }
        return display();

    }

    const display = () => {
        return (
            <div className="container">
                <h2>Featured Lists</h2>
                <div aria-hidden><Divider style={{ "marginTop": "11px" }} /></div>
                <Layout>
                    <Layout.Sider width={280} className="hide" theme="light">
                        <FeaturesFilters setSearchFilters={setSearchFilters} searchFilters={searchFilters} filters={filters} />
                        {/* <pre>{JSON.stringify(filters,null,2)}</pre> */}
                    </Layout.Sider>
                    <Layout.Content>
                        <div className="adjust-margin">
                            <div style={{ "margin": "0 25px" }}>

                                {/* <pre>{JSON.stringify(searchFilters, null, 2)}</pre> */}
                                <FeaturesResults initialFilters={initialFilters} filters={filters} limit={limit} setSearchFilters={setSearchFilters} searchFilters={searchFilters} results={results} dim={dim} />
                            </div>
                        </div>
                    </Layout.Content>
                </Layout>
            </div>
        );
    }

    return displayStatus();

}