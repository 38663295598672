import React from "react";
import Html from "../components/utilities/Html";
import { getColor } from "../util/CC";

export default function HeadlineParagraph(props) {


    const template = window.sitesettings.template_info;
    const {content = {}} = props; 

    const {
        location = "main",
        component = "headline_paragraph",
        container = true,
        data = {}
    } = content;

    const {
        width = 100,
        gap = 0,
        align = "center",
        text_align = "center",
        padding_x = 10,
        padding_y = 10,
        heading = "",
        heading_preset = 0,
        paragraph = "",
        paragraph_preset = 0,
        background_colour = 0,
        link_id = 0,
    } = data;


    let _style = {
        padding: padding_y + "px " + padding_x + "px",
        maxWidth: (1248 * (width / 100)) + "px",
        alignSelf: "flex-end",
        textAlign: text_align,
    }

    let _align = {
        display: "flex",
        flexDirection: "column",
        background: getColor(background_colour, template)
    }

    switch (align) {
        case "center":
            _style.alignSelf = "center";
            break;
        case "right":
            _style.alignSelf = "flex-end";
            break;
        default:
            _style.alignSelf = "flex-start";
            break;
    }


    return (
        <div className={(container) ? "preview-container" : ""}>
            <div style={_align}>
                <div style={_style}>
                    {(heading &&
                        <h2 style={{ "display": "block" }} className={"bm-txt-" + heading_preset} >
                            {heading}
                        </h2>
                    )}
                    {((gap > 0) &&
                        <div className="shim" style={{ "height": gap + "px" }} />
                    )}
                    {(paragraph &&
                        <div className={"bm-txt-" + paragraph_preset} >
                            <Html clear={false} className={"bm-lnkin-" + link_id} html={paragraph} />
                        </div>

                    )}
                </div>
            </div>
        </div>
    )
}