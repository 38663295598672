// Resources
import React from "react";
import { Row, Col, message } from 'antd';
import { useEffect } from "react";
import { apiCall } from "../components/utilities/Api";
import { useState } from "react";
import HeadlineParagraph from "../content_creator/HeadlineParagraph";
import BlankSpace from "../content_creator/BlankSpace";
import ImageGif from "../content_creator/ImageGif";
import TextOverImage from "../content_creator/TextOverImage";
import TextOverVideo from "../content_creator/TextOverVideo";
import SideBySide from "../content_creator/SideBySide";
import ProductSlider from "../content_creator/ProductSlider";
import ImageGrid from "../content_creator/ImageGrid";
import ImageCarousel from "../content_creator/ImageCarousel";
import Youtube from "../content_creator/Youtube";
import ProductGrid from "../content_creator/ProductGrid";
import EventsCalendar from "../content_creator/EventsCalendar";
import EventsCalendarUpcoming from "../content_creator/EventsCalendarUpcoming";
import SingleEventFeature from "../content_creator/SingleEventFeature";
import CustomButton from "../content_creator/CustomButton";
import GiftCard from "../content_creator/GiftCard";
import BalanceChecker from "../content_creator/BalanceChecker";
import MailChimpEmbedded from "../content_creator/MailChimpEmbedded";
import Custom from "../content_creator/Custom";
import Events from "../content_creator/Events";
import GiftCards from "../content_creator/GiftCards";
import ContactAndHours from "../content_creator/ContactAndHours";
import BrowseLayout from "./BrowseLayout";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { findNested } from "../util/Utils";
import PopupNotice from "../content_creator/PopupNotice";
import AboveSearchbarNotice from "../content_creator/AboveSearchbarNotice";
import SingleTitleFeature from "../content_creator/SingleTitleFeature";
import TextOverParallax from "../content_creator/TextOverParallax";
import ButtonGrid from "../content_creator/ButtonGrid";
import MapStoresLocation from "../content_creator/MapStoresLocation";
import { isLocalStorageAvailable } from "../util/Persistant";
import EventList from "../content_creator/EventsList";
import EventsGrid from "../content_creator/EventsGrid";

// Components


export default function CustomLayout(props) {



    const { is_global = false, banner = false, events_details = false } = props;
    const history = useHistory();
    const [results, setResults] = useState([]);

    // This is ugly - but if a pages is routed with browse filters, need to get the browse id
    const browse_page = findNested(window.sitesettings.site_navigation, "label", "Browse", "submenu")
    const hidden_browse_page = window.sitesettings.extra_navigation.find(item => item.path === "/browse");
    let browse_page_id = 0;
    let page_is_v1 = false;
    let _is_browse_page = false;


    if (history.location.pathname === "/browse" || history.location.pathname === "/browse/" || history.location.pathname.includes("/browse/filter")) {

        _is_browse_page = true;
        if (browse_page) {
            browse_page_id = browse_page.id;
            page_is_v1 = browse_page.is_v1;
        }
        if (hidden_browse_page) {
            browse_page_id = hidden_browse_page.id;
            page_is_v1 = hidden_browse_page.is_v1;
        }
    }

    const globals = window.sitesettings.global_content || [];
    const { location = {} } = props;




    const {
        label = "",
        path = "",
        id = "",
        search = "",
        url = null,
        type = "",
        is_v1 = false,
        link_type = "",
        submenu = []
    } = location;

    const getContent = () => {

        if (isLocalStorageAvailable() && !_is_browse_page) {
            let _local = localStorage.getItem(window.location.pathname)
            if (_local) {
                console.log("Using Cache");
                setResults(JSON.parse(_local));
                return;
            }
        }

        if (is_v1) {
            return;
        }

        if (is_global) {
            return;
        }

        if (window.sitesettings.is_v1) {
            return;
        }

        // use the browse id if it exists....
        let _id = (browse_page_id) ? browse_page_id : id;

        if (!_id) {
            return;
        }

        // browse page is v1.. don't call
        if (page_is_v1) {
            return;
        }


        apiCall("site_content/getPage", { page_id: _id }, (_status, _result) => {
            if (_status) {
                setResults(_result);
                if (isLocalStorageAvailable() && !_is_browse_page) {
                    localStorage.setItem(window.location.pathname, JSON.stringify(_result));
                }
            } else {
                message.error(_result.error)
            }
        });
    }

    useEffect(getContent, [])


    const drawComponenet = (_component) => {

        const {
            component = "headline_paragraph",
        } = _component;

        if (events_details) {
            if (component === "events") {
                return <Events content={_component} />
            } else {
                return (<></>)
            }
        }

        switch (component) {

            case "blank_space":
                return <BlankSpace content={_component} />

            case "headline_paragraph":
                return <HeadlineParagraph content={_component} />

            case "image_gif":
                return <ImageGif content={_component} />

            case "text_over_image":
                return <TextOverImage content={_component} />

            case "text_over_parallax":
                return <TextOverParallax content={_component} />

            case "text_over_video":
                return <TextOverVideo content={_component} />

            case "side_by_side_text_image":
                return <SideBySide content={_component} />

            case "image_grid":
                return <ImageGrid content={_component} />

            case "image_carousel":
                return <ImageCarousel content={_component} />

            case "youtube":
                return <Youtube content={_component} />

            case "product_slider":
                return <ProductSlider content={_component} />

            case "product_grid":
                return <ProductGrid content={_component} />

            case "single_title_feature":
                return <SingleTitleFeature content={_component} />

            case "events_calendar":
                return <EventsCalendar content={_component} />

            case "events_calendar_upcoming":
                return <EventsCalendarUpcoming content={_component} />

            case "single_event_feature":
                return <SingleEventFeature content={_component} />

            case "custom_button":
                return <CustomButton content={_component} />

            case "button_grid":
                return <ButtonGrid content={_component} />

            case "gift_card_image":
                return <GiftCard content={_component} />

            case "balance_checker":
                return <BalanceChecker content={_component} />

            case "mailchimp_embedded":
                return <MailChimpEmbedded content={_component} />

            case "custom_html_css":
                return <Custom content={_component} />

            case "popup_notice":
                return <PopupNotice content={_component} />

            case "above_searchbar_notice":
                return <AboveSearchbarNotice content={_component} />


            // Built-ins

            case "events":
                
                return <Events content={_component} />

            case "event_list":
                return <EventList content={_component} />

            case "events_grid":
                return <EventsGrid content={_component} />

            case "gift_cards":
                return <GiftCards content={_component} />

            case "contact_and_hours":
                return <ContactAndHours content={_component} />

            case "map_stores_location":
                return <MapStoresLocation content={_component} />

            case "browse":
                return <BrowseLayout location={location} />




        }

    }


    if (is_global) {
        let _globals = [...globals];
        if (!banner) {
            _globals = _globals.filter(item => item.component !== "above_searchbar_notice" && item.component !== "popup_notice")
        } else {
            _globals = _globals.filter(item => item.component === "above_searchbar_notice" || item.component === "popup_notice")
        }
        return (
            <>
                {_globals.map((item, index) => {
                    return <div key={index} className="bm-component">{drawComponenet(item)}</div>
                })}
            </>
        )
    }


    if (window.sitesettings.is_v1 || (is_v1 && type === "browse") || page_is_v1) {
        // V1 - use browse
        return <BrowseLayout location={location} />;
    }







    return (
        <div>

            {/* PAGE SPECIFIC */}
            {results.map((item, index) => {
                // Don't wrap browse component...
                return (item.component === "browse") ? drawComponenet(item) : <div key={index} className="bm-component">{drawComponenet(item)}</div>
            })}

            {/* <pre>{JSON.stringify(location, null, 2)}</pre> */}
        </div>
    );

}
