import moment from "moment";
import React, { useState, useEffect } from "react";
import { Button, Divider, Skeleton } from "antd";
import EventUI from "./EventUI";
import { useHistory } from "react-router-dom";

export default function Upcoming(props) {

    const { data = [] } = props;
    const [results, setResults] = useState({ rows: data });
    const history = useHistory(); 
    const {
        text_colour = 0,
        highlight_colour = 0,
        title_preset = 0,
        description_preset = 0,
        date_preset = 0,
        limit = 2,
        button_label = "",
        button_type = 0,
        button_align = "center",
        link_id = 0
    } = props

  


    const drawEvents = () => {

        if (results.rows.length === 0) {
            return Array.from(Array(limit).keys()).map((item, index) => {
                return <div key={index}><Skeleton /><div aria-hidden><Divider dashed /></div></div>
            })
        }

        return results.rows.slice(0, limit).map((item, index) => {
            return (
                <div key={index}>
                    <EventUI
                        link_id={link_id}
                        link={true}
                        text_colour={text_colour}
                        highlight_colour={highlight_colour}
                        event={item}
                        title_preset={title_preset}
                        date_preset={date_preset}
                        description_preset={description_preset}
                    />
                    <div aria-hidden><Divider dashed /></div>
                </div>
            )
        })
    }

    const draw = () => {

        return (
            <>
                {drawEvents()}

                {((button_label) && <>
                    <div style={{ "display": "block", "textAlign": button_align }}>
                        <Button onClick={() => history.push("/events")} className={"bm-btn-" + button_type}>{button_label}</Button>
                    </div>

                </>)}

            </>
        )
    }


    return draw();
}