import React from "react";
import {Input, Form, Button, Select, Space } from 'antd';

export default function FeaturesSearchSort(props){

    const { searchFilters={}, setSearchFilters, initialFilters={}} = props; 
    
    const setSearch = (v) =>{
        setSearchFilters({...searchFilters, "o" : 0, "t" : v});
    }

    const setReset = () =>{
        setSearchFilters(initialFilters)
    }

    const setSort = (v) =>{
        setSearchFilters({...searchFilters, "o" : 0, "s" : v});
    }

    return(
        <>
            <Form style={{"textAlign" : "right"}} layout="vertical">
                <Space direction="horizontal">
                    {/* <Form.Item label="Find a list"> */}
                        <Input.Search onSearch={(v) => setSearch(v)} style={{"width" : "100%", "maxWidth" : "400px"}}  placeholder="Search lists" allowClear  />
                    {/* </Form.Item> */}
                    {/* <Form.Item label="Sort by"> */}
                        <Select dropdownMatchSelectWidth={false} defaultValue="lastupdate" onChange={(e) => setSort(e)} className="select-before">
                            <Select.Option value="name">Alphabetical</Select.Option>
                            <Select.Option value="lastupdate">Last updated</Select.Option>
                            <Select.Option value="dateposted">Date posted</Select.Option>
                            <Select.Option value="tldefsequence">---</Select.Option>
                        </Select>
                    {/* </Form.Item> */}
                    {/* <Form.Item label=" "> */}
                        <Button onClick={() => setReset()}>Reset</Button>
                    {/* </Form.Item> */}
                </Space>
            </Form>
            
        </>
    );
    

}