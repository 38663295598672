import React, { useState, useEffect } from "react";
import LazyLoad from 'react-lazyload';
import { useHistory } from "react-router-dom";
import Html from "../../utilities/Html";
import { apiCall } from "../../utilities/Api"
import EventsCalandar from "../events/EventsCalandar";
import TrevSlider from "./TrevSlider";

function NewSlider(props) {


    const {type = "list", features = false} = props;  
    const { calendar_widgets = [], has_titles = true, name = "" } = props.data;
    const history = useHistory();
    const [results, setResults] = useState([]);
    const [rows, setRows] = useState(0);
    const list_id = props.data.list_id;
    const [loading, setLoading] = useState(true);

    // Callback from api call
    const onGetListItems = (_status, _result) => {
        setLoading(false);
        if (_status) {
            if(_result && _result.hasOwnProperty("rows")){
                if (_result.row_count === 0) {
                    setSliderVisible("none");
                }
                setResults(_result.rows.splice(0, 25));
                setRows(_result.row_count)
            }
        }
    }

    const [sliderVisible, setSliderVisible] = useState("block");

    const getListItems = () =>{
        apiCall("titlelist/getItemsSimple", { "list_id": list_id }, onGetListItems);
    }

    useEffect(getListItems, []);

    const gotoList = (_item) => {
        
        if(has_titles){
            history.push("/lists/" + list_id);
        }
        

    }

    const parseCalendar = () => {
        let chunks = props.data.description.split("<!--Calendar-->");
        return chunks.map((item, ind) => {
            if (ind === chunks.length - 1) {
                return (<Html key={ind} html={item} />);
            } else {
            
                return (<div key={ind}><Html html={item} /><span><EventsCalandar /></span></div>)
            }
        });
    }

    const displaySlider = () => {

        return (<div className="testmike"><TrevSlider list_id={list_id} sidebar={props.sidebar} features={features} row_count={rows} items={results} /></div>);
    }

    const displayLazyLoad = () => {


        if(rows === 0 && type === "customer_list" && !loading){
            return(<>{props.drawEmpty(props.data)}</>)
        }

        if (props.index === 0 || props.index === 1) {
            return (<div style={{ height: "223px", overflow: "hidden", display: sliderVisible }}><LazyLoad offset={700} once height={223}>
                {displaySlider()}
            </LazyLoad></div>)
        } else {
            return (<div style={{ height: "223px", overflow: "hidden", display: sliderVisible }}><LazyLoad offset={700} once height={223}>
                {displaySlider()}
            </LazyLoad></div>)
        }
    }

    // Wrap list with slider functionality
    return (
        <div className={(props.data.placement === "left_sidebar") ? "show-block inside_container noshadow" : "inside_container noshadow"} style={{ "marginTop": "0px" }}>
            {/* Display Header */}
         
            {(props.data.header_visible && (<><div><div style={{ "cursor": "pointer" }} onClick={() => gotoList()} href="/" className="search_bar_text_colour"><a className="left_list_title " style={{ "paddingLeft": "10px", "textAlign" : "center" }}>{props.data.header}</a>
            <div className="shim"></div><div className="shim"></div>
            </div></div></>))}

            {/* inject Calendars into html */}

            {(calendar_widgets.length > 0 && parseCalendar())}

            {/* Display HTML regularly */}
            {(calendar_widgets.length === 0 && !features && <div>

                {(!name && <Html html={props.data.description} />)}
                
                </div>)}

            {(features && <br />)}

            <div style={{ "position": "relative" }} className="slider-container">
                <div style={{ "overflowX": "hidden", "width": "100%" }}>


                    {displayLazyLoad()}
                </div>
            </div>
        </div>
    )
}


export default NewSlider;
