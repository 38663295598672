import React, { useState } from "react";
import { Button, Modal, Divider, Upload, Card, Table, Alert, Spin, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { apiCall } from "../components/utilities/Api";
import { useSession } from "../util/Session";
export default function ImportExcelToCart() {

    const [session, setSession] = useSession();
    const [modalVisible, setModalVisible] = useState(false);
    const [position, setPosition] = useState("upload");
    const [uploading, setUploading] = useState(false);
    const [file, setFile] = useState({});
    const [processed, setProcessed] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(""); 

    const close = () => {
        setModalVisible(false);
        setPosition("upload");
    }

    const onUploadFile = (_status, _result) => {
        if (_status) {
            setProcessed(_result.result.titles);
            setPosition("process");
        } else {
            setPosition("error")
        }
        setUploading(false);
    }

    const uploadFile = (_file) => {

        if(_file.file.size > 50000000){
            message.error("File is too large. (Max size is 50MB)");
            return; 
        }

        setUploading(true);
        setFile(_file);
        apiCall("cart/import", { file: _file.file }, onUploadFile);
    }

    const onProcessFile = (_status, _result) => {
        setLoading(false)
        if (_status) {
            setSession({ ...session, "cart": _result.cart })
            setPosition("complete");
        } else {
            setErrorMsg(_result.error);
            setPosition("error");
        }
        setUploading(false);
    }

    const processFile = () => {
        setLoading(true);
        setUploading(true);
        apiCall("cart/import", { addToCart: true, file: file.file }, onProcessFile);
    }

    const columns = [
        {
            title: 'isbn',
            dataIndex: 'isbn',
            key: 'isbn',
        },
        {
            title: 'qty',
            dataIndex: 'qty',
            key: 'qty',
        }
    ];

    const dataSource = processed.map((item, index) => {
        return { key: index, isbn: (<span style={{ "color": (item.success) ? "#3C8617" : "#e70d3d" }}>{item.isbn}</span>), qty: item.qty, status: item.success }
    })

    const drawTable = () => {
        return (
            <div>
                {(processed.filter((i) => i.success === true).length === 0) ? (<p style={{ "textAlign": "center" }}><strong>No rows can be processed. </strong> <br /> Try another file.</p>) : (<p style={{ "textAlign": "center" }}><strong>{processed.filter((i) => i.success === true).length}</strong> rows shown in <strong style={{ "color": "#3C8617" }}>green</strong> will be processed. Any rows in <strong style={{ "color": "#e70d3d" }}>red</strong> will not be processed. </p>)}
                <Card bodyStyle={{ "padding": "0px" }}>
                    <Table scroll={{y : 300}} pagination={false} columns={columns} dataSource={dataSource} size="small"></Table>
                </Card>
            </div>
        )
    }

    const drawComplete = () => {
        return (
            <div>
                <div style={{ "textAlign": "center" }}>
                    <h4><strong>Import complete.</strong></h4>
                </div>
                <div aria-hidden><Divider /></div>
                <Alert
                    style={{ "padding": "5px", "paddingBottom": "8px", "position": "relative", "zIndex": "11", "width": "100%", "margin": "0 auto", "textAlign": "center", "display": "block" }}
                    message={<>Added <strong>{processed.filter((i) => i.success === true).length}</strong> items to your cart. </>}
                    description={<div style={{ "textAlign": "center", "display": "block" }}>Return to your cart to review or submit the imported order.</div>}
                    type="success"
                />
                <div aria-hidden><Divider /></div>
                <Button onClick={() => close()} type={"primary"} block>Return to cart</Button>
            </div>
        )
    }

    const drawError = () => {

        return (
            <>
                <div style={{ "textAlign": "center" }}>
                    <h4><strong>Import failed</strong></h4>
                </div>
                <div aria-hidden><Divider /></div>
                <Alert
                    style={{ "padding": "5px", "paddingBottom": "8px", "position": "relative", "zIndex": "11", "width": "100%", "margin": "0 auto", "textAlign": "center", "display": "block" }}
                    message={<><strong>Error</strong></>}
                    description={<div style={{ "textAlign": "center", "display": "block" }}>{errorMsg}</div>}
                    type="error"
                />
                <div aria-hidden><Divider /></div>
                <Button onClick={() => close()} type={"primary"} block>Try another file</Button>
            </>
        )
    }

    const drawUpload = () => {
        return (
            <>
                <div style={{ "textAlign": "center" }}>
                    <h4><strong>Import an Excel or text file into your cart.</strong></h4>
                </div>
                <div aria-hidden><Divider /></div>
                <Upload.Dragger accept=".txt, .pdf, .xls, .xlsx, .html, .csv" showUploadList={false} customRequest={(e) => uploadFile(e)} style={{ "padding": "0px 20px" }}>
                    <div >
                        {(uploading) ? <Spin style={{ height: "35px" }} /> : <UploadOutlined style={{ "fontSize": "30px", height: "35px" }} />}
                    </div>
                    <p className="ant-upload-text">Click or drag to upload</p>
                    <p className="ant-upload-hint">
                        Upload an Excel file (.xls or .xlsx) or tab delimited (.txt) with the headers "qty" and "isbn" <small>(Max size is 50MB)</small>
                    </p>
                </Upload.Dragger>
                <div aria-hidden><Divider /></div>
                <div style={{ "textAlign": "center" }}>
                    <small>There must be values entered in both the "qty" and "isbn" fields, otherwise the file will not process. </small>
                    <small>You will be able to preview the list of titles before importing into your cart.</small>
                </div>
            </>
        )
    }

    const drawProcess = () => {
        return (
            <>
                <div style={{ "textAlign": "center" }}>
                    <h4><strong>Preview of the file contents</strong></h4>
                    <span>(scroll down to import)</span>
                </div>
                <div aria-hidden><Divider /></div>
                {drawTable()}
                <div aria-hidden><Divider /></div>
                {(processed.filter((i) => i.success === true).length === 0) ? (<Button onClick={() => setPosition("upload")} type="primary" block>Try another file.</Button>) : (<Button loading={loading} onClick={() => processFile()} type="primary" block>Import to cart</Button>)}
            </>
        )
    }

    const drawPosition = () => {
        switch (position) {
            case "upload":
                return drawUpload();
            case "process":
                return drawProcess();
            case "complete":
                return drawComplete();
            case "error":
                return drawError();
            default:
                return drawUpload();
        }
    }

    const drawModal = () => {
        return (
            <>
                <Modal
                    open={modalVisible}
                    footer={false}
                    title="Import to cart"
                    onCancel={() => close()}
                >
                    {drawPosition()}
                </Modal>
            </>
        )
    }


    const draw = () => {

        if (!session.logged_in) {
            return (<></>)
        }

        return (
            <>
                {drawModal()}
                <Button style={{"padding" : "5px 5px"}}  onClick={() => setModalVisible(true)} className="hide" block icon={<UploadOutlined aria-hidden />} type="text">Import an Excel or text file into your cart. </Button>
            </>
        )

    }

    return draw();
}