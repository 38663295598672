import React from "react";
import { Card, Divider } from 'antd';
import Html from "../../utilities/Html";

export default function Comments(props){

    const data = props.data; 

    const displayComments = () =>{

        if(data.comments.length>0){
           return data.comments.map((item, index) =>{
               return displayComment(item, index);
            })
    
        } else {
            return (<></>)
        }
    }
  
    const displayComment = (_item, _index) => {

    return(<div key={_index}>
        <br />
        <Card bodyStyle={{"padding" : "10px 15px", "backgroundColor" : "#ebfdff"}}>
            <span><strong>Message from:</strong> <em>{_item.author}</em></span><br />
            <div aria-hidden><Divider style={{"margin" : "5px 0px"}} /></div>
            <Html className="" html={_item.text} />
        </Card>
        </div>);

    }

    return(<div>{displayComments()}</div>);

}