import React from "react";
import { getColor } from "../util/CC";
import wrap from "../util/wrap";
import CalendarUI from "./helpers/CalendarUI";
export default function EventsCalendar(props) {

    const template = window.sitesettings.template_info;
    const { content = {} } = props;

    const {
        location = "main",
        component = "blank_space",
        container = true,
        injected_data = [],
        data = {}
    } = content;

    const {
        width = 500,
        full_width = false,
        align = "center",
        calendar_colour = 0,
        background_colour = 0,
        highlight_colour = 0,
        text_colour = 0,
        active_colour = 0,
        padding_x = 0,
        padding_y = 0,
        font = 0,
        display_titles = false,

        // New
        date_bar_preset = 0,
        day_preset = 0,
        border_colour = 0,
        day_border_colour = 0,
        calendar_header_colour = 0,
        weekdays_background_colour = 0,
        prev_next_background_colour = 0,

        categories = [],
        display_categories = false
    } = data;

   

    const drawRender = () => {

        let _align;
        switch (align) {
            case "center":
                _align = "0 auto"
                break;
            case "right":
                _align = "0 0 0 auto"
                break
            default:
                _align = "0 0 0 0"
        }

        let _bgStyle = {

            background: getColor(background_colour, template),
            padding: padding_y + "px " + padding_x + "px"

        }
        let _wrapperStyle = {
            width: (full_width) ? "100%" : width + "px",
            maxWidth: "100%",
            margin: _align,
            border: "1px solid " + getColor(border_colour, template, "#")
        }

        return (
            <>
            <div className={(container) ? "preview-container" : ""}>
                <div className={"bm-font-" + font} style={_bgStyle}>
                    <div style={_wrapperStyle}>
                        <CalendarUI data={data} injected_data={injected_data} 
                        
                        display_titles={display_titles}
                        display_categories={display_categories}
                        active_colour={active_colour}
                        highlight_colour={highlight_colour}
                        text_colour={text_colour}
                        calendar_colour={calendar_colour}
                        categories={categories}
                        date_bar_preset={date_bar_preset}
                        day_preset={day_preset}
                        calendar_header_colour={calendar_header_colour}
                        weekdays_background_colour={weekdays_background_colour}
                        prev_next_background_colour={prev_next_background_colour}
                        day_border_colour={day_border_colour}
                        
                        />
                    </div>
                </div>
            </div>
            </>
        )
    }


    return drawRender();
}