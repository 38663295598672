import React, {useState} from "react";
import { Button, List, Modal, Result, Divider, Skeleton, Empty, Space} from 'antd';
import {apiCall} from "../../utilities/Api";

export default function Audiobook(props){

    const eisbn = props.eisbn; 
    const [modalVisible, setModalVisible] = useState(false); 
    const close = () =>{ setModalVisible(false)}
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [results, setResults] = useState({});


    const onGetLibroAudiobooks = (_status, _results) =>{

        if(!_status){
           setError(true);
        } 
        setResults(_results);
        setLoading(false);
    }

    const getLibroAudiobooks = () =>{
        setModalVisible(true);
        setLoading(true);
        apiCall("title/getLibroAudiobooks",{"eisbn" : eisbn}, onGetLibroAudiobooks);
    }


    const displayLoading = () =>{
        return (
            <Result
            style={{"padding" : "20px"}}
            size="small"
            icon={<div aria-hidden><img alt="libro fm logo" height="48px" src="https://cdn1.bookmanager.com/i/librofm_logo.png" /> </div>}
            title={<><small>Loading...</small><div className="shim" /></>}
            subTitle={<Skeleton active={true} />}
            extra={
                <></>
            
            }
        />)
    }

    const displayError = () =>{
        return (
            <Result
            style={{"padding" : "20px"}}
            size="small"
            status="warning"
            title={<><small>There was a problem.</small><div className="shim" /></>}
            subTitle={<p>We were unable to process this request. <br /> Please try again later.<br />
                <Button style={{"marginTop" : "20px"}} onClick={() => setModalVisible(false)}>Close</Button>
            </p>}
            extra={
                <></>
            
            }
        />)
    }

    const displayEmpty = () =>{
        return (
            <Result
            style={{"padding" : "20px"}}
            size="small"
            status="warning"
            icon={<Empty aria-hidden description="" />}
            title={<><small>No audiobooks found.</small><div className="shim" /></>}
            subTitle={<p>We were unable to find audiobooks that match this title.<br />
                <Button style={{"marginTop" : "20px"}} onClick={() => setModalVisible(false)}>Close</Button>
            </p>}
            extra={
                <></>
            
            }
        />)
    }

    const displaySingle = () =>{
        return (
            <Result
            style={{"padding" : "20px"}}
            size="small"
            icon={<div aria-hidden><img alt="libro fm logo" height="48px" src="https://cdn1.bookmanager.com/i/librofm_logo.png" /></div>}
            title={<><small>Before you go!</small><div className="shim" /></>}
            subTitle={<p>After selecting go, you will be taken to <strong>Libro.fm</strong> for your audiobook checkout and download. If you have any items in your cart here, remember to head back once you're done on Libro.fm to checkout and complete your order.</p>}
            extra={
                <><a target="_blank" rel="noopener noreferrer" href={results.rows[0].url}><Button type="primary">Go to Libro.fm</Button></a></>
            
            }
        />)
    }

    const displayMultiple = () =>{


        let list_data = results.rows;
        let more = false; 
        if(list_data != null && list_data.length>5){
            list_data = list_data.slice(0,5);
            more = true; 
        }

        
        return (
            <Result
                style={{"padding" : "20px"}}
                size="small"
                icon={<div aria-hidden><img alt="libro fm logo" height="48px" src="https://cdn1.bookmanager.com/i/librofm_logo.png" /></div>}
                title={<><strong>You've got some choices!</strong><div className="shim" /></>}
                subTitle="After selecting an audiobook version, you will be taken to Libro.fm for your audiobook checkout and download. If you have any items in your cart here, remember to head back once you're done on Libro.fm to checkout and complete your order."
                extra={
                <>
                <p style={{"textAlign" : "left"}}>
                <em><small>Listed in order of popularity</small></em></p>
                <div aria-hidden><Divider style={{"margin" : "0px"}} /></div>
              
                <List dataSource={list_data }
                
                renderItem={item => (
                    <List.Item >

                    <Space align="center" size={10}>
                     <span>{item.title}</span> 
                     <span>{item.narrators.join(", ")}</span>
                     <span>{item.language}</span>
                     <a target="_blank" href={item.url}><Button type="primary" size="small"><small>Select</small></Button></a>
                     </Space>
                     
                    </List.Item>
                  )}

                />
                 <div aria-hidden><Divider style={{"marginTop" : "0px"}} /></div>
                 {(more && <><em style={{"color" : "555"}}>More editions are available.</em> <br /> <a target="_blank" href={results.see_more_url}><Button  style={{"marginTop" : "5px"}} size="small"><small>See all</small></Button></a></>)}
                    </>
                }
            />
        )
    }

    const displayStatus = () => {

        if(error) return displayError();
        if(loading) return displayLoading();

        if(results.row_count === 0){
           return displayEmpty(); 
        }

        if(results.row_count === 1){
            return displaySingle(); 
        } else {
            return displayMultiple(); 
        }

    }
   

    return(<>
        <div onClick={() => getLibroAudiobooks()}>  
           {props.children}
        </div>
        <Modal open={modalVisible} onCancel={close} destroyOnClose closable={true} footer={null}>
           {displayStatus()}
        </Modal>
        </>
    ); 

}