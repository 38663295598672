import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Input, Space, Divider, Table, Tag, message, Popconfirm, Alert } from 'antd';
import { apiCall } from "../../utilities/Api";
import { useHistory } from "react-router-dom";
import { useSession } from "../../../util/Session";
import conditional from "../../utilities/conditional";
export default function AccountLink() {

    const history = useHistory();
    const [session,] = useSession();
    const [children, setChildren] = useState([]);
    const [parents, setParents] = useState([]);
    const isInstitution = session.user.is_institution;
    const [verified, setVerified] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);

    //Check to see if there is a token on the call:
    let hasToken = false;
    if (history.location.search) {
        if (history.location.search.includes("?token=")) {
            hasToken = history.location.search.replace("?token=", "");
        }
    }

    const getChildren = () => {
        setError(false);
        setLoading(true);
        // If not logged in send to login
        if (!session.logged_in) {

            if (hasToken) {
                // set redirect if there is a token
                window.loginRedirect = "/account/link?token=" + hasToken;
            }
            history.push("/account/login");
            return;
        }

        // If this is a personal account and there is a token -> process 
        if (!isInstitution) {
            if (hasToken) {
                // is personal w token
                apiCall("account/linkToVerify", { token: hasToken }, (_status, _result) => {
                    if (_status) {
                        setVerified(true);
                        setParents(_result.filter(item => item.is_institution && item.type === "customer"));
                        setLoading(false);
                    } else {
                        // Dismiss error if it's re-tried
                        if (_result.error !== "Already verified") {
                            setError("Verification Failed.");
                        }
                        // Fetch
                        apiCall("account/getAvailableCorps", { token: hasToken }, (_status, _result) => {
                            if (_status) {
                                setParents(_result.filter(item => item.is_institution && item.type === "customer"));
                                setLoading(false);
                            } else {
                                message.error("There was an error (" + _result.error + ")");
                                setLoading(false)
                            }
                        })
                    }
                })
            } else {
                // is personal w/o token
                apiCall("account/getAvailableCorps", { token: hasToken }, (_status, _result) => {
                    if (_status) {
                        setParents(_result.filter(item => item.is_institution && item.type === "customer"));
                        setLoading(false);
                    } else {
                        message.error("There was an error (" + _result.error + ")");
                        setLoading(false)
                    }
                })
            }
        }
        else {
            if (isInstitution) {
                // get children
                if (!session.user.is_owner) {
                    return;
                }
                apiCall("account/getAvailableChildren", {}, (_status, _result) => {
                    if (_status) {
                        setChildren(_result);
                        setLoading(false)
                    } else {
                        message.error("There was an error (" + _result.error + ")");
                        setLoading(false)
                    }
                })
            }
        }
    }

    useEffect(getChildren, [session]);

    // Remove child link
    const removeChildLink = (_id) => {
        apiCall("account/removeChildLink", { id: _id }, (_status, _result) => {
            if (_status) {
                setChildren(_result);
            }
        })
    }

    // Remove parent link
    const removeParentLink = (_id) => {
        apiCall("account/removeParentLink", { id: _id }, (_status, _result) => {
            if (_status) {
                setParents(_result.filter(item => item.is_institution && item.type === "customer"));
            }
        })
    }

    const requestLink = (_f) => {
        apiCall("account/linkToRequest", { email: _f["email_address"] }, (_status, _result) => {
            if (_status) {
                message.success("Link request sent.");
                setChildren(_result);
                setModalVisible(false);
            } else {
                setModalVisible(false);
                message.error("There was an error (" + _result.error + ")");
            }
        })
    }

    const drawParents = () => {

        if (isInstitution) {
            return (<></>);
        }

        const parentColumns = [
            { title: 'name', dataIndex: 'name', key: 'name' },
            {
                title: <></>, dataIndex: 'remove', width: "55px", key: 'remove', render: (e, f) => {
                    return <Popconfirm title="Are you sure?" onConfirm={() => removeParentLink(f.id)}><Button danger size="small" ><small>remove</small></Button></Popconfirm>
                }
            },
        ];

        return (
            <>
                <conditional.true value={(error)}>
                    <Alert
                        type="error"
                        message={error}
                        closable
                        style={{ "marginBottom": "20px" }}
                    />
                </conditional.true>
                <conditional.true value={(verified)}>
                    <Alert
                        type="success"
                        message="Account authorized successfully."
                        closable
                        style={{ "marginBottom": "20px" }}
                    />
                </conditional.true>
                <h3 style={{ "fontSize": "16px" }}>Linked Accounts</h3>
                <p>You have permission to purchase on behalf of the following accounts:</p>
                <div aria-hidden><Divider dashed style={{ "marginTop": "10px" }} /></div>
                <Table bordered loading={loading} showHeader={false} size="small" pagination={false} dataSource={parents} columns={parentColumns} />
            </>
        );
    }

    const drawChildren = () => {

        if (!isInstitution) {
            return (<></>);
        }

        if (!session.user.is_owner) {

            return (
                <>
                    <h3 style={{ "fontSize": "16px" }}>Linked Accounts</h3>
                    <div aria-hidden><Divider dashed style={{ "marginTop": "10px" }} /></div>
                    <Alert
                        type="error"
                        message={<div>You don't have permission to modify the linked accounts of: <strong>{session.user.company}</strong></div>}

                        style={{ "marginBottom": "20px" }}
                    />
                </>
            )

        }

        const columns = [
            { title: 'name', dataIndex: 'name', key: 'name' },
            { title: 'email', dataIndex: 'email', key: 'email' },
            {
                title: 'pending', dataIndex: 'pending', key: 'pending', render: (e) => {
                    return (!e) ? <Tag color="#87d068">Authorized</Tag> : <Tag color="#888888" >Pending</Tag>
                }
            },
            {
                title: <></>, dataIndex: 'remove', width: "55px", key: 'remove', render: (e, f) => {
                    return <Popconfirm title="Are you sure?" onConfirm={() => removeChildLink(f.id)}><Button danger size="small" ><small>remove</small></Button></Popconfirm>
                }
            },
        ];

        return (
            <>
                <Modal destroyOnClose footer={null} closeIcon={<></>} onCancel={() => setModalVisible(false)} open={modalVisible}>
                    <Form onFinish={(f) => requestLink(f)} layout="vertical">
                        <h3 style={{ "fontSize": "16px" }}>Request to link account.</h3>
                        <div aria-hidden><Divider /></div>
                        <Form.Item name="email_address" rules={[{ message: "Email address required.", required: true }]}>
                            <Input inputMode="email" type="email" placeholder="Email address" />
                        </Form.Item>
                        <div className="shim"></div>
                        <div style={{ "float": "right" }}>
                            <Space>
                                <Button onClick={() => setModalVisible(false)}>Cancel</Button>
                                <Button htmlType="submit" type="primary">Request</Button>
                            </Space>
                        </div>
                        <br clear="all" />
                    </Form>
                </Modal>
                <h3 style={{ "fontSize": "16px" }}>Linked Accounts</h3>
                <p>The following accounts have permission to purchase on your behalf:</p>
                <div aria-hidden><Divider dashed style={{ "marginTop": "10px" }} /></div>
                <Table loading={loading} bordered showHeader={false} size="small" pagination={false} dataSource={children} columns={columns} />
                <br />
                <div style={{ "float": "right" }}>
                    <Button size="small" onClick={() => setModalVisible(true)} type="primary"><small>Link Account</small></Button>
                </div> <br clear="all" />
            </>
        )
    }

    return (
        <>
            {drawChildren()}
            {drawParents()}
        </>
    );
}