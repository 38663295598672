import { Input, message, Modal, Space, Button, Select, Alert } from "antd";
import React, { useState } from "react";
import { apiCall } from "../../utilities/Api";
import conditional from "../../utilities/conditional";
export default function CopyListToList(props) {

    const { list_id = "" } = props;
    const [visible, setVisible] = useState(false);
    const [showList, setShowList] = useState(false);
    const [lists, setLists] = useState({ rows: [] });
    const [newListName, setNewListName] = useState("");
    const [selectedList, setSelectedList] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const copyListToList = (_to_list) => {
        setError("");
        setLoading(true);
        apiCall("titlelist/copyFromList", { from_list_id: list_id, to_list_id: _to_list }, (_status, _result) => {
            if (_status) {
                setVisible(false);
                message.success("List copied successfully.");
            } else {
                setError(_result.error)
            }
            setLoading(false);
        })
    }

    const addNewList = () => {
        setError("");
        setLoading(true);
        apiCall("titlelist/create", { name: newListName, description: "" }, (_status, _result) => {
            if (_status) {
                let _find = _result.rows.find(item => item.name === newListName);
                if (_find) {
                    copyListToList(_find.list_id);
                } else {
                    setError("There was an error.")
                    setLoading(false);
                }
            } else {
                setError(_result.error);
                setLoading(false);
            }
           
        })
    }

    const fetchLists = () => {
        apiCall("titlelist/getLists", {}, (_status, _result) => {
            if (_status) {
                setLists(_result);
            }
        })
    }

    const toggle = () => {
        if (lists.rows.length === 0) {
            fetchLists();
        }
        setShowList(!showList)
    }

    const drawError = () => {

        return (
            <conditional.true value={(error)}>
                <Alert
                    style={{ "padding": "3px 6px" }}
                    message={<small>{error}</small>}
                    type="error"
                    closable

                />
            </conditional.true>
        )
    }

    const drawModal = () => {

        return (
            <Modal
                onCancel={() => setVisible(false)}
                title="Copy to list"
                destroyOnClose
                centered
                width={350}
                footer={null}
                open={visible}>

                <conditional.true value={!showList}>
                    <div className="ant-col ant-form-item-label">Add contents to a <strong>new</strong> list</div>
                    <div className="shim" />
                    <Input onChange={(e) => setNewListName(e.target.value)} placeholder="New list name..."></Input>
                    <div className="shim" />
                    <div className="shim" />
                    {drawError()}
                    <div className="shim" />
                    <div className="shim" />
                    <Button onClick={() => toggle()} type="link" style={{ "color": "#00aeef" }}>Add to existing list</Button>
                    <div style={{ "float": "right" }}>
                        <Space>
                            <Button onClick={() => setVisible(false)}>Cancel</Button>
                            <Button loading={loading} disabled={(!newListName)} onClick={() => addNewList()} type="primary">Save</Button>
                        </Space>
                    </div>
                    <br clear="all" />
                </conditional.true>

                <conditional.true value={showList}>
                    <div className="ant-col ant-form-item-label">Add contents to an <strong>existing</strong> list</div>
                    <div className="shim" />
                    
                    <Select placeholder="Select a list..." onChange={(e) => setSelectedList(e)} style={{ "width": "100%" }}>
                        {lists.rows.map(item => {
                            return (<Select.Option key={item.list_id} value={item.list_id}>{item.name}</Select.Option>)
                        })}

                    </Select>
                    <div className="shim" />
                    <div className="shim" />
                    {drawError()}
                    <div className="shim" />
                    <div className="shim" />
                    <Button onClick={() => toggle()} type="link" style={{ "color": "#00aeef" }}>Add to new list</Button>
                    <div style={{ "float": "right" }}>
                        <Space>
                            <Button onClick={() => setVisible(false)}>Cancel</Button>
                            <Button loading={loading} disabled={(!selectedList)} onClick={() => copyListToList(selectedList)} type="primary">Save</Button>
                        </Space>
                    </div>
                    <br clear="all" />
                </conditional.true>
            </Modal>
        )

    }

    // no list id, can't do anything...
    if(!list_id){
        return(<></>)
    }

    return (
        <>
            {drawModal()}
            <div onClick={() => setVisible(true)}>{props.children}</div>
        </>
    )

}