import React from "react";
import { Divider, Typography } from 'antd';
import { useParams, useHistory } from "react-router-dom";
import { useSession } from "../util/Session";
import AccountSignUp from "../components/components/account/AccountSignUp";
import AccountForgot from "../components/components/account/AccountForgot";
import AccountLoginForm from "../components/components/account/AccountLoginForm";
import AccountDetails from "../components/components/account/AccountDetails";
import AccountCreate from "../components/components/account/AccountCreate";
import AccountComplete from "../components/components/account/AccountComplete";
import AccountVerify from "../components/components/account/AccountVerify";
import OrderComplete from "../components/components/account/OrderComplete";
import AccountAddressBook from "../components/components/account/AccountAddressBook";
import AccountLists from "../components/components/account/AccountLists";
import AccountLink from "../components/components/account/AccountLink";

function AccountLayout() {

    const { view } = useParams();
    const history = useHistory();
    const [context] = useSession();
    const { logged_in } = context;

    const wrapContainer = (_component, _size = "inherit") => {
        return (<div className="container" style={{ "maxWidth": _size }} >{_component}</div>)
    }

    const displaySignUp = () => {
        return (<AccountSignUp />);
    }

    const displayLogin = () => {
        return (
            <>
                <h2>Login</h2>
                <div aria-hidden><Divider /></div>
                <div style={{ "maxWidth": "500px", "margin": "0 auto" }}>
                    <AccountLoginForm size="large" show_create={true} />
                </div>
            </>
        );
    }

    const verifyEmail = () => {
        return (<AccountVerify />)
    }

    const LoginFirst = () => {

        if (history.location.search) {
            return (
                <>
                    <div>
                        <h2>Login to continue</h2>
                        <div aria-hidden><Divider /></div>
                    </div>
                    <AccountLoginForm callback={() => history.push("/account/details" + history.location.search)} />
                </>
            )
        } else {
            history.push({ "pathname": "/" });
        }
        
        return(<></>);
      
    }

    const displayView = () => {

        if (view === "complete") {
            return <OrderComplete />;
        }

        if (!logged_in) {
            switch (view) {
                case "details":
                    return wrapContainer(<LoginFirst />, "350px");
                case "signup":
                    return wrapContainer(displaySignUp(), "500px");
                case "create":
                    return wrapContainer(<AccountCreate />, "500px");
                case "forgot":
                    return wrapContainer(<AccountForgot />, "500px");
                case "activate":
                    return wrapContainer(<AccountForgot activate />, "500px");
                case "verify":
                    return wrapContainer(verifyEmail(), "500px");
                case "link":
                    return wrapContainer(<AccountLink />, "500px");
                case "login":
                    return wrapContainer(displayLogin(), "300px");
                default:
                    history.push({ "pathname": "/account/login" });
                    return wrapContainer(displayLogin(), "300px");
            }

        } else {

            switch (view) {
                case "details":
                    return wrapContainer(<AccountDetails />, "800px");
                case "lists":
                    return <AccountLists />;
                case "link":
                    return wrapContainer(<AccountLink />, "500px");
                case "addresses":
                    return wrapContainer(<AccountAddressBook />, "1000px");
                case "finished":
                    return wrapContainer(<AccountComplete />, "500px");
                default:
                    history.push({ "pathname": "/account/details" });
                    return (<></>);
            }
        }
    }

    return displayView();

}

export default AccountLayout;