import React, { useState, useRef } from "react";
import { Input, Result, Modal, Divider, Button } from 'antd';
import { CreditCardOutlined, DollarOutlined } from '@ant-design/icons';
import { apiCall } from "../../utilities/Api";
import ReCAPTCHA from "react-google-recaptcha";

export default function GiftCardBalance() {

    //GC15976734

    const recaptchaRef = useRef();
    const [ballance, setBallance] = useState("");
    const [loading, setLoading] = useState(false); 
    const [code, setCode] = useState("")
    const onCheckBalance = (_success, _result) => {

        setBallance("")
        setLoading(false)
        if (_success) {

            Modal.success({
                icon: <DollarOutlined />,
                centered: true,
                title: 'Your balance is ' + _result.balance,
                content: `Gift cards never expire.`,

            });

        } else {

            Modal.warning({
                centered: true,
                title: "There was an error",
                content:_result.error,
            });
        }
    }



    const checkBalance = (v) => {
        if (v) {
            setBallance(v);
            setLoading(true)
            recaptchaRef.current.reset()
            recaptchaRef.current.execute()
        }
    }
    const onChange = (e) => {
        if (e) {
            apiCall("giftcard/balance", { "number": ballance.replace(" ", ""), "captcha": e }, onCheckBalance);
        }

    }



    return (
        <div>
            <Result
                icon={<CreditCardOutlined aria-hidden />}
                title="Check your Gift Card balance"
                subTitle={<div style={{"color" : "#666"}}>Type in your Gift Card number (found on the back of the card, right below the barcode) and hit go.</div>}
                extra={<>

                    <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey="6LfPBUslAAAAAIrLp814HpBIixMj0rL5J3cAxEUw"
                        onChange={onChange}
                    />
                    <Input
                        aria-label="Gift card number"
                        placeholder="Gift card number.."
                        prefix={<div style={{"color" : "#666"}}><CreditCardOutlined /> &nbsp; GC &nbsp;</div>}
                        style={{ "maxWidth": "370px", "padding": "7px 12px" }}
                        enterButton="Check Balance"
                        loading={loading}
                        size="large"
                        onChange={(e) => setCode(e.target.value)}
                    />
                     <div className="shim" /><div className="shim" /><div className="shim" />
                            <Button type="primary" onClick={() => checkBalance(code)} >Check Balance</Button>

                </>}
            />
            <div aria-hidden><Divider /></div>
        </div>
    )
}