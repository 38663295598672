import React from "react";
import { getColor } from "../util/CC";
import { Button, Skeleton } from "antd";
import GiftCardAddToCart from "../components/components/giftcards/GiftCardAddToCart";

export default function GiftCards(props) {

    const template = window.sitesettings.template_info;
    const { content = {} } = props;

    const {
        location = "main",
        component = "blank_space",
        container = true,
        data = {},
        injected_data = []
    } = content;

    const {
        cover_image_cache = 0,
        eisbn = "",
        name = ""
    } = injected_data;


    const {
        width = 800,
        align = "0 auto",
        radius = 15,
        padding_x = 20,
        padding_y = 20,
        gap = 20,
        shadow = true,
        button_type = 0,
        text_preset = 0,
        background_colour = 0,
        card_width = 50,
    } = data;

    const drawCards = () => {

        let _style = {
            flexGrow: 1,
        }

        let containerStyle = {
            width: width + "px",
            maxWidth: "100%",
            margin: align,
        }

      

        return injected_data.map(item => {
            return (
                <div>

                    <div style={{ background: getColor(background_colour, template), padding: padding_y + "px " + padding_x + "px" }}>
                        <div style={containerStyle}>
                            <div className="bm-stack" style={{ display: 'flex', width: '100%', gap: gap + "px" }}>
                                <div style={{ flex: '0 0 ' + card_width + "%", display: 'flex' }}>
                                    <img alt={item.name + " image"} onError={i => i.target.style.display = 'none'} style={{ "width": "100%", "borderRadius": radius + "px", "boxShadow": (shadow) ? "0px 2px 5px 0px rgba(0,0,0,0.5)" : "none" }} src={"https://cdn1.bookmanager.com/i/m.php?b=" + item.eisbn + "&cb=" + item.cover_image_cache} />
                                </div>
                                <div style={{ flex: '0 0 ' + (100 - card_width) + "%", display: 'flex', justifyContent: align }}>
                                    <div style={{ ..._style }}>
                                        <div>
                                            <h4 className={"bm-txt-" + text_preset}>{item.name}</h4>
                                            <div className="shim" />
                                            <GiftCardAddToCart item={item} eisbn={item.eisbn} cc_giftcard={true}>
                                                <Button aria-label={item.title + " Add to cart"} className={"bm-btn-" + button_type}>Add to Cart</Button>
                                            </GiftCardAddToCart>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    const drawRender = () => {


        let _wrap = {
            margin: align,
            maxWidth: width + "px",
        }

        return (
            <>
                <div className={(container) ? "preview-container" : ""}>
                    <div style={{ "backgroundColor": getColor(background_colour, template, "#ffffff00") }}>
                        <div style={_wrap}>
                            {drawCards()}
                            <div className="shim" style={{ "height": padding_y + "px" }} />
                        </div>
                    </div>

                </div>
            </>
        )
    }

    return drawRender();
}