import { DownOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import List from "../wrappers/List";

export default function IncludedIn(props){

    const { 
        data = {},
        title = "",
    } = props; 
    const loc = useLocation(); 
    const [modalVisible, setModalVisible] = useState(false);
    const [rows, setRows] = useState([]);
    const close = () =>{ setModalVisible(false)}
    
    const isBundle = () => {
        if(data.included_items.row_count > 0){
            return true;
        }
        return false
    }

    const closeOnLocation = () =>{
        if(modalVisible){
            close();
        }
    }

    useEffect(closeOnLocation, [loc])

    const openModal = () =>{
        setRows(isBundle() ? data.included_items.rows : data.included_in.rows)
        setModalVisible(true);
    }
    
    return(<>
        <Button 
            icon={<DownOutlined aria-hidden style={{"float" : "right", "padding" : "15px 10px"}} />} 
            onClick={() => openModal()} 
            size="large" 
            style={{"textAlign" : "left", "height" : "60px", "fontSize" : "14px", "marginTop" : "10px"}} 
            block type="dashed">
            <strong>{isBundle() ? "Included Items" : "Included In"}</strong><br />
            <div className="see-other">
                {isBundle() 
                ? "See what items are part of this one"
                : "See other items that this one is part of"}
            </div>
        </Button>
        <Modal 
            wrapClassName="siteModal" 
            width="90%" 
            title={isBundle() ? "Included Items in " + title : "Included In"} 
            open={modalVisible} 
            onCancel={close}>
            <List popup={true} data={rows} />
        </Modal>
        </>
    ); 

}