import React, {useState} from "react";
import { Form, Input, Button, Space, Divider} from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import {apiCall} from "../../utilities/Api";
function AccountChangePassword(props){

    const {title, close, callback} = props; 
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const onChangePassword = (_status, _result) =>{

        if(!_status){
            setError(true);
            setErrorMsg(_result.error);
          } else {
              close(); 
              callback(); 
          }
    }

    const changePassword = (_form) =>{

        delete _form.confirm; 
        apiCall("account/modify",_form, onChangePassword);
    }

    const displayConfirmPassword = () => {

        return (
            <>
                <Form.Item
                    name="new_password"
                    label="New Password"
                    rules={[{
                        required: true,
                        min: 8,
                        message: 'Password must be at least 8 characters.',
                    },]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    name="confirm"
                    label="Confirm New Password"
                    dependencies={['new_password']}
                    hasFeedback
                    rules={[{
                        required: true,
                        message: 'Please confirm your password.',
                    }, ({ getFieldValue }) => ({
                        validator(rule, value) {
                            if (!value || getFieldValue('new_password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject('The two passwords that you entered do not match.');
                        },
                    }),]}
                >
                    <Input.Password min={8} max={12} />
                </Form.Item>
            </>
        )

    }

    return( 
        <div style={{"minWidth" : "300px"}} >
            <strong>{title}</strong>
            <div aria-hidden><Divider /></div>
            <Form  layout="vertical" onFinish={(form) => changePassword(form)} >
                <Form.Item rules={[{ message : "Current password required.", required: true }]} name="password" label="Current password">
                    <Input.Password  />
                </Form.Item>
                {displayConfirmPassword()}
                {(error && <p style={{ "paddingBottom": "15px", "color": "#ff4d4f", "textAlign" : "center" }} className="error"><ExclamationCircleFilled /> {errorMsg}</p>)}
                <Space><Button type="primary" htmlType="submit">Change Password</Button><Button onClick={() => close()}>Cancel</Button></Space>
            </Form>
        </div>
    )
}

export default AccountChangePassword; 