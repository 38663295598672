import { Form, Input } from "antd";
import React from "react";

export default function TextPhoneField(props) {

    const { data = {}, form, contact = {} } = props;

    const setValue = (_v) => {
        if (form) {
            form.setFieldsValue({ [id]: _v });
            form.validateFields([id]);
        }
        return (_v);
    }

    const validatePhone = (rule, value) => {
        if (!value || value === "") {
            return Promise.resolve();
        }
        if (!value && form.getFieldValue([id])) {
            return Promise.reject('Phone number is required');
        }
        if (value.length !== 14) {
            return Promise.reject('Please enter a valid phone number');
        }
        return Promise.resolve();
    };

    const formatPhoneNumber = (_value) => {
        if (!_value) return _value;
        let value = _value.replace(/[^\d]/g, '');
        // remove international digit if it exists & trim the rest
        if (value.length > 9 && value.substr(0, 1) === "1") {
            value = value.substr(1, value.length - 1);
        }
        // first bit
        if (value.length < 4) {
            return setValue(value);
        }
        // middle bit
        if (value.length < 7) {
            return setValue(`(${value.slice(0, 3)}) ${value.slice(3)}`);
        };
        // full number
        return setValue(`(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`);
    };

    const handlePaste = () => {

    }

    const {
        id = "",
        label = "Text",
        type = "text",
        description = "",
        placeholder = "Enter text...",
        required = false,
        required_message = "Phone number is required"
    } = data;

    let defaultValue = (contact.hasOwnProperty([id])) ? contact[id] : "";

    return (
        <Form.Item initialValue={defaultValue} extra={description} rules={[{ required: required, message: required_message }, { validator: validatePhone }]} label={label} name={id} >
            <Input onChange={(_v) => formatPhoneNumber(_v.target.value)} onPaste={handlePaste} maxLength={14} placeholder={placeholder} />
        </Form.Item>
    )
}