import React from "react";
import { Row, Col, Pagination, Space } from "antd";
import { addCommas } from "../util/Utils";
import conditional from "../util/conditional";
import Cookies from "js-cookie";
export default function Paginate(props) {
    const {
        save = "",
        paginate = { current: 1, pagesize: 26, offset: 0 },
        setPaginate = () => { },
        show_pagination = true,
        pageSizeOptions = [26, 50, 100, 200],
        precomponent = null,
        component = null,
        showSizeChanger = true,
        count = 0,
        hide_single = false,
        showing_right = false,
        loading = false,
    } = props;

    const itemRender = (_, type, originalElement) => {
        if (type === 'prev') {
            return <a style={{"margin": "5px"}}>Previous</a>;
        }
        if (type === 'next') {
            return <a style={{"margin": "5px"}}>Next</a>;
        }
        return originalElement;
    };

    const drawShowing = () => {
        let end = paginate.current * paginate.pagesize;        
        let start = (paginate.current * paginate.pagesize) - (paginate.pagesize - 1);
        if (end > count) {
            end = count;
        }
        if (count === 0) {
            return "0";
        }
        return addCommas(start) + " to " + addCommas(end);
    }

    const pageChange = (_page, _limit) => {
        let _offset = (_page - 1) * _limit;
        if (_limit !== paginate.pagesize) {
            _offset = 0;
            _page = 1;
            if (save) {
                Cookies.set(save, _limit, { expires: 365 });
            }
        }
        setPaginate({
            pagesize: _limit,
            offset: _offset,
            current: _page
        })
    }
    if ((hide_single && paginate.pagesize > count)) {
        return (<></>);
    }
    const drawShowingText = () => {

        let _txt = "Showing " + drawShowing() + " of " + addCommas(count) + " results"
        return (<em>{_txt}</em>)
    }

    return (
        <>
            <div className="paginate" style={{}}>
                <div className="float-flex">
                    <Space align="baseline" wrap>               
                        <div style={{ "paddingTop": "10px"  }}>
                            {(precomponent && precomponent)}
                            {(!showing_right && drawShowingText())}
                            {(component && component)}
                        </div>
                        <div>
                            <Space>
                                {(showing_right && drawShowingText())}
                                <div style={{"paddingRight": "20px", "textAlign": "right"}}>
                                    <div className="shim" /><div className="shim" />
                                    <conditional.true value={(show_pagination)} >
                                        <Pagination 
                                            pageSizeOptions={pageSizeOptions}
                                            size="small" 
                                            showSizeChanger={showSizeChanger} 
                                            itemRender={itemRender}
                                            current={paginate.current} 
                                            pageSize={paginate.pagesize} 
                                            total={count} 
                                            onChange={(_page, _limit) => pageChange(_page, _limit)} 
                                        />
                                    </conditional.true>
                                    <div className="shim" /><div className="shim" />
                                </div>
                            </Space>
                        </div>
                    </Space>
                </div>
            </div>
        </>
    )
}