import { Button, Modal } from "antd";
import React from "react";
import { useState } from "react";
import GlobalPaymentsForm from "./GlobalPaymentsForm";
import LoadingOverlay from "./LoadingOverlay";

export default function GlobalPaymentsButton(props) {

    const { amount = "" } = props;
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState("")

    const onClose = () => {
        setOpen(false);
    }

    return (
        <>
            <Modal destroyOnClose bodyStyle={{ "paddingBottom": "0px" }} onCancel={() => onClose()} title="Global Payments" width={350} footer={null} open={open}>
                <GlobalPaymentsForm onClose={onClose} setLoading={setLoading} setOpen={setOpen} amount={amount} />
            </Modal>
            <br />
            <Button loading={loading} onClick={() => setOpen(true)} type="primary" block ><strong>Pay Now</strong></Button>
            <LoadingOverlay loading={loading} />
        </>
    )
}