import React,{useState, useEffect} from "react";
import { Form, Input, Checkbox, Space, Select } from 'antd';
import {apiCall} from "../../utilities/Api";
import PostalCodeFormatter from "../../formatters/PostalCodeFormatter";
import PhoneNumberFormatter from "../../formatters/PhoneNumberFormatter";
export default function AddressForm(props){

    const {data = {}} = props;
    const {
        name = "",
        address_id = 0,
        company_name = "",
        preferred = false,
        phone_number = "",
        phone_number_ext = "",
        email_address = "",
        street_address = "",
        street_address_2 = "",
        postal_code = "",
        country = "",
        province = "",
        city = "",
    } = data; 

 
    const writeToForm = () =>{
       
        if(Object.keys(data).length === 0){
            props.form.resetFields(); 
        } else {
            props.form.setFieldsValue(data);
        }
        
    }
    useEffect(writeToForm, [props.selectedAddress])

    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(country);
    const [selectedProvince, setSelectedProvince] = useState(province);
    const [provinces, setProvinces] = useState([])
 
    const onListCountries = (_status, _result) => {
        if (_status) {
            setCountries(_result);
        }
    }

    const listCountries = () =>{
        apiCall("country/getList", {}, onListCountries)
    }
    
    useEffect(listCountries,[])

    const onListProvinces = (_status, _result) => {
        if (_status) {
            setProvinces(_result);
        }
    }

    const listProvinces = (_country) => {
        if(!_country){
            _country = selectedCountry; 
        }
        apiCall("province/getList", { "country": _country }, onListProvinces)
    }

    useEffect(listProvinces, [selectedCountry])

    const formatMessage = () => {

        if(selectedCountry === "Canada"){

        }

        if(selectedCountry === "Canada"){
            
        }

        return ; 

    }

    const postalError = () => {
        let ret = "Valid postal code is required.";
        if(selectedCountry === "Canada"){
            return ret + " eg. A1B 2C3" 
        } 

        if(selectedCountry === "United States"){
            return ret + " eg. 12345 or 12345-6789" 
        } 

        return ret; 
    }

    return(<>
                <Form.Item initialValue={address_id} name="address_id" noStyle >
                    <Input type="hidden" />
                </Form.Item>
                
                <Form.Item initialValue={name} label="Full Name" name="name" rules={[{ message: "Full Name is required.", required: true }]}>
                    <Input />
                </Form.Item>

                <Form.Item initialValue={company_name} label={<>Company / Institution&nbsp;<em> (optional)</em></>} name="company_name">
                    <Input />
                </Form.Item>

                <Form.Item  rules={[{ required: true }]} label={<><span style={{color : "#ff4d4f", fontSize:"14px", fontFamily: "SimSun, sans-serif"}}>*</span>&nbsp;Phone Number</>}>
                <Input.Group compact>
                <Form.Item noStyle initialValue={phone_number} label="Phone Number" name="phone_number" rules={[{ pattern: new RegExp(/^[0-9+. \-)(]{10,15}$/g), required: true, min:10, message: "Phone number is required." }]}>
                    <PhoneNumberFormatter style={{ width: "156px" }} field="phone_number" form={props.form} country={selectedCountry} />
                </Form.Item>
                
                <Form.Item noStyle initialValue={phone_number_ext} name="phone_number_ext" rules={[{ message: "Must be between 2 and 6 digits", pattern: new RegExp(/^[0-9]{2,6}$/g), required: false }]}>
                            <Input field="phone_number_ext" style={{ width: '80px' }} maxLength={6} inputMode="numeric" placeholder="Ext." />
                        </Form.Item>
                </Input.Group>
                </Form.Item>

                <Form.Item initialValue={email_address} label="Email Address" type="email" name="email_address" rules={[{ message: "Email address is required." }]}>
                    <Input inputMode="email" type="email"  />
                </Form.Item>

                <Form.Item initialValue={street_address} label="Street Address" name="street_address" rules={[{ message: "Street Address is required.", required: true }]}>
                    <Input />
                </Form.Item>
                
                <Form.Item initialValue={street_address_2} label="Street Address (line 2)" name="street_address_2" rules={[{ required: false }]}>
                    <Input />
                </Form.Item>

                <Form.Item initialValue={city} label="City" name="city" rules={[{ message: "City is required.", required: true }]}>
                    <Input />
                </Form.Item>

               <Form.Item initialValue={country} label="Country" name="country" rules={[{ validateTrigger:"onBlur", validator: (_, value) =>(countries.includes(selectedCountry)) ? Promise.resolve() : Promise.reject("no"), message: "Valid country is required.", required: true }]}>
                    
                    <Select showSearch placeholder="Country" style={{ maxWidth: 400 }} onChange={(v) => setSelectedCountry(v)}>
                         {countries.map((item, index) =>{
                                return(<Select.Option key={index} value={item}>{item}</Select.Option>)
                         })}
                    </Select>

                </Form.Item>
            
               {(province !== false && (provinces.length > 0) && (selectedCountry) && <Form.Item initialValue={province} label="State / Province" name="province" rules={[{ validateTrigger: "onBlur", validator: (_, value) => (provinces.includes(selectedProvince)) ? Promise.resolve() : (provinces.length > 0) ? Promise.reject("no") : Promise.resolve(), message: "Valid state / province is required.", required: true }]}>
                    <Select showSearch placeholder="State / Province" onChange={(v) => setSelectedProvince(v)} style={{ maxWidth: 400 }}>
                        {provinces.map((item, index) => {
                            return (<Select.Option key={index} value={item}>{item}</Select.Option>)
                        })}
                    </Select>
                </Form.Item>)}

                {(province !== false && (!provinces.length > 0) && (selectedCountry) && <Form.Item initialValue={province} label="State / Province" name="province" rules={[{ validateTrigger: "onBlur", validator: (_, value) => (provinces.includes(selectedProvince)) ? Promise.resolve() : (provinces.length > 0) ? Promise.reject("no") : Promise.resolve(), message: "Valid state / province is required.", required: false }]}>
                        <Input style={{ maxWidth: 400 }} maxLength={80} />
                </Form.Item>)}

               

                <Form.Item initialValue={postal_code} label={(selectedCountry === "United States") ? "Zip Code" : "Postal Code"}  name="postal_code" rules={[{ pattern: (selectedCountry === "Canada") ? new RegExp(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/) : (selectedCountry === "United States") ? new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/) : "", message: postalError(), required: true }]}>
                    <PostalCodeFormatter form={props.form} country={selectedCountry} />
                </Form.Item>

                <Space>

                {(props.type==="shipping" && <>
                <Form.Item initialValue={true} name="preferred" noStyle >
                    <Input type="hidden" />
                </Form.Item>
                </>)}

                {(!props.type && <>
   
                <Form.Item noStyle name="preferred" initialValue={preferred} valuePropName="checked">
                    <Checkbox>Default Shipping Address</Checkbox>
                </Form.Item></>)}
                
                </Space>

    </>);

}