/*
    ---- CSS ----
    Displays CSS
*/

import React from "react";

function CSS(props){
    const css = props.html;
    const display = () =>{
        return {__html: css};
    }

    return(<style dangerouslySetInnerHTML={display()}></style>);
}

export default CSS; 