import React, {useState} from "react";
import { Modal, Form, Input, Button, Alert } from 'antd';
import { MailOutlined} from '@ant-design/icons';
import { apiCall } from "./utilities/Api";

function EmailCartList(){

    const [form] = Form.useForm();
    const [sent, setSent] = useState(false);
    const [modalVisible, setModalVisible] = useState(false); 

    // cart/email

    const close = () =>{
        setSent(false);
        setModalVisible(false);
    }

    const onFinish = () =>{

        let _email = form.getFieldValue("email_address"); 
        apiCall("cart/email", {email_address : _email}, (_status, _result) =>{
            setSent(true);
        })
    }
    
    const displaySent = () =>{

        return(<>
               <Alert type="success" description={<div style={{"textAlign" : "center"}}>Your cart list has been sent!</div>} />
               </>
          )
        
    }

    const display = () =>{

        return(<>
                <p>Sharing your cart as an emailed list does not submit an order. 
                </p><p>It simply sends a copy of the items in your cart to the email address entered below. Items will remain in your cart.</p>
                <Form layout="vertical" onFinish={onFinish} form={form} >
                    <Form.Item label="Email" name="email_address" rules={[{ required: true,  type: 'email',  message: 'Please enter a valid email address.' }]}>
                        <Input  />
                    </Form.Item>
                </Form>
            </>
          )
        
    }

    const displayStatus = () =>{
        if(sent) {
           return  displaySent();
        } else {
           return display(); 
        }
    }

    const reset = () =>{

        setModalVisible(true);
    }


    return(<>
        <Button style={{"padding" : "5px 5px"}} type="text" onClick={() => reset()} icon={<MailOutlined aria-hidden />}>Email cart</Button>
        <Modal
          title="Email as list"
          open={modalVisible}
          okText = "Email List"
          onOk={onFinish}
          onCancel={close}
          cancelText={(sent) ? "Close" : "Cancel"}
          okButtonProps={{"disabled" : (sent) ? true : false}}
          destroyOnClose
          
        >

            {displayStatus()}
          
        </Modal>
        </>
    ); 

}

export default EmailCartList; 