import { Button, Modal } from 'antd';
import React, { useEffect, useState } from "react";

import List from "../wrappers/List";

export default function Related(props){

    const {
        related = {},
    } = props; 
    const [data, setData] = useState([]);
    const [title, setTitle] = useState("");
    const [modalVisible, setModalVisible] = useState(false);
    
    const openModal = (_key) =>{
        setData(related[_key].rows);
        setTitle(related[_key].fullname)
        setModalVisible(true);
    }

    const closeModal = () =>{
        setModalVisible(false);
    }

    const drawRelated = () =>{
        return Object.keys(related).map((key, index) =>{
            if(related[key].row_count>0){
                return(<div key={index}> <strong>{related[key].fullname} ({related[key].row_count}): </strong> <Button onClick={() => openModal(key)} style={{"color" : "#069"}} type="link"> {related[key].tooltip}</Button></div>)
            } else {
                return;
            }
        })
    }

    const display = () =>{
        return(
            <>
                <Modal title={title} footer={null} onCancel={() => closeModal()} open={modalVisible}>
                    <List popup={true} data={data} />
                </Modal>
                {drawRelated()}
            </>
        )
    }

    return display(); 

}