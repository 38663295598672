import React from "react";

export default function DrawShelfLocation(props) {

    const { shim=0, available = { bm_class: "", onhand: "" } } = props;
    const { bm_class = "", onhand = "" } = available;

  
    if (!bm_class || !onhand || !window.sitesettings.bm_class_enabled) {
        return (<></>);
    }

    return (<div style={{ "color": "#3c8617", "fontSize": "12px", "lineHeight" : "16px" }}><em>{window.sitesettings.bm_class_name}: <strong>{bm_class}</strong></em><div style={{"height" : shim + "px"}} className="shim" /></div>)
}