import React from "react";

import { Button, Space, Tag } from "antd";
import conditional from "../utilities/conditional";
import { useHistory } from "react-router-dom";
import { CloseOutlined } from '@ant-design/icons';
export default function BrowseFilterTags(props) {

    const history = useHistory(); 
    const { count = 0, searchFilters, setSearchFilters, filters = [], single = false } = props;
    const current_filters = searchFilters.b.concat(searchFilters.s).concat(searchFilters.n).concat(searchFilters.d).concat(searchFilters.f).concat(searchFilters.a).concat(searchFilters.c).concat(searchFilters.r).concat(searchFilters.g);

    const clear = {
        b: [], // browse
        s: [], // subjects
        n: [], // non-books
        d: [], // date 
        f: [], // format 
        a: [], // age
        o: 0, // offset
        l: 26, // limit
        t: "", // term
        k: "", // type
        v: "", // type
        x: "",
        r: [],
        c: [],
        g: [],
    };

    const ucFirst = (_str) =>{

        _str = _str.replace("_", " ");
        return _str.charAt(0).toUpperCase() + _str.slice(1);
    }


    const getLabel = (_id) => {

        let ret = false;
        Object.keys(filters).forEach(cat => {
            filters[cat].forEach(itm => {
                if (itm.k === _id) {
                    ret = itm;
                    return;
                }
                if (itm.hasOwnProperty("subfilter")) {
                    itm.subfilter.forEach(chld => {
                        if (chld.k === _id) {
                            ret = chld;
                            return;
                        }
                    })
                }
            })
        });



        return (ret) ? ret.e.replace("__", "") : _id;
    }

    const removeFilter = (e, _cat, _item) => {
        e.preventDefault();
        setSearchFilters({ ...searchFilters, [_cat]: searchFilters[_cat].filter(itm => itm !== _item) })
    }

    const drawTags = () => {

        return ["b", "s", "n", "d", "f", "a", "r", "c", "g"].map(cat => {
            return searchFilters[cat].map(item => {
                return (<Tag className="filterTag" closeIcon={<CloseOutlined aria-hidden />} onClose={(e) => removeFilter(e, cat, item)} key={item} closable> {getLabel(item)}</Tag>)
            })
        })

    }

    const removeTerm = () => {
      
        setSearchFilters({ ...searchFilters, "k": "", "t" : "" })
    }

    const drawSearch = () => {

        if (!searchFilters["t"]) {
            return (<></>)
        }

        return(<Tag className="filterTag" onClose={(e) => removeTerm()} key={searchFilters["k"]} closable> {ucFirst(searchFilters["k"])} : {searchFilters["t"]}</Tag>)

        



    }


    const showEditions = (_itm) =>{
        let _term = _itm.title; 
        let _authors = _itm.authors.map(item => item.name).join(" ");
        history.push("/browse/filter/k/keyword/t/" + _term + " " + _authors);
    }

    return (
        <div style={{ "marginTop": "-5px", "lineHeight" : "16px", "minHeight": "32px" }}>

            
            {/* <conditional.true value={((searchFilters["t"]) && (current_filters.length))}>
                    <div className="shim" /> <div className="shim" /> <div className="shim" />
                </conditional.true> */}

            
            <Space wrap size={[0, 10]}>

                {drawSearch()}
                {drawTags()}
                <conditional.true value={(current_filters.length)}>
                    <Button onClick={() => setSearchFilters(clear)} type="link">← Clear all</Button>
                </conditional.true>
                <conditional.true value={(single)}>
                    <em onClick={() => showEditions(single)} style={{"color" : "#069", "cursor" : "pointer"}}>&nbsp; See <strong>all editions</strong> of {single.title}</em>
                </conditional.true>
            </Space>
        </div>


    )
}