import React from "react";
import { getColor } from "../util/CC";
import { Button, Skeleton } from "antd";
import GiftCardAddToCart from "../components/components/giftcards/GiftCardAddToCart";

export default function GiftCard(props) {

    const template = window.sitesettings.template_info;
    const { content = {} } = props;

    const {
        location = "main",
        component = "blank_space",
        container = true,
        data = {},
        injected_data = {}
    } = content;

    const {
        cover_image_cache = 0,
        eisbn = "",
        name = ""
    } = injected_data;


    const {
        width = 800,
        align = "0 auto",
        radius = 15,
        padding_x = 20,
        padding_y = 20,
        gap = 20,
        shadow = true,
        button_type = 0,
        preset = 0,
        background = 0,
        button_block = false,
        card_width = 50,
    } = data;

    const drawRender = () => {

        let _style = {
            flexGrow: 1,
        }

        let containerStyle = {
            width: width + "px",
            maxWidth: "100%",
            margin: align,
            padding: padding_y + "px " + padding_x + "px"
        }

        let _stack = "row";

        if (props.view === "phone") {
            _stack = "column-reverse";
        }
        
        return (
            <>
                <div className={(container) ? "preview-container" : ""}>
                    <div style={{ background: getColor(background, template) }}>
                        <div style={containerStyle}>
                            <div style={{ display: 'flex', width: '100%', flexDirection: _stack, gap: gap + "px" }}>
                                <div style={{ flex: '0 0 ' + card_width + "%", display: 'flex' }}>
                                    {(!eisbn && <img alt={name + " placeholder image"} onError={i => i.target.style.display = 'none'} style={{ width: "100%", objectFit: "cover", height: "150px" }} src={" https://cdn1.bookmanager.com/i/sample_content/placeholder.jpg"} />)}
                                    {(eisbn && <img alt={name + " image"} onError={i => i.target.style.display = 'none'} style={{ "width": "100%", "borderRadius": radius + "px", "boxShadow": (shadow) ? "0px 2px 5px 0px rgba(0,0,0,0.5)" : "none" }} src={"https://cdn1.bookmanager.com/i/m.php?b=" + eisbn + "&cb=" + cover_image_cache} />)}
                                </div>
                                <div style={{ flex: '0 0 ' + (100 - card_width) + "%", display: 'flex', justifyContent: align }}>
                                    <div style={{ ..._style }}>
                                        {(!eisbn &&
                                            <Skeleton />
                                        )}
                                        {(eisbn &&
                                            <div>
                                                <h4 className={"bm-txt-" + preset}>{name}</h4>
                                                <div className="shim" />
                                                <GiftCardAddToCart item={injected_data} eisbn={eisbn} cc_giftcard={true}>
                                                    <Button aria-label={name + " Add to cart"} block={button_block} className={"bm-btn-" + button_type}>Add to Cart</Button>
                                                </GiftCardAddToCart>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    
}

return drawRender();
}