import React, { useState, useEffect } from "react";
import BrowseFilters from "../components/components/BrowseFilters";
import { AppstoreOutlined, PicLeftOutlined, ProfileOutlined, DownOutlined, TableOutlined, MenuOutlined } from '@ant-design/icons';
import { Layout, Divider, Skeleton, Button, Drawer, Result, Menu, Dropdown, Row, Col, Space, Radio, Spin } from 'antd';
import { apiCall } from "../components/utilities/Api";
import { useLocation, useHistory } from "react-router-dom";
import Paginate from "../shared/Paginate"
import List from "../components/components/wrappers/List";
import BrowseFilterTags from "../components/components/BrowseFilterTags";
import BrowseListView from "../components/components/BrowseListView";
import { useSession } from "../util/Session";
import { isLocalStorageAvailable } from "../util/Persistant";
import { objectToPath } from "../util/Utils";
import Cookies from "js-cookie";

export default function BrowseLayout() {

  // Always get results from location, 

  const [session, setSession] = useSession();
  const history = useHistory();
  const loc = useLocation();
  const path = "/browse";
  const [initialRun, setInitalRun] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [results, setResults] = useState({});
  const [browseVisible, setBrowseVisible] = useState(false);
  const [dim, setDim] = useState(false);
  const [unlimited, setUnlimited] = useState(false);
  const [current, setCurrent] = useState();
  const [pageSize, setPageSize] = useState(26);
  const [view, setView] = useState((window.listView) ? window.listView : "grid");

  const setTheView = (_view) => {
    window.listView = _view;
    setView(_view)
  }

  let initialFilters = {
    b: [], // browse
    s: [], // subjects
    n: [], // non-books
    d: [], // date 
    f: [], // format 
    a: [], // age
    o: 0, // offset
    l: pageSize, // limit
    t: "", // term
    k: "", // type
    v: "", // sort
    x : "",
    r: [], // stock (on hand, on order)
    c: [], // categories
    g: [], // language
    j: [], // secondary subject thing... 
  };

  const pathToObject = (_path) => {

    let returnObject = { ...initialFilters }
    let startIndex = _path.search(/filter/i);
    let p = _path.substr(startIndex, _path.length);
    let split = p.split("/");
    let key;
    let ka = ["b"];

    for (let i = 0; i < split.length; i++) {
      if (split[i].length === 1) {
        key = split[i];
      } else {
        if (returnObject.hasOwnProperty(key)) {
          if (Array.isArray(returnObject[key])) {
            returnObject[key] = [...returnObject[key], split[i]];
          } else {
            if (key === "o" || key === "l") {
              returnObject[key] = parseInt(split[i]);
            } else {
              returnObject[key] = split[i];
            }
          }
          (ka.indexOf(key) === -1 && ka.push(key));
        }
      }
    }
    return returnObject;
  }

  const [searchFilters, setSearchFilters] = useState(pathToObject(loc.pathname));

  const setPage = (_p) => {
    if (Number.isInteger(_p)) {
      return _p;
    } else {
      return 1;
    }
  }

  const onGetBrowseItems = (_status, _result) => {
    if (!_status) {
      setError(true);
      setResults(_result);
    } else {
      setResults(_result)
      if (isLocalStorageAvailable()) {
        localStorage.setItem("filters", JSON.stringify(searchFilters));
        localStorage.setItem("data", JSON.stringify(_result));
      }
      setError(false);
    }
    setDim(false);
    if (loading) {
      setLoading(false);
    }
  }

  const getBrowseItems = () => {

    setDim(true);
    if (loc.pathname.includes("/browse/url/")) {
      if (loc.state) {
        setUnlimited(true);
        apiCall("browse/url", { url: loc.state }, onGetBrowseItems);
      }
      return;
    }else if (loc.pathname.includes("/browse/file/")) {

      if (loc.state) {
        setUnlimited(true);
        setResults(loc.state);
        setLoading(false);
        setError(false);
        setDim(false);
      }
      return;
    }
    else {
      let form_data = {};
      for (let key in searchFilters) {
        form_data[key] = JSON.stringify(searchFilters[key]);
      }
      // weird issue with percent decoding on useHistory()
      if (searchFilters["t"]) {
        form_data["t"] = JSON.stringify(searchFilters["t"].replace("%25", "%"));
      }
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      setUnlimited(false);
      apiCall("browse/get", form_data, onGetBrowseItems);
    }
  }

  const hasData = (_filters) => {
    if (isLocalStorageAvailable()) {
      if (localStorage.getItem("filters") === JSON.stringify(_filters)) {
        setResults(JSON.parse(localStorage.getItem("data")))
        setLoading(false);
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  const onSearchChanged = () => {
    window.search = searchFilters;
    if (!hasData(searchFilters) || loc.pathname.includes("/browse/url/") || loc.pathname.includes("/browse/file/")) {
      getBrowseItems();
    }
  }

  useEffect(onSearchChanged, [searchFilters])

  const onLocChange = () => {
    if (!initialRun) {
      setSearchFilters(pathToObject(loc.pathname));
    } else {
      setInitalRun(false);
    }
  }

  useEffect(onLocChange, [loc])

  const updateLocation = (_obj) => {
    setSearchFilters(_obj);
    history.push({pathname: path + objectToPath(_obj)});
  }

  const displayLoading = () => {
    return (
      <>
        <div className="container">
          <h2>Browse</h2>
          <div aria-hidden><Divider /></div>
          <Layout>
            <Layout.Sider width={300} className="hide" theme="light">
              <BrowseFilters setSearchFilters={updateLocation} searchFilters={searchFilters} filters={window.sitesettings.filter_info} />
            </Layout.Sider>
            <Layout.Content className="adjust-margin adjust-padding-invert" style={{ "backgroundColor": "#fff", "padding": "0 20px" }}>
              <div style={{ "height": "27px" }}></div>
              <div aria-hidden><Divider style={{ "marginBottom": "0px", "marginTop": "22px" }} /></div>
              <div className="wrapper"><Skeleton active /><Skeleton active /><Skeleton active /><Skeleton active /></div>
            </Layout.Content>
          </Layout>
        </div>
      </>
    )
  }

  const displayError = () => {
    return (<></>)
  }

  const displayEmpty = () => {
    return (
      <>
        <Result
          status="success"
          icon={<></>}
          title={<><p>No matches found! <small><br />Please change your search terms</small></p></>}
          extra={[<>
            <p><strong>Spelling counts:</strong> Our site does not perform a spell check or suggest similar words, so please try to be accurate.</p>
            <p><strong>Using exact words:</strong> To find "War" and not "Warriors", use <strong><em>War!</em></strong> - The exclamation mark ensures that no variations of the word are included.</p>
            <p><strong>Less is more:</strong> Long words can be shortened, especially if you are not sure of the spelling or tense (e.g. using <strong><em>magnif</em></strong> will find all titles containing words starting with those letters, like "magnify, magnificent, magnificently").</p>
            <p><strong>Search types:</strong> Searching by Keyword (usually the most effective) looks at title, author, series, subject and ISBN information for matches. Searching by Author, Title, and Publisher only looks at the respective type of information.</p>
          </>
          ]}
        />
      </>
    )
  }

  const displayList = () => {
    if (results.row_count === 0) {
      return displayEmpty()
    }
    // [TODO] LISTVIEW
    // eslint-disable-next-line default-case
    switch (view) {
      case "grid":
        return (<List data={results.rows} />);
      case "list":
        return (<BrowseListView data={results} />);
      case "cards":
        return (<List view="card" data={results.rows} />);
    }
  }

  const displayStatus = () => {
    if (error) return displayError();
    if (loading) return displayLoading();
    return display();
  }

  const setSort = (_key) => {
    updateLocation({ ...searchFilters, "v": _key });
  }

  const getSortName = (_key) => {
    let ret = "";
    // eslint-disable-next-line default-case
    switch (_key) {
      case "":
        ret = "What's in store";
        break;
      case "popularity":
        ret = "Popularity";
        break;
      case "date":
        ret = "Release date";
        break;
      case "title":
        ret = "Title";
        break;
      case "author":
        ret = "Author";
        break;
    }
    return ret;
  }

  const sortOptions = (
    <Menu>
      <Menu.Item onClick={() => setSort("")} key={""}>What's in store</Menu.Item>
      <Menu.Item onClick={() => setSort("popularity")} key={"popularity"}>Popularity</Menu.Item>
      <Menu.Item onClick={() => setSort("date")} key={"date"}>Release date</Menu.Item>
      <Menu.Item onClick={() => setSort("title")} key={"title"}>Title</Menu.Item>
      <Menu.Item onClick={() => setSort("author")} key={"author"}>Author</Menu.Item>
    </Menu>
  );


  const drawContext = () => {
    if(!results.hasOwnProperty("context")){
      return; 
    }
    let {
      name = "",
      list_id = "",
      header = ""
    } = results.context; 
    if(list_id){
      return(<p>Viewing products from: <a onClick={() => history.push("/lists/" + list_id)}>{name}</a></p>)
    }
  }

  function drawPagination(){
    return(
      <Paginate 
        save={"browse_limit"} 
        loading={loading} 
        paginate={{ 
            current: setPage((searchFilters.o / searchFilters.l) + 1), 
            offset: (searchFilters.hasOwnProperty("o")) ? searchFilters.o : 0, 
            pagesize: (searchFilters.hasOwnProperty("l")) ? searchFilters.l : 26 }} 
        setPaginate={(e) => {
            setCurrent(e.current)
            let sf = { ...searchFilters, "o": e.offset, "l": e.pagesize }
            setSearchFilters(sf);
            getBrowseItems();
            history.push({ pathname: path + objectToPath(sf) });
        }} 
        count={(results.row_count < results.max_offset) ? results.row_count : results.max_offset} 
      />
    )
  }


  const display = () => {
    return (
      <div className="container">
        <Drawer
          title="Browse"
          placement="left"
          mask={false}
          width="350px"
          open={browseVisible}
          onClose={() => setBrowseVisible(false)}
        >
          <div style={{ "padding": "9px", "backgroundColor": "#fff" }}>
            <BrowseFilters setSearchFilters={updateLocation} searchFilters={searchFilters} filters={results.filter_info} />
          </div>
        </Drawer>
        {/* [TODO] LISTVIEW */}
        <div style={{ "float": "right" }}>
          <div className="shim" />
          <Space>
            <Radio.Group buttonStyle="solid" value={view} onChange={(e) => setTheView(e.target.value)} size={"small"}>
              <Radio.Button aria-label="Display as cards" value={"cards"}><small aria-hidden><AppstoreOutlined /></small></Radio.Button>
              <Radio.Button aria-label="Display as grid" value={"grid"}><small aria-hidden><PicLeftOutlined /></small></Radio.Button>

              {(session.user.is_institution && <Radio.Button value={"list"}><small><MenuOutlined /></small></Radio.Button>)}
            </Radio.Group>
          </Space>
          {/* <Button size="large" type="link" onClick={() => toggleListView(!listView)} icon={(listView) ? <AppstoreOutlined /> : <ProfileOutlined />} /> */}
        </div>
        <h2>Browse &nbsp;<span style={{ "fontSize": "15px" }} className="hide">({results.row_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{((results.row_count === 10000 || results.row_count === 1000) && "+")} {(results.row_count === 1) ? "product" : "products"})</span></h2>
        {drawContext()}
        <span style={{ "fontSize": "15px", "marginTop": "-10px" }} className="show-block">({results.row_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {(results.row_count === 1) ? "product" : "products"})</span>
        <div className="show-block" style={{ "textAlign": "center" }}>
          <br />
          <Button block type="primary" onClick={() => setBrowseVisible(!browseVisible)}>Filters</Button>
        </div>
        <div aria-hidden><Divider /></div>
        <Layout>
          <Layout.Sider width={300} className="hide" theme="light">
            <BrowseFilters setSearchFilters={updateLocation} searchFilters={searchFilters} filters={results.filter_info} />
          </Layout.Sider>
          <Layout.Content className="adjust-margin adjust-padding-invert" style={{ "backgroundColor": "#fff", "padding": "0 20px" }}>
            <div>
              <div style={{ "float": "right" }}>
                <Dropdown overlay={sortOptions} placement="bottomRight" arrow>
                  <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <div><Button><small>Sort: <strong>{getSortName(searchFilters.v)} &nbsp;</strong><small><DownOutlined /></small></small></Button> </div>
                  </a>
                </Dropdown>
              </div>
              <div style={{ "width": "calc(100% - 160px)" }}>
                <BrowseFilterTags single={(results.row_count === 1) ? results.rows[0] : false} count={results.row_count} searchFilters={searchFilters} setSearchFilters={updateLocation} filters={results.filter_info} />
              </div>
            </div>
            {drawPagination()}
            <div aria-hidden><Divider /></div>
            <br />
            <Spin spinning={(dim)} >
              {displayList()}
            </Spin>
            <div aria-hidden><Divider /></div>
            {/* [TODO] LISTVIEW */}
            {drawPagination()}
          </Layout.Content>
        </Layout>
      </div>
    );

  }

  return displayStatus();

}