import { Button, Input, Modal, Divider } from "antd";
import React, { useState } from "react";
import { apiCall } from "../../utilities/Api";
export default function ShareList(props) {

    const { list_id } = props;
    const [modalVisible, setModalVisible] = useState(false);
    const [shareLink, setShareLink] = useState("")

    const onShareList = (_status, _result) => {
        if (_status) {
            setShareLink(_result.url);
            setModalVisible(true);
        }
    }
    
    const shareList = () => {
        if (!shareLink) {
            apiCall("titlelist/getPublicUrl", { list_id: list_id }, onShareList)
        } else {
            setModalVisible(true);
        }
    }

    return (
        <>
            <Modal onCancel={() => setModalVisible(false)} destroyOnClose open={modalVisible} centered={true} width={500} footer={null}>
                <h3>Share</h3>
                <div aria-hidden><Divider /></div>
                <p>To share this with others, copy this link (e.g. Ctrl-C) and then paste it into an email.</p>
                <div className="shim"></div>
                <Input value={shareLink}></Input>
                <div className="shim"></div><div className="shim"></div><div className="shim"></div><div className="shim"></div>
                <div style={{ "float": "right" }}><Button onClick={() => setModalVisible(false)}>Cancel</Button></div>
                <br clear="all" />
            </Modal>
            <div onClick={() => shareList()}>{props.children}</div>
        </>
    )
}