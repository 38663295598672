import React from "react";
import { Divider } from 'antd';
import AccountLoginForm from "../components/components/account/AccountLoginForm";
export default function RequiredLogin() {

    return (
        <div style={{ "height": "80vh", "display": "flex", "alignItems": "center", justifyContent: "center" }}>
            <div className="container" style={{ "width": "350px", "margin": "20px" }}>
                <div style={{ "textAlign": "center" }}>
                    <h3>Login Required</h3>
                  
                    {(!window.sitesettings.is_exclusive && <><div aria-hidden><Divider /></div>
                    <p>This site is only accessible to specific accounts.</p></>)}

                    {(window.sitesettings.is_exclusive && <><div aria-hidden><Divider /></div>
                    <p>{window.sitesettings.login_required_message}</p></>)}
                    
                </div>
                <div aria-hidden><Divider /></div>
                <AccountLoginForm show_forgot={false} redirect show_create={false} loginmessage />
            </div>
        </div>
    );



}