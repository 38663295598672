import { Button, Progress } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiCall, apiDownload } from "../utilities/Api"

export default function ProcessPDF(props) {


  
    // get the process id from the browser param
    const { process_id = "" } = useParams();


   

    // set defaults
    const [report, setReport] = useState({
        progress: 0,
        eta_seconds: null,
        completed: false,
    })

    
    const getProgress = () => {

        if(!process_id){
            return;
        }

        apiCall("report/progress", { progress_report_id: process_id }, (_status, _result) => {
            if (_status) {
                setReport(_result)
                if (!_result.completed) {
                    // not done, poll again
                    setTimeout(getProgress, 100);
                } 
            }
        })
    }

    const downloadReport = () => {
        apiDownload({ progress_report_id: process_id }, "report/get");
    }

    useEffect(getProgress, []);


    if(!process_id){
        return(<></>);
    }

    const drawETA = () => {


        if(!report.completed){

            if(report.eta_seconds === null){
                return "Waiting..."
            }

            if(report.progress === 99){
                return "Converting...."
            }

            return "Approx " + report.eta_seconds + " seconds remaining..."
        }

    
        return "";
    }


    return (
        <div className="container">
        <div style={{"width" : "300px", "margin" : "80px auto", "textAlign" : "center"}}>
            <Progress type="circle" percent={report.progress} width={80} />
            <br /> <br />   
            <p style={{"textAlign" : "center", "fontSize" : "16px", "fontWeight" : "500"}}>{drawETA()}</p>
            <Button disabled={(!report.completed)} onClick={() => downloadReport()}>Download</Button>
        </div>
        </div>
        );

}