import React, { useState } from "react";
import { Button, Modal, Divider, Upload, Card, Table, Alert, Spin, Input, Checkbox, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { apiCall } from "../../utilities/Api";
import conditional from "../../utilities/conditional";

export default function ImportToList(props){

    const {list_id, getLists} = props; 
    const [modalVisible, setModalVisible] = useState(false);
    const [position, setPosition] = useState("upload");
    const [uploading, setUploading] = useState(false);
    const [file, setFile] = useState({});
    const [processed, setProcessed] = useState([]);

    const close = () => {
        setModalVisible(false);
        setPosition("upload");
    }

    const onUploadFile = (_status, _result) => {
        if (_status) {
            setProcessed(_result.result.titles);
            setPosition("process");
        } else {
            setPosition("error")
        }
        setUploading(false);
    }

    const uploadFile = (_file) => {

        if(_file.file.size > 50000000){
            message.error("File is too large. (Max size is 50MB)");
            return; 
        }


        setUploading(true);
        setFile(_file);
        apiCall("titlelist/importFile", { file: _file.file, list_id : list_id }, onUploadFile);
    }

    const onProcessFile = (_status, _result) => {
        if (_status) {
            getLists(); 
            setPosition("complete");
        } else {
            setPosition("error")
        }
        setUploading(false);
    }

    const processFile = () => {
        setUploading(true);
        apiCall("titlelist/importFile", { add_to_list: true, file: file.file, list_id : list_id }, onProcessFile);
    }

    const columns = [
        {
            title: 'isbn',
            dataIndex: 'isbn',
            key: 'isbn',
        }
    ];

    const dataSource = processed.map((item, index) => {
        return { key: index, isbn: (<span style={{ "color": (item.success) ? "#3C8617" : "#e70d3d" }}>{item.isbn}</span>), status: item.success }
    })

    const drawTable = () => {
        return (
            <div>
                {(processed.filter((i) => i.success === true).length === 0) ? (<p style={{ "textAlign": "center" }}><strong>No rows can be processed. </strong> <br /> Try another file.</p>) : (<p style={{ "textAlign": "center" }}><strong>{processed.filter((i) => i.success === true).length}</strong> rows shown in <strong style={{ "color": "#3C8617" }}>green</strong> will be processed. Any rows in <strong style={{ "color": "#e70d3d" }}>red</strong> will not be processed. </p>)}
                <Card bodyStyle={{ "padding": "0px" }}>
                    <Table scroll={{y : 300}} pagination={false} columns={columns} dataSource={dataSource} size="small"></Table>
                </Card>
            </div>
        )
    }

    const drawComplete = () => {
        return (
            <div>
                <div style={{ "textAlign": "center" }}>
                    <h4><strong>Import complete.</strong></h4>
                </div>
                <div aria-hidden><Divider /></div>
                <Alert
                    style={{ "padding": "5px", "paddingBottom": "8px", "position": "relative", "zIndex": "11", "width": "100%", "margin": "0 auto", "textAlign": "center", "display": "block" }}
                    message={<>Added <strong>{processed.filter((i) => i.success === true).length}</strong> items to your list. </>}
                    type="success"
                />
                <div aria-hidden><Divider /></div>
                <Button onClick={() => close()} type={"primary"} block>Close</Button>
            </div>
        )
    }

    const drawError = () => {

        return (
            <>
                <div style={{ "textAlign": "center" }}>
                    <h4><strong>Import failed</strong></h4>
                </div>
                <div aria-hidden><Divider /></div>
                <Alert
                    style={{ "padding": "5px", "paddingBottom": "8px", "position": "relative", "zIndex": "11", "width": "100%", "margin": "0 auto", "textAlign": "center", "display": "block" }}
                    message={<><strong>Error</strong></>}
                    description={<div style={{ "textAlign": "center", "display": "block" }}>There was an error processing the file.</div>}
                    type="error"
                />
                <div aria-hidden><Divider /></div>
                <Button onClick={() => close()} type={"primary"} block>Try another file</Button>
            </>
        )
    }

    
    const [paste, setPaste] = useState(false);
    const [pasteValue, setPasteValue] = useState("")

    const generateFile = () =>{
        var filename = 'pasted.txt';
        let _file = new File([new Blob([pasteValue])], filename)
        let f = {}
        f.file = _file; 
        uploadFile(f);
    }

    const drawUpload = () => {
        return (
            <>
                <div>
                    <h3>Import an Excel or text file into your list.</h3>
                </div>
                <div aria-hidden><Divider /></div>
                <Checkbox onChange={() => setPaste(!paste)} checked={(paste)} >Paste ISBNs</Checkbox>
            
                <div className="shim" />
                <div className="shim" />
                <div className="shim" />
                <conditional.true value={paste}>
                    <Input.TextArea onChange={(e) => setPasteValue(e.target.value)} rows={4} placeholder="Paste ISBNs separated by commas">
                    </Input.TextArea>
                    <div className="shim" />
                <div className="shim" />
                    <div style={{"float" : "right"}} >
                    <Button disabled={(!pasteValue)} type="primary" onClick={() => generateFile()} >Save</Button>
                    </div>
                    <br clear="all" />
                </conditional.true>

                <conditional.true value={!paste}>
                <Upload.Dragger accept=".txt, .pdf, .xls, .xlsx, .html, .csv" showUploadList={false} customRequest={(e) => uploadFile(e)} style={{ "padding": "0px 20px" }}>
                    <div >
                        {(uploading) ? <Spin style={{ height: "35px" }} /> : <UploadOutlined style={{ "fontSize": "30px", height: "35px" }} />}
                    </div>
                    <p className="ant-upload-text">Click or drag to upload</p>
                    <p className="ant-upload-hint">
                        Upload an Excel file (.xls or .xlsx) or tab delimited (.txt) with the header "isbn". <small>(Max size is 50MB)</small>
                        </p>
                </Upload.Dragger>
                </conditional.true>
                <div aria-hidden><Divider /></div>
                <div style={{ "textAlign": "center" }}>
                    <small>You will be able to preview the list of titles before adding them to your list.</small>
                </div>
            </>
        )
    }

    const drawProcess = () => {
        return (
            <>
                <div style={{ "textAlign": "center" }}>
                    <h4><strong>Preview of the file contents</strong></h4>
                    <span>(scroll down to import)</span>
                </div>
                <div aria-hidden><Divider /></div>
                {drawTable()}
                <div aria-hidden><Divider /></div>
                {(processed.filter((i) => i.success === true).length === 0) ? (<Button onClick={() => setPosition("upload")} type="primary" block>Try another file.</Button>) : (<Button onClick={() => processFile()} type="primary" block>Import to list</Button>)}
            </>
        )
    }

    const drawPosition = () => {
        switch (position) {
            case "upload":
                return drawUpload();
            case "process":
                return drawProcess();
            case "complete":
                return drawComplete();
            case "error":
                return drawError();
            default :
                return drawUpload();
        }
    }

    const drawModal = () => {
        return (
            <>
                <Modal
                    open={modalVisible}
                    footer={false}
                    centered
                    onCancel={() => close()}
                >
                    {drawPosition()}
                </Modal>
            </>
        )
    }

    const draw = () =>{
        return (
            <>
                {drawModal()}
                <div onClick={() => setModalVisible(true)} className="hide" type="text">{props.children}</div>
            </>
        )
    }

   return draw(); 
}