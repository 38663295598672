import { Col, Row, Skeleton } from 'antd';
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import ItemDetails from "../components/components/item/ItemDetails";
import { apiCall } from "../components/utilities/Api";

function ItemLayout() {

    const params = useParams();
    const loc = useLocation();

    let useSummary = false;
    if (loc.data) {
        useSummary = true;
    }

    const [data, setData] = useState(loc.data);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [results, setResults] = useState({});
    const [summary, setSummary] = useState(useSummary);

    const onGetItem = (_status, _result) => {
        if (!_status) {
            setError(true);
        }
        document.title = window.sitesettings.store_info.name + " | " + _result.title;
        setResults(_result);
        setLoading(false);
        setSummary(false);
    }

    const getItem = () => {
        if (!loading) {
            setLoading(true);
        }
        if (loc.data) {
            setData(loc.data);
            setSummary(true);
        }


        let list_id = "";
        if(loc.pathname.includes("/lists/")){
            let m;
            m = loc.pathname.split("/");
            if(loc.pathname.charAt(loc.pathname.length-1) === "/"){
                list_id = m[m.length-2];
            } else {
                list_id = m[m.length-1];
            }
        }
        let obj = {
            eisbn : params.item_id
        }
        if(list_id){
            obj.list_id = list_id;
        }

        apiCall("title/getItem", obj, onGetItem);
    }

    useEffect(getItem, [loc])

    const displayLoading = () => {
        return (
            <div>
                <Row gutter={5}>
                    <Col xs={7} lg={7}>
                    </Col>
                    <Col xs={17} lg={11}>
                        <Skeleton />
                    </Col>
                    <Col style={{ "display": "block", "paddingLeft": "20px" }} xs={24} lg={6}>
                        <Skeleton />
                    </Col>
                </Row>
            </div>
        )
    }

    const displayError = () => {
        return (<></>)
    }

    const displayEmpty = () => {
        return (
            <div>
                <h2>No Results</h2>
            </div>
        )
    }

    const displaySummary = () => {
        return (<ItemDetails loading={loading} data={data} />)
    }

    const displayResults = () => {
        return (
            <ItemDetails loading={loading} data={results} />
        );
    }

    const displayStatus = () => {
        // Check loading status
        if (summary) {
            return displaySummary();
        }
        if (loading) {
            return displayLoading();
        } else {
            // check for error
            if (error) { return displayError(); }
            else {
                // check if results are empty
                if (results.length < 1) {
                    return displayEmpty();
                }
                else {
                    return (<>{displayResults()}</>);
                }
            }
        }
    }

    return (
        <>
            <div className="container">
                {displayStatus()}
            </div>
        </>
    );

}

export default ItemLayout;