import { message } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { apiCall } from "../../utilities/Api";
import Html from "../../utilities/Html";

export default function ChasePaymentsForm() {

    const [form, setForm] = useState("loading...");
    

    // Fetch the form id - only lasts 90 seconds
    const chaseInit = () => {
        apiCall("checkout/chaseInit", {}, (_status, _result) => {
            if (_status) {
                window.chaseUID = _result.uid;
                setForm(drawForm(_result.uid))
            } else {
                message.error(_result.error)
            }
        })
    }

    useEffect(chaseInit, []);

    // Add PostMessage listener
    useEffect(() => {

        const handler = event => {
          switch(event.data.subject){
            case "completePayment":
                // done
                apiCall("checkout/chaseSuccess", {uid : window.chaseUID}, (_status, _result) => {
                    if(_status){
                        console.log(_result)
                    } else {
                        message.error(_result.error)
                    }
                }); 

            break;

            case "handlePaymentErrors":
                // handle errors
            break;

            case "startPayment":
                // start payment
            break;

            case "cancelPayment":
                // cancel payment
            break;

            default : 
            break; 


          }
          console.log(event);
        }
        window.addEventListener("message", handler)
        return () => window.removeEventListener("message", handler)
      }, []) 

   
    const drawForm = (_uid) => {
        return (
            `<div id="secureFrameWrapper">
                <iframe class="secureFrame" height="270px" id="secureFrame" src=" https://chase-var.hostedpaymentservice.net/hpf/?uid=`+ _uid + `" style="border:1px dashed slategrey; background-color:#f4f4f4;" width="400px">
                </iframe>
            </div>`
        )
    }



    // https://tbraunapi.bookmanager.com/customer/checkout/chaseSuccess?session_id=FGJoWHWa5k5zScR3JrK3bxARldDw&store_id=101069&uid=MI054IV3UCYQ829IQOXQZ0FK2LVDYO7B

    // https://tbraunapi.bookmanager.com/customer/checkout/chaseInit?session_id=FGJoWHWa5k5zScR3JrK3bxARldDw&store_id=101069


    return (
        <>
            Chase
            {/* <Html html={msg} /> */}
            <Html html={form} />

        </>
    )

}