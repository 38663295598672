import React, { useEffect, useState } from "react";
import { apiCall } from "../components/utilities/Api";
import { useParams } from "react-router-dom";
import Event from "../components/components/events/Event";
import moment from 'moment';
import HTML from "../components/utilities/Html"
import EventsCalandar from "../components/components/events/EventsCalandar";
import conditional from "../components/utilities/conditional";
import 'moment-timezone';
import NewSlider from "../components/components/wrappers/NewSlider";
export default function EventsLayout() {

    // add calandar
    const { event_filter } = useParams();
    const [isSingle,] = useState((!isNaN(event_filter) && event_filter.length !== 8));
    const isFiltered = (!isNaN(event_filter));
    const filterEvents = (_r) => {
        // isdate or event#
        if (!isNaN(event_filter)) {
            if (event_filter.length === 8) {
                // filter by date
                return _r.filter((item) => {
                    return (moment(item.from * 1000).tz('America/Los_Angeles').format("YYYYMMDD") === event_filter);
                })
            } else {
                return _r.filter((item) => {
                    return (item.id.toString() === event_filter.toString());
                })
            }
        } else {
            return _r;
        }
    }

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [results, setResults] = useState({});
    const [mainList, setMainList] = useState([]);
    const [bannerList, setBannerList] = useState([]);

    // sideload custom lists

    const parseCalendar = (_desc) => {
        let chunks = _desc.split("<!--Calendar-->");
        return chunks.map((_item, ind) => {
            if (ind === chunks.length - 1) {
                return (<HTML key={ind} html={_item} />);
            } else {
                return (<div key={ind}><HTML html={_item} /><span><EventsCalandar /></span></div>)
            }
        });
    }



    const displayCustomList = (_main = false) => {

        return (
            <>
                {bannerList.map((item, index) => {
                    return (<div key={index} style={{ "maxWidth": (window.sitesettings.styles.fullscreen_banner) ? "100%" : "1248px" }} className="site-banner">{parseCalendar(item.description)}</div>)
                })}

                {((mainList.length > 0 && _main) && <>
                    <div id="maintable" className="container">
                        {displayMain()}
                    </div>
                </>)}

            </>
        )

    }

    const displayMain = () => {
        return mainList.map((item, index) => {

            return (<NewSlider index={index} key={item.list_id} data={item} />)
            

            // return (<div key={index} style={{ "maxWidth": (window.sitesettings.styles.fullscreen_banner) ? "100%" : "1248px" }} className="site-banner">{parseCalendar(item.description)}</div>)
        })
    }

    const onGetEventList = (_status, _result) => {
        if (!_status) {
            setError(true)
        }
        if (_result.custom_content) {
            setBannerList(_result.custom_content.filter(item => item.placement === "banner"));
            setMainList(_result.custom_content.filter(item => item.placement !== "banner"));
        }
        setResults(_result);
        setLoading(false);
    }

    const getEventList = () => {

        if(isSingle){
            apiCall("event/get", {event_id : event_filter}, (_status, _result) =>{
                if(_status){
                    setResults({rows : [_result]});
                    setLoading(false);
                }
            });
            return; 
        }
        setLoading(true);
        apiCall("event/getList", {}, onGetEventList);
    }

    useEffect(getEventList, [])

    const displayError = () => {
        return <></>;
    }

    const displayEmpty = () => {
        return <>
            {displayCustomList(true)}
        </>;
    }

    const displayLoading = () => {
        return <>
        </>;
    }

    const displayEvents = () => {

        let _rows = (event_filter) ? filterEvents(results.rows) : results.rows;
        return _rows.map((event, index) => {

            // get rid of earlier
            // if((event.to*1000) < Date.now()){
            //     return false; 
            // }

            return (<>
                <Event.ListItem key={index} data={event} />

            </>)
        })
    }

    const display = () => {
        return (
            <>
                <conditional.true value={(!isFiltered)}>
                    {displayCustomList()}
                </conditional.true>

                <div id="maintable" className="container">



                    <div className="shim" />
                    <div className="shim" />

                    <conditional.true value={(!isFiltered)}>
                        <div className="shim" />
                        <div className="shim" />
                        {displayMain()}
                    </conditional.true>
                    {displayEvents()}
                </div>
            </>
        )
    }

    const displayStatus = () => {
        if (error) { return displayError(); }
        if (loading) { return displayLoading(); }

        if (results.rows.length > 0) {

            return display();
        } else {

            return displayEmpty();
        }
    }


    return displayStatus();
}