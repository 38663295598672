import React from "react"
import { Divider, Row, Col, Button, Table, Space } from 'antd';

import { Link } from "react-router-dom";
import Html from "../../utilities/Html";
import EventViewMore from "./EventViewMore";
import EventSocial from "./EventSocial";
import moment from 'moment';
import 'moment-timezone';
import EventsCalandar from "./EventsCalandar";
export default function Event() {

    return "event";

}



const parseCalendar = (_desc) => {
    let chunks = _desc.split("<!--Calendar-->");
    return chunks.map((_item, ind) => {
        if (ind === chunks.length - 1) {
            return (<Html key={ind} html={_item} />);
        } else {

            return (<div key={ind}><Html html={_item} /><span><EventsCalandar /></span></div>)
        }
    });
}


const drawDateRange = (_start_ts, _end_ts) => {
    // lock to pacific time
    let start_time = moment.unix(_start_ts).tz('America/Los_Angeles');
    let end_time = moment.unix(_end_ts).tz('America/Los_Angeles');
    // format
    let format = 'dddd MMMM Do, YYYY @ h:mmA';
    // check if the range spans over multiple days
    if (start_time.isSame(end_time, 'day')) {
        // start and end match - just display start
        if (_start_ts === _end_ts) {
            return start_time.format(format);
        }

        // same day, draw one date and time range
        return start_time.format(format) + ' - ' + end_time.format('h:mmA');
    } else {
        // different days, draw the date and time for each day
        return start_time.format(format) + ' - ' + end_time.format(format);
    }
}

Event.ListItem = (props) => {

    const { info = null, to, from, id } = props.data;
    const { book = null, ticket = null, description = null, name = "", img_path = null } = info;

    return (
        <>
            <Row gutter={[20, 40]} className="bm-wrap" >
                <Col flex={"309px"} xl={8} l={24} >
                    <div className="shadow-light">
                        {(img_path && <img onError={i => i.target.style.display = 'none'} style={{ "width": "100%" }} alt={name} src={img_path} />)}
                        {(book.length !== 1 || img_path !== "") ?
                            <div style={{ "display": (book.length > 1) ? "inherit" : "inherit" }} className="wrapBooks"><EventViewMore event_id={id} type="image" data={book} ><Event.Books books={book} image={img_path} /></EventViewMore></div> : (book.length > 0) ? <img onError={i => i.target.style.display = 'none'} style={{ "width": "100%" }} alt={name} src={"https://cdn1.bookmanager.com/i/m?b=" + book[0].eisbn + "&cb=" + book[0].cover_image_cache} /> : ""}
                    </div>
                    {(book.length > 2 && <div><EventViewMore event_id={id} data={book} /></div>)}
                    <EventSocial name={name} path={id} />
                    <br /><br />
                </Col>
                <Col flex={"auto"} l={24} xl={18}>

                    <h2>{name}
                        <span>
                            <em>
                                {drawDateRange(from, to)}
                            </em>
                        </span>
                    </h2>

                    {(description && <div> {parseCalendar(description)}</div>)}

                    {((ticket.length > 0 || book.length > 0) && <>
                        <br />
                        <div style={{ "textAlign": "center" }}>
                            <Space>
                                {(ticket.length > 0 && <EventViewMore event_id={id} type="ticket" data={ticket} />)}
                                {(book.length > 0 && <EventViewMore event_id={id} type="book" data={book} />)}
                            </Space>
                        </div>

                    </>)}


                </Col>
            </Row>
            <div aria-hidden><Divider /></div>
            <br /><div className="shim"></div><div className="shim"></div>
        </>
    );

}




Event.Tickets = (props) => {

    const { ticket = [] } = props;


    const columns = [
        {
            "key": 0,
            "title": "Image",
            "dataIndex": "name"
        },
        {
            "key": 0,
            "title": "Title",
            "dataIndex": "title",
        }

    ];


    const data = ticket.map((item, i) => {

        return {
            "key": "key",
            "name": "name",
            "title": "name",
        }

    })

    return (<Table ghost={true} pagination={false} columns={columns} dataSource={data} ></Table>)
}

Event.SummaryItem = (props) => {



    const { info = null, to, from, id } = props.data;
    const { description = null, name = "" } = info;

    return (
        <>
            <Row style={{ "flexFlow": "inherit" }} gutter={15}>
                <Col flex={"50px"} >
                    <div className="oldcal dateBox vcluster">
                        <span><small>{moment(from * 1000).format("MMM").toUpperCase()}</small></span>
                        <span style={{ "fontSize": "20px" }}>{moment(from * 1000).format("D")}</span>
                        <span><small>{moment(from * 1000).format("YYYY").toUpperCase()}</small></span>
                    </div>
                </Col>
                <Col flex="auto">
                    <Link to={"/events/" + id}><h3>{name}</h3></Link>
                    <span><strong>  {moment(from * 1000).tz('America/Los_Angeles').format("h:mma")} - {moment(to * 1000).tz('America/Los_Angeles').format("h:mma")}</strong></span>
                    {(description && <span style={{ "color": "666" }}><Html clip={400} strip html={description} /></span>)}
                </Col>
            </Row>
            <div aria-hidden><Divider /></div>
        </>
    );

}

Event.SidebarItem = (props) => {

    // array, capped at 3
    const { data } = props;
    const { info, from, to, id } = data;
    const { img_path } = info;
    const { name } = info;

    return (
        <div>
            <Link to={"/events/" + id}>
                <div className="eventCard">
                    <div className="eventCardWrap">
                        {(img_path && <img onError={i => i.target.style.display = 'none'} style={{ "width": "100%" }} alt={name} src={img_path} />)}
                        <h4 className="eventCard">{name}</h4>
                        <Button type="text"><small>{moment(from * 1000).tz('America/Los_Angeles').format("MMM Do h:mma") + " - " + moment(to * 1000).tz('America/Los_Angeles').format("h:mma")}</small></Button>
                    </div>
                </div>
            </Link>
        </div>
    )

}



Event.Books = (props) => {


    const books = props.books.slice(0, 2);

    return books.map((book, index) => {
        return (<img key={index} onError={i => i.target.style.display = 'none'} style={{ "width": (books.length == 2) ? "126px" : "100%" }} alt={book.title} src={"https://cdn1.bookmanager.com/i/m?b=" + book.eisbn + "&cb=" + book.cover_image_cache} />)
    })

}
