import React from "react";
import Html from "../components/utilities/Html";
import { getColor } from "../util/CC";
import { Button } from "antd";
import { useMediaQuery } from 'react-responsive';

export default function TextOverImage(props) {

    const template = window.sitesettings.template_info;
    const ismobile = useMediaQuery({ query: '(max-width: 716px)' })
    const { content = {} } = props;

    const {
        location = "main",
        component = "blank_space",
        container = true,
        data = {},
        injected_data = {}
    } = content;

    const {

        background_margin_top = 30,
        background_margin_right = 30,
        background_margin_bottom = 30,
        background_margin_left = 30,
        background_colour = 1,
        background_size = "cover",
        bg_horizontal_align = "center",
        bg_vertical_align = "center",
        text_container = true,
        text_background_colour = 1,
        text_background_radius = 0,
        width = 100,
        align = "center",
        text_align = "center",
        bg_padding_x = 0,
        bg_padding_y = 0,
        padding_x = 10,
        padding_y = 10,
        heading = "Heading...",
        heading_preset = 2,
        paragraph = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tincidunt enim vel lectus egestas hendrerit. Sed at tempus ante. Interdum et malesuada fames ac ante ipsum primis in faucibus.",
        paragraph_preset = 1,
        button_url = "",
        button_label = "",
        button_type = 1,
        button_tab = true,
        button_block = false,
        background_image_radius = 0,
        link_id = 0,

        // NEW 
        bg_height_enabled = false,
        bg_height = 500,
        align_content = "start",
 
        allow_mobile_overrides = false,
        mobile_align_content = "start",
        mobile_bg_height_enabled = false,
        mobile_bg_height = 500,
        mobile_background_size = "cover",
        mobile_bg_horizontal_align = "center",
        mobile_bg_vertical_align = "center",

        mobile_background_margin_top = 30,
        mobile_background_margin_right = 30,
        mobile_background_margin_bottom = 30,
        mobile_background_margin_left = 30,

        background_alt = ""

    } = data;

    const { background_image = "" } = injected_data;

    const linkOut = () => {
        if (button_url) {
            window.open(button_url, (button_tab) ? "_blank" : "_self");
        }
    }

    const parsePath = (url) => {
        const abs = /^(?:[a-z]+:)?\/\//i;
        return (abs.test(url)) ? url : "https://cdn1.bookmanager.com/i/" + window.san + url;
    }

    const drawRender = () => {

        let _style = {
            background: getColor(text_background_colour, template),
            borderRadius: text_background_radius + "px",
            padding: padding_y + "px " + padding_x + "px",
            maxWidth: (1248 * (width / 100)) + "px",
            alignSelf: "flex-end",
            textAlign: text_align
        }

        let bg_style = {
            borderRadius : background_image_radius+"px",
            backgroundRepeat: "no-repeat",
            backgroundPositionX: bg_horizontal_align,
            backgroundPositionY: bg_vertical_align,
            padding: background_margin_top + "px " + background_margin_right + "px " + background_margin_bottom + "px " + background_margin_left + "px",
            backgroundSize: background_size,
            backgroundImage: `url('${parsePath(background_image)}')`
        }

        if (bg_height_enabled) {
            bg_style.height = bg_height + "px";
            bg_style.overflow = "hidden";
            bg_style.alignContent = align_content
        }

        
        if (allow_mobile_overrides && ismobile) {
            if (mobile_bg_height_enabled) {
                bg_style.height = mobile_bg_height + "px";
                bg_style.overflow = "hidden";
                bg_style.alignContent = mobile_align_content
            }
            bg_style.padding = mobile_background_margin_top + "px " + mobile_background_margin_right + "px " + mobile_background_margin_bottom + "px " + mobile_background_margin_left + "px";
            bg_style.backgroundSize = mobile_background_size;
            bg_style.backgroundPositionX = mobile_bg_horizontal_align;
            bg_style.backgroundPositionY = mobile_bg_vertical_align;
        }


        let _align = {
            display: "flex",
            flexDirection: "column",
        }

        switch (align) {
            case "center":
                _style.alignSelf = "center";
                break;
            case "right":
                _style.alignSelf = "flex-end";
                break;
            default:
                _style.alignSelf = "flex-start";
                break;
        }


        return (
            <div className={(container) ? "preview-container" : ""}>

                
                <div style={{ "padding": bg_padding_y + "px " + bg_padding_x + "px", "background": getColor(background_colour, template) }}>
                    <div role="img" aria-label={background_alt} style={bg_style}>

                        <div className={(text_container) ? "child-preview-container" : ""} style={_align}>
                            <div style={_style}>

                                {(heading &&
                                    <h2 style={{ "display": "block" }} className={"bm-txt-" + heading_preset} >
                                        {heading}
                                    </h2>
                                )}
                                {(paragraph &&
                                    <div className={"bm-txt-" + paragraph_preset} >
                                        <Html clear={false} className={"bm-lnkin-" + link_id} html={paragraph} />
                                    </div>
                                )}

                                {(button_label &&
                                    <div style={{ "display": "block" }}>
                                        <div className="shim" /><div className="shim" />
                                        <Button block={button_block} onClick={() => linkOut()} className={"bm-btn-" + button_type} >{button_label}</Button>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
                {/* <pre>{JSON.stringify(_style, null, 2)}</pre> */}
            </div>
        )
    }



    return drawRender();
}