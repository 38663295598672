import React, { useEffect } from "react";
import { getColor, getFontFamily } from "../util/CC";

export default function AboveSearchbarNotice(props) {


    const template = window.sitesettings.template_info
    const { content = {} } = props;

    // Component agnostic
    const {
        location = "main",
        component = "above_searchbar_notice",
        container = true,
        data = {},
    } = content;

    // Component specific
    const {
        message = "Above Searchbar Notice",
        font = 0,
        text_preset = 0,
        align = "center",
        padding_x = 5,
        padding_y = 5,
        colour = 0,
        background_colour = 0,
        link = "",
        tab = false,
        link_id = ""
    } = data;



    useEffect(() => {

        // Function to replace the content of the existing div with a new paragraph
        const replaceContentOfDiv = () => {


            const divId = 'bm-notification'; // Replace 'existingDiv' with the ID of your target div
            const divElement = document.getElementById(divId);

            if (divElement) {
                // Clear the existing content of the div
                divElement.innerHTML = '';

                const cont = document.createElement('div');
                cont.className = "bm-render no_p_margin " + "bm-lnkin-" + link_id + " bm-txt-" + text_preset;
                cont.style["background-color"] = getColor(background_colour, template, "#eee");

                // Create a new paragraph element
                const ele = document.createElement('div');
                if (container) {
                    ele.style.maxWidth = "1248px"
                    ele.style.margin = "0 auto";
                } else {
                    ele.style.maxWidth = "100%"
                    ele.style.margin = "0 auto";
                }

                ele.innerHTML = message;
                ele.style.textAlign = align;
                //ele.style.color = getColor(colour, template, "#fff");
                // ele.style.fontFamily = `'${getFontFamily(font, template)}'`;

                if (message) {
                    ele.style.padding = padding_y + "px " + padding_x + "px";
                }

                if (link) {
                    cont.style.cursor = "pointer";
                    cont.addEventListener("click", function () {
                        window.open(link, (tab) ? "_blank" : "_self");
                    });

                }


                cont.appendChild(ele)

                // Append the paragraph element to the target div
                divElement.appendChild(cont);
            }
        };

        // Call the function to replace the content when the component mounts
        replaceContentOfDiv();

        return () => {

            let divElement = document.getElementById("bm-notification");
            divElement.innerHTML = '';
        }
    })

 

    return (<></>);

}