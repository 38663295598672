import React, { useEffect, useState } from "react";
import { Form, Input, Select, Layout, Divider, Card, Empty, Result, Skeleton } from 'antd';
import OrderItem from "./OrderItem";
import Paginate from "../../../shared/Paginate";
import { apiCall } from "../../utilities/Api";
import PageNavigation from "../../../shared/PageNavigation";
import conditional from "../../utilities/conditional";
import { Link } from "react-router-dom";

function Orders() {

    const [results, setResults] = useState({});
    const [filteredResults, setFilteredResults] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [term, setTerm] = useState("");
    const [year, setYear] = useState("");
    const [locId, setLocId] = useState(null);

    // Pagination
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(26);
    const [dim, setDim] = useState(false);

    const onPageChange = (values) => {
        setCurrentPage(values.current);
        setOffset((values.current - 1) * values.pagesize);
    }

    const onGetPreviousOrders = (_status, _result) => {
        if (!_status) {
            setError(true);
        } else {
            setTotal(_result.rowcount);
            setFilteredResults(_result);
        }
        setDim(false);
        setResults(_result);
        setLoading(false);
    }

    const getPreviousOrders = () => {
        setDim(true);
        let args = { offset: offset, limit: pageSize}
        if(year){
            args["year"] = year; 
        }
        if(term){
            args["term"] = term; 
        }
        if(locId){
            args["location_id"] = locId; 
        }
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        apiCall("order/getList", args, onGetPreviousOrders);
    }

    useEffect(getPreviousOrders, [offset, pageSize, year, term, locId])

    const getYears = () => {
        let oldest = results.years[results.years.length - 1];
        let today = new Date().getFullYear();
        let allyears = [];
        for (let i = today; i >= oldest; i--) {
            allyears.push(i);
        }
        return allyears.map((item) => {
            if (results.years.includes(item)) {
                return (<Select.Option value={item.toString()} key={item.toString()} >{item}</Select.Option>);
            } else {
                return (<Select.Option disabled value={item.toString()} key={item.toString()} >{item}</Select.Option>);
            }
        })

    }

    const displayLoading = () => {
        return (<div>
                <h2>Order History</h2>
                <div aria-hidden><Divider /></div>
                <Layout className="responsiveSider" >
                    <Layout.Sider className="responsiveSummaryLeft" width="none" theme="light">
                        <Skeleton active />
                    </Layout.Sider>
                    <Layout.Content style={{ "width": "auto" }} >
                        <Skeleton active /><Skeleton active />
                    </Layout.Content>
                </Layout>
            </div>)
    }

    const displayError = () => {
        return (
            <>
                <Result status="error" title="There was an error" subTitle={results.error} />
            </>
        )
    }

    const displayEmpty = () => {
        return (<><br /><br /><br /><Empty description={<span style={{ "color": "#ddd" }}>no results</span>} /><br /><br /></>)
    }

    const displayStatus = () => {
        if (loading) {
            return displayLoading();
        }

        if (error) {
            return displayError();
        }
       
        return display();
    }

    const displayOrderItems = () => {
        if (filteredResults.rows.length < 1) {
            return displayEmpty();
        } else {
            return filteredResults.rows.map((item, i) => {
                return (<OrderItem data={item} key={item.order_number} />);
            })
        }
    }

    const onSearch = () => {
        searchForm.submit();
        setCurrentPage(1);
        setOffset(0);
    }

    const onFinish = (_form_fields) => {
        setTerm(_form_fields.t);
        setYear(_form_fields.y.toString());
        setLocId(_form_fields.location_id);
    }

    //draw pagination component, but made into a function so it's reuseable
    function drawPagination(){
        return(
            <Paginate
                loading={loading}
                paginate={{
                    current: currentPage,
                    offset: offset,
                    pagesize: pageSize,
                }}
                setPaginate={(e) => {
                    setPageSize(e.pagesize);
                    setCurrentPage(e.current);
                    setOffset(e.offset);
                    onPageChange(e);
                }}
                count={results.row_count} 
            />
        )
    }

    const [searchForm] = Form.useForm();

    const display = () => {
        return (
            <div>
                <h2>Order History</h2>
                <em>Stock statuses and order timeframes are as of the date the order was placed. Please <Link style={{"fontWeight" : "bold", "color" : "#069"}} to={"/contact-hours"}>contact us</Link> for order updates.</em>
                <div aria-hidden><Divider /></div>
                <Layout className="responsiveSider" >
                    <Layout.Sider className="responsiveSummaryLeft" width="none" theme="light">
                        <Card className="adjust-margin" >
                            <div><h3>Filters </h3><span style={{ "display": "block" }}>Showing <strong>{filteredResults.rowcount}</strong> orders {(year !== "" && <>from <strong>{year}</strong></>)}</span></div>
                            <div aria-hidden><Divider style={{ "marginTop": "10px", "marginBottom": "15px" }} /></div>
                            <Form onFinish={(form_fields) => onFinish(form_fields)} form={searchForm} layout="vertical">
                                <Form.Item initialValue="" name="t" label="Search term">
                                    <Input.Search allowClear onSearch={() => onSearch()} placeholder="Search titles" />
                                </Form.Item>
                                <Form.Item initialValue="" name="y" label="Year">
                                    <Select onChange={() => onSearch()} style={{ "width": "100%" }}>
                                        <Select.Option key={"anytime"} value="" >Anytime</Select.Option>
                                        {getYears()}
                                    </Select>
                                </Form.Item>
                                <conditional.true value={(window.sitesettings.store_info.is_multistore && window.sitesettings.store_info.is_consolidated_multistore)}>
                                <Form.Item initialValue="" name="location_id" label="Location">
                                    <Select onChange={() => onSearch()} style={{ "width": "100%" }}>
                                        <Select.Option key={"anytime"} value="" >All locations</Select.Option>
                                        {window.sitesettings.store_info.multistore_info.map((item, index) => {
                                            return(<Select.Option key={index} value={item.id} >{item.name}</Select.Option>)
                                        } )}
                                    </Select>
                                </Form.Item>
                                </conditional.true>
                            </Form>
                        </Card>
                    </Layout.Sider>
                    <Layout.Content style={{ "width": "auto" }} >
                        {drawPagination()}
                        <div className={(dim) ? "wait" : "ready"}>
                            {displayOrderItems()}
                        </div>
                        {drawPagination()}
                    </Layout.Content>
                </Layout>
            </div>
        );
    }
    return displayStatus();
}

export default Orders; 