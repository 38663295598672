import React, { useState } from "react";
import { Space, Divider, Button, Alert, Modal } from 'antd';
import { useSession } from "../../../util/Session";
import AccountLoginForm from "../account/AccountLoginForm"
import { useHistory } from "react-router-dom";

export default function ClickAndCollect(props) {

    const history = useHistory();
    const [session] = useSession();
    const [loginModalVisible, setLoginModalVisible] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);

    const { can_click_collect, contains_unavailable } = session.cart;
    const hasGiftCard = () => {

        let ret = false;
        for (let i = 0; i < session.cart.items.length; i++) {

            if (session.cart.items[i].cart_info.is_giftcard) {
                ret = true;
            }
        }

        return ret;

    }

    const hasUncartable = () => {

        let ret = false;
        for (let i = 0; i < session.cart.items.length; i++) {

            if (!session.cart.items[i].cart_info.is_giftcard && session.cart.items[i].title_info.available.onhand === 0) {
                ret = true;
            }
        }

        return ret;

    }

    const displayLoginModal = (_show_create = false) => {
        return (
            <>
                <Modal style={{ "maxWidth": "300px" }} title="Login and checkout" footer={null} closeIcon={<></>} onCancel={() => setLoginModalVisible(false)} open={loginModalVisible}>
                    <AccountLoginForm show_create={_show_create} />
                </Modal>
            </>
        )

    }

    const displayDisabled = () => {

        return (
            <>
                <div style={{ "textAlign": "center" }}>
                    <h3>We're sorry!</h3>
                    <p>The <em>Checkout as Guest</em> option is not possible with this order, as it contains a gift card purchase, and/or items or quantities not currently in stock <br /><br />This option is only for in-store pick up of in-stock items. </p>
                    <div aria-hidden><Divider /></div>
                    {(hasGiftCard() && <Alert className="msg" style={{ "marginBottom": "5px" }} description={<><span style={{ "textAlign": "center", "width": "100%", "display": "block" }}>Orders that include Gift Cards cannot be completed using the Checkout as Guest option because we require immediate payment for Gift Cards. Please <strong>create an account</strong> or <strong>log in</strong> to complete a Gift Card Purchase.</span></>} type="error" />)}
                    {(hasUncartable() && <Alert className="msg" description={<><span style={{ "textAlign": "center", "width": "100%", "display": "block" }}>There are items in your cart we do not currently have in stock. <br /> We will need you to <strong>create an account</strong> or <strong>log in</strong> to place an order.</span></>} type="error" />)}
                </div>
                <div style={{ "float": "right" }}>
                    <br />
                    <Space>
                        <Button onClick={() => setModalVisible(false)}>Cancel</Button>
                        <Button onClick={() => history.push("/account/signup")}>Create an account</Button>
                        <Button onClick={() => history.push("/account/login")} type="primary">Login</Button>
                    </Space>
                </div>
                <br clear="both" />

            </>
        )
    }


    const loginRedirect = () => {

        window.loginRedirect = "/checkout/cart";
        history.push("/account/login");
    }

    const display = () => {

        if (session.logged_in) {
            return (<></>);
        }

        if (!window.sitesettings.guest_allow_checkout) {
            return (<><Button className="checkout-btn" onClick={() => loginRedirect()} block type="primary"><strong>Checkout</strong></Button></>)
        }


        if (contains_unavailable || !can_click_collect) {

            return (
                <>
                    <Modal footer={null} closeIcon={<></>} onCancel={() => setModalVisible(false)} open={modalVisible}>
                        {displayDisabled()}
                    </Modal>
                    <Button className="checkout-btn" block style={{ "opacity": (can_click_collect) ? "1" : "1" }} type={"primary"} onClick={() => setModalVisible(true)}>Checkout as Guest</Button>
                    {displayLoginModal(true)}
                    <Button className="checkout-btn" onClick={() => setLoginModalVisible(true)} style={{ "marginTop": "10px" }} block>Login and checkout </Button>
                </>
            )
        } else {

            return (
                <>
                    {props.children}
                    {displayLoginModal(true)}
                    <Button className="checkout-btn" onClick={() => setLoginModalVisible(true)} style={{ "marginTop": "10px" }} block>Login and checkout </Button>
                </>
            )
        }

    }

    return display();


}