import { Checkbox, Form, Space } from "antd";
import React from "react";

export default function CheckBoxField(props) {

    const { data = {}, contact = {} } = props;

    const {
        id = "",
        label = "Checkbox",
        type = "checkbox",
        description = "",
        required = false,
        required_message = ""
    } = data;

    let defaultValue = (contact.hasOwnProperty([id])) ? true : false;

    // <Form.Item initialValue={defaultValue} label={null} valuePropName="checked" extra={description} name={id} >
    //     <Checkbox >{label}</Checkbox>
    // </Form.Item>

    return (
        <>
        <Space align="start">
            {(required && <span style={{ "color": "red" }}>*</span>)}
            <Form.Item rules={[{ required: required, message: required_message }]} valuePropName="checked" extra={description} name={id} >
                <Checkbox >{label}</Checkbox>
            </Form.Item>
        </Space>
        </>

    )
}