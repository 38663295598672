import React, { useEffect, useState } from "react";
import { Affix, Space, Button, Badge, Drawer, Input } from "antd";
import { ShoppingCartOutlined, UserOutlined, MenuOutlined, SearchOutlined } from '@ant-design/icons';
import CSS from "../components/utilities/CSS"
import conditional from "../util/conditional";
import { getColor } from "../util/CC";
import { useSession } from "../util/Session";
import SearchField from "./SearchField";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AccountBox from "./AccountBox";
import LoginBox from "./LoginBox";
import LocationSelector from "./LocationSelector";
import SiteNav2 from "./SiteNav2";
import { XIcon } from "../util/Icons";
import { useRef } from "react";
import { apiCall } from "./utilities/Api";
import { getOGColor } from "../util/Utils";

export default function SiteSearchBar(props) {

    const history = useHistory();
    const [mobileMenu, setMobileMenu] = useState(false);
    const [searchBox, setSearchBox] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const searchCategories = ["Keyword", "Title", "Author", "Publisher", "URL", "File"];
    const [searchType, setSearchType] = useState(searchCategories[0].toLowerCase());
    const searchRef = useRef(0);

    const {
        container = true,
        data = {},
    } = window.sitesettings.global_content.find(item => item.component === "search_bar") || {};

    // Get template
    const template = window.sitesettings.template_info;
    const [session, setSession] = useSession()
    const { title = "Bookstore", view = "desktop", containerRef = {} } = props;

    // Destructure component specific settings
    const {
        background_colour = 0,
        text_colour = 0,
        text_hover_colour = 0,
        search_bar_logo = "",
        pin_navigation = true,
        logo_height = 40,
        padding_x = 0,
        padding_y = 2,
        drop_shadow = true,
        image_cache_key = 0
    } = data;


    const _full_height = logo_height + padding_y + padding_y + 20;

    const is_mobile = (view !== "desktop");

    let avatar_bg = getColor(background_colour, template, getOGColor("search_bar_colour", "#000"));
    let avatar_color = getColor(text_colour, template, getOGColor("search_bar_text_colour", "#fff"));

    // Write class 
    let _class = ``;

    _class += `
    .search_bg {
        background: ${getColor(background_colour, template, getOGColor("search_bar_colour", "#000"))};
        color:  ${getColor(text_colour, template, getOGColor("search_bar_text_colour", "#fff"))};
        height: calc(20px + ${(logo_height + padding_y + padding_y) + "px"})
    }`;

    _class += `
    .search_bg .logo {
        max-width : 300px;
        max-height : ${logo_height}px;
        width : auto; 
        height : auto:
    }`;

    _class += `
    .search_bg .logo-mobile {
        max-width : 100%;
        max-height : ${logo_height}px;
        width : auto; 
        height : auto:
    }`;

    _class += `
    .search_bg .float-flex{
        align-items : center;
        height: calc(20px + ${(logo_height + padding_y + padding_y)}px)
    }`;

    _class += `
    .bm-search-icons .ant-btn:not(:hover), .search_bg h3 {
        color:  ${getColor(text_colour, template, getOGColor("search_bar_text_colour", "#fff"))} !important;
        border-color:  ${getColor(text_colour, template, getOGColor("search_bar_text_colour", "#fff"))} !important;
        background: transparent;
    }`;

    _class += `
    .bm-search-icons .ant-btn:hover, .search_bg h3:hover {
        color:  ${getColor(text_hover_colour, template, getOGColor("search_bar_text_colour", "#fff"))} !important;
        border-color:  ${getColor(text_hover_colour, template, getOGColor("search_bar_text_colour", "#fff"))} !important;
        background: transparent;
    }`


    _class += `
    .bm-search-icons .bm-icon:not(:hover) {
        color:  ${getColor(text_colour, template, getOGColor("search_bar_text_colour", "#fff"))} !important;
        fill:  ${getColor(text_colour, template, getOGColor("search_bar_text_colour", "#fff"))} !important;
    }`;

    _class += `
    .bm-search-icons .bm-icon:hover {
        color:  ${getColor(text_hover_colour, template, getOGColor("search_bar_text_colour", "#fff"))} !important;
        fill:  ${getColor(text_hover_colour, template, getOGColor("search_bar_text_colour", "#fff"))} !important;
    }`;

    _class += `
    .bm-search-icons .ant-input-search-button{
        width: 50px;
    }`

    _class += `
    .bm-mobile-search {
        background: ${getColor(background_colour, template, getOGColor("search_bar_colour"))} !important;
    }`;

    _class += `
    .bm-mobile-search .ant-input-group-addon .ant-input-search-button {
        width: 50px !important;
    }`;



    ;


    const onSearch = (_search, e) => {
        searchRef.current.blur()
        if (e) { e.target.blur(); }
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        if (_search !== "") {
            if (searchType === "url") {
                searchURL(_search)
                return;
            } else {
                // REGEX for weird issue with percent decoding on useHistory()
                history.push({ "pathname": "/browse/filter/t/" + encodeURI(_search).replace(new RegExp('%25', 'g'), '%2525') + "/k/" + encodeURI(searchType), "search_bar": { "searchTerm": _search, "searchType": searchType } })
            }
        } else {
            // clear search
            history.push("/browse/filter/");
        }
    }

    const searchURL = (_url) => {
        apiCall("browse/url", { url: _url }, (_status, _result) => {
            if (_status) {
                history.push("/browse/filter/x/" + _result.id)
            }
        })
    }

    const linkHome = () => {
        if (window.sitesettings.store_info.alternate_home) {
            window.location.href = window.sitesettings.store_info.alternate_home;
            return;
        } else {
            if (window.sitesettings.landing_page_url) {
                history.push("/home");
            } else {
                history.push("/");
            }
        }
    }

    const [hasLogo, setHasLogo] = useState(true);

    const displayLegacyLogo = () => {
        if (hasLogo) {
            return (<img onError={(i) => setHasLogo(false)} alt={window.sitesettings.store_info.name} className={(is_mobile) ? "logo-mobile" : "logo"} src={window.sitesettings.styles.logo_url} />);
        } else {
            return (<h3>{window.sitesettings.store_info.name}</h3>)
        }
    }

    // Draw the logo, or the title if there is no logo
    const drawLogo = () => {
        if (search_bar_logo) {
            return (
                <div style={{ "padding": "5px 0px" }}><img alt={window.sitesettings.store_info.name} className={(is_mobile) ? "logo-mobile" : "logo"} src={"https://cdn1.bookmanager.com/i/" + window.san + search_bar_logo + "?cb=" + image_cache_key} /></div>
            )
        } else {

            return displayLegacyLogo()
        }
    }

    const displayLogin = (size) => {
        if (session.logged_in) {
            return (<AccountBox avatar_bg={avatar_bg} avatar_color={avatar_color} size={size} />);
        } else {
            return (<LoginBox size={size} />);
        }
    }

    // useEffect(() => {
    //     if (!searchBox && searchRef.current) {
    //         searchRef.current.setAttribute('autofocus', 'autofocus');
    //         searchRef.current.focus({ preventScroll: true });
    //     }
    // }, [searchBox]);
    
    const toggleSearch = () => {


        setSearchBox(!searchBox);
    }

    const clearSearch = (_val) => {
        setSearchValue("")
        if (history.location.pathname.includes("/k/")) {
            onSearch(_val, null);
        }
    }

    const drawMenu = () => {
        let _has_locations = (window.sitesettings.store_info.is_consolidated_multistore && window.sitesettings.store_info.is_multistore) ? true : false;
        let _has_lists = (session.logged_in) ? true : false;
        let _has_cart = (window.sitesettings.cart_enabled) ? true : false;
        _has_cart = (!session.logged_in && !window.sitesettings.guest_show_cart) ? false : true;

        return (
            <Space className="bm-search-icons" size={0}>
                {(_has_locations && <LocationSelector location_btn />)}
                {displayLogin("medium")}
                <div className="hide-block" style={{ "width": "10px" }}>&nbsp;</div>
                {(_has_lists && <Button onClick={() => history.push("/account/lists")} className="tab hide styled_btn" >My lists</Button>)}
                {(_has_cart &&
                    <Link className="tab" aria-label="Checkout" to="/checkout">
                        <Badge className="cart-icon" offset={[-15, 15]} count={(session.hasOwnProperty("cart") && session.cart !== null && session.cart.hasOwnProperty("summary")) ? session.cart.summary.item_count : "0"}>
                            <ShoppingCartOutlined aria-hidden style={{ "fontSize": "25px" }} className="icon-btn cart-icon bm-icon" />
                        </Badge>
                    </Link>
                )}
                <SearchOutlined aria-label="Search" name="searchtype" onClick={() => toggleSearch()} className="icon-btn bm-icon show" />
                <MenuOutlined aria-label="Menu" onClick={() => setMobileMenu(true)} className="icon-btn bm-icon show" />
                <div className="show">&nbsp;</div>
                <div className="show">&nbsp;</div>
            </Space>
        )
    }

    // Draw the search bar for desktop or mobile
    const draw = () => {
        return (
            <div className={(drop_shadow) ? "search_bg headerShadow bm-render" : "search_bg bm-render"} style={{ "maxWidth": "100%" }}>
                <div style={{ "maxWidth": (container) ? "1248px" : "100%", "margin": "0 auto", "padding": (!is_mobile && container) ? "0px 0px" : "0px 20px" }} className="float-flex">
                    {/* Desktop */}
                    <Space.Compact style={{ "alignItems": "center" }} block size={20}>
                        <div className="hide" onClick={() => linkHome()} style={{  "padding": padding_y + "px " + padding_x + "px", cursor: "pointer" }}>{drawLogo()}</div>
                        <div className="show" onClick={() => linkHome()} style={{  "padding": padding_y + "px " + "0px", cursor: "pointer" }}>{drawLogo()}</div>
                        <div className="hide" style={{ "width": "30px" }}>&nbsp;</div>
                        <div className="hide" style={{ "width": "100%" }}>
                            <SearchField />
                        </div>
                        <div className="hide" style={{ "width": "30px" }}>&nbsp;</div>
                    </Space.Compact>
                    {drawMenu()}
                    {/* Mobile */}
                </div>

            </div>
        )
    }

    useEffect(() => {
        setMobileMenu(false);
    }, [history.location.pathname])
    // Draw pinned or scrolling

    return (
        <div style={{ "position": "relative", "zIndex": "12" }}>
            <CSS html={_class} />
            <conditional.true value={(pin_navigation)}>
                <Affix>
                    <div style={{ "width": "100%" }}>{draw()}</div>
                </Affix>

                {/* <div className="shim" style={{"height" : _full_height + "px"}} /> */}
            </conditional.true>
            <conditional.true value={(!pin_navigation)}>
                <div style={{ "marginTop": "-2px" }}>{draw()}</div>
            </conditional.true>

            <Drawer
                title="Menu"
                style={{ "padding": "0px" }}
                className="show mobile_drawer"
                placement="right"
                width="250px"
                mask={false}
                closable={true}
                open={mobileMenu}
                onClose={() => setMobileMenu(false)}
            >
                <SiteNav2 mobile site_navigation={props.site_navigation} />
            </Drawer>
            <div style={{ "marginTop": (searchBox) ? "0px" : "-100px" }} className={(searchBox) ? "open show-block" : "close show-block"}>
                <div className={(searchBox) ? "mobileSearchBG show-block bm-mobile-search" : "mobileSearchBG hide-block bm-mobile-search"} style={{ "padding": "10px 10px", "height": "60px" }}>
                    {(searchBox && <Input.Search autoFocus={searchBox} ref={searchRef} allowClear={false} enterButton addonAfter={<XIcon onClick={() => clearSearch("")} style={{ "fill": (searchValue) ? "#595959" : "#ffffff", "right": "75px", "position": "relative", "zIndex": "10" }} />} className="header_search" size="large" value={searchValue} onChange={(v) => setSearchValue(v.target.value)} onSearch={(_value, e) => onSearch(_value, e)} name="searchtype" id="mobileSearch" type="search" placeholder="Search for anything" />)}
                </div>
            </div>
        </div>
    )
}