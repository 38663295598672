export const getColor = (_id, _template, _default = false) => {

    // check to see if id exists at all

    
    if(_template.hasOwnProperty("colours")){
        _id = (_template.colours.find(item => item.id === _id) || _template.gradients.find(item => item.id === _id)) ? _id : 0;
    }
   

    // id of zero means no colour
    if (_id === 0) {
        return (_default) ? _default : "inherit";
    }

    let _f;
    if (_id % 2) {
        // colour
        _f = _template.colours.find(item => item.id === _id);
        return (_f) ? _f.style : "rgba(0, 0, 0, 1)";
    } else {
        // gradient
        _f = _template.gradients.find(item => item.id === _id);
        return (_f) ? _f.style : "rgba(0, 0, 0, 1)";
    }

}

export const getFontFamily = (font = 1, _template, loop = false) => {

    if(!_template.hasOwnProperty("fonts")){
        return 'Default';
    }
    
    let _f = _template.fonts.find(item => item.id === font);
    if (_f) {
        return _f.name;
    } else {
        if (_template.base_font && !loop) {
            return getFontFamily(_template.base_font, _template, true);
        } else {
            return 'Default';
        }
    }
}
