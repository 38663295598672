import React, { useState } from "react";
import { Card, Layout, Divider, Form, Alert, Button } from 'antd';
import { CartSummary } from "../checkout/Cart";
import { useSession } from "../../../util/Session";
import CreditCardForm from "../checkout/CreditCardForm";
import { useHistory } from "react-router-dom";
import { apiCall } from "../../utilities/Api";

export default function CreditCard(props) {

    const { saved = {}, setSaved = () => { } } = props;

    const history = useHistory();
    const [session, setSession] = useSession();
    const [errorMSG, setErrorMSG] = useState("");
    const [creditCardForm] = Form.useForm();

    const onSetCreditCard = (_status, _result) => {
        if (_status) {
            window.checksum = _result.checksum;
            setSaved({ ...saved, "cc_info": _result.cc_info })
            setSession({ ...session, "cart": _result.cart });
            history.push("/checkout/review");
        } else {
            setErrorMSG(_result.error)
        }
    }

    const setCreditCard = (_fields) => {
        setErrorMSG("")
        apiCall("checkout/setCreditCard", _fields, onSetCreditCard);
    }

    return (
        <>
            <Layout className="responsiveSider">
                <Layout.Content style={{ "width": "auto" }}>
                    <Card className="hideCard">
                        <div aria-hidden><Divider orientation="left" >Credit Card</Divider></div>
                        <Form form={creditCardForm} onFinish={(_form) => setCreditCard(_form)} layout="vertical">
                            <CreditCardForm form={creditCardForm} />
                        </Form>
                    </Card>
                </Layout.Content>
                <Layout.Sider width="none" className="responsiveSummary" theme="light">
                    <CartSummary>
                        <br />
                        {(errorMSG &&
                            <>
                                <Alert className="msg" description={<><span style={{ "textAlign": "center", "width": "100%", "display": "block" }}>{errorMSG}</span></>} type="error" /><br />
                            </>
                        )}
                        <p style={{ "textAlign": "center", "lineHeight": "18px" }}>You will be able to review your order before submitting</p>
                        <Button block onClick={() => creditCardForm.submit()} type="primary">Continue</Button>
                    </CartSummary>
                </Layout.Sider>
            </Layout>
        </>
    )
}