import React from "react"
import { Form, Input } from 'antd';

export default function ListForm(props) {

    const {list} = props; 

    return (
        <>
            <Form.Item initialValue={list.name} name="name" label="Name" rules={[{required : true, message : "Name is required."}]} >
                <Input placeholder="List name"  maxLength={80} />
            </Form.Item>
            <Form.Item initialValue={list.list_id} hidden name="list_id">
                <Input></Input>
            </Form.Item>
            <Form.Item initialValue={(list.description) ? list.description : ""} hidden name="description">
                <Input></Input>
            </Form.Item>
        </>
    )
}