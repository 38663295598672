import React from "react";
import { useSession } from "../../../util/Session";
import { Divider } from 'antd';
import Html from "../../utilities/Html";

function AccountComplete() {

        const [session] = useSession();
        const { email_address, first_name, last_name } = session.user;
        const { phone, name, email } = window.sitesettings.store_info;
        let _message = window.sitesettings.ws_custom_welcome_msg.replace("USER'S EMAIL ADDRESS", email_address);

        if (_message) {
                return (
                        <>
                                <h2>Account Created</h2>
                                <p><Html html={_message} /></p>
                        </>
                )
        }

        return (
                <div>
                        <h2>Account Created</h2>
                        <div aria-hidden><Divider /></div>
                        <h3>Hello {first_name} {last_name}, <br /><br />Welcome to {name}!</h3>
                        <p>Your account has been created using <strong>{email_address}</strong>. The upper corner of our site will now indicate that you are logged in and ready to go.</p>
                        <p>Thank you for creating your account with us, and feel free to contact us at <strong><a href={"tel:" + phone}>{phone}</a></strong> or <strong><a href={"mailto:" + email}>{email}</a></strong> if you have any questions about our site or services.</p>
                </div>
        );

}

export default AccountComplete; 