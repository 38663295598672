import { Alert, Button, Divider, Form, Input, Modal, Space, Spin, Typography, message } from "antd";
import React, { useState, useRef, useEffect } from "react";
import PhoneNumberFormatter from "../../formatters/PhoneNumberFormatter";
import { apiCall } from "../../utilities/Api";
import ReCAPTCHA from "react-google-recaptcha";
import { useSession } from "../../../util/Session";
import moment from "moment-timezone";

export default function EventRSVP(props) {

    const [session, setSession] = useSession();

    const {
        first_name = "",
        last_name = "",
        email_address = "",
        phone_number = "",
    } = (Array.isArray(session.user)) ? {} : session.user;

    const full_name = (first_name) ? first_name + " " + last_name : "";
    const recaptchaRef = useRef();
    const { event = {} } = props;
    const active = moment().startOf("day").add(-1,"minute").isBefore(moment(event.date, "YYYYMMDD"));

    const [vals, setVals] = useState();
    const [init, setInit] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("")

    const {
        id = 0,
        title = ""
    } = event;


    const full_msg = <>Thank you for your interest in attending the <em><strong>{title}</strong></em> event. <div className="shim" /><div className="shim" />Unfortunately, our RSVP list has reached its limit. </>;
    const waitlist_msg = <>The RSVP list for the <em><strong>{title}</strong></em> event is at its limit. <div className="shim" /><div className="shim" />Please sign up for the waitlist to secure a spot if one becomes available.</>

    const [info, setInfo] = useState({})

    const {
        limit = 0,
        current_count = 0,
        waitlist_enabled = false,
        user_rsvp_count = 1,
        user_waitlist_count = 0
    } = info;


    const has_rsvp = (user_rsvp_count) ? <>You have already RSVP'd to this event{(user_rsvp_count>1) ? " " + user_rsvp_count + " times." : "."}</> : "";
    const has_waitlist = (user_waitlist_count) ? <>You have already signed up for the waitlist{(user_waitlist_count>1) ? " " + user_waitlist_count + " times." : "."}</> : "";
    const has_both = (user_rsvp_count && user_waitlist_count) ? <>You have already RSVP'd{(user_rsvp_count>1) ? " " + user_rsvp_count + " times" : ""} and signed up for the waitlist{(user_waitlist_count>1) ? " " + user_waitlist_count + " times" : "."}</> : ""

    const [form] = Form.useForm();

    const [open, setOpen] = useState(false)

    const close = () => {
        setError("")
        setOpen(false);
        form.resetFields();
    }

    const rsvp = (_f) => {




        _f["waitlist"] = (waitlist_enabled && current_count >= limit);

        apiCall("event/v2/rsvp", _f, (_status, _result) => {
            if (_status) {
                close();
                message.success("Registration successful!")
            } else {
                setError(_result.error);
                getInfo();
            }
        })

    }

    const getInfo = () => {
        apiCall("event/v2/getRsvpInfo", { event_id: id }, (_status, _result) => {
            if (_status) {
                setInfo(_result)
            } else {
                message.error(_result.error)
            }
            setLoading(false);
        })
    }

    useEffect(getInfo, [open])

    const captchaCheck = (_f) => {
        setVals(_f);
        recaptchaRef.current.reset()
        recaptchaRef.current.execute()
    }

    const captachPass = (_e) => {
        if (_e) {
            rsvp({ ...vals, "captcha": _e })
        }
    }



    const drawForm = () => {
        return (
            <>
                <Spin spinning={loading}>
                    <Typography.Title level={5}>{title}</Typography.Title>
                    <div className="shim" />

                    {((current_count >= limit && (limit>0)) && <>
                        <Typography.Paragraph>
                            {(waitlist_enabled && waitlist_msg)}
                            {(!waitlist_enabled && full_msg)}
                        </Typography.Paragraph>
                    </>)}

                    {(error && <><Alert type="error" message={error} /><div className="shim" /><div className="shim" /></>)}

                    {((!limit || current_count < limit || waitlist_enabled) && <>
                        <Form onFinish={(_f) => captchaCheck(_f)} form={form} layout="vertical">
                            <Form.Item hidden initialValue={id} name="event_id">
                                <Input />
                            </Form.Item>
                            <Form.Item initialValue={full_name} name="name" label="Name" rules={[{ required: true, message: 'Name is required' }]}>
                                <Input placeholder="Name..." maxLength={50} />
                            </Form.Item>
                            <Form.Item initialValue={email_address} label="Email" name="email" rules={[{ required: true, type: 'email', message: 'Please enter a valid email address.' }]}>
                                <Input placeholder="Email address..." />
                            </Form.Item>
                            <Form.Item initialValue={phone_number} rules={[{ required: true, message: 'Phone number is required' }]} name="phone" label="Phone Number">
                                <PhoneNumberFormatter field="phone_number" form={form} country={"Canada"} style={{ width: "302px" }} inputMode="numeric" placeholder="Phone number..." />
                            </Form.Item>
                            <Divider dashed style={{ "margin": "15px 0px" }} />
                            {(has_both && <Alert style={{"borderColor" : "#8ea4db", "backgroundColor" : "#cdd8f6", "marginBottom" : "20px"}} message={has_both} type="error" />)}
                            {((!has_both && (has_waitlist || has_rsvp))  && <Alert style={{"borderColor" : "#8ea4db", "backgroundColor" : "#cdd8f6", "marginBottom" : "20px"}} message={<>{has_rsvp}{has_waitlist}</>} type="error" />)}

                            <div className="float-flex">
                                <div>&nbsp;</div>
                                <Space>
                                    <Button onClick={() => close()}>Cancel</Button>
                                    <Button type="primary" htmlType="submit">Submit</Button>
                                </Space>
                            </div>
                        </Form>
                        <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey="6LfPBUslAAAAAIrLp814HpBIixMj0rL5J3cAxEUw" onChange={captachPass} />
                    </>)}

                </Spin>
            </>
        )
    }

    if(!active){
        return(<></>)
    }

    return (
        <>
            <Modal wrapClassName="siteModal" width={350} title={"RSVP"} open={open} onCancel={() => close()} destroyOnClose closable={true} footer={null}>
                {drawForm()}
            </Modal>
            <div onClick={() => setOpen(true)}>{props.children}</div>
        </>
    )
}