import { Form, Input, Space } from "antd";
import React, { useEffect, useState } from "react";

export default function TextDayMonthField(props) {

    const { data = {}, form, contact = {} } = props;

    const {
        id = "",
        label = "Text",
        type = "text",
        description = "",
        required = false,
        required_message = "Phone number is required"
    } = data;

    const [init, setInit] = useState(true);

    const setValue = (_v) => {
        if (form) {
            form.setFieldsValue({ [id]: _v })
            form.validateFields([id]);
        }
        return (_v);
    }



    const updateHiddenField = () => {
        const fullDate = `${day}${month}`;
        if (init) {
            setInit(false);
            return;
        }
        setValue(fullDate)
    };

    let defaultValue = (contact.hasOwnProperty([id])) ? contact[id] : "";

    let f1 = "";
    let f2 = "";
    let f3 = "";
    if (defaultValue) {
        f1 = defaultValue.substr(0, 2) || "";
        f2 = defaultValue.substr(2, 2) || "";
    }

    const [day, setDay] = useState(f1);
    const [month, setMonth] = useState(f2);


    useEffect(() => {
        updateHiddenField();
    }, [day, month])

    const validateDate = (rule, value) => {

        if (!value || value === "") {
            return Promise.resolve();
        }
        if (!value && form.getFieldValue([id])) {
            return Promise.reject('Please enter a valid date');
        }
        if (value.length !== 4) {
            return Promise.reject('Please enter a valid date');
        }
        return Promise.resolve();
    };

    const handleDayChange = (e) => {
        const { value, keyCode } = e.target;
        if (value.length <= 2 && /^\d*$/.test(value)) {
            setDay(value);
            if (value.length === 2) {
                document.getElementById(id.toString()+'monthInput').focus();
            }
        }
    };

    const handleMonthChange = (e) => {
        const { value } = e.target;
        if (value.length <= 2 && /^\d*$/.test(value)) {
            setMonth(value);
            if (value === '') {
                document.getElementById(id.toString()+'dayInput').focus();
                return;
            } 
        }
    };




    return (
        <Form.Item initialValue={defaultValue} extra={description} rules={[{ required: required, message: required_message }, { validator: validateDate, required: false, }]} label={label} name={id} >
            <Input hidden maxLength={14} />
            <Space>
                <Input
                    id={id.toString()+"dayInput"}
                    style={{ "width": "50px" }}
                    value={day}
                    onChange={handleDayChange}
                    onKeyDown={(e) =>{
                        if((e.key==="Enter" || e.key==="Tab") && e.target.value.length === 1){
                           setDay("0" + e.target.value.toString());
                           if(e.key==="Enter"){
                            document.getElementById(id.toString() + 'monthInput').focus();
                           }
                          
                        }
                    }}
                    maxLength={2}
                    placeholder="DD"
                    onFocus={(e) => e.target.select()}
                />
                <span> / </span>
                <Input
                    id={id.toString()+"monthInput"}
                    style={{ "width": "50px" }}
                    value={month}
                    onChange={handleMonthChange}
                    onKeyDown={(e) =>{
                        if((e.key==="Enter" || e.key==="Tab") && e.target.value.length === 1){
                           setMonth("0" + e.target.value.toString());
                        }
                    }}
                    maxLength={2}
                    placeholder="MM"
                    onFocus={(e) => e.target.select()}
                />
            </Space>
        </Form.Item>
    )
}