import React from "react";
import { getColor } from "../util/CC";
import wrap from "../util/wrap";
import CalendarUI from "./helpers/CalendarUI";
import { Col, Row } from "antd";
import Upcoming from "./helpers/Upcoming";
export default function EventsCalendarUpcoming(props) {

    const template = window.sitesettings.template_info;
    const { content = {} } = props;

    const {
        location = "main",
        component = "blank_space",
        container = true,
        injected_data = {},
        data = {}
    } = content;

    const {
        upcoming = [],
        current = []
    } = injected_data;

    const {
        width = 500,
        calendar_colour = 0,
        background_colour = 0,
        highlight_colour = 0,
        text_colour = 0,
        active_colour = 0,
        padding_x = 0,
        padding_y = 0,
        font = 0,
        title_preset = 0,
        description_preset = 0,
        date_preset = 0,
        limit = 2,
        button_type = 0,
        button_label = "View Events",
        button_align = "center",
        link_id = 0,
        display_titles = false,

        // New
        date_bar_preset = 0,
        day_preset = 0,
        border_colour = 0,
        day_border_colour = 0,
        calendar_header_colour = 0,
        weekdays_background_colour = 0,
        prev_next_background_colour = 0,

        categories = [],
        display_categories = false,

    } = data;

    if(injected_data.hasOwnProperty("rows") && injected_data.rows.length === 0){
        return(<></>);
    }

    
    const drawRender = () => {

        let _bgStyle = {
            background: getColor(background_colour, template),
            padding: padding_y + "px " + padding_x + "px"
        }

        let _wrapperStyle = {
            maxWidth: "100%",
        }

        let _calstyle = {}
        if (border_colour) {
            _calstyle.border = "1px solid " + getColor(border_colour, template, "#000");
        }

        return (
            <div className={(container) ? "preview-container" : ""}>
                <div style={_bgStyle}>
                    <div style={_wrapperStyle}>
                        <Row className="bm-wrap" gutter={[20, 40]} >
                            <Col flex={(width + "px")}>
                                <div style={_calstyle} className={"bm-font-" + font} >
                                    <CalendarUI data={data} injected_data={current}  />
                                </div>
                            </Col>
                            <Col flex="auto">
                                <Upcoming
                                    link_id={link_id}
                                    highlight_colour={highlight_colour}
                                    text_colour={text_colour}
                                    data={upcoming.rows}
                                    title_preset={title_preset}
                                    description_preset={description_preset}
                                    date_preset={date_preset}
                                    limit={limit}
                                    button_label={button_label}
                                    button_type={button_type}
                                    button_align={button_align}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }


    return drawRender();
}