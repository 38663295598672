import React, {useState, useEffect} from "react";
import { Button, Modal} from 'antd';
import GridDisplay from "../../../shared/GridDisplay";
import {useLocation } from "react-router-dom";
import {DownOutlined } from '@ant-design/icons';

export default function Series(props){

    const data = props.data; 
    const loc = useLocation(); 
    const [modalVisible, setModalVisible] = useState(false); 
    const close = () =>{ setModalVisible(false)}
    
    const closeOnLocation = () =>{
        if(modalVisible){
            close();
        }
    }

    useEffect(closeOnLocation, [loc])

    return(<>
        <Button  icon={<DownOutlined aria-hidden style={{"float" : "right", "padding" : "15px 10px"}} />} onClick={() => setModalVisible(true)} size="large" style={{"textAlign" : "left", "height" : "60px", "fontSize" : "14px", "marginTop" : "10px"}} block type="dashed">
            <strong>Series</strong><br />
            <div className="see-other" ><em>More in this series</em></div>
            <span>{data.series} {(data.series_num) ?  "#" +data.series_num : ""}</span>
        </Button>
        <Modal wrapClassName="siteModal" width="90%" title={"Other titles in the '" + data.series + "' series"} open={modalVisible} onCancel={close} destroyOnClose closable={true} footer={null}>
            <GridDisplay browse_link={"/browse/filter/k/whole_series/t/" + data.series} popup={true} show_count={false} method="title/getSeriesItems" wrap={false} args={{"series" : data.series}} />
        </Modal>
        </>
    ); 

}