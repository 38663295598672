import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { apiCall } from "../../utilities/Api";
import { Button, Result, Spin } from "antd";

export default function CancelEvent() {

    const [loading, setLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState("");
    const params = useParams();
    const {
        event_id = "",
        encrypted_rsvp_tag = ""
    } = params;

    const title = <>RSVP Cancellation Confirmation</>
    const details = <>You've successfully canceled your RSVP.</>
    const link = <>View Event Details</>

    const cancelEvent = () => {
        apiCall("event/v2/cancelRsvp", { event_id: event_id, rsvp_id: encrypted_rsvp_tag }, (_status, _result) => {
            if (_status) {

            } else {
                setErrorMsg(_result.error)
            }
            setLoading(false)
        })
    }

    useEffect(cancelEvent, [])

    if (loading) {
        return (<div className="container"><Spin spinning={loading}><div style={{ "minHeight": "calc(100vh - 500px)" }} className="container"></div></Spin></div>)
    }

    if (errorMsg) {
        return (
            <div style={{ "minHeight": "calc(100vh - 500px)" }} className="container">
                <br /><br />
                <Result
                    style={{ "paddingTop": "0px", 'margin': '0 auto' }}
                    className="halfWidth"
                    status="error"
                    title={<span style={{ 'display': 'block', 'textAlign': 'center' }}><strong>There was an error</strong></span>}
                    subTitle={<span style={{ 'display': 'block', 'textAlign': 'center' }}><div style={{ "color": "#5f5f5f" }}>{errorMsg}</div></span>}
                    extra={<Link to={"/"}><Button>Home</Button></Link>}
                />
            </div>
        )
    }

    return (
        <div style={{ "minHeight": "calc(100vh - 500px)" }} className="container">
            <Result
                style={{ "paddingTop": "0px", 'margin': '0 auto' }}
                className="halfWidth"
                status="success"
                title={<span style={{ 'display': 'block', 'textAlign': 'center' }}><strong>{title}</strong></span>}
                subTitle={<span style={{ 'display': 'block', 'textAlign': 'center' }}><div style={{ "color": "#5f5f5f" }}>{details}</div></span>}
                extra={<Link to={"/events/" + event_id}><Button type="primary">{link}</Button></Link>}
            />
        </div>
    )
}