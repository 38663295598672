import { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useScrollMemory = () => {
  const location = useLocation();
  const history = useHistory();
  const scrollPositionRef = useRef(0);
  useEffect(() => {
    const unlisten = history.listen((_, action) => {
      if (action === 'PUSH') {
        scrollPositionRef.current = window.scrollY;
      }
    });
    return () => {
      unlisten();
    };
  }, [history]);

  // Restore scroll position on back navigation (within the app)
  useEffect(() => {
    if (history.action === 'POP' && scrollPositionRef.current) {
        if(history.location.pathname.includes("browse/filter")){
            setTimeout(() => {
                document.body.scrollTop = scrollPositionRef.current;
                document.documentElement.scrollTop = scrollPositionRef.current;
            }, 200)
        }
    }
  }, [location, history]);
};

export default useScrollMemory;
