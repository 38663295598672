/* 

CustomListsLayout
    - Fetch the Custom Lists based on the pageId recieved from the props.location.
    - Show loading animation until pre-fetch is complete
    - map results and pass data to Slider component

    Example fetch result: 
        [{
            "header": "Top notch kids graphic novels",
            "description": " div class  ListHeadline  Top Notch Kids Graphic Novels  div    div class  ListDescription  Loved by our booksellers too   div ",
            "image": "https:\/\/bookmanager.com\/i\/mosaic\/pool_floates.png",
            "listId": 326998
        }, {
            "header": "Celebrating Black Lives",
            "description": " div class  ListHeadline  Celebrating Black Lives  div ",
            "listId": 312975
        }]

    Methods
        setLoading([bool]) // state
        setError([bool]) // state
        setResults([array]) // state

        displayLoading() 
        displayError() 
        displayEmpty()
        displayResults()
        displayStatus()

        - Display status fire



*/

// Resources
import React, { useState, useEffect } from "react";

import { Layout } from 'antd';
import { Link } from "react-router-dom";
// Components

import NewSlider from "../components/components/wrappers/NewSlider";

import Banner from "../components/components/wrappers/Banner";
import Vertical from "../components/components/wrappers/Vertical";
import { apiCall } from "../components/utilities/Api"
import Featured from "../components/components/wrappers/Featured";
import { BrowseIcon, FeaturesIcon, ArrowIcon } from "../util/Icons";
import { usePersistedState } from "../util/Persistant";
import EventFetch from "../components/components/events/EventFetch";
import conditional from "../components/utilities/conditional";

const { Sider, Content } = Layout;


function CustomListsLayout(props) {

    // state for loading status, error status and results array
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [results, setResults] = usePersistedState(props.location.id, []);
    const [sidebar, setSidebar] = usePersistedState(props.location.id.toString() + "sb", []);
    const [banners, setBanners] = usePersistedState(props.location.id.toString() + "bn", []);
    // const [events, setEvents] = usePersistedState(props.location.id.toString() + "ev", []);
    const { show_browse = false, show_features = false } = props;

    const showBrowseBtn = () => {
        if (!show_browse) { return }
        // check for home props
        return (
            <>
                <div className="featured_btn"><Link to="/browse/filter"><BrowseIcon className="featured_icon" /> <span className="featured_txt" style={{ "fontSize": "20px" }}> &nbsp; Browse &nbsp;</span> <ArrowIcon className="featured_icon" /></Link><span className="featured_border"></span></div>
                <div className="cap" />
            </>
        );
    }

    const showFeaturesBtn = () => {
        if (!show_features) { return }
        // check for home props
        return (
            <>
                <div className="featured_btn"><Link to="/features"><FeaturesIcon className="featured_icon" /> <span className="featured_txt" style={{ "fontSize": "20px" }}> &nbsp; Features &nbsp;</span> <ArrowIcon className="featured_icon" /></Link><span className="featured_border"></span></div>
                <div className="cap" />
            </>
        );
    }

    const onGetCustomList = (_status, _result) => {

        if (!_status) {
            setError(true);

        } else {
            let sb = _result.filter((item) => item.placement === "left_sidebar");
            let nsb = _result.filter((item) => item.placement !== "left_sidebar");
            _result = nsb.concat(sb);

            setBanners(_result.filter((item) => item.placement === "banner"));



            setResults(_result.filter((item) => item.placement !== "banner" && item.placement !== "left_sidebar"));
            // setEvents(_result.filter((item) => item.type === "event"));

            // setSidebar([]);
            setSidebar(_result.filter((item) => item.placement === "left_sidebar"));
        }
        setLoading(false);
    }

    const getCustomList = () => {
        if (results.length === 0) {
            apiCall("titlelist/getCustom", { "page_id": props.location.id }, onGetCustomList);
        }
    }

    useEffect(getCustomList, [])

    const displayLoading = () => {
        return (
            <div style={{ "minHeight": "calc(100vh - 380px)" }} className="container" ></div>
        )
    }

    const displayError = () => {
        return (<></>)
    }

    const displayEmpty = () => {
        return (<></>)
    }

    const displayBanners = () => {
        return banners.map((item, i) => {
            return (<Banner key={i} data={item} />);
        })
    }

    const displaySidebar = () => {
        return sidebar.map((item, index) => {

            let { type = "" } = item;

            if (type === "event") {
                return (<EventFetch sidebar event_id={item.event_id} />);
            }


            return (<div key={item.list_id}>
                <Vertical data={item} /></div>)
        })
    }

    // const displayEvents = () => {
    //     return events.map((item, i) => {
    //         return (<EventFetch event_id={item.event_id} />);
    //     })
    // }


    const displayResults = () => {


        return results.map((item, i) => {
            let { type = "" } = item;

            if (type === "event") {
                return (<EventFetch event_id={item.event_id} />);
            }

            if (item.placement === "featured") {
                return (<div key={item.list_id} style={{ padding: "30px 30px" }}><Featured key={item.list_id} data={item} /></div>)
            } else {

                return (<NewSlider sidebar={sidebar.length > 0} index={i} key={item.list_id} data={item} />)
            }
        })
    }

    const displayStatus = () => {

        // Check loading status
        if (loading) {
            return displayLoading();
        } else {
            // check for error
            if (error) { return displayError(); }
            else {
                // check if results are empty
                if (results.length < 1 && sidebar.length < 1 && banners.length < 1) {
                    return displayEmpty();
                }
                else {
                    if (sidebar.length > 0 || show_browse || show_features) {
                        return (
                            <div>
                                <div>
                                    {/* banners are just html */}
                                    {displayBanners()}
                                </div>
                                <div className="container_wrap" >
                                    <Layout className="softShadow" style={{ "backgroundColor": "#fff" }}>
                                        <Sider id="sidebarcontent" className="hide" style={{ "background": "#fff", "borderRight": "1px solid #eee" }}>
                                            {showBrowseBtn()}
                                            {showFeaturesBtn()}
                                            <div style={{ "backgroundColor": "#fff" }}>{displaySidebar()}</div>
                                        </Sider>

                                        <Content id="maintable">
                                            <div className="inside_container" style={{ "padding": "10px" }}>
                                                {/* {displayEvents()} */}
                                                {displayResults()}
                                            </div>

                                            <div id="sidebarcontent" className="show" style={{ "background": "#fff", "borderRight": "1px solid #eee" }}>
                                            {showBrowseBtn()}
                                            {showFeaturesBtn()}
                                            <div style={{ "backgroundColor": "#fff" }}>{displaySidebar()}</div>
                                        </div>

                                        </Content>
                                    </Layout>
                                </div>
                            </div>
                        )
                    }
                    return (<>
                        <div>{displayBanners()}</div>
                        <conditional.true value={(results.length !== 0)}>
                            <div className="inside_container" style={{ "padding": "10px" }}>
                                {/* {displayEvents()} */}
                                {displayResults()}
                            </div>
                        </conditional.true>
                    </>
                    );
                }
            }
        }
    }


    return (
        <div>
            {displayStatus()}
        </div>
    );

}

export default CustomListsLayout;