import React, { useState } from "react"
import { Pagination, Radio, Select, Space } from 'antd';
import PropTypes from "prop-types";
import conditional from "../components/utilities/conditional";
import { DashOutlined } from '@ant-design/icons';
import Cookies from "js-cookie";

/**
 * Page Navigation - UI and functions for page navigation
 */

PageNavigation.propTypes = {
  /** Total number of results */
  total: PropTypes.number.isRequired,
  /** Number of results per page */
  page_size: PropTypes.number.isRequired,
  /** Current page */
  current: PropTypes.number.isRequired,
  /** Called when page is changed (passes current page & page size) */
  onChange: PropTypes.func.isRequired,
}

export default function PageNavigation(props) {

  const { 
      showSizeChanger = false, 
      displayPicker = false, 
      rows = false, 
      updateRows = () => { }, 
      pageSize = 26,
      updatePageSize = () => {} 
  } = props;

  // const [pageSize, setPageSize] = useState(props.page)
  function displayNextPrev(current, type, originalElement) {
    if (type === 'prev') {
      return <div style={{ "color": "#069", "cursor": "pointer" }}>Previous</div>;
    }
    if (type === 'next') {
      return <div style={{ "color": "#069", "cursor": "pointer" }}>Next</div>;
    }
    return originalElement;
  }


  const changePageSize = (e) => {
    updatePageSize(e)
    props.onChange(props.current, e);
  }

  return (<>
    <div style={{ "float": "right" }}>
      <Space>
        <conditional.true value={(showSizeChanger)}>
          <Select style={{ "width": "100px" }} 
                  onChange={(e) => changePageSize(e)} 
                  value={pageSize} 
                  size="small">
            <Select.Option value={26}><small>26 / page</small></Select.Option>
            <Select.Option value={50}><small>50 / page</small></Select.Option>
            <Select.Option value={100}><small>100 / page</small></Select.Option>
            <Select.Option value={200}><small>200 / page</small></Select.Option>
          </Select>
          {/* <Pagination
            total={props.total}
            pageSize={pageSize}
            showSizeChanger={showSizeChanger}
            style={{ "textAlign": "center", "margin": "0 auto" }}
            current={props.current}
            size="small"
            onChange={(e) => changePageSize(e)} 
            showTotal={total => `Total ${total} results`}
            itemRender={displayNextPrev} 
  />*/}
          
        </conditional.true>

        <conditional.true value={(displayPicker)}>
          <Radio.Group onChange={(e) => updateRows(e.target.value)} value={rows} buttonStyle="solid" size="small">
            <Radio.Button value={"false"}>||</Radio.Button>
            <Radio.Button value={"true"}>|||</Radio.Button>
          </Radio.Group>
        </conditional.true>
      </Space>
    </div>

    <Pagination
      total={props.total}
      pageSize={pageSize}
      style={{ "textAlign": "center", "width": "250px", "margin": "0 auto" }}
      current={props.current}
      onChange={(e) => changePageSize(e)} 
      simple
      showTotal={total => `Total ${total} results`}
      itemRender={displayNextPrev}
    />
  </>);
}