import { message, Modal } from "antd";
import React from "react";
import { useEffect } from "react";
import { $ } from 'react-jquery-plugin';
import { useHistory } from "react-router-dom";
import { apiCall } from "../../utilities/Api";
import LoadingOverlay from "./LoadingOverlay";
import { useState } from "react";
export default function ClearentPaymentsForm(props) {

    const history = useHistory();
    const [loading, setLoading] = useState(false);


  


    const completeTransaction = (_response) => {

        let _obj = {
            provider: "clearent",
            transaction_data: JSON.stringify(_response)
        }

        setLoading(true);
       
        window.Clearent.modalClose();
        apiCall("checkout/cardPayment", _obj, (_status, _result) => {
            setLoading(false);
            if (_status) {
                let order_number = _result.order_number;
                history.push({ "pathname": "/account/order/" + order_number, "state": { "data": _result }, "search": "success" });
                
            } else {
                Modal.error({ title: "There was an error", content: <>{_result.error}</> })
            }
        })



        // _obj.method = "clearent";
        // _obj.token_multi_use = false;

        // switch (payload.payloadType) {

        //     case "transaction":

        //         // Check if card was saved
        //         let _token = links.find(item => item.rel === "token");
        //         if (_token) {
        //             // update multiuse + add token
        //             _obj.token_multi_use = true;
        //             _obj.token = _token.id;
        //             _obj.save_token = true; 
        //         }

        //         _obj.txn_id = payload.transaction.id;
        //         _obj.card_brand = payload.transaction["card-type"];
        //         _obj.card_last_four = payload.transaction["last-four"];
        //         _obj.card_expiry = payload.transaction["exp-date"];
        //         break;

        //     case "token":
        //         _obj.token_multi_use = true;
        //         _obj.token = payload.tokenResponse["token-id"];
        //         _obj.card_brand = payload.tokenResponse["card-type"];
        //         _obj.card_last_four = payload.tokenResponse["last-four-digits"];
        //         _obj.card_expiry = payload.tokenResponse["exp-date"];
        //         _obj.card_description = payload.tokenResponse.description;
        //         //_obj.postal_code = payload.tokenResponse["avs-zip"];

        //         break;

        //     default:
        //         break;

        // }

        // if (payload.payloadType) {
        //     // submit
        //     apiCall("checkout/cardPayment", _obj, (_status, _result) => {
        //         if (_status) {
        //             let order_number = _result.order_number;
        //             history.push({ "pathname": "/account/order/" + order_number, "state": { "data": _result }, "search": "success" });
        //         } else {
        //             message.error(_result.error)
        //         }
        //     })
        // }

    }

    const { amount } = props;

    useEffect(() => {

        // Using Jquery to load the clearent script - !NOTE the script is also loaded in index.html to make the form styles behave properly
        $.getScript("https://" + window.clearent_host + "/js/clearent.js", () => {

            let cl = window.Clearent;

            // Public Key
            cl.setProperty("pk", window.sitesettings.checkout_info.clearent_pk);
            // HTML Element ID (where the button gets drawn)
            cl.setProperty("pay-button-parent", "clearent_button");

            // If capture setting, collect payment right away - otherwise collect the token to be processed by Bookmanager later
            if (window.sitesettings.checkout_info.clearent_should_capture) {
                // cl.setProperty("request-type", "SALE");
                cl.setProperty("amount", amount);
                // Show save card option (This will return token as well)
                cl.setProperty("show-save-card-option", true);
            } else {
                // cl.setProperty("request-type", "TOKEN-ONLY");

                cl.setProperty("card-description-placeholder", "Cardholder name");
                cl.setProperty("card-description-tooltip", "Enter the name on this card");
                cl.setProperty("add-payment-button-text", "Pay Now");
                cl.setProperty("submit-pay-method-button-text", "Pay $" + amount + " Now");
                cl.setProperty("card-token-only", true);
            }

            // Run
            cl.payButton();

            window.ClearentOnSuccess = (responseRaw, responseJSON) => {
                console.log("transaction succeeded");
                completeTransaction(responseJSON);
            }

            window.ClearentOnError = (responseRaw, responseJSON) => {
                console.log("transaction failed");
                completeTransaction(responseJSON);
            }

        });

        return () => {
            // this doesn't work.
            window.Clearent.modalClose();
        };


    }, [])

    return (
        <>
            <LoadingOverlay loading={loading} />
            <div id="clearent_button"></div>
        </>
    )

}