import React, { useState } from "react";
import { Collapse, Checkbox, Tree } from 'antd';

export default function FeaturesFilters(props) {

    const { filters = [], searchFilters = {}, setSearchFilters } = props;

    const calculateOpened = () => {
        let ret = [];
        if (searchFilters.f.length > 0) {
            ret = searchFilters.c.slice(0, 1).concat(searchFilters.f);
        } else {
            ret = searchFilters.c.slice(0, 1);
        }
        return ret;
    }


    const [opened, setOpened] = useState(calculateOpened());
    const [checked, setChecked] = useState([]);



    const setTopLevelOpen = (v, _filter) => {
        if (v.target.checked) {
            setOpened([_filter])
            setChecked([...checked, _filter])
            setSearchFilters({ ...searchFilters, "o": 0, "c": [...searchFilters["c"], _filter], "f": [] });
        }
        else {
            setOpened([]);
            setChecked(checked.filter((item => item !== _filter)));
            // clear top level filter + all children
            setSearchFilters({ ...searchFilters, "o": 0, "c": [...searchFilters["c"].filter((item => item !== _filter))], "f": [] });
        }
    }

    const setTopOpen = (_filter) => {

        if (!checked.includes(_filter)) {
            setOpened([_filter]);
            setChecked([...checked, _filter])
            setSearchFilters({ ...searchFilters, "o": 0, "c": [...searchFilters["c"], _filter], "f": [] });
        } else {
            setOpened([]);
            setChecked(checked.filter((item => item !== _filter)));
            setSearchFilters({ ...searchFilters, "o": 0, "c": [...searchFilters["c"].filter((item => item !== _filter))], "f": [] });
        }
    }

    const setBottomOpen = (_filter, _parent) =>{

        if (!searchFilters["f"].includes(_filter)) {
            setSearchFilters({ ...searchFilters, "f": [...searchFilters["f"], _filter], "c": [_parent] });
        } else {
            setSearchFilters({ ...searchFilters, "f": searchFilters["f"].filter(item => item !== _filter) });
        }
    }


    const setSubLevelOpen = (e, _filter, _parent) => {

        setOpened([_parent]);
        setChecked([_parent]);

        if (e.target.checked) {
            setSearchFilters({ ...searchFilters, "f": [...searchFilters["f"], _filter], "c": [_parent] });
        }
        else {
            setSearchFilters({ ...searchFilters, "f": searchFilters["f"].filter(item => item !== _filter) });
        }

    }

    const displayTree = () => {

        return filters.map((_filter, _index) => {

            return ({
                "title": <span onClick={() => setTopOpen(_filter.k)} style={{ "width": "240px", "display": "inline-block" }}>{_filter.e} <span className="count">({_filter.c})</span></span>,
                "key": _filter.k,
                "switcherIcon": <></>,
                "icon": <Checkbox checked={(searchFilters["c"].includes(_filter.k))} onChange={(v) => setTopLevelOpen(v, _filter.k)} />,
                // "icon" : (_filter.subfilter.length===0) ? <Checkbox onChange={(v) => setOpened((v.target.checked) ? [_filter.k] : [])} /> : <></>,
                "children": _filter.subfilter.map((_sub, _i) => {
                    return ({
                        "title": <span onClick={() => setBottomOpen(_sub.k, _filter.e)} style={{ "fontSize": "13px", "width": "215px", "display": "inline-block" }}>{_sub.e} <span className="count">({_sub.c})</span></span>,
                        "key": _filter.k + _sub.k,
                        "icon": <Checkbox checked={searchFilters["f"].includes(_sub.k)} onChange={(e) => setSubLevelOpen(e, _sub.k, _filter.e)} />,
                        "checkable": true,
                        "children": []
                    })
                })
            })

        })

    }


    const displayFilters = () => {


        return (<>
            <div className="shim" />
            <div className="shim" />
            <div className="shim" />
            <Collapse
                ghost
                expandIconPosition="right"
                bordered={true}
                defaultActiveKey={"c"}
            >
                <Collapse.Panel key="c" header="Featured Lists">
                    <Tree expandedKeys={opened} showIcon selectable={false} treeData={displayTree()} />
                </Collapse.Panel>
            </Collapse>
        </>
        )





    }





    return displayFilters();






}