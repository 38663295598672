import React, { useEffect, useState } from "react";

import { Calendar, Button, Row, Col, Divider } from 'antd';
import {ArrowIcon} from "../../../util/Icons"
import {apiCall} from "../../utilities/Api";
import moment from 'moment';
import 'moment-timezone';
import Event from "../events/Event"
import {Link } from "react-router-dom";

export default function EventsCalandar(props){
 // .tz('America/Los_Angeles')
    const [date, setDate] = useState(moment().tz('America/Los_Angeles'));
    const [results, setResults] = useState({});
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);

    const onGetEventsList = (_status, _result) =>{
        if(!_status){
            setError(true);
        }
        setResults(_result);
        setLoading(false);
    }

    const getEventsList = () =>{
        let from = moment().tz('America/Los_Angeles').subtract(1, 'months').format('YYYYMMDD');

        let obj = {
            from : from
        }
        obj.increment_views = true
        apiCall("event/getList",obj, onGetEventsList)
    }

    useEffect(getEventsList,[])

    const displayError = () =>{
        return (<></>);
    }

    const displayLoading = () =>{
        return (<></>);
    }

    const displayList = () =>{
        // return results.rows.filter(e => (e.to*1000) > Date.now() ).slice(0,3).map((event, index) => {
        //     return (<Event.SummaryItem key={index} data = {event} />)
        // })

        return results.rows.slice(0,3).map((event, index) => {
            return (<Event.SummaryItem key={index} data = {event} />)
        })
    }

    const displayCalandar = () =>{
        return(<>
            <Row gutter={20}>
                <Col xs={24} lg={10}><Calendar
                className="oldcal"
                value={date}
                monthCellRender={clear}
                headerRender = {headerRender}
                dateFullCellRender = {dateCellRender}
                />
                <br />
                <div aria-hidden><Divider className="show-block" /></div>
                </Col>
                <Col xs={24} lg={14}>
                    {displayList()}
                    {(results && results.rows.length>0 && <Link to="/events"><Button block type="text">View all events</Button></Link>)}
                </Col>
            </Row>
            </>
        )
    }

    const displaySidebar = () =>{
        return (<>
                {results.rows.filter(e => (e.to*1000) > Date.now() ).slice(0,3).map((event, index) => {
                    return (<Event.SidebarItem key={index} data = {event} />)
                })}
                  {(results && results.rows.length>0 && <Link to="/events"><Button block style={{"textAlign" : "center"}} type="text">View all events</Button></Link>)}
            </>);
    }

    const displayStatus = () =>{

        if(error) {return displayError()}
        if(loading) {return displayLoading()}

        if(props.sidebar){
            return displaySidebar(); 
        } else {
            return displayCalandar(); 
        }
    }
    


    function dateCellRender(value) {

        // rows filter 
        let _dayEvents = results.rows.filter((item)=>{
            return (moment(item.from*1000).tz('America/Los_Angeles').format("YYYYMMDD") === value.format("YYYYMMDD"));
        })
       
        if(!_dayEvents.length){
            return (
                <div className="eventCell">
                    <small className="caldate">{value.format("D")}</small>
                </div>
            );
        } else {
            return (
                <Link to={"/events/" + value.format("YYYYMMDD")}><div className="eventCellActive">
                    <small className="caldate">{value.format("D")}</small>
                    <div className="cluster">
                        {_dayEvents.slice(0,3).map((dot,i) =>{
                            return  (<span key={i} className="dot" />);
                        })}
                        {(_dayEvents.length>3 && <small>+</small>)}
                    </div>
                </div>
                </Link>
            );
        }
        

      
      }



    const prev = () =>{
        setDate(moment(date.subtract(1,"month")).tz('America/Los_Angeles'));
    }

    const next = () =>{
        setDate(moment(date.add(1,"month")).tz('America/Los_Angeles'));
    }

    const headerRender = (props) =>{

        const {value} = props; 
        return(
            <>
                <div className="calHeader">
                    <Row>
                        <Col flex="20px"><Button onClick={() => prev()} type="text" icon={<div aria-hidden className="flipH"><ArrowIcon /></div>} /></Col>
                        <Col flex="auto"><h2>{value.format("MMMM")}</h2></Col>
                        <Col flex="20px"><Button onClick={() => next()} type="text"  icon={<ArrowIcon aria-hidden />} /></Col>
                    </Row>
                </div>
            </>
        )
    }

    const clear = () =>{

        return(<></>)
    }

    return displayStatus(); 

  
}