import React from "react";
import Html from "../../utilities/Html";
import EventsCalandar from "../events/EventsCalandar";

export default function Banner(props){

    const {data} = props; 

    
    const parseCalendar = () =>{
        let chunks = data.description.split("<!--Calendar-->"); 
        return chunks.map((_item, ind) =>{
            if(ind === chunks.length-1){
                return (<Html key={ind} html={_item} />);
            } else {
                
                return (<div key={ind}><Html html={_item} /><span><EventsCalandar /></span></div>)
            }
        });
    }
    

    const display = () =>{

        if(data.calendar_widgets.length>0){
            return (<div style={{"maxWidth" : (window.sitesettings.styles.fullscreen_banner) ? "100%" : "1248px"}} className="site-banner">{parseCalendar()}</div>);
        }
        
        return(<div style={{"maxWidth" : (window.sitesettings.styles.fullscreen_banner) ? "100%" : "1248px"}} className="site-banner"><Html html={data.description} /></div>)
    }
    
    return display(); 

    
}