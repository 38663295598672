import { Tooltip } from "antd";
import React from "react";
import conditional from "../../utilities/conditional";

export default function SalesIndicator(props) {

    const { rank } = props;
    const futureColor = "#1581E9";
    const bestsellerColor = "#e70d3d";
    
    if (!rank) { return "" }

    if (rank.hasOwnProperty('bestseller')) {

        let r = 5;
        if (rank.bestseller <= 1000) { r = 1 } else if (rank.bestseller <= 10000) { r = 2 } else if (rank.bestseller <= 30000) { r = 3 } else if (rank.bestseller <= 100000) { r = 4 }

        return (
            <>
                <conditional.true value={(r === 1)}>
                    <span style={{ "color": bestsellerColor }}>{"#" + rank.bestseller + " in bestsellers"}</span>
                </conditional.true>
                <conditional.true value={(r !== 1)}>
                    <span style={{ "color": bestsellerColor }}>{"Sales demand:"}</span>
                </conditional.true>
                <div className="temp"><Tooltip title={"#" + rank.bestseller + " in bestsellers"}><img alt={"#" + rank.bestseller + " in bestsellers"} style={{"marginBottom" : "5px"}} src={"/temp/"+r.toString()+".png"} /></Tooltip></div>

            </>
        );
    }

    if (rank.hasOwnProperty('future')) {


        let r = 5;
        if (rank.future <= 100) { r = 1 } else if (rank.future <= 1000) { r = 2 } else if (rank.future <= 3000) { r = 3 } else if (rank.future <= 10000) { r = 4 }

        return (
            <>
                <conditional.true value={(r === 1)}>
                    <span style={{ "color": futureColor }}>{"#" + rank.future + " in future releases"}</span>
                </conditional.true>
                <conditional.true value={(r !== 1)}>
                    <span style={{ "color": futureColor }}>{"Forthcoming demand:"}</span>
                </conditional.true>
                <div className="temp"><Tooltip title={"#" + rank.future + " in future releases"}><img style={{"marginBottom" : "5px"}} src={"/temp/"+r.toString()+".png"} /></Tooltip></div>

            </>
        );


      
    }
    return "";
}