import React,{useEffect, useState} from "react";
import {apiCall} from "../../utilities/Api";
import Event from "./Event";
export default function EventFetch(props){

    let {event_id} = props; 
    const [results, setResults] = useState({});
    const [loading, setLoading] = useState(true);
    const onGetEvent = (_status, _result) =>{

        if(_status){
            setResults(_result);
            setLoading(false);
        }

    }

    const getEvent = () =>{
       
        apiCall("event/get", {event_id: event_id}, onGetEvent)
    }

    useEffect(getEvent, []);

    if(loading){
        return(<></>)
    } else {

        if(props.sidebar){
            return(<Event.SidebarItem data={results} /> )
        }
        return(<Event.ListItem data={results} /> )
    }

}