

import { Input } from "antd";
import React from "react";

export default function PostalCodeFormatter(props){

    
    const setValue = (_v) =>{
        if(props.form){
            props.form.setFieldsValue({"postal_code" : _v})
        }

        return(_v);

    }
    const formatPostalCode = (_value, _country = "") =>{

      

        if(!_value){
            return(_value);
        }

       

        if(_country === "Canada"){
           
            let can_value = _value.replace(/[^a-zA-Z0-9]/g, '');
            if(can_value.length > 3){

              
                return(setValue((can_value.substr(0,3) + " " + can_value.substr(3,3)).toUpperCase()))
            }

           
            return(setValue(can_value.toUpperCase()));
           
        } 

        if(_country === "United States"){

            let us_value = _value.replace(/[^\d]/g, '');
            if(us_value.length > 5){
                return(setValue((us_value.substr(0,5) + "-" + us_value.substr(5,4)).toUpperCase()))
            }
            
            return(setValue(us_value));
        }

        return(_value);
        
    }
    



    if(props.display){
        return formatPostalCode(props.value, props.country)
    } else {
        return(<Input aria-label="Postal Code" {...props} placeholder="Postal code" value={formatPostalCode(props.value, props.country)} maxLength={10} />); 
    }

   




}