import React, { useEffect, useState } from "react";
import AccountForm from "./AccountForm";
import { apiCall } from "../../utilities/Api";
import { Form, Input, Button, Divider, Checkbox, Spin } from 'antd';
import { useSession } from "../../../util/Session";
import { useHistory } from "react-router-dom";
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useForm } from "antd/lib/form/Form";


/**
 * Create account
 *  - Fill in contact details
 *  - Add password confirm
 *  - Validate form 
 *  - Auto login and update context
 *  - Submit, and move to account created message. 
 *
 * @component
 * @property {object}  defaults               - The default values for parties.
 * @link /AccountForm
 * @param {string} [user] Optionally pre-fill fields with user data
 * 
 * @mermaid
 *   graph TD;
 *     A[Fill in contact details] -->
 *     B{Validate} -->
 *     B-->D;
 *     C-->D;
 */
function AccountCreate() {


    // get email address from sign-up
    // submit

    /** @var {context} */
    const [session, setSession] = useSession();
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState();

    /**
    * API Call to pass the form data and create the account
    */
    const history = useHistory();
    const { email_address } = session.user;

    /**
    * API Call to pass the form data and create the account
    * @param {boolean} _status
    * @param {object} _result session object or error object
    */



    const [loading, setLoading] = useState(true);



    const [defaultLocation, setDefaultLocation] = useState({
        country: "",
        province: ""
    });

    const getDefaultLocation = () => {

        if (!session.user.hasOwnProperty("email_address")) {
            // refreshed so redirect
            history.push("/account/signup")
        }

        let _country = "";
        let _province = window.sitesettings.store_info.province;

        switch (window.sitesettings.store_info.iso3166_2_country) {
            case "CA":
                _country = "Canada"
                break;
            case "US":
                _country = "United States"
                break;
            case "MX":
                _country = "Mexico"
                break;
            default:
                _country = ""
                break;
        }
       

        if (_country) {
            apiCall("province/get", { country: _country, province: _province }, (_status, _result) => {
                if (_status) {
                    setDefaultLocation({ country: _country, province: _result.province })
                }
                setLoading(false);
            })
        } else {
            setLoading(false);
        }

    }

    useEffect(getDefaultLocation, []);



    const onCreateAccount = (_status, _result) => {

        if (_status) {
            // set session
            if (history.location.hasOwnProperty("state") && history.location.state.hasOwnProperty("validation_required") && history.location.state.validation_required) {
                history.push({ "pathname": "/account/verify", "state": _result });
            } else {
                setSession(_result);

                if (_result.cart.hasOwnProperty("items") && _result.cart.items.length > 0) {
                    history.push({ "pathname": "/checkout/cart" });

                } else {
                    history.push({ "pathname": "/account/finished" });
                }

            }

        } else {
            setError(true);
            setErrorMsg(_result.error);
        }
    }

    /**
    * API Call to pass the form data and create the account
    * @param {object} form
    */
    const createAccount = (_form) => {


        if (_form["confirm"]) {
            delete _form["confirm"];
        }

        if (_form["aext"]) {
            _form["alt_phone_number_ext"] = _form["aext"];
            delete _form["aext"];
        }

        if (window.sitesettings.account_extra_fields.length > 0) {
            let obj = {};
            for (let i = 0; i < window.sitesettings.account_extra_fields.length; i++) {
                obj[window.sitesettings.account_extra_fields[i]] = _form[window.sitesettings.account_extra_fields[i]];
                delete _form[window.sitesettings.account_extra_fields[i]];
            }
            _form["account_extra_fields"] = JSON.stringify(obj);
        }


        if (history.location.hasOwnProperty("state")) {
            if (history.location.state.hasOwnProperty("token")) {
                if (history.location.state.token) {
                    delete _form["email_address"];
                    _form["token"] = history.location.state.token;
                }
            }
        }

        apiCall("account/create", _form, onCreateAccount);
    }



    /**
    * Augments form with email address from signup. 
    * - If there is no email in the session, return to signup
    * - Email is hidden as it's a requirement for the API, but since it's already valid - we don't want the user to edit
    * @returns {jsx} email address from signup.
    */
    const displayHiddenEmail = () => {


        if (!history) {
            if (!email_address && !history.location.state.token) {
                history.push({ "pathname": "/account/signup" });
            }
        }



        return (
            <Form.Item label="Email address" name="email_address" initialValue={email_address}  >
                <Input aria-label="Email Address" disabled autoComplete="off" />
            </Form.Item>
        );


    }

    /**
    * Augments form with password / confirm form fields 
    *  - Validates 8 character minimum
    *  - Validates the Password / Confirm form fields match
    * @returns {jsx} password and confirm form fields
    */
    const displayConfirmPassword = () => {

        return (
            <>
                <Form.Item
                    name="password"
                    label="Password"
                    rules={[{
                        required: true,
                        min: 8,
                        message: 'Password must be at least 8 characters.',
                    },]}
                    hasFeedback
                >
                    <Input.Password aria-label="Password" placeholder="Password" />
                </Form.Item>

                <Form.Item
                    name="confirm"
                    label="Confirm password"
                    dependencies={['password']}
                    hasFeedback
                    rules={[{
                        required: true,
                        message: 'Please confirm your password.',
                    }, ({ getFieldValue }) => ({
                        validator(rule, value) {

                            if (value.length < 8) {
                                return Promise.reject('Password must be at least 8 characters.');
                            }

                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject('The two passwords that you entered do not match.');
                        },
                    }),]}
                >
                    <Input.Password aria-label="Confirm Password" placeholder="Confirm password" min={8} max={12} />
                </Form.Item>
            </>
        )

    }


    const drawExtra = () => {


        if (window.sitesettings.account_extra_fields.length > 0) {
            return window.sitesettings.account_extra_fields.map((item => {
                return (
                    <Form.Item rules={[{
                        required: true,
                        message: 'Required',
                    },]}
                        key={item} name={item} label={item}>
                        <Input maxLength={50} />
                    </Form.Item>
                )
            }))
        } else {
            return (<></>)
        }

    }

    const [createForm] = useForm();


    const AcceptTerms = (props) => {

        const [showWarning, setShowWarning] = useState(window.sitesettings.checkout_info.terms_consent_enabled)

        if (window.sitesettings.checkout_info.terms_consent_enabled) {
            return (
                <div className="float-flex">
                    <div><Checkbox onChange={(e) => setShowWarning(!e.target.checked)}><p style={{ "fontSize": "13px" }}><nobr>I accept the <a href="/terms-conditions" target="_blank" title="Terms & Conditions" style={{ "color": "#069" }}>Terms & Conditions</a>.</nobr></p></Checkbox></div>
                    <div>
                        <Spin spinning={showWarning} indicator={<></>}>
                            {props.children}
                        </Spin>
                    </div>
                </div>
            )
        } else {
            return props.children;
        }

    }


    const display = () => {




        return (
            <>

                <div>
                    <h2>Account Details</h2>
                    <div aria-hidden><Divider /></div>
                    <Form form={createForm} validateTrigger={['onChange', 'onBlur']} onFinish={(_form) => createAccount(_form)} layout="vertical">
                        {displayHiddenEmail()}

                        {displayConfirmPassword()}

                        <div aria-hidden><Divider /></div>
                        {<AccountForm user={{ country: defaultLocation.country }} create form={createForm} />}

                        {drawExtra()}
                        {(error && <> <div aria-hidden><Divider /></div><p style={{ "paddingBottom": "15px", "color": "#ff4d4f", "textAlign": "center" }} className="error"><ExclamationCircleFilled /> {errorMsg}</p></>)}


                        <div aria-hidden><Divider /></div>
                        <AcceptTerms>
                            <Button type="primary" htmlType="submit">Create Account</Button>
                        </AcceptTerms>
                    </Form>
                </div>
            </>
        )

    }

    /**
    * Displays the jsx for the form
    * @returns {jsx} jsx for the form
    */

    if (loading) {
        return (<></>)
    }
    return display();


}

export default AccountCreate;