/*
 LoginBox 
  - 
 Props
  -
*/

// Resources
import React, { useState } from "react";
import { Popover, Button, Drawer, Modal } from 'antd';
import { UserOutlined} from '@ant-design/icons';

// Components
import AccountLoginForm from "./components/account/AccountLoginForm";


function LoginBox(props){

    const [popOverVisible, setPopOverVisible] = useState(false);
    
    const dismiss = () =>{
      setPopOverVisible(false);
    }

    const handlePopOverVisibleChange = (visible) =>{
        setPopOverVisible(visible);
    }

    // Mobile Drawer 
    const [visible, setVisible] = useState(false);

    const showDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };

    return(
        <div>
            <Popover open={popOverVisible}  onOpenChange={handlePopOverVisibleChange} title="Login" overlayClassName="fixed" className="hide" trigger="click" content={<AccountLoginForm dismiss={dismiss} />} placement="bottomRight" >
                <Button className="styled_btn" type="primary" size={props.size}><UserOutlined aria-hidden /> Login</Button>
            </Popover>
            <div className="show"><UserOutlined className="icon-btn bm-icon" onClick={showDrawer} />
                <Modal
                    placement="bottom"
                    title="Login"
                    height="450px"
                    // mask={false}
                    // style={{"backgroundColor" : "#fff"}}
                    onCancel={onClose}
                    open={visible}
                >
                    <div style={{"backgroundColor" : "#fff", "padding" : "20px"}}>
                    <AccountLoginForm dismiss={onClose} />
                    </div>
                </Modal>
            </div>
        </div>
  );
}

export default LoginBox; 