import React, { useState } from "react";
import { Tooltip, Badge } from "antd";
import { apiCall } from "../../utilities/Api";
import { GiftOutlined } from '@ant-design/icons';

export default function GiftRegistryBadge(props) {


    const { gift_registry_id } = props;
    const [title, setTitle] = useState("");

    let asked = false;
    const fetch = () => {
        if (!asked) {
            asked = true;
            apiCall("giftregistry/get", { gift_registry_id: gift_registry_id }, (_status, _result) => {
                if (_status) {
                    setTitle(_result.title);
                }
            })
        }
    }

    if (!gift_registry_id) {
        return <>{props.children}</>
    }

  

    return (
        <>
            <div onMouseOver={() => fetch()}>
                <Badge.Ribbon text={<Tooltip title={title}><div><small><GiftOutlined /></small></div></Tooltip>} color="volcano" placement="start" >
                    {props.children}
                </Badge.Ribbon>
            </div >
        </>
    )

}