import React, { useState } from "react";
import { Table, Modal, Space, Button } from 'antd';

import { useHistory } from "react-router-dom";
import moment from "moment";
import { parceAuthors, parseRank } from "../../util/Utils";
import IncrementFormatter from "../formatters/IncrementFormatter";
import { apiCall } from "../utilities/Api"
import { useSession } from "../../util/Session";
import { existsInCart } from "../../util/Utils";
export default function BrowseListView(props) {

    const { data } = props;

    const [values, setValues] = useState({});
    const [session, setSession] = useSession();

    const [checkCart, setCheckCart] = useState(false);
    // 
    const history = useHistory();

    const drawCount = (_arr) => {
        return _arr.map((item, index) => {
            let messageColor = (item.row_price > 0 && (item.onhand > 0 || item.onhand === "Y")) ? "#3C8617" : "#BD5800 ";
            return (<div style={{"height" : "32px" }}><span style={{"color": messageColor}}>{item.message}</span> {(item.text && <small>({item.text})</small>)}<div className="shim" /></div>)
        })
    }

    const drawPrice = (_arr) => {
        return _arr.map((item, index) => {
            return (<div style={{"height" : "32px"}}>${item.row_price}<div className="shim" /></div>)
        })
    }

    const showConfirm = (_eisbn, _item, _quantity, _onhand) => {
        let m = Modal.warn({
            icon: null,
            centered: true,
            title: "Add to cart",
            width: "auto",
            style: { "padding": "0px" },
            content: <>
                <p>We only have <strong>{_item.onhand}</strong> in stock</p>
                <Space direction="vertical">
                    <Button onClick={() => returnItem(true)}>I'll take the {_item.onhand}</Button>
                    {/* <conditional.true value={context.logged_in}> */}
                    {(_item.is_extra_orderable && <Button onClick={() => returnItem(false)}>I'll take the {_item.onhand} but want the rest ordered</Button>)}
                    {/* </conditional.true> */}
                </Space>
            </>,
            okText: "Cancel",
        });
        const returnItem = (_onlyonhand) => {
            m.destroy();
            let found = existsInCart(session, _eisbn, _item.code);
            addToItemToCart(_eisbn, _item, (_onlyonhand) ? _item.onhand : _quantity, found, true)
        }
    }


    const onAddToItemToCart = (_status, _result) => {

    
        if (_status) {
            setSession({ ...session, "cart": _result })
            setCheckCart(true);
            // transition();
        } else { }
    }


    const onRemoveFromCart = (_status, _result) => {
        if (_status) {
            // update context
            setSession({ ...session, "cart": _result })
        } else {
            // error
        }
    }

    const addToItemToCart = (_eisbn, _item, _quantity, found = false, force = false) => {

        setValues({ ...values, [_eisbn + _item.code]: _quantity });

        let _code = _item.code;


        if (!_quantity) {
            apiCall("cart/removeItem", { cart_id: found.cart_id }, onRemoveFromCart);
            return _quantity; 
        }



        if (!force && (parseInt(_item.onhand) > 0) && (parseInt(_quantity) > parseInt(_item.onhand))) {
            showConfirm(_eisbn, _item, _quantity, _item.onhand);
        
            return;
        }

        

      
        apiCall("cart/add", { eisbn: _eisbn, condition: _code, quantity: _quantity }, onAddToItemToCart);

        return _quantity; 
    }


    const drawAddToCart = (_itm) => {
        // defaultValue={item.cart_info.quantity}

        return _itm.available.map((item, index) => {

            let found = existsInCart(session, _itm.eisbn, item.code);
            let defaultValue = (found) ? found.count : 0;

            if(defaultValue){
                return (
                    <><IncrementFormatter checkCart={checkCart} setCheckCart={setCheckCart} return_value onChange={(_quantity) => addToItemToCart(_itm.eisbn, item, _quantity, found)} defaultValue={(values[_itm.eisbn + item.code] ? values[_itm.eisbn + item.code] : defaultValue)} min={0} />
                {((index+1) !== _itm.available.length && <div className="shim" />)}
                </>)
            } else {
                return <><Button onClick={() => addToItemToCart(_itm.eisbn, item, 1, found)} type="primary">Add to cart</Button><div className="shim" /></>;
            }

          
        })



    }


    const columns = [
        {
            title: <small></small>, width: '60px', dataIndex: 'eisbn', key: 'eisbn', render: (e, f) => {
                return (<div style={{ "cursor": "pointer" }} onClick={() => history.push({ pathname: "/item/" + e, data: f })}><img alt={f.title} onError={(i) => { i.target.src = "https://cdn1.bookmanager.com/i/nocover.png"; i.target.className = "light"; }} className="shadow-small" src={"https://cdn1.bookmanager.com/i/m?b=" + e + "&cb=" + f.cover_image_cache} /></div>)
            }
        },
       
        {
            title: <small>Title</small>, className: "single_line w150", dataIndex: 'title', key: 'title', render: (e, f) => {
                return <div style={{"overflow" : "hidden", "width" : "150px", "textOverflow" : "ellipsis"}} onClick={() => history.push({ pathname: "/item/" + f.eisbn, data: f })}><a>{e}</a><br /><small>{parceAuthors(f.authors)}</small></div>
            }
        },

        // {
        //     title: <small>Author</small>, className: "single_line", width: "100px", dataIndex: 'author', key: 'author', render: (e, f) => {
        //         return parceAuthors(f.authors);
        //     }
        // },
        { title: <small>Series</small>, width : "100px", dataIndex: 'series', key: 'series', render : (e, f) =>{
            return <div style={{"lineHeight" : "15px"}}><small>{e} {(f.series_num && "#" + f.series_num.toString())}</small></div>
        } },

        {
            title: <small></small>, dataIndex: 'binding_code', key: 'binding_code'
        },
        // { title: <small>#</small>, width : "20px", dataIndex: 'series_num', key: 'series_num' },
        // { title: <small>Publisher</small>, className : "single_line", dataIndex: 'publisher', key: 'publisher' },
        {
            title: <small>PubDate</small>, dataIndex: 'release_date', key: 'release_date', render: (e) => {
                return moment(e * 1000).format('MMM DD, YYYY')
            }
        },
        {
            title: <small>#</small>, dataIndex: 'rank', key: 'rank', render: (e) => {
                return parseRank(e, false);
            }
        },
        {
            title: <small>Status</small>, width : "140px", dataIndex: 'status', key: 'status', render: (e, f) => {
                return <><div className="shim" style={{"height" : "8px"}}></div>{drawCount(f.available)}</>
            }
        },
        {
            title: <small>Price</small>, dataIndex: 'price', key: 'price', render: (e, f) => {
                return  <><div className="shim" style={{"height" : "8px"}}></div>{drawPrice(f.available)}</>;
            }
        },

        {
            title: <small>Cart</small>, width : "110px", dataIndex: 'rank', key: 'rank', render: (e, f) => {
                return drawAddToCart(f);
            }
        },
    ]


    const addKeys = (_arr) =>{

        return _arr.map(item =>{
            item.key = item.eisbn; 
            return item;
        })
    }
    const dataSource = addKeys(data.rows);




    return (
        <>
            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
            <Table
               
                className={"resultsTable"}
                scroll={{ x: 868 }}
                pagination={false}
                dataSource={dataSource}
                columns={columns}
            />
        </>

    )
}