import { Button, Divider, Form, Modal, Select, Space, Input } from "antd";
import React, { useState } from "react";
import { apiCall, apiDownload } from "../../utilities/Api";
import { FilePdfOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";

export default function ExportPDF(props) {

    const { list_id = "" } = props;
    const [modalVisible, setModalVisible] = useState(false);
    const [options, setOptions] = useState([]);

    const history = useHistory(); 
    const getPdf = (_f) => {

       apiCall("titlelist/getPdf", _f, (_status, _result) => {
            if(_status){
                window.open("/pdf/" + _result.progress_report_id);
                setModalVisible(false);
            }
       })

        //apiDownload(_f, "titlelist/getPdf");
    }

    const onGetPdfOptions = (_status, _result) => {
        if (_status) {
            setOptions(_result);
            setModalVisible(true);
        }
    }

    const getPdfOptions = () => {
        apiCall("titlelist/getPdfOptions", {}, onGetPdfOptions);
    }

    const drawOptions = () => {
        let initial_value = "";
        if (options.length > 0) {
            initial_value = options[0].layout;
        }
        return (
            <>
                <h3>Create PDF</h3>
                <div aria-hidden><Divider /></div>
                <Form onFinish={(_f) => getPdf(_f)} layout="vertical">
                    <Form.Item noStyle initialValue={list_id} name="list_id">
                        <Input type="hidden"></Input>
                    </Form.Item>
                    <Form.Item initialValue={initial_value} label="Layout Option:" name="layout">
                        <Select>
                            {options.map((item, index) => {

                                return (
                                    <Select.Option key={index} value={item.layout}>
                                        {item.options.display}
                                    </Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <div className="shim"></div>   <div className="shim"></div>
                    <div className="shim"></div> <div className="shim"></div>
                    <div style={{ "float": "right" }}>
                        <Space>
                            <Button onClick={() => setModalVisible(false)}>Cancel</Button>
                            <Button type="primary" htmlType="submit">Export</Button>
                        </Space>
                    </div>
                    <br clear="all" />
                </Form>
            </>
        );
    }

    return (
        <>
            <Modal onCancel={() => setModalVisible(false)} destroyOnClose open={modalVisible} centered={true} width={300} footer={null} >
                {drawOptions()}
            </Modal>
            <Button type="text" icon={<FilePdfOutlined aria-hidden />} onClick={() => getPdfOptions()}></Button>
        </>
    )
}