import React, { useState } from "react";
import { Button, Modal, Divider, Space, message } from 'antd';
import ListForm from "../lists/ListForm";
import Form from "antd/lib/form/Form";
import { apiCall } from "../../utilities/Api";
import conditional from "../../utilities/conditional";

export default function AddEditList(props) {

    const { list = {}, edit = false, getLists } = props;
    const [modalVisible, setModalVisible] = useState(false)
    const [errorMsg, setErrorMsg] = useState("");

    const onCreateList = (_status, _result) => {

        if (_status) {
            setModalVisible(false);
            getLists();
            message.success('List created successfully.');
        } else {
            setErrorMsg(_result.error);
        }
    }

    const createList = (_form) => {
        setErrorMsg("");
        apiCall("titlelist/create", _form, onCreateList)
    }

    const onModifyList = (_status, _result) => {

        if (_status) {
            setModalVisible(false);
            getLists();
            message.success('List saved successfully.');
        } else {
            setErrorMsg(_result.error);
        }
    }

    const modifyList = (_form) => {
        setErrorMsg("");
        apiCall("titlelist/modify", _form, onModifyList)
    }

    const onFormFinish = (_f) => {
        if (edit) {
            modifyList(_f);
        } else {
            createList(_f);
        }
    }

    return (
        <>
            <Modal centered destroyOnClose={true} footer={null} onCancel={() => setModalVisible(false)} open={modalVisible}>
                <h3>{(edit) ? "Edit" : "Create"} List</h3>
                <div aria-hidden><Divider /></div>
                <Form onFinish={(_f) => onFormFinish(_f)} layout="vertical">
                    <ListForm list={list} />
                    <conditional.true value={errorMsg}>
                        <p style={{ "paddingBottom": "15px", "color": "#ff4d4f", "textAlign": "center" }} className="error">{errorMsg}</p>
                    </conditional.true>
                    <div style={{ "float": "right" }}>
                        <Space>
                            <Button onClick={() => setModalVisible(false)}>Cancel</Button>
                            <Button htmlType="submit" type="primary">Save</Button>
                        </Space>
                    </div>
                    <br clear="all" />
                </Form>
            </Modal>
            <div onClick={() => setModalVisible(true)}>
                {props.children}
            </div>
        </>
    )
}