import React, { useState } from "react";
import { apiCall } from "../../utilities/Api";
import { Button, Divider, Modal, Space, Spin, Typography, message } from "antd";
import ItemSummary from "../item/ItemSummary";

export default function ImpulsePurchases(props) {

    const { delivery_type = "ship", disabled = false, callback = () => { }, session = {}, setSession = () => { } } = props;

    // Temp session for adding/removing without re-rendering...
    const [tempSession, setTempSession] = useState({ ...session });
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [impluse, setImpulse] = useState(
        {
            headline: "",
            description: "",
            isbns_info: []
        })

    if (disabled) {
        return props.children
    }

    if (delivery_type === "ship" && !window.sitesettings.checkout_info.impulse_ship) {
        return props.children;
    }

    if (delivery_type === "pickup" && !window.sitesettings.checkout_info.impulse_pickup) {
        return props.children;
    }

    const fetchImpulse = () => {
        setOpen(true);
        setLoading(true);
        apiCall("impulse_buy/get", { delivery_type: delivery_type }, (_status, _result) => {
            setLoading(false)
            if (_status) {
                setImpulse(_result)
            } else {
                setOpen(false);
                if (_result.error === "Type doesn't exist") {
                    // continue to cart...
                    callback();
                } else {
                    message.error(_result.error);
                }


            }
        })
    }

    const drawItems = () => {
        {/* Don't draw giftcards */ }
        return impluse.isbns_info.filter(item => !item.info.is_giftcard).map(item => {
            return <ItemSummary.Impulse description={item.description} setTempSession={setTempSession} tempSession={tempSession} data={item.info} />
        })
    }

    const returnToCart = () => {
        // set session to temp session
        setSession({ ...tempSession })
    }

    return (
        <>
            <Modal onCancel={() => returnToCart()} closeIcon={<></>} footer={false} open={open}>
                <Spin tip="Loading..." spinning={loading}>
                    <div style={{ "minHeight": "200px" }}>
                        <Typography.Title level={4}>{impluse.headline}</Typography.Title>
                        <Typography.Paragraph>{impluse.description}</Typography.Paragraph>
                        <div aria-hidden><Divider dashed style={{ "margin": "15px 0px" }} /></div>
                        {drawItems()}
                        {(!loading && <div className="float-flex">
                            <div>&nbsp;</div>
                            <Space>
                                <Button onClick={() => returnToCart()}>Back to cart</Button>
                                <Button onClick={() => callback()} type="primary">Continue to checkout</Button>
                            </Space>
                        </div>)}
                    </div>
                </Spin>
            </Modal>
            <div style={{ "cursor": "pointer" }} onClick={() => fetchImpulse()}>
                <div style={{ "pointerEvents": "none" }}>  {props.children} </div>
            </div>
        </>
    )
}