import React from "react";
import { Button, Modal, Input, Space } from 'antd';
import { FacebookOutlined, ShareAltOutlined, TwitterSquareFilled } from '@ant-design/icons';
import { TwitterIcon } from "../../../util/Icons";

export default function EventSocial(props) {


    const { path, name, link_id = 0 } = props;

    const shareEvent = () => {

        Modal.info({
            icon: null,
            centered: true,
            title: name,
            width: "auto",
            style: { "padding": "0px" },
            content: <>
                <p>To share this with others, copy this link (e.g. Ctrl-C) and then paste it into an email.</p>
                <Input defaultValue={window.sitesettings.store_info.base_url + "/events/" + path} style={{ "marginBottom": "10px" }} />
            </>,
            okType: "default",
            okText: "Close"
        });

    }

    return (<>
        <br />
        <div style={{ "textAlign": "center" }}>
            <Space>
                {(window.sitesettings.share_twitter && <a target="_blank" rel="noopener noreferrer" href={"https://twitter.com/share?url=" + window.sitesettings.store_info.base_url + "/events/" + path}><Button className={"bm-lnk-" + link_id} type="text" icon={<TwitterIcon aria-hidden style={{ "fontSize": "23px" }} />} /></a>)}
                {(window.sitesettings.share_facebook && <a target="_blank" rel="noopener noreferrer" href={"https://www.facebook.com/sharer/sharer.php?u=" + window.sitesettings.store_info.base_url + "/events/" + path}><Button className={"bm-lnk-" + link_id} type="text" icon={<FacebookOutlined aria-hidden style={{ "fontSize": "25px" }} />} /></a>)}
                <Button className={"bm-lnk-" + link_id} onClick={() => shareEvent()} type="text" icon={<ShareAltOutlined aria-hidden style={{ "fontSize": "25px" }} />} />
            </Space>
        </div>
    </>
    )
}