import { DownOutlined } from '@ant-design/icons';
import { Button, Modal, Tooltip } from 'antd';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import GridDisplay from "../../../shared/GridDisplay";
import { apiCall } from '../../utilities/Api';
import conditional from "../../utilities/conditional";

export default function MoreSizes(props) {

    const data = props.data;
    const {is_large_print} = data; 
    const loc = useLocation();
    const close = () => { setModalVisible(false) }

    const [initLoaded, setInitLoaded] = useState(false)
    const [modalVisible, setModalVisible] = useState(false);
    const [moreSizes, setMoreSizes] = useState();
    const closeOnChange = () => {
        if (modalVisible) {
            close();
        }
    }

    const grabMoreSizes = () => {
        apiCall("title/getMoreSizes", {eisbn: data.eisbn}, (_status, _results) => {
            if(_status){
                setMoreSizes(_results.related[0]);
                setInitLoaded(true);
            }
        })
    }

    useEffect(closeOnChange, [loc])
    useEffect(grabMoreSizes, [])

    const errorMessage = () => {
        return (
            <div style={{ "textAlign": "center", "padding": "50px" }}>
                <h2>We're sorry!</h2>
                <p><span style={{ "fontSize": "20px", "color": "#444" }}>There seems to be no other sizes for {data.title}.</span>
                    <br />
                    Our database is not always perfectly linked up. We suggest trying the search to locate other sizes.
                </p>
            </div>
        )
    }

    const wrapPublishDate = (_date) => {
        if (data.is_forthcoming) {
            return (
                <span style={{ "color": "#BD5800" }}>
                    Releases: {moment(data.release_date * 1000).format('MMM DD, YYYY')}
                </span>)
        } else {
            return (<span>Published: {moment(data.release_date * 1000).format('MMM DD, YYYY')}</span>)
        }
    }

    return (
        (initLoaded && <>
            <conditional.true value={((moreSizes.rows.length > 0))}>
                <div className='shim'/>
                <Tooltip mouseEnterDelay={0.5} title={"View other sizes of this item."}>
                    <Button 
                        icon={<DownOutlined aria-hidden style={{ "float": "right", "padding": "15px 10px" }} />} 
                        onClick={() => setModalVisible(true)} 
                        size="large" 
                        style={{ "textAlign": "left", "height": "60px", "fontSize": "14px" }} 
                        block type="dashed">
                        <strong>
                            {data.title}
                        </strong><br />
                        <div className="see-other">
                            <em>See more sizes</em>
                        </div>
                        {(data.release_date && wrapPublishDate(data.release_date))}
                    </Button>
                </Tooltip>
            </conditional.true>
            <Modal 
                wrapClassName="siteModal" 
                width="90%" 
                title={"Other sizes of '" + data.title + "'"} 
                open={modalVisible} 
                onCancel={close} 
                destroyOnClose 
                closable={true}
                footer={null}>
                <GridDisplay 
                    popup={true} 
                    overwrite_error={errorMessage()} 
                    audiobook={false} 
                    data={data} 
                    show_count={false} 
                    method="title/getMoreSizes" 
                    wrap={false} 
                    args={{ "eisbn": data.eisbn }} />
            </Modal>
        </>)
    );

}