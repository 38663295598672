import React, { useState } from "react";
import { Checkbox, Form, Modal, Input } from 'antd';
import { apiCall } from "../../utilities/Api";
import { useSession } from "../../../util/Session";
import conditional from "../../utilities/conditional";
export default function AccountInsitutionToggle(props) {


    const { company, create, isInstitution = false, setIsInstitution } = props;

    const [, setSession] = useSession()

    // nonsense 
    const [showField, setShowField] = useState((company));

    // const toggleInstitution = (_checked) => {


    //     if (create) {
    //         setIsInstitution(_checked);
    //         if (_checked) {
    //             showHelp();
    //         }
    //         return;
    //     }

    //     apiCall("account/toggleInstitution", { is_institution: _checked }, (_status, _result) => {
    //         if (_status) {
    //             setIsInstitution(_checked);
    //             if (_checked) {
    //                 showHelp();
    //             }
    //             setSession(_result);

    //         } else {
    //             Modal.error({ title: "Error", content: "You must remove all linked accounts before changing your institution status." });
    //         }
    //     })
    // }

    const helpContent = () => {
        return (
            <>
                An account designated as an institution will have the ability to add other accounts made on this site as purchasers for the institution. This is helpful for teachers, librarians, or employees at businesses who are buying on behalf of an institution or even multiple institutions. The institution account will act as the master account, able to view all orders made by purchasers linked to the master account. Each purchasing account can have their own unique cart, lists, and account details. Purchasing accounts can also be toggled to buy on behalf of any institution they are linked to (for example, a teacher who works at multiple schools can be linked to multiple school accounts), or make personal purchases not for an institution.<br /><br />
                Once an institution account has been created, you can use the "Linked Accounts" option in the "Log In" box at the top of the site to add and link purchasers by email address. An email will be sent to the purchaser asking them to approve the link and enable purchasing on behalf of the institution. In the same "Linked Accounts" menu, the institution account also has the ability to remove linked purchasers.<br /><br />
                If purchasers have yet to create an account (personal or not), they will need to do so before they can be added by email address as a linked purchaser. A purchaser will not select the "Institution Account" checkbox during account creation, and instead go through account creation as a normal customer would.<br /><br />
                A personal account can be converted to an institution account by using the "Account Details" menu in the Log In box.
            </>
        )
    }

    const showHelp = () => {
        Modal.info({ okText: "Got it!", title: "Institution account", content: helpContent(), icon: false, closable: true, width: 600 })
    }


    // if (create) {

    //     return (
    //         <Form.Item initialValue={company} label="Institution Account" name="company" rules={[{ required: true }]}>
    //             <Input maxLength={80} />
    //         </Form.Item>
    //     )
    // }

    return (
        <>

            {/* <Form.Item name={(create) ? "is_institution" : null} help={
                    <small>This type of account will enable you to link other accounts made on this site that can purchase on behalf of this institution. <br />
                        <a onClick={() => showHelp()}>Learn more</a>
                    </small>
                } valuePropName="checked">
                    <Checkbox checked={isInstitution} onChange={(v) => toggleInstitution(v.target.checked)}>Institution account <small>(school, library, or business)</small> </Checkbox>
                </Form.Item>
                 */}

            {/* <conditional.true value={((create && isInstitution) || !create)}> */}


            <Form.Item noStyle initialValue={(showField)}>
                <Checkbox checked={showField} onChange={(v) => {setShowField(v.target.checked); setIsInstitution(v.target.checked);}}>Institution account? </Checkbox>
            </Form.Item>
            <div className="shim" />
            <div><small style={{ "color": "#e70d3d" }}>Only use this field if you are purchasing on behalf of an institution, school, or business.</small></div>
            <br />
            <conditional.true value={(showField)}>
                {(company !== false && <><Form.Item initialValue={company} label="Institution name" name="company" rules={[{ required: (isInstitution) }]}>
                    <Input aria-label="Institution name" placeholder="Institution name" maxLength={80} />
                </Form.Item><div className="shim" /><div className="shim" /></>)}
            </conditional.true>

          


            {/* </conditional.true> */}

            {/* <br /> */}
        </>
    )
}