import React from "react";
import { getColor } from "../util/CC";
export default function Youtube(props) {

    const template = window.sitesettings.template_info;
    const { content = {} } = props;

    const {
        location = "main",
        component = "blank_space",
        container = true,
        data = {}
    } = content;

    const {
        url = "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
        padding_x = 0,
        padding_y = 0,
        background_colour = 0,
        width = 600,
        aspect_ratio = "16/9",
        align = "center",
        full_width = false,
    } = data;


    const idFromURL = (url) => {
        const regex = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/)|youtu\.be\/)([\w-]{11})/;
        const match = url.match(regex);
        // If there's a match, return the video ID, else return null
        return match ? match[1] : null;
    }



    const drawRender = () => {

        let _align;
        switch (align) {
            case "center":
                _align = "0 auto"
                break;
            case "right":
                _align = "0 0 0 auto"
                break
            default:
                _align = "0 0 0 0"
        }


        let _wrapperStyle = {
            width: (full_width) ? "100%" : width + "px",
            maxWidth: "100%",
            padding: padding_y + "px " + padding_x + "px",
            margin: _align,
        }

        let _iframeStyle = {
            width: "100%",
            display: "block",
            height: "100%",
            aspectRatio: aspect_ratio
        }

        return (
            <div className={(container) ? "preview-container" : ""}>
                <div style={{ "background": getColor(background_colour, template) }}>
                    <div style={_wrapperStyle}>
                        <iframe style={_iframeStyle} width="100%" height="100%" src={"https://www.youtube.com/embed/" + idFromURL(url)} frameborder="0" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        )

    }


    return drawRender();
}