import React, { useState, useEffect } from "react";
import { getColor } from "../util/CC";
import { Button, Skeleton } from "antd";
import { apiCall } from "../components/utilities/Api";
import Hours from "./helpers/Hours";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import { useSession } from "../util/Session";

export default function ContactAndHours(props) {

    const template = window.sitesettings.template_info;
    const [session, setSession] = useSession(); 
    const { content = {} } = props;

    const {
        location = "main",
        component = "blank_space",
        container = true,
        data = {},
        injected_data = {}
    } = content;

    const {
        cover_image_cache = 0,
        eisbn = "",
        name = ""
    } = injected_data;


    const {
        container_colour = 0,
        background_image = "",
        background_fit_width = false,
        background_fit_height = false,
        background_tile_image = false,

        background_colour = 0,
        background_width = 700,
        padding_x = 10,
        padding_y = 10,
        address_preset = 0,
        phone_preset = 0,
        hours_heading_preset = 0,
        hours_heading = "Hours",
        hours_preset = 0,
        upcoming_heading_preset = 0,
        upcoming_heading = "Upcoming Dates",
        upcoming_preset = 0,
        link_id = 0,
        background_alt = "",
    } = data;

    const [contactData, setContactData] = useState({
        location_id: 0,
        san: "",
        ws_name: "",
        sc_name: "",
        altname: "",
        sc_addr_string: "",
        sc_addr_array: {
            address: "",
            city: "",
            prov: "",
            postal: "",
            country: ""
        },
        sc_email: "",
        sc_phone: "",
        sc_phone_2: "",
        sc_tollfree: "",
        lat_lng: {
            lat: "",
            lng: ""
        },
        ws_htmlhours: "",
        ws_contact_blurb: "",
        chain_number: 0,
        ws_hours: { title: "", weekdays: [], singles: [] }
    });

    const fetchContactAndHours = () => {
        apiCall("webstore/getContactAndHours", {}, (_status, _result) => {
            if (_status) {
                if(session.location_id){
                    _result = _result.filter(item => item.location_id === session.location_id)
                }
               
                if (_result.length > 0) {
                    setContactData(_result[0]);
                }

            }
        })
    }

    useEffect(fetchContactAndHours, [])

    const drawMarkers = () => {


        return (
            <Marker key={"marker"} position={[window.sitesettings.store_info.latitude, window.sitesettings.store_info.longitude]}>
                <Popup ><strong>{window.sitesettings.store_info.name}</strong><br />
                    <span>{window.sitesettings.store_info.address}</span><br />
                    <span>{window.sitesettings.store_info.city}, {window.sitesettings.store_info.province} {window.sitesettings.store_info.country}</span><br />
                    <span>{window.sitesettings.store_info.postal_code}</span><br />
                </Popup>
            </Marker>
        )

    }

    const parsePath = (url) => {
        const abs = /^(?:[a-z]+:)?\/\//i;
        return (abs.test(url)) ? url : "https://cdn1.bookmanager.com/i/" + window.san + url;
    }
    
    const drawRender = () => {

        let _sizeY = (background_fit_height) ? "100%" : "auto";
        let _sizeX = (background_fit_width) ? "100%" : "auto";

        const _bg_style = {
            backgroundColor: getColor(container_colour, template, "#fff"),
            backgroundImage: `url('${parsePath(background_image)}')`,
            backgroundRepeat: (background_tile_image) ? "repeat" : "no-repeat",
            backgroundSize: `${_sizeX} ${_sizeY}`,

        }
        
        const _style = {
            backgroundColor: getColor(background_colour, template, "#202020e6"),
            maxWidth: background_width + "px",
            padding: padding_y + "px " + padding_x + "px",
            margin: "0 auto",
            textAlign: "center",
        }

        let _default_address_style = {
            color: "#fff"
        }

        let _default_phone_style = {
            color: "#069"
        }


        return (
            <div role="img" aria-label={(background_image) ? background_alt : ""} style={_bg_style}>
            <div style={_style}>
                <div className="shim" style={{ "height": "20px" }} />
                <h3 className={"bm-txt-" + address_preset} style={(address_preset ? {} : _default_address_style)} >
                    {(contactData.sc_name && <div>{contactData.sc_name}</div>)}
                    {(contactData.sc_addr_array.address && <div>{contactData.sc_addr_array.address}</div>)}
                    {((contactData.sc_addr_array.city) && <div>{contactData.sc_addr_array.city}, {contactData.sc_addr_array.prov} {contactData.sc_addr_array.postal}</div>)}
                    {(contactData.sc_addr_array.country && <div>{contactData.sc_addr_array.country}</div>)}
                </h3>

                <div className="shim" style={{ "height": "20px" }} />

                <h3 className={"bm-txt-" + phone_preset} style={(address_preset ? {} : _default_phone_style)}>
                    {(contactData.sc_phone && <div><a className={(link_id) ? "bm-lnk-" + link_id : ""} style={{ "color": "inherit" }} href={"tel:" + contactData.sc_phone}>{contactData.sc_phone}</a></div>)}
                    {(contactData.sc_phone_2 && <div><a className={(link_id) ? "bm-lnk-" + link_id : ""} style={{ "color": "inherit" }} href={"tel:" + contactData.sc_phone_2}>{contactData.sc_phone_2}</a></div>)}
                    {(contactData.sc_tollfree && <div><a className={(link_id) ? "bm-lnk-" + link_id : ""} style={{ "color": "inherit" }} href={"tel:" + contactData.sc_tollfree}>toll free {contactData.sc_tollfree}</a></div>)}
                    {(contactData.sc_email && <div><a className={(link_id) ? "bm-lnk-" + link_id : ""} style={{ "color": "inherit" }} href={"mailto:" + contactData.sc_email}>{contactData.sc_email}</a></div>)}
                </h3>
                <div className="shim" style={{ "height": "20px" }} />

                <Hours ws_hours={contactData.ws_hours} template={template} data={data} />
                <div className="shim" style={{ "height": "20px" }} />
            </div>
            </div>
        )
    }


    return drawRender();
}