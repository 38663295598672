import React, { useEffect, useState } from "react"
import Html from "../components/utilities/Html";
import CSS from "../components/utilities/CSS";
import { apiCall } from "../components/utilities/Api";
export default function PrivacyPolicy() {

    const [banners, setBanners] = useState([])
    const [elements, setElements] = useState([])

    const onGetPrivacyPolicyPage = (_status, _results) => {
        if (_status) {
            setElements(_results.filter((itm) => itm.placement !== "banner"))
            setBanners(_results.filter((itm) => itm.placement === "banner"))
        }
    }

    const drawElements = (_arr) => {
        return _arr.map((_item, i) => {
            return (
                <div key={i}>
                    {(_item.header_visible && <h2>{_item.header}</h2>)}
                    {(_item.image && <img alt={_item.header} src={_item.image} />)}
                    {(_item.description && <Html html={_item.description} />)}
                </div>
            )
        })
    }

    const getPrivacyPolicyPage = () => {
        apiCall("webstore/getPrivacyPolicyPage", {}, onGetPrivacyPolicyPage)
    }

    useEffect(getPrivacyPolicyPage, []);

    return (
        <>
            <div>{drawElements(banners)}</div>
            <div className="container" style={{ "maxWidth": "800px" }}>
                <div style={{ "padding": "0px 20px" }}>
                    <CSS html=".container table{width:100% !important;}" />
                    <div>{drawElements(elements)}</div>
                </div>
            </div>
            <br />
        </>
    )
}