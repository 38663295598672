//Resources
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { $ } from 'react-jquery-plugin';
import { ConfigProvider, Spin } from 'antd';
//import "antd/dist/antd.variable.css";
import "./antd.css";
import './App.css';
import './accessibility.css';


// Components
import Cookies from 'js-cookie';
import Style from "./util/Style";
import Session from "./util/Session";
import SiteRouter from "./util/SiteRouter";
import { apiCall } from "./components/utilities/Api";
import { Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { isLocalStorageAvailable } from './util/Persistant';



// import {apiCall} from "./components/utilities/Api";


/**
 * App - Entry point for the consumer bookmanager website
 *  - Fill in contact details
 *  - Add password confirm
 *  - Validate form 
 *  - Auto login and update context
 *  - Submit, and move to account created message. 
 * @namespace Bookmanager
 * @property {object}  defaults               - The default values for parties.
 * @link /AccountForm
 * @param {string} [user] Optionally pre-fill fields with user data
 * 
 * @mermaid
 *   graph TD;
 *   
 */
function Bookmanager() {

  let temp_session = {
    "session_id": "",
    "logged_in": false,
    "user": [],
    "wishlist": [],
    "cart": {
      "checksum": "",
      "items": [],
      "summary": {
        "item_count": 0,
        "total_quantity": 0
      }
    },
    "pick_location": 0
  }

  const [session, setSession] = useState(temp_session);
  const sitesettings = window.sitesettings;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [queue, setQueue] = useState(0);
  const loadingAni = <LoadingOutlined style={{ fontSize: 24 }} spin />;


  // const checkLandingPage = (_logged_in) =>{
  //   if(window.sitesettings.landing_page_url){
  //     if(!Cookies.get("visited")){
  //       Cookies.set("visited", "true");

  //       if(window.sitesettings.landing_page_login_skip && _logged_in){
  //         // do nothing
  //       } else {
  //         // off you go
  //        // window.location.href = window.sitesettings.landing_page_url;
  //       }   
  //     }
  //   }
  // }

  const loadCode = (_string) => {
    let qualityRegex = /<script[^>]*>(.*?)<\/script>/gis,
      matches,
      qualities = [];
    while (matches = qualityRegex.exec(_string)) {

      // some stuff can't be decoded, so try first then try the unencoded below
      try {
        qualities.push(decodeURIComponent(matches[1]));
      } catch (err) {
        qualities.push(matches[1]);
      }

    }
    if (qualities.length > 0) {
      qualities.forEach(element => {
        if (element !== "") {
          try {
            window.eval(element);
          } catch (e) {
            console.log(e);
          }
        }
      });
    }
  }

  const loadScript = (_string) => {
    let qualityRegex = /<script.*?src="(.*?)"/gis,
      matches,
      qualities = [];
    while (matches = qualityRegex.exec(_string)) {

      // some stuff can't be decoded, so try first then try the unencoded below
      try {
        qualities.push(decodeURIComponent(matches[1]));
      } catch (err) {
        qualities.push(matches[1]);
      }
    }
    if (qualities.length > 0) {
      qualities.forEach(element => {
        if (!window.loadedScripts) {
          window.loadedScripts = [];
        }
        if (!window.loadedScripts.includes(element)) {

          if (element) {
            $.getScript(element, () => {
              loadCode(_string);
            });
          }

        }
        window.loadedScripts.push(element);
      });

    } else {

      loadCode(_string);
    }

  }


  const appendHeader = () => {
    let custom_head = (window.sitesettings.custom_head) ? window.sitesettings.custom_head : "";

    loadScript(custom_head);

    document.head.innerHTML += custom_head;
  }

  const getLocalSettings = () => {

    try {

      if (isLocalStorageAvailable() && localStorage.getItem(window.san)) {
        window.sitesettings = JSON.parse(localStorage.getItem(window.san));
        appendHeader();
        return true;
      } else {
        return false;
      }

    }
    catch (e) {

      setError(true);

    }


  }



  useEffect(() => {
    window.addEventListener('focus', onFocus);
    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, []);

  const onFocus = (e) => {


    if(window.payment === "global" && window.location.pathname.includes("/checkout/review")){
      return; 
    }
    // make sure settings have been fetched...
    if (window.hasOwnProperty("sitesettings") && window.sitesettings.hasOwnProperty("store_info")) {
      getSession();
    }
  }


  const onGetSession = (_status, _result) => {
    if (_status) {

      localStorage.clear();
      window.session_id = _result.session_id;
      Cookies.set("session_id", _result.session_id, { expires: 365 });



      setSession(_result);

    } else {
      setError(true);
    }
    setLoading(false);
  }

  const getSession = () => {
    apiCall("session/get", {}, onGetSession);
  }

  const onGetSettings = (_status, _result) => {

    if (_status) {
      setQueue(1);

      try {

        if (isLocalStorageAvailable()) {
          localStorage.setItem(window.san, JSON.stringify(_result));
        }

        window.sitesettings = _result;
        appendHeader();
      } catch (e) {
        setError(true);

      }


    } else {
      setError(true);
    }

  }

  const getSettings = () => {
    apiCall("store/getSettings", { "webstore_name": window.san }, onGetSettings);
  }


  const loadSession = () => {
    if (queue === 0) {

      try {

        if (isLocalStorageAvailable()) {
          localStorage.clear();
        }

        if (getLocalSettings()) {
          getSession();
        } else {
          getSettings();
        }

      } catch (e) {
        setError(true);
      }

    }
    if (queue === 1) {
      getSession();
    }
  }

  useEffect(loadSession, [queue])

  const displayLoading = () => {
    return (
      <>
        <Spin tip={<><br />Please wait...</>} indicator={loadingAni}>
          <div style={{ "height": "100vh" }}>&nbsp;</div>
        </Spin>
      </>
    );
  }

  const refresh = () => {
    Cookies.remove("session_id");
    window.location.reload();
  }

  const displayError = () => {
    return (<>&nbsp; There was an error - please ensure javascript and cookies are enabled. <Button type="link" onClick={() => refresh()}><strong>Click here to refresh</strong></Button></>)
  }

  const displayStatus = () => {
    if (error) return displayError();
    if (loading) return displayLoading();
    return display();
  }


  // ConfigProvider.config({
  //   theme: {
  //     primaryColor: '#000000',
  //     errorColor: "#e70d3d",
  //   },
  // });



  const display = () => {
    return (

      <Session value={[session, setSession]}>
        <Style />
        {/* <ConfigProvider> */}
          <Router basename={sitesettings.basename}>
            <SiteRouter sitesettings={sitesettings} />
          </Router>
        {/* </ConfigProvider> */}
      </Session>
    );
  }


  return displayStatus();





}

export default Bookmanager;
