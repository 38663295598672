import React, {useState, useEffect } from "react";
import { Form, Input, Button, Modal, Divider, Space, message } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import {useSession} from "../../../util/Session"
import { LockFilled } from '@ant-design/icons';
import AccountSave from "./AccountSave";
import AccountDelete from "./AccountDelete";
import AccountForm from "./AccountForm";
import AccountChangePassword from "./AccountChangePassword";
import AccountChangeEmail from "./AccountChangeEmail";
import {useHistory } from "react-router-dom";
import { apiCall } from "../../utilities/Api";

function AccountDetails() {

    // Session and Destructure
    const history = useHistory(); 
    const [context, setContext] = useSession(); 
    const [formError, setFormError] = useState(false);
    const { user = {} } = context;
    const { email_address = "" } = user;

    let hasToken = false;
    if (history.location.search) {
        if (history.location.search.includes("?token=")) {
            hasToken = history.location.search.replace("?token=", "");
        }
    }

    const [token] = useState(hasToken);
    const onEmailChange = (_status, _result) =>{

        if(_status){
            setContext({...context, "user" : _result.user})
            message.success('Email address updated successfully.', 3)
            // message changed
        } else {
            message.error(_result.error, 3)
        }

    }

    const emailChange = () =>{
        if(token){
            apiCall("account/modify", {token : token}, onEmailChange);
        }
    }

    useEffect(emailChange, []);

    const changeEmail = (_modal) => {

        let m = _modal.info({
            icon: null,
            centered: true,
            title: "Change Email",
            width: "auto",
            style: { "padding": "0px" },
            content: <AccountChangeEmail close={() => m.destroy()} email_address={email_address} callback={() => message.success('Email updated.', 2)} />,
            okButtonProps: { style: { "display": "none" } },
        });

    }

    const changePassword = (_modal) => {

        let m = _modal.info({
            icon: null,
            centered: true,
            title: "Change Password",
            width: "auto",
            style: { "padding": "0px" },
            content: <AccountChangePassword close={() => m.destroy()} email_address={email_address} callback={() => message.success('Password updated.', 2)} />,
            okButtonProps: { style: { "display": "none" } },
        });
    }


    const saveAccount = (_form, _modal) => {

        
        if (_form["aext"]) {
            _form["alt_phone_number_ext"] = _form["aext"];
            delete _form["aext"];
        }
        

        setFormError(false);

        let m = _modal.info({
            icon: null,
            centered: true,
            title: "Save Changes",
            width: "auto",
            style: { "padding": "0px" },
            content: <AccountSave form={_form} close={() => m.destroy()} email_address={email_address} callback={() => message.success('Account updated.', 2)} />,
            okButtonProps: { style: { "display": "none" } },
        });
    }

    const deleteAccount = (_modal) => {

        let x = _modal.info({
            icon: null,
            centered: true,
            title: "Delete Account",
            width: "auto",
            style: { "padding": "0px" },
            content: <AccountDelete close={() => x.destroy()} email_address={email_address} callback={() => message.success('Account Deleted.', 2)} />,
            okButtonProps: { style: { "display": "none" } },
        });
    }

    const [accountForm] = Form.useForm();

    const display = () => {

        const [modal, contextHolder] = Modal.useModal();

        return (

            <div >
               <h2>Account Details</h2>


               <div aria-hidden><Divider /></div>


                <Form onFinishFailed={() => setFormError(true)} form={accountForm} validateTrigger={['onChange', 'onBlur']} onFinish={(v) => saveAccount(v, modal)} layout="vertical">

                    <Form.Item label="Email Address">
                        <Input value={email_address} />
                    </Form.Item>
                    <Space>
                        {/* <AccountChangeEmail2 email_address={email_address}><Button icon={<LockFilled />} size="small"  style={{"fontSize" : "11px"}}>Change Email Address</Button></AccountChangeEmail2> */}
                        <Button onClick={() => changeEmail(modal)} icon={<LockFilled aria-hidden />} size="small" style={{ "fontSize": "11px" }}>Change Email Address</Button>
                        <Button onClick={() => changePassword(modal)} icon={<LockFilled aria-hidden />} size="small" style={{ "fontSize": "11px" }}>Change Password</Button>
                    </Space>
                    <div aria-hidden><Divider /></div>

                    <AccountForm form={accountForm} user={user} />

                    
                    {(formError && <p style={{ "paddingBottom": "15px", "color": "#ff4d4f", "textAlign": "center" }} className="error"><ExclamationCircleFilled /> There was an error. Please review form.</p>)}
                    <Space>
                        <Button icon={<LockFilled aria-hidden />} htmlType="submit" type="primary" >
                            Save Changes
                        </Button>

                        <Button icon={<LockFilled aria-hidden />} onClick={() => deleteAccount(modal)} type="danger" >
                            Delete Account
                            </Button>
                    </Space>
                </Form>
                {contextHolder}
            </div>

        );

    }


    return display();

}

export default AccountDetails;