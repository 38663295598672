import React, { useState, useEffect } from "react";
import { getColor } from "../util/CC";
import wrap from "../util/wrap";
import CalendarUI from "./helpers/CalendarUI";
import { Button, Col, Row, Space, Skeleton, message } from "antd";
import Upcoming from "./helpers/Upcoming";
import EventUI from "./helpers/EventUI";
import EventViewMore from "../components/components/events/EventViewMore";
import EventSocial from "../components/components/events/EventSocial";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { apiCall } from "../components/utilities/Api";
import EventRSVP from "../components/components/events/EventRSVP";
export default function SingleEventFeature(props) {

    const template = window.sitesettings.template_info;
    const history = useHistory();
    const { content = {} } = props;

    const {
        location = "main",
        component = "blank_space",
        container = true,
        injected_data = {},
        data = {}
    } = content;

    const {
        upcoming = [],
        current = []

    } = injected_data;

    const {
        width = 300,
        event = 0,
        background_colour = 0,
        padding_x = 20,
        padding_y = 20,
        title_preset = 0,
        description_preset = 0,
        date_preset = 0,
        button_type = 0,
        button_label = "Buy Books",
        button_align = "center",
        button_block = false,
        link_id = 0,
        next_event = false,
        categories = [],
    } = data;


    const [eventData, setEventData] = useState(injected_data);

    const fetchEvents = () => {


        if (next_event) {
            let _obj = {
                limit: 1,
                start_date: moment().format('YYYYMMDD')
            }

            if (categories) {
                _obj.categories = JSON.stringify(categories.map(item => item.id))
            }

            apiCall("event/v2/list", _obj, (_status, _result) => {
                if (_status) {
                    if (_result.rows.length > 0) {
                        setEventData(_result.rows[0])
                    } else {
                        setEventData({});
                    }
                } else {
                    message.error(_result.error)
                }
            })


        }


    }
    useEffect(fetchEvents, []);


    const drawImages = () => {

        const {
            info = {},
            image_url = "",
            title = "",
            books = []
        } = eventData;
        const { name = "", img_path = "", book = [] } = info;

        let _books = book;
        let _image = img_path;
        let _title = name;

        if (window.sitesettings.using_events_v2) {
            _books = books;
            _image = image_url;
            _title = title;
        }

        return (
            <div style={{ width: '100%', textAlign: 'center' }}>
                <div style={{ width: '100%' }}>
                    <img className="bm-img-link" onError={i => i.target.style.display = 'none'}  src={_image} alt={_title} style={{ width: '100%', height: 'auto' }} />
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                    {(_books.slice(0,2).map(item => {
                        return (
                            <div>
                                <EventViewMore event_id={eventData.id} type="book" data={_books} cc_event><img className="bm-img-link" onError={(e) => e.target.src = "https://bookmanager.com/i/sample_content/placeholder.jpg"} src={"https://bookmanager.com/i/m?b=" + item.eisbn} alt={item.title} style={{ width: '100%', height: 'auto' }} /></EventViewMore>
                            </div>
                        )
                    }))}
                </div>
            </div>
        )
    }

    const drawButtons = (eventData) => {

        // Buy Tickets
        // Buy Books
        // RSVP
        const {
            info = {},
            books = [],
            tickets = [],
            ticket_label = "",
            rsvp_label = "",
            attendance = false
        } = eventData;
        const { book = [], ticket = [] } = info;

        let _tickets = ticket;
        let _tickets_label = "Tickets"
        let _books = book;
        let _books_label = button_label;
        let _attendance = attendance;
        let _rsvp_label = "";

        // New Events
        if (window.sitesettings.using_events_v2) {
            _tickets = tickets;
            _tickets_label = (ticket_label) ? ticket_label : "Tickets";
            _books = books;
            _rsvp_label = (rsvp_label) ? rsvp_label : "RSVP";
        }

        return (
            <>
                <div className="shim" /><div className="shim" />
                <Space wrap>
                    {((_tickets.length > 0) &&  <EventViewMore event_id={eventData.id} type="ticket" data={_tickets} cc_event><Button className={"bm-btn-" + button_type}>{_tickets_label}</Button></EventViewMore>)}
                    {((_books.length > 0) &&  <EventViewMore event_id={eventData.id} type="book" data={_books} cc_event><Button className={"bm-btn-" + button_type}>{_books_label}</Button></EventViewMore>)}
                    {((_attendance === "request") && <EventRSVP event={eventData}><Button className={"bm-btn-" + button_type}>{_rsvp_label}</Button></EventRSVP>)}
                </Space>
            </>
        )
    }


    const drawRender = () => {
        let _bgStyle = {
            background: getColor(background_colour, template),
            padding: padding_y + "px " + padding_x + "px",
        }
        let _wrapperStyle = {
            maxWidth: "100%",
        }
        const drawEvent = () => {
            if (event || (next_event && eventData)) {
                return (
                    <>
                        <EventUI
                            link_id={link_id}
                            flourish={false}
                            event_id={event}
                            title_preset={title_preset}
                            date_preset={date_preset}
                            description_preset={description_preset}
                            event={eventData} />
                        {drawButtons(eventData)}
                    </>
                )
            } else {
                return (<Skeleton />)
            }
        }

       let {title = "", name = ""} = eventData;
       let _title = (title) ? title : name; 

        return (
            <div id={"bm-" + eventData.id} className={(container) ? "preview-container" : ""}>
                <div style={_bgStyle}>
                    <div style={_wrapperStyle}>
                        <Row gutter={[20, 40]} className="bm-wrap">
                            <Col flex={(width + "px")}>
                                {/* {drawEventImages()} */}
                                {drawImages()}
                                <br />
                                <div style={{ "textAlign": "center" }}>
                                    <EventSocial link_id={link_id} name={_title} path={eventData.id} />
                                </div>
                            </Col>
                            <Col flex="auto">
                                {drawEvent()}
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }



    return (
        <>
            {drawRender()}
        </>
    )
}


// import React from "react";
// import { getColor } from "../util/CC";
// import wrap from "../util/wrap";
// import CalendarUI from "./helpers/CalendarUI";
// import { Button, Col, Row, Space } from "antd";
// import Upcoming from "./helpers/Upcoming";
// import EventUI from "./helpers/EventUI";
// import EventViewMore from "../components/components/events/EventViewMore";
// import EventSocial from "../components/components/events/EventSocial";
// import { useHistory } from "react-router-dom";
// export default function SingleEventFeature(props) {

//     const template = window.sitesettings.template_info;
//     const history = useHistory();
//     const { content = {} } = props;

//     const {
//         location = "main",
//         component = "blank_space",
//         container = true,
//         injected_data = {},
//         data = {}
//     } = content;

//     const {
//         upcoming = [],
//         current = []
//     } = injected_data;

//     const {
//         width = 300,
//         event = 0,
//         background_colour = 0,
//         padding_x = 20,
//         padding_y = 20,
//         title_preset = 0,
//         description_preset = 0,
//         date_preset = 0,
//         button_type = 0,
//         button_label = "Buy Books",
//         button_align = "center",
//         button_block = false,
//         link_id = 0
//     } = data;

//     const drawEventImages = () => {

//         const { info = {}, id = 0 } = injected_data;
//         const { name = "", img_path = "", book = [] } = info;

//         let book_img = ""
//         if (book.length > 0) {
//             book_img = "https://cdn1.bookmanager.com/i/m?b=" + book[0].eisbn + "&cb=" + book[0].cover_image_cache
//         }

//         if (!event) {
//             return (
//                 <div style={{ "width": width + "px", "display": "flex", "flexDirection": "column" }}>
//                     <img onError={i => i.target.style.display = 'none'} style={{ width: "100%", objectFit: "cover", height: "auto" }} alt={name} src={" https://cdn1.bookmanager.com/i/sample_content/placeholder.jpg"} />
//                 </div>
//             )
//         }

//         return (
//             <div onClick={() => history.push("/events/" + id)} style={{ "width": width + "px", "display": "flex", "flexDirection": "column" }}>
//                 {(img_path && <img className="bm-img-link" onError={i => i.target.style.display = 'none'} style={{ width: "100%", objectFit: "cover", height: "auto", "cursor": "pointer" }} alt={name} src={img_path} />)}
//                 {(book_img && <img className="bm-img-link" onError={i => i.target.style.display = 'none'} style={{ width: "100%", objectFit: "cover", height: "auto", "cursor": "pointer" }} alt={name} src={book_img} />)}
//             </div>
//         )
//     }


//     const drawButton = () => {
//         const { info = {} } = injected_data;
//         const { name = "", img_path = "", book = [] } = info;
//         if (book.length > 0) {
//             return (<>
//                 {((button_label) && <>
//                     <div className="shim" /><div className="shim" />
//                     <EventViewMore type="book" data={book} cc_event>
//                         <Button className={"bm-btn-" + button_type}>{button_label}</Button>
//                     </EventViewMore>


//                 </>)}
//             </>)
//         } else {
//             return (<></>)
//         }
//     }

//     const drawTicket = () => {
//         const { info = {} } = injected_data;
//         const { name = "", img_path = "", ticket = [] } = info;

//         if (ticket.length > 0) {
//             return (<>
//                 {((button_label) && <>
//                     <div className="shim" /><div className="shim" />
//                     <EventViewMore type="ticket" data={ticket} cc_event>
//                         <Button className={"bm-btn-" + button_type}>Buy Tickets</Button>
//                     </EventViewMore>
//                 </>)}
//             </>)
//         } else {
//             return (<></>)
//         }
//     }

//     const drawRender = () => {
//         const { info = {}, id = 0 } = injected_data;
//         const { name = "", img_path = "", book = [] } = info;

//         let _bgStyle = {
//             background: getColor(background_colour, template),
//             padding: padding_y + "px " + padding_x + "px"

//         }

//         let _wrapperStyle = {
//             maxWidth: "100%",
//         }

//         return (
//             <div className={(container) ? "preview-container" : ""}>
//                 <div style={_bgStyle}>
//                     <div style={_wrapperStyle}>
//                         <Row gutter={[20, 40]} className="bm-wrap">
//                             <Col flex={(width + "px")}>
//                                 {drawEventImages()}
//                                 <div>
//                                     <EventSocial link_id={link_id} name={name} path={id} />
//                                 </div>
//                             </Col>
//                             <Col flex="auto">
//                                 <EventUI
//                                     link_id={link_id}
//                                     link={true}
//                                     event_id={event}
//                                     title_preset={title_preset}
//                                     date_preset={date_preset}
//                                     description_preset={description_preset}
//                                     event={injected_data} />
//                                 <div>
//                                     <Space size={0}>
//                                         {drawButton()}
//                                         {drawTicket()}
//                                     </Space>
//                                 </div>

//                             </Col>
//                         </Row>
//                     </div>
//                 </div>
//             </div>
//         )
//     }


//     return drawRender();
// }