import { Alert } from "antd";
import React from "react";
import LocationSelector from "../LocationSelector";

export default function LocationWarning() {



    if(!window.sitesettings.store_info.is_consolidated_multistore || !window.sitesettings.store_info.is_multistore){
        return(<></>);
    }



    return (<>

        <Alert style={{ "backgroundColor": "#e6f7ff", "border": "1px solid #91d5ff", "padding": "6px 15px", "textAlign" : "center" }} description={
            <div style={{ "opacity": "0.9" }}>You are currently ordering from the <strong>{window.sitesettings.store_info.name} - {window.sitesettings.store_info.address}</strong> location. To change your location <strong><LocationSelector wrap>click here</LocationSelector></strong></div>
        } type="info" />
        <div className="shim"></div><div className="shim"></div>
    </>
    )
}