
import React from "react";
import { Button } from 'antd';
import { PaypalIcon, VisaIcon, MasterCardIcon, AmericanExpressIcon, DiscoverIcon, ClearentIcon } from "../../../util/Icons";
import { CheckOutlined } from '@ant-design/icons';

export const getPaymentLabel = (_choice, _index) => {



    switch (_choice) {

        case "not_required":
            return ("Not applicable");

        case "pickup":
            return ("Pay at pick up");

        case "paypal":
            return ("Paypal");

        case "contact":
            return (window.sitesettings.checkout_info.contact_payment_text);

        case "clearent":
            return ("Clearent");

        case "chase":
            return ("Chase");

        case "global":
            return ("Global");

        case "credit_card":
            return ("Credit Card");

        default:
            return (<span key={_index}></span>)

    }


}


export const getCreditCardIcon = (_card, _key = "") => {

    switch (_card) {
        case "visa":
            return <VisaIcon key={_key} />

        case "mastercard":
            return <MasterCardIcon key={_key} />

        case "amex":
        case "american-express":
            return <AmericanExpressIcon key={_key} />

        case "discover":
            return <DiscoverIcon key={_key} />

        default:
            return (<></>)
    }
}

export const getAcceptedCreditCardIcons = () => {

    return (
        <>
            {window.sitesettings.checkout_info.accepted_credit_cards.map((_item, _index) => {
                return getCreditCardIcon(_item, _index);
            })}
        </>
    )
}

export const getPaymentButton = (_choice, _index, _type = "default", _disabled) => {


    switch (_choice) {

        case "pickup":
            return (<Button disabled={_disabled} type={_type} style={{ "height": "50px" }} block key={_index}><div style={{ "fontWeight": (_type === "selected") ? "bold" : "inherit" }}><CheckOutlined style={{ "marginLeft": "-20px", "fontSize": "16px", color: (_type === "selected") ? "#1f1f1f" : "rgb(0,0,0,0)" }} />&nbsp; {getPaymentLabel("pickup")}</div></Button>);

        case "paypal":
            // return(<><div style={{"marginTop" : "-5px"}}></div></>)
            return (<Button disabled={_disabled} type={_type} style={{ "height": "50px" }} block key={_index}><CheckOutlined style={{ "marginLeft": "-20px", "position": "relative", "top": "-15px", "left": "15px", "fontSize": "16px", color: (_type === "selected") ? "#1f1f1f" : "rgb(0,0,0,0)" }} />&nbsp;  <PaypalIcon /></Button>);

        case "contact":
            return (<Button disabled={_disabled} type={_type} style={{ "height": "50px" }} block key={_index}><div style={{ "fontWeight": (_type === "selected") ? "bold" : "inherit" }}><CheckOutlined style={{ "marginLeft": "-20px", "fontSize": "16px", color: (_type === "selected") ? "#1f1f1f" : "rgb(0,0,0,0)" }} />&nbsp;  {getPaymentLabel("contact")}</div></Button>);

        case "clearent":
            return (<Button disabled={_disabled} type={_type} style={{ "height": "50px" }} block key={_index}><div style={{ "fontWeight": (_type === "selected") ? "bold" : "inherit" }}><CheckOutlined style={{ "marginLeft": "-20px", "fontSize": "16px", color: (_type === "selected") ? "#1f1f1f" : "rgb(0,0,0,0)" }} />&nbsp;  Credit card</div></Button>);
            // return (<><Button disabled={_disabled} type={_type} style={{ "height": "50px" }} block key={_index}><h4 style={{ "fontWeight": (_type === "selected") ? "bold" : "inherit" }}><CheckOutlined style={{ "marginLeft": "-20px", "fontSize": "16px", color: (_type === "selected") ? "#1f1f1f" : "rgb(0,0,0,0)" }} />&nbsp;  <ClearentIcon /></h4></Button></>)
            //return (<Button type={_type} style={{"height" : "50px"}} block key={_index}><ClearentIcon /></Button>);

        case "global":
            return (<><Button disabled={_disabled} type={_type} style={{ "height": "50px" }} block key={_index}><div style={{ "fontWeight": (_type === "selected") ? "bold" : "inherit" }}><CheckOutlined style={{ "marginLeft": "-20px", "fontSize": "16px", color: (_type === "selected") ? "#1f1f1f" : "rgb(0,0,0,0)" }} />&nbsp;  Credit card</div></Button></>)
        // return (<Button type={_type} style={{"height" : "50px"}} block key={_index}><GlobalIcon /></Button>);

        case "no_global":
            return (<><Button disabled={_disabled} type={_type} style={{ "height": "50px" }} block key={_index}><div style={{ "fontWeight": (_type === "selected") ? "bold" : "inherit" }}><CheckOutlined style={{ "marginLeft": "-20px", "fontSize": "16px", color: (_type === "selected") ? "#1f1f1f" : "rgb(0,0,0,0)" }} />&nbsp;  Credit card</div></Button></>)
        // return (<Button type={_type} style={{"height" : "50px"}} block key={_index}><GlobalIcon /></Button>);

        case "chase":
            return (<><Button disabled={_disabled} type={_type} style={{ "height": "50px" }} block key={_index}><div style={{ "fontWeight": (_type === "selected") ? "bold" : "inherit" }}><CheckOutlined style={{ "marginLeft": "-20px", "fontSize": "16px", color: (_type === "selected") ? "#1f1f1f" : "rgb(0,0,0,0)" }} />&nbsp;  Credit card</div></Button></>)
        // return (<Button type={_type} style={{"height" : "50px"}} block key={_index}><GlobalIcon /></Button>);

        case "credit_card":
            return (<Button disabled={_disabled} type={_type} style={{ "height": "50px" }} block key={_index}><div style={{ "fontWeight": (_type === "selected") ? "bold" : "inherit" }}><CheckOutlined style={{ "marginLeft": "-20px", "fontSize": "16px", color: (_type === "selected") ? "#1f1f1f" : "rgb(0,0,0,0)" }} />&nbsp;  Credit card</div></Button>);

        default:
            return (<span key={_index}></span>)

    }


}

