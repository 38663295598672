// Resources
import React from "react";
import CustomListsLayout from "./CustomListsLayout";

function HomeLayout(props){
    
    let show_browse = window.sitesettings.styles.show_browse; 
    let show_features = window.sitesettings.styles.show_features; 
    
    return(
       <CustomListsLayout location={props.location} show_browse={show_browse} show_features={show_features} />
    );

}

export default HomeLayout;