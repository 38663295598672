import React, { useEffect, useState, useRef } from "react";
import Html from "../components/utilities/Html";
import { getColor } from "../util/CC";
import { Button } from "antd";
import { useMediaQuery } from 'react-responsive';

export default function TextOverParallax(props) {

    const template = window.sitesettings.template_info;
    const ismobile = useMediaQuery({ query: '(max-width: 716px)' })
    const { content = {} } = props;

    const {
        location = "main",
        component = "blank_space",
        container = true,
        data = {},
        injected_data = {}
    } = content;

    const {

        background_colour = 0,
        text_container = true,
        text_background_colour = 0,
        text_background_radius = 0,
        height = 600,
        speed = 1.5,
        width = 100,
        align = "center",
        text_align = "center",
        bg_padding_x = 0,
        bg_padding_y = 0,
        padding_x = 10,
        padding_y = 10,
        margin_x = 0,
        margin_y = 0,
        vertical_position = "center",
        heading = "Heading...",
        heading_preset = 0,
        paragraph = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tincidunt enim vel lectus egestas hendrerit. Sed at tempus ante. Interdum et malesuada fames ac ante ipsum primis in faucibus.",
        paragraph_preset = 0,
        button_url = "",
        button_label = "",
        button_type = 0,
        button_tab = true,
        button_block = false,
        link_id = 0,

        //New
        allow_mobile_overrides = false,
        mobile_vertical_position = "center",
        mobile_bg_height = 600,

        background_alt = ""
    } = data;

    const { background_image = "" } = injected_data;

    const linkOut = () => {
        if (button_url) {
            window.open(button_url, (button_tab) ? "_blank" : "_self");
        }
    }

    const parsePath = (url) => {
        const abs = /^(?:[a-z]+:)?\/\//i;
        return (abs.test(url)) ? url : "https://cdn1.bookmanager.com/i/" + window.san + url;
    }



    const [wh, setWH] = useState({ w: 1000, h: 1000 });

    useEffect(() => {

        const img = new Image();
        img.src = parsePath(background_image);
        img.onload = () => {
            setWH({ w: img.width, h: img.height })
        };

    }, [])



    const [offset, setOffset] = useState(0);
    const [visible, setVisible] = useState(false);
    const ref = useRef(null);
    const [ypos, setYpos] = useState(false);
    const [init, setInit] = useState(false);

    useEffect(() => {

        setInit(true);
        if (ref.current && !ypos) {
            const rect = ref.current.getBoundingClientRect();
            setYpos(rect.top)
        }

        const handleScroll = () => {
            if (ypos === false) { return; }
            setOffset(window.pageYOffset - ypos);
        };

        if (!visible) {
            if (ypos === false) { return; }
            setOffset(window.pageYOffset - ypos);
            setVisible(true);
        }
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);


    }, [ypos, init]);


    const drawRender = () => {

        let _margin;
        switch (align) {
            case "center":
                _margin = "0 auto";
                break;
            case "right":
                _margin = "0 0 0 auto";
                break;
            default:
                _margin = "0";
                break;
        }


        let _style = {
            margin: _margin,
            maxWidth: (1248 * (width / 100)) + "px",
            alignSelf: "flex-end",
        }

        let _text = {
            background: getColor(text_background_colour, template),
            borderRadius: text_background_radius + "px",
            padding: padding_y + "px " + padding_x + "px",
            margin: margin_y + "px " + margin_x + "px",
            textAlign: text_align,
        }

        let _align = {
            flexGrow: 1,
        }

        const isMobileSafari = () => {
            const userAgent = navigator.userAgent;
            return userAgent.includes('Safari') && userAgent.includes('Mobile');
        };

        const tweak = (isMobileSafari()) ? -(ypos * 5.85) : 0;

        let _parallax = {
            height: height + "px",
            width: '100%',
            opacity: (!visible) ? "0" : "100",
            display: "flex",
            alignItems: vertical_position,
            backgroundPosition: `50% ${(-(offset + tweak) / speed) + ((offset + tweak) / 2)}px`,
            backgroundSize: wh.w + "px " + wh.h + "px",
            backgroundImage: "url('"+parsePath(background_image)+"')",
            backgroundRepeat: 'no-repeat',
            transition: 'background-position 0.0s',
            willChange: 'background-position',
        }

        if (allow_mobile_overrides && ismobile) {
            _parallax.height = mobile_bg_height + "px";
            _parallax.alignItems = mobile_vertical_position;
            _parallax.overflow = "hidden";
        }






        return (
            <div className={(container) ? "preview-container" : ""}>
                <div style={{ "padding": bg_padding_y + "px " + bg_padding_x + "px", "background": getColor(background_colour, template) }}>

                    <div role="img" aria-label={background_alt} className="bm-parallax" ref={ref}
                        style={_parallax}
                    >
                        <div className={(text_container) ? "child-preview-container" : ""} style={_align}>
                            <div style={_style}>
                                <div style={_text}>
                                    {(heading &&
                                        <h2 style={{ "display": "block" }} className={"bm-txt-" + heading_preset} >
                                            {heading}
                                        </h2>
                                    )}
                                    {(paragraph &&
                                        <div className={"bm-txt-" + paragraph_preset} >
                                            <Html clear={false} className={"bm-lnkin-" + link_id} html={paragraph} />
                                        </div>
                                    )}

                                    {(button_label &&
                                        <div style={{ "display": "block" }}>
                                            <div className="shim" /><div className="shim" />
                                            <Button block={button_block} onClick={() => linkOut()} className={"bm-btn-" + button_type} >{button_label}</Button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }



    return drawRender();
}