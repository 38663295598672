import { Button, Col, Divider, Empty, EyeIcon, message, Modal, Radio, Result, Row, Skeleton, Space, Tooltip } from 'antd';
import Cookies from "js-cookie";
import PropTypes from "prop-types";
// Resources
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import AccountLoginForm from "../components/components/account/AccountLoginForm";
import CopyListToList from "../components/components/lists/CopyListToList";
// Components
import List from "../components/components/wrappers/List";
import { apiCall } from "../components/utilities/Api"
import Paginate from "../shared/Paginate"
import conditional from "../util/conditional";
import { useSession } from "../util/Session";
import { pluralize } from "../util/Utils";

GridDisplay.propTypes = {
    /** Optional Title */
    title: PropTypes.string,
    /** API Method */
    method: PropTypes.string.isRequired,
    /** API Args */
    args: PropTypes.object,
    /** Is login required  */
    require_login: PropTypes.bool,
    /** Error Message */
    error_message: PropTypes.string,
    /** Empty Mesage */
    empty_message: PropTypes.string,
    /** Login message */
    login_message: PropTypes.string,
    /** Can add all to cart */
    cartable: PropTypes.bool,
    /** Can add all to wishlist */
    wishlistable: PropTypes.bool,
    /** Wrap in container */
    wrap: PropTypes.bool,
    /** Wrap in container */
    show_count: PropTypes.bool,
    /** Optional page id */
    page_id: PropTypes.number,
}

GridDisplay.defaultProps = {
    /** Optional Title */
    title: "",
    /** API Args */
    args: {},
    /** Error default message */
    error_message: "There was an error.",
    /** Empty default message */
    empty_message: "No products.",
    /** Error default message */
    login_message: "You must be logged in to view.",
    /** Can users add all to cart */
    cartable: false,
    /** Can users add all to wishlist */
    wishlistable: false,
    /** Is login required  */
    require_login: false,
    /** Wrap in container  */
    wrap: true,
    /** Show count  */
    show_count: true,
    /** Optional page id */
    page_id: 1,
}


/**
 * Grid Display is a wrapper for list items
 * - Api Call
 * - Pagination
 * - Optional add all to Cart
 * - Optional add all to Wishlist
 * - Optional add audiobook item
 */

//  <Button  onClick={history.goBack}>Go back</Button>
export default function GridDisplay(props) {

    const {showSizeChanger = false, displayPicker = false, browse_link = ""} = props;

    // Loading & Results
    const [context, setContext] = useSession();
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(props.error_message);
    const [results, setResults] = useState((JSON.parse(localStorage.getItem("grid"))) ? JSON.parse(localStorage.getItem("grid")) : []);
    const [loading, setLoading] = useState((!props.cartable) ? (results.length === 0) ? true : false : false);
    const history = useHistory();
    const loc = useLocation();
    const { list_id } = useParams();

    // Pagination
    const [currentPage, setCurrentPage] = useState(props.page_id);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(26);
    const [dim, setDim] = useState(false);
    const [offset, setOffset] = useState((props.page_id - 1) * pageSize);
    const args = { "limit": pageSize, "offset": offset }
    const [rows, setRows] = useState(Cookies.get("rows") || "false");
    const updateRows = (_bool) => {
        Cookies.set("rows", _bool);
        setRows(_bool)
    }
    const [adding, setAdding] = useState(false);
    const addAllToCart = () => {

        setAdding(true);
        apiCall("titlelist/copyToCart", { list_id: list_id }, (_status, _result) => {
            if (_status) {
                setContext({...context, "cart" : _result.cart});
                message.success("Added to cart successfully.");
            } else {
                message.error(_result.error);
            }
            setAdding(false)
        });
    }

    const onAddAllToCart = (_status, _result) => {
        if (_status) {
            // returns cart + add to context
            setContext({ ...context, "wishlist": _result.wishlist, "cart": _result.cart })
        }
    }

    const onRemoveAllFromWishlist = (_status, _result) => {
        if (_status) {
            // returns cart + add to context
            setContext({ ...context, "wishlist": _result })
        }
    }

    const removeAllFromWishlist = () => {
        apiCall("wishlist/removeAll", {}, onRemoveAllFromWishlist);
    }

    const onPageChange = (_page, _pageSize) => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if (props.routable) {
            history.push(props.routable + _page);
        } else {
            onLocChange(_page);
        }
    }

    //draw pagination component, but made into a function so it's reuseable
    function drawPagination(){
        return(
            <Paginate
                loading={loading}
                paginate={{
                    current: currentPage,
                    offset: offset,
                    pagesize: pageSize,
                }}
                setPaginate={(e) => {
                    setPageSize(e.pagesize);
                    setCurrentPage(e.current);
                    setOffset(e.offset);
                    onPageChange(e.current, e.pageSize);
                }}
                count={results.row_count} 
            />
        )
    }

    const onResults = (_status, _result) => {
        if (!_status) {
            setError(true);
            setResults(_result);
            if(_result.error === "Bad list id"){
                setErrorMessage("You have provided a bad list ID.");
            }else if(_result.error === "invalid list id"){
                setErrorMessage("You have provided an incorrect list ID.");
            }
        }
        localStorage.setItem("grid", JSON.stringify(_result));
        setTotal(_result.row_count)
        setResults(_result);
        setDim(false);
        setLoading(false);
    }

    const onLocChange = (_page_id = props.page_id) => {
        setDim(true);
        if(props.method === "title/getMoreSizes"){
            apiCall(props.method, props.args, (_status, _result) => {
                if(_status){
                    setResults(_result.related[0]);
                    setDim(false);
                    setLoading(false);
                }
            });
        }else {
            apiCall(props.method, { ...args, ...props.args, "offset": ((_page_id - 1) * pageSize) }, onResults);
        }
        setCurrentPage(_page_id);
    }
    useEffect(onLocChange, [loc])

    const displayStatus = () => {
        if (loading) {
            return displayLoading();
        }
        if (error) {
            return displayError();
        }
        if (props.require_login && !context.logged_in) {
            return displayLogin();
        }
        if (results.row_count < 1) {
            if (props.method === "title/getBindingItems" && props.audiobook) {
                return display();
            }
            if (props.method === "title/getMoreSizes") {
                return display();
            }
            return displayEmpty();
        } else {
            return display();
        }
    }

    const displayError = () => {
        return (<div className={(props.wrap) ? "container" : ""} >
            {drawBackButton()}
            {props.title}
            <div aria-hidden><Divider /></div>
            <div style={{ "display": "block", "textAlign": "center" }}>
                <Result
                    status="error"
                    title={errorMessage}
                />
            </div>
        </div>
        )
    }

    const displayLoading = () => {
        return (<div className={(props.wrap) ? "container" : ""} >
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
        </div>
        )
    }

    const displayLogin = () => {
        return (<div className={(props.wrap) ? "container" : ""} >
            {drawBackButton()}
            {props.title}
            <div aria-hidden><Divider /></div>
            <div style={{ "display": "block", "textAlign": "center" }}>
                <Result
                    status="warning"
                    title={props.login_message}
                />
                <div style={{ "width": "350px", "margin": "0 auto" }}>
                    <AccountLoginForm />
                </div>
            </div>
        </div>
        )
    }

    const drawBackButton = () => {
        if (props.back_button) {
            return (<><Button style={{ "float": "right" }} onClick={history.goBack}>Go back</Button></>)
        } else {
            return (<></>)
        }
    }

    const displayEmpty = () => {
        if (props.overwrite_error) {
            return (<div className={(props.wrap) ? "container" : ""} >
                {props.overwrite_error}
            </div>)
        } else {
            return (<div className={(props.wrap) ? "container" : ""} >
                {drawBackButton()}
                {props.title}
                <Result
                    status="warning"
                    icon={<Empty aria-hidden description="" />}
                    title={props.empty_message}
                />
            </div>)
        }
    }

    const drawLogin = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let m = Modal.warn({
            icon: null,
            centered: true,
            title: <>
                <div className="shim"></div> <div className="shim"></div>
                <div style={{ "textAlign": "center", "margin": "0 auto" }}>
                    You must login or sign up to use this feature.</div>
                <div className="shim"></div><div className="shim"></div><div className="shim"></div>
                <div className="shim"></div><div className="shim"></div>
                <div style={{ "textAlign": "center", "margin": "0 auto" }}>
                    <Space size={5}><Button onClick={() => goto("/account/signup", true)} >Sign up</Button><Button onClick={() => goto("/account/login", true)}>Login</Button></Space>
                    <div className="shim"></div><div className="shim"></div><div className="shim"></div>
                    <div className="shim"></div>
                    <Divider style={{ "margin": "0px" }} />
                </div>
            </>
            ,
            content: <>
            </>,
            okText: "Close",
        });

        const goto = (_path, _redirect = false) => {
            m.destroy();
            if (_redirect) {
                window.loginRedirect = history.location.pathname;
            }
            history.push(_path)
        }
    }
    const allToCartButton = (e) => {
        if(!context.logged_in){
            return drawLogin(e)
        }
        Modal.confirm({
            icon: null,
            centered: true,
            title: "Add to cart",
            width: "auto",
            style: {"padding": "0px"},
            content:<><p>Are you sure you want to add {results.row_count} {pluralize(results.row_count, "item", "s")} from this list to your cart?</p></>,
            cancelText: "Cancel",
            okText: "Confirm",
            onOk: () => addAllToCart(),
        })
    }

    const display = () => {
        return (
            <div className={(props.wrap) ? "container" : ""} >
                {drawBackButton()}
                {props.title}
                {(props.show_count && <p style={{ "display": "block" }}>{results.row_count + " " + pluralize(results.row_count, "title", "s")} </p>)}
                <Row justify="end">
                    <Space size={"small"} wrap>
                        <conditional.true value={browse_link}>
                            <Button className='mbl-btn' onClick={() => history.push(browse_link)} style={{ "marginBottom": "20px" }}>Browse</Button>
                        </conditional.true>
                        <Button 
                         className='mbl-btn' 
                            loading={adding} 
                            style={{ "marginBottom": "20px" }} 
                            onClick={(e) => {allToCartButton(e)}} >Add all to cart
                        </Button>
                        {(context.logged_in && 
                            <CopyListToList list_id={list_id}>
                                <Button className='mbl-btn'  style={{ "marginBottom": "20px" }}>Copy to list</Button>
                            </CopyListToList>
                        )}
                        {(!context.logged_in && <Button onClick={(e) => drawLogin(e)} style={{ "marginBottom": "20px" }}>Copy to list</Button>)}
                    </Space>
                </Row>
                <div className='hide'> 
                    <conditional.true value={(displayPicker)}>
                        <Radio.Group onChange={(e) => updateRows(e.target.value)} style={{"padding": "10px"}} value={rows} buttonStyle="solid" size="small">
                            <Radio.Button value={"false"}>||</Radio.Button>
                            <Radio.Button value={"true"}>|||</Radio.Button>
                        </Radio.Group>
                    </conditional.true>
                </div>
                <Row justify="space-between">
                    <div className='hide'>
                        {(props.show_count && <Divider />)}
                    </div>
                    <div style={{"float": "right"}}>
                        {((total > pageSize || displayPicker) && drawPagination())}
                    </div>
                </Row>
                <div aria-hidden><Divider /></div>
                <div className={(dim) ? "wait" : "ready"}> 
                    <List 
                        displayPicker={displayPicker} 
                        rows={rows}  
                        popup={props.popup} 
                        routable={props.routable} 
                        audiobook={props.audiobook} 
                        data={results.rows} 
                    />
                </div>
                {(props.show_count && <div aria-hidden><Divider /></div>)}
                <div aria-hidden><Divider /></div>
                {((total > pageSize || displayPicker) && drawPagination())}
            </div>
        )
    }
    return displayStatus();
}