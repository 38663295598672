import { Button, message, Modal, Space } from "antd";
import React, { useState } from "react";
import { useSession } from "../../../util/Session";
import { apiCall } from "../../utilities/Api";

export default function AddListToCart(props) {

    const { list_id } = props;
    const [session, setSession] = useSession(); 
    const [modalVisible, setModalVisible] = useState(false);
    const [errorMsg, setErrorMsg] = useState("")

    const onCopyToCart = (_status, _result) => {
      
        if (_status) {
            setModalVisible(false);
            setSession({...session, "cart" : _result.cart});
        
            message.success("Added to cart successfully.");
        } else {
            setErrorMsg(_result.error);
            message.error(_result.error);
        }
    }

    const copyToCart = () => {
        if (errorMsg) {
            setErrorMsg("")
        }
       
        apiCall("titlelist/copyToCart", { list_id: list_id }, onCopyToCart);
    }



    if(!window.sitesettings.cart_enabled){
        return (<></>)
    }

    return (
        <>
            <Modal onCancel={() => setModalVisible(false)} destroyOnClose open={modalVisible} centered={true} width={300} footer={null} >
                <h3>Add to cart</h3>
                <p>Are you sure you want to add all items from this list to your cart?</p>
                <div style={{"float" : "right"}}>
                    <Space>
                        <Button onClick={() => setModalVisible(false)}>Cancel</Button>
                        <Button onClick={() => copyToCart()} type="primary">Confirm</Button>
                    </Space>
                </div>
                <br clear="both" />
            </Modal>
            <div onClick={() => setModalVisible(true)}>{props.children}</div>
        </>
    )

}