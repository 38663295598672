import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from 'antd';

export default function BuyAgainButton(props){

    const history = useHistory(); 
    const {eisbn, giftcard=false} = props; 
  
    const display = () =>{

        if(giftcard){
            return(<><Button size={"small"} onClick={() => history.push("/gift-cards")}><small>Buy Again</small></Button></>)
        } else {
            return(<><Button size={"small"} onClick={() => history.push("/item/" + eisbn)}><small>Buy Again</small></Button></>)
        }
    }

    return display(); 
   
}