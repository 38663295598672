import React, { useEffect, useState } from "react"
import { apiCall } from "../components/utilities/Api";
import { Divider } from 'antd';
import Html from "../components/utilities/Html";
import GiftCardBalance from "../components/components/giftcards/GiftCardBalance"
import GiftCardAddToCart from "../components/components/giftcards/GiftCardAddToCart"
import conditional from "../components/utilities/conditional";
export default function GiftCardsLayout() {

    const [results, setResults] = useState({});
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [banners, setBanners] = useState([])
    const [elements, setElements] = useState([])

    const onGiftcardPageInfo = (_status, _results) => {
        if (_status) {
            setResults(_results);
            setElements(_results.webstore_custom_elements.filter((itm) => itm.placement !== "banner"))
            setBanners(_results.webstore_custom_elements.filter((itm) => itm.placement === "banner"))
        } else {
            setError(true);
            setResults(_results);
        }
        setLoading(false);
    }

    const giftcardPageInfo = () => {
        apiCall("giftcard/get", {}, onGiftcardPageInfo)
    }

    useEffect(giftcardPageInfo, []);

    const displayError = () => {
        return (<></>)
    }

    const displayLoading = () => {
        return (<></>)
    }

    const displayCustomList = (customList) => {
        return customList.map((item) => {
            return (<div style={{ "maxWidth": (window.sitesettings.styles.fullscreen_banner) ? "100%" : "1248px" }} className="site-banner"><Html html={item.description} /></div>)
        })
    }


    const drawElements = (_arr) => {
        return _arr.map((_item, i) => {
            return (
                <div key={i}>
                    {(_item.header_visible && <h2>{_item.header}</h2>)}
                    {(_item.image && <img alt={_item.header} src={_item.image} />)}
                    {(_item.description && <Html html={_item.description} />)}
                </div>
            )
        })
    }

    const displayResults = () => {
        return (
            <>
                {displayCustomList(banners)}
                <div className="container" style={{ "maxWidth": "800px" }}>
                    <div>{(results.show_gc_balanace_checker && <GiftCardBalance />)}</div>
                    {/* <conditional.true value={(window.sitesettings.store_info.giftcards_enabled)}> */}
                        <div>
                            {(results.store_giftcards.map((item, i) => <GiftCardAddToCart title={item.name} item={item} cover_image_cache={item.cover_image_cache} eisbn={item.eisbn} key={i} image={item.eisbn} />))}
                        </div>
                        <div aria-hidden><Divider /></div>
                    {/* </conditional.true> */}
                    {/* <conditional.true value={(results.show_html_blurb)}> */}
                        <div>
                            {drawElements(elements)}
                        </div>
                    {/* </conditional.true> */}


                </div>
            </>
        )
    }

    const displayStatus = () => {
        if (loading) return displayLoading();
        if (error) return displayError();
        return displayResults();
    }

    return displayStatus();

}