import React, { useState } from "react";
import { Button, Space, Modal, message } from "antd";
import { apiCall } from "../../utilities/Api";

export default function DeleteList(props) {

    const { list_id = "", setLists, setTotal } = props;
    const [modalVisible, setModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const onDeleteList = (_status, _result) => {
        if (_status) {
            setLists(_result);
            setTotal(_result.row_count);
            message.success("List deleted successfully.")
        }
        setLoading(false);
    }

    const deleteList = () => {
        setLoading(true);
        apiCall("titlelist/delete", { list_id: list_id }, onDeleteList)
    }

    return (
        <>
            <Modal onCancel={() => setModalVisible(false)} destroyOnClose open={modalVisible} centered={true} width={310} footer={null}>
                <h3>Delete List</h3>
                <p>Are you sure you wish to delete this list?</p>
                <div className="shim"></div>
                <div style={{ "float": "right" }}>
                    <Space>
                        <Button onClick={() => setModalVisible(false)}>Cancel</Button>
                        <Button loading={loading} onClick={() => deleteList()} type="danger" htmlType="submit">Delete</Button>
                    </Space>
                </div>
                <br clear="all" />
            </Modal>
            <div onClick={() => setModalVisible(true)}>
                {props.children}
            </div>
        </>
    );
}