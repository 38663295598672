import React, { useState } from "react";
import { Menu, Dropdown, Button, Input, Form, Drawer, Divider, Space } from 'antd';
import { PlusCircleOutlined, CheckCircleFilled, CloseOutlined } from '@ant-design/icons';
import { apiCall } from "../../utilities/Api";
import { useHistory } from "react-router";
import { ListIcon, StarIcon, StarStrokeIcon } from "../../../util/Icons";
import { useForm } from "antd/lib/form/Form";
export default function AddToList(props) {

    const { eisbn = "", popup = false } = props;
    const history = useHistory();
    const [menuVisible, setMenuVisible] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [list, setList] = useState([]);
    const [fav, setFav] = useState([]);
    const [loading, setLoading] = useState(false);
    const [form] = useForm();

    //George (09Jun2023) - put all the same code in one place, to make it reusable
    function filterLists(_result){
        setFav(_result.rows.filter(item => item.can_delete !== true));
        setList(getMergedLists(_result));
    }

    //George (09Jun2023) - do the work somewhere else to pass back the combined arrays cleanly
    function getMergedLists(_result){
        //lists that have the current title
        let addedList = _result.rows.filter(item => item.can_delete && item.contains_eisbn);
        //lists that do not have the current title
        let removedList = _result.rows.filter(item => item.can_delete && !item.contains_eisbn);
        return addedList.concat(removedList);
    }

    const onGetLists = (_status, _result) => {
        if (_status) {
            setMenuVisible(true);
            filterLists(_result);
        }
        setLoading(false);
    }

    const getLists = () => {
        if (list.length === 0) {
            apiCall("titlelist/getLists", { eisbn: eisbn }, onGetLists);
        } else {
            setMenuVisible(true);
        }
    }

    const updateList = (_status, _result) => {
        if (_status) {
            filterLists(_result)
        }
    }

    const onCreateList = (_status, _result) => {
        if (_status) {
            form.resetFields();
            filterLists(_result)
        }
    }

    const createList = (_f) => {
        apiCall("titlelist/create", { name: _f.name, description: "", eisbn: eisbn }, onCreateList);
    }

    const toggle = (e, _list_id, _eisbn, _contains_eisbn) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (_contains_eisbn) {
            apiCall("titlelist/removeItemFromList", { list_id: _list_id, eisbn: eisbn }, updateList)
        } else {
            apiCall("titlelist/addItemToList", { list_id: _list_id, eisbn: eisbn }, updateList)
        }
    }

    const drawList = (_mobile = false) => {


        if (_mobile) {
            return list.map((item, index) => {
                return (<Menu.Item key={item.list_id} style={{ "padding": "0" }}><div style={{ "padding": "5px 12px", "maxWidth": "260px", "overflow": "hidden", "textOverflow": "ellipsis" }} onClick={(e) => toggle(e, item.list_id, eisbn, item.contains_eisbn)}>{(item.contains_eisbn) ? <CheckCircleFilled style={{ "color": "#3C8617" }} /> : <PlusCircleOutlined style={{ "color": "#aaa" }} />} &nbsp; {item.name}</div></Menu.Item>)
            })
        } else {

            return list.map((item, index) => {
                return (
                    <Button block type="text" key={item.list_id} style={{ "padding": "0", "textAlign": "left" }}><div style={{ "padding": "5px 12px", "overflow": "hidden", "textOverflow": "ellipsis" }} onClick={(e) => toggle(e, item.list_id, eisbn, item.contains_eisbn)}>{(item.contains_eisbn) ? <CheckCircleFilled style={{ "color": "#3C8617" }} /> : <PlusCircleOutlined style={{ "color": "#aaa" }} />} &nbsp; {item.name}</div></Button>
                )
            })
        }

    }

    const drawFav = (_mobile = false) => {



        if (_mobile) {
            return fav.map((item, index) => {
                if (item.list_id === "LMAqvWHk6OME") {
                    return (<></>)
                }
                return (<Menu.Item key={item.list_id} style={{ "padding": "0" }}><div style={{ "padding": "5px 12px", "maxWidth": "260px", "overflow": "hidden", "textOverflow": "ellipsis" }} onClick={(e) => toggle(e, item.list_id, eisbn, item.contains_eisbn)}>{(item.contains_eisbn) ? <StarIcon style={{ "color": "#3C8617" }} /> : <StarStrokeIcon style={{ "color": "#aaa" }} />} &nbsp; {(!window.sitesettings.store_info.is_american) ? "Favourite" : "Favorite"}{(item.contains_eisbn) ? "d" : ""}</div></Menu.Item>)
            })
        } else {

            return fav.map((item, index) => {
                if (item.list_id === "LMAqvWHk6OME") {
                    return (<></>)
                }
                return (<Button block type="text" key={item.list_id} style={{ "padding": "0", "textAlign": "left" }}><div style={{ "padding": "5px 12px", "overflow": "hidden", "textOverflow": "ellipsis" }} onClick={(e) => toggle(e, item.list_id, eisbn, item.contains_eisbn)}>{(item.contains_eisbn) ? <StarIcon style={{ "color": "#3C8617" }} /> : <StarStrokeIcon style={{ "color": "#aaa" }} />} &nbsp; {(!window.sitesettings.store_info.is_american) ? "Favourite" : "Favorite"}{(item.contains_eisbn) ? "d" : ""}</div></Button>)
            })
        }

    }

    const goto = (e, _path) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        history.push(_path);
    }

    const submit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        form.submit();
    }

    const DrawForm = (p, _mobile) => {

        let _style = { "margin": "0px 5px", "width": "220px" };
        if (_mobile) {
            _style = { "padding": "10px" };
        }
        return (
            <Form form={form} onFinish={(_f) => createList(_f)}>
                <Form.Item rules={[{ message: "Name is required.", required: true }]} name="name" noStyle >
                    <Input maxLength={80} onClick={(e) => e.preventDefault()} addonAfter={<Button onClick={(e) => submit(e)} htmlType="submit" type="text" size="small" style={{ "margin": "-10px", "height": "28px" }} ><small>Save</small></Button>} placeholder="New list name..." style={_style}></Input>
                </Form.Item>
            </Form>
        )
    }


    const closeMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setMenuVisible(false)
    }
    const menu = (
        <Menu style={{ "background": "#fff" }}>
            <Menu.ItemGroup title={<></>} style={{ "maxHeight": "198px", "overflow": "auto", "margin": "0px", "padding": "0px" }}>
                <DrawForm />
            </Menu.ItemGroup>
            {drawFav()}
            <Menu.Divider />
            <Menu.ItemGroup title={<></>} style={{ "maxHeight": "198px", "overflow": "auto", "margin": "0px", "padding": "0px" }}>
                {drawList()}
            </Menu.ItemGroup>
            {((!loading && list.length > 0) && <Menu.Divider />)}



            {((!loading && list.length > 0) &&
                <Menu.ItemGroup title={<></>} style={{ "maxHeight": "198px", "overflow": "auto", "margin": "0px", "padding": "0px" }}>
                    <Space style={{ "padding": "0px 5px" }}>
                        <Button onClick={(e) => goto(e, "/account/lists")}>Go to my lists</Button>
                        <Button type="primary" onClick={(e) => closeMenu(e)}>Done <CloseOutlined /></Button>
                    </Space>
                </Menu.ItemGroup>



            )}





            {((!loading && list.length === 0) &&

                <Menu.Item style={{ "padding": "0" }}><div style={{ "padding": "5px 12px" }} onClick={(e) => goto(e, "/account/lists")}>No lists created - Manage lists</div></Menu.Item>

            )}
        </Menu>
    );


    const mobilemenu = (

        <>
            <div style={{ "textAlign": "center" }}>
                {DrawForm(null, true)}
            </div>

            <div style={{ "padding": "10px" }}>
                <div aria-hidden><Divider style={{ "marginTop": "0px", "marginBottom": "5px" }} /></div>
                {drawFav()}
                <div aria-hidden><Divider style={{ "marginTop": "10px", "marginBottom": "10px" }} /></div>
                {drawList()}

                {((!loading && list.length > 0) && <div aria-hidden><Divider /></div>)}
                <Space>

                    {((!loading && list.length > 0) &&
                        <Button style={{ "padding": "0" }}><div style={{ "padding": "5px 12px" }} onClick={(e) => goto(e, "/account/lists")}>Go to my lists</div></Button>
                    )}

                    {((!loading && list.length === 0) &&
                        <Button style={{ "padding": "0" }}><div style={{ "padding": "5px 12px" }} onClick={(e) => goto(e, "/account/lists")}>No lists created - Manage lists</div></Button>
                    )}

                    {((!loading && list.length > 0) &&
                        <Button type="primary" style={{ "padding": "0" }}><div style={{ "padding": "5px 12px" }} onClick={() => setDrawerOpen(false)}>Done <CloseOutlined /></div></Button>
                    )}


                </Space>
            </div>
            <div className="shim"></div><div className="shim"></div><div className="shim"></div>
        </>


    );



    const openDrawer = (e) => {

        e.preventDefault();
        e.stopPropagation();

        setDrawerOpen(true);

        if (list.length === 0) {
            apiCall("titlelist/getLists", { eisbn: eisbn }, onGetLists);
        }
    }

    let height = 240;
    if (list.length > 0) {

        if (list.length > 7) {
            height = height + (7 * 32);
        } else {
            height = height + (list.length * 32);
        }

    }

    return (
        <>
            {/* Desktop */}

            <div className="hide">
                <Dropdown
                    zIndex={(popup) ? 1000 : 0}
                    overlay={menu}
                    onVisibleChange={(e) => (!e && setMenuVisible(false))}
                    open={menuVisible}
                    trigger={['click']}
                    overlayClassName="sidebar"
                    arrow
                    overlayStyle={{ "maxWidth": "250px", "backgroundColor": "transparent" }}
                >
                    <Button style={{ "padding": "0px" }} onClick={(e) => getLists(e)} icon={<ListIcon aria-hidden style={{ "fill": "#595959" }} />} type="text"><small> Lists</small></Button>
                </Dropdown>
            </div>

            {/* Mobile */}
            <div className="show">
                <Drawer title={"Add to lists"} height={height + "px"} onClose={() => setDrawerOpen(false)} placement="bottom" open={drawerOpen} className="show">
                    {mobilemenu}
                </Drawer>
                <Button style={{ "padding": "0px" }} onClick={(e) => openDrawer(e)} icon={<ListIcon aria-hidden style={{ "fill": "#595959" }} />} type="text"><small> Lists</small></Button>
            </div>
        </>
    )
}
