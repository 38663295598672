import React from "react";
import Html from "../components/utilities/Html";
import { getColor } from "../util/CC";
import { Button } from "antd";
import { useMediaQuery } from 'react-responsive';

export default function TextOverVideo(props) {

    const template = window.sitesettings.template_info;
    const ismobile = useMediaQuery({ query: '(max-width: 716px)' })
    const { content = {} } = props;

    const {
        location = "main",
        component = "blank_space",
        container = true,
        data = {}
    } = content;

    const {
        background_video = "https://bookmanager.com/i/sample_content/placeholder.mp4",
        background_colour = 0,
        text_container = true,
        text_background_colour = 0,
        text_background_radius = 0,
        width = 100,
        align = "center",
        textAlign = "center",
        padding_x = 10,
        padding_y = 10,
        bg_padding_x = 10,
        bg_padding_y = 10,
        heading = "Text Over Video",
        heading_preset = 0,
        paragraph = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tincidunt enim vel lectus egestas hendrerit. Sed at tempus ante. Interdum et malesuada fames ac ante ipsum primis in faucibus.",
        paragraph_preset = 0,
        button_url = "",
        button_label = "",
        button_type = 0,
        button_tab = true,
        button_block = false,
        link_id = 0,

        // NEW 
        bg_height_enabled = false,
        bg_height = 500,
        align_content = "start",

        allow_mobile_overrides = false,
        mobile_align_content = "start",
        mobile_bg_height_enabled = false,
        mobile_bg_height = 500,

    } = data;

    const linkOut = () => {
        if (button_url) {
            window.open(button_url, (button_tab) ? "_blank" : "_self");
        }
    }

    const drawRender = () => {

        let _video = {
            position: "absolute",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            overflow: "hidden",
            zIndex: -1,
            backgroundColor: getColor(text_background_colour, template),
        }

        let _style = {
            backgroundColor: getColor(text_background_colour, template),
            borderRadius: text_background_radius + "px",
            padding: padding_y + "px " + padding_x + "px",
            maxWidth: (1248 * (width / 100)) + "px",
            alignSelf: "flex-end",
            textAlign: textAlign
        }

        let bg_style = {
            zIndex: 1,
            position: "relative",
            overflow: "hidden"
        }

        let _align = {
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: align_content,
        }

        if (bg_height_enabled && !ismobile) {
            bg_style.height = bg_height + "px";
            bg_style.overflow = "hidden";

        }

        if (allow_mobile_overrides && ismobile) {
            if (mobile_bg_height_enabled) {
                bg_style.height = mobile_bg_height + "px";
                bg_style.overflow = "hidden";
                _align.justifyContent = mobile_align_content
            }
        }

        switch (align) {
            case "center":
                _style.alignSelf = "center";
                break;
            case "right":
                _style.alignSelf = "flex-end";
                break;
            default:
                _style.alignSelf = "flex-start";
                break;
        }


        return (
            <div className={(container) ? "preview-container" : ""}>


                <div style={bg_style}>
                    <video style={_video} className="background-video" autoPlay loop muted>
                        <source src="https://cdn1.bookmanager.com/i/sample_content/placeholder.mp4" type="video/webm" />
                        Your browser does not support the video tag.
                    </video>

                    <div style={{ "height": "100%", "padding": bg_padding_y + "px " + bg_padding_y + "px" }}>

                        <div className={(text_container) ? "child-preview-container" : ""} style={_align}>
                            <div style={_style}>

                                {(heading &&
                                    <h2 style={{ "display": "block" }} className={"bm-txt-" + heading_preset} >
                                        {heading}
                                    </h2>
                                )}
                                {(paragraph &&
                                    <div className={"bm-txt-" + paragraph_preset} >
                                        <Html clear={false} className={"bm-lnkin-" + link_id} html={paragraph} />
                                    </div>
                                )}

                                {(button_label &&
                                    <div style={{ "display": "block" }}>
                                        <div className="shim" /><div className="shim" />
                                        <Button block={button_block} onClick={() => linkOut()} className={"bm-btn-" + button_type} >{button_label}</Button>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
                {/* <pre>{JSON.stringify(_style, null, 2)}</pre> */}
            </div>
        )
    }



    return drawRender();
}