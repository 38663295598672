import React from "react";
import { getColor } from "../util/CC";
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import { Icon } from 'leaflet'
import marker from "../media/marker.png"
import { useSession } from "../util/Session";

export default function MapStoresLocation(props) {

    const template = window.sitesettings.template_info;
    const [session, setSession] = useSession();
    const { content = {} } = props;

    const icon = new Icon({
        iconUrl: marker,
        iconSize: [60, 60], // size of the icon
        iconAnchor: [30, 45], // point of the icon which will correspond to marker's location
        popupAnchor: [0, -76] // point from which the popup should open relative to the iconAnchor
    })


    const {
        location = "main",
        component = "map_stores_location",
        container = true,
        data = {},
        injected_data = []
    } = content;

    // Defaults (kelowna)
    let latitude = 49.88609;
    let longitude = -119.4941869;

    // Single store
    if (injected_data.length === 1) {
        latitude = injected_data[0].lat;
        longitude = injected_data[0].lng;
    }

    // Multistore
    if (injected_data.length > 1) {
        let _loc = (session.location_id) ? session.location_id.toString() : window.sitesettings.store_info.id.toString();  
        let _index = window.sitesettings.store_info.multistore_info.findIndex(item => item.id.toString() === _loc);
        if (_index < 0) { _index = 0; }
        latitude = injected_data[_index].lat;
        longitude = injected_data[_index].lng;
    }

    const drawRender = () => {
        return (
            <div className={(container) ? "preview-container" : ""}>
                <div style={{ "height": "400px", "marginBottom": "-20px", "position": "relative", "overflow": "hidden" }}>
                    <Map scrollWheelZoom={false} style={{ "height": "380px", "overflow": "hidden" }} center={{ lat: latitude, lng: longitude }} zoom={14}>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' />
                        <Marker icon={icon} key={"marker"} position={[latitude, longitude]} >
                            <Popup ><strong>{window.sitesettings.store_info.name}</strong><br />
                                <span>{window.sitesettings.store_info.address}</span><br />
                                <span>{window.sitesettings.store_info.city}, {window.sitesettings.store_info.province} {window.sitesettings.store_info.country}</span><br />
                                <span>{window.sitesettings.store_info.postal_code}</span><br />
                            </Popup>
                        </Marker>

                    </Map>
                </div>
            </div >
        )
    }

    return drawRender();
}