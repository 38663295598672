import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useSession } from "../util/Session";
import { apiCall } from "./utilities/Api";
import Cookies from 'js-cookie';

export default function PermissionSelector(props){

   
    // /customer/account/getAvailableCorps
    // /customer/account/setCorp
    // v1/transfer

    const [session, setSession] = useSession(); 
    const [loading, setLoading] = useState(false)
    const [selectedCorp, setSelectedCorp] = useState(session.user.corp_id);
    const [availableCorps, setAvailableCorps] = useState([]); 
    const drawOptions = () =>{

        return availableCorps.map((item, index) =>{

            let n = item.name; 
            if(item.is_institution && item.company){
                n = item.company;
            }
            return(<Select.Option key={index} value={item.id}>{n}</Select.Option>)
        })
    }

    const onGetAvailableCorps = (_status, _result) =>{
        if(_status){  
            
            if(_result.length>1){
                props.setHasAccountLink(true);
            }
            setAvailableCorps(_result)
        }
    }

    const getAvailableCorps = () =>{
        apiCall("account/getAvailableCorps",{}, onGetAvailableCorps)
    }

    useEffect(getAvailableCorps, []);


    const changeCorp = (_corp) =>{
        setLoading(true);
        setSelectedCorp(_corp);
        setCorp(_corp);
    }

    const onTransfer = (_status, _result) =>{
        if(_status){
            if(_result["STG"]){
                Cookies.set("STG", _result["STG"], { expires: 365 });
            }
            Cookies.set("want_v1", 1, { expires: 365 });
            setLoading(false);
            window.location.reload();
        }
    }

    const onSetCorp = (_status, _result) =>{
        if(_status){
            if(_result.is_personal || _result.is_institution){
                setLoading(false);
                    apiCall("session/get", {}, (_status, _result) =>{
                        if(_status){
                            setSession(_result);
                            window.location.reload();
                        }
                    })  
            } else {
                apiCall("v1/transfer",{}, onTransfer)
            }   
        }
    }

    const setCorp = (_corp) =>{

        let check = availableCorps.find(i => i.id===_corp);
        if(check.type !== "customer"){
            apiCall("v1/changeCorpAndTransfer",{corp_id : _corp}, onTransfer)
        } else {
            apiCall("account/setCorp",{corp_id : _corp}, onSetCorp)
        }

    }

    return(
        <>

            {((availableCorps.length>1) &&  <><Select loading={loading} onChange={(_v) => changeCorp(_v)} style={{ width: '100%' }}  value={selectedCorp}>{drawOptions()}</Select>
            <br clear="all" />
      <div className="shim"></div><div className="shim"></div>
            </>)}
           
        </>
    )
}